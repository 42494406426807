import React, { useState } from "react";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import UnitTable from "./table";

const UnitList = () =>{
  const [sidebaropen , setSidebarOpen] = useState(false)
    
    return (
        <>
        {/* <section>
        <Header />
        <div id='wrapper'>
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
              <UnitTable />
            </div>
        </div> 
    </section> */}
    <div className='d-flex'>
     <Sidebar setSidebarOpen={setSidebarOpen} />
     <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
     <Header  />
     <UnitTable />
     </div>
     </div>
        </>
    )
}
export default UnitList ;