import React, { useState } from 'react'
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
import InactiveUsertable from "../InactiveList/inactibetable"

function UserInactiveList() {
  const [sidebaropen , setSidebarOpen] = useState(false)

  return (

    <div className='d-flex'>
    <Sidebar setSidebarOpen={setSidebarOpen} />
    <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
    <Header  />
    <InactiveUsertable />
    </div>
</div>
    
  )
}

export default UserInactiveList;
