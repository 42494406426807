import React, { useState, useEffect } from "react";
import store from "../../../../../Redux/MyRedux/store";
import Pagination from "react-mui-pagination";
import { toast } from "react-toastify";
import { LuSearch } from "react-icons/lu";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { BiCloudUpload } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import short_imag from '../../../../../Assets/table/shortarrow.png'
import {FaRegRectangleList} from 'react-icons/fa6'
import { childMasterList, updateChildMasterStatus } from "../../../../../Redux/MyRedux/action/phase3";

function ProductMasterTable() {
  const navigate = useNavigate();

  const [page, setPage] = React.useState(1);

  const [total_count, setTotalCount] = React.useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [Search, setSearch] = useState("");
  const [listdata, setListdata] = useState([]);
  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      active:false,
      itemCode:[],
      itemDescription:[],
      productName:[],
      subcategoryName:[],
      categoryName:[],
      unit:[],
      industry:[],
      moq:[],
      factorOfSafety:[],
    };

    try {
      store.dispatch(childMasterList(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data?.docs.length > 0) {
            setListdata(res?.data?.docs);
            setTotalCount(res?.data?.paginate[0]?.totalDocs);
          }
          else {
            setListdata([]);  
            setTotalCount(0);
          }
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getdata();
  }, [recordperpage, page, Search]);

  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      "Item code",
      "Item Description ",
      "Product Name ",
      "Sub-Category",
      "Sub-catrgory Id ",
      "Category Id",
      "Category",
      "MOQ",
      "Factor of Safety",
      "Unit",
      "Industry",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [
        item.itemCode,
        item.itemDescription,
        item.productName,
        item.subcategoryName,
        item.subcategoryid,
        item.categoryid,
        item.categoryName,
        item.moq,
        item.factorOfSafety,
        item.unit,
        item.industry,
      ]),
    ];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "Inactive_Child_Master_List.xlsx");
  };


  


  const handleProductStatusUpdate = (id, value) => {
    const data = {
      active: value,
      productid: id,
    };
    try {
      store.dispatch(updateChildMasterStatus(data ,id)).then((res) => {
        if (res?.isError == false) {
          //  handleUpdateBoxClose()
           toast.success(res?.message)
           getdata();
        }
        else {
          toast.error(res?.message)

        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handleProductStatusUpdate(userId, updatedStatus);
    }
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
const sortBy = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  const sortedData = [...listdata].sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  setListdata(sortedData);
  setSortConfig({ key, direction });
};
const [isHovered , setIsHovered] = useState(false)

  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className="text-light page_heading"> Inactive Product Master</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={(e) => setSearch(e.target.value)}
              value={Search} />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>
        <div className="d-flex gap-2 flex-wrap ms-1">
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
          onClick={() => handleDownloadEXcel()} >
        <span>Excel Export</span>
        <div className='pageheader_icons_container' >
        <BiCloudUpload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
        </div>
      </div>
      <div className="page_header_slider_container justify-content-end ">
        <button className='inactive_page_btn' 
       onClick={() => navigate(-1)}> <FaRegRectangleList className='list_icons' /> <span>    Active Product Master List </span> </button>
      </div>
      <div className="table-container  ">
        <table className="table ">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">
                <div className="th_common">
                  <span>Item Code</span>
                  <img src={short_imag} alt="short img" 
                     style={{ cursor: "pointer" }}
                     onClick={() => sortBy("itemCode")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Item Description</span>
                    <img src={short_imag} alt="short img" 
                     style={{ cursor: "pointer" }}
                     onClick={() => sortBy("itemDescription")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Product Name</span>
                    <img src={short_imag} alt="short img" 
                     style={{ cursor: "pointer" }}
                     onClick={() => sortBy("productName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Category</span>
                    <img src={short_imag} alt="short img" 
                     style={{ cursor: "pointer" }}
                     onClick={() => sortBy("categoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Sub-Category</span>
                    <img src={short_imag} alt="short img" 
                     style={{ cursor: "pointer" }}
                     onClick={() => sortBy("subcategoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>MOQ</span>
                    <img src={short_imag} alt="short img" 
                     style={{ cursor: "pointer" }}
                     onClick={() => sortBy("moq")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Factor of Safety</span>
                    <img src={short_imag} alt="short img" 
                     style={{ cursor: "pointer" }}
                     onClick={() => sortBy("factorOfSafety")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Unit</span>
                    <img src={short_imag} alt="short img" 
                     style={{ cursor: "pointer" }}
                     onClick={() => sortBy("unit")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Industries Applications</span>
                    <img src={short_imag} alt="short img" 
                     style={{ cursor: "pointer" }}
                     onClick={() => sortBy("industry")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Status</span>
                 
                </div>
              </td>
            </tr>
            <br />
          </thead>
          <tbody>
            {listdata?.map((value, i) => (
              <>
              <tr key={i}>
                <td>{i + 1}</td>
                <td>{value?.itemCode}</td>
                <td>{value?.itemDescription}</td>
                <td>{value?.productName}</td>
                <td>{value?.categoryName}</td>
                <td>{value?.subcategoryName}</td>
                <td>{value?.moq}</td>
                <td>{value?.factorOfSafety}</td>
                <td>{value?.unit}</td>
                <td>{value?.industry}</td>
                <td>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={value?.active}
                      onChange={() => toggleStatus(value?._id)}
                    />
                  </div>
                </td>
              </tr>
              <br />
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} > Showing 01 - {recordperpage > total_count ? total_count : recordperpage } 
            
              &nbsp; of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => setRecordPerPage(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>

     
    </div>
  );
}

export default ProductMasterTable;
