import React, { useState, useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Pagination from "react-mui-pagination";
import { toast } from "react-toastify";
import Select from "react-select";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./table.css";
import {
  API_URL,
  AddExpectedDaysByRotes,
  AddsapSoNumberByROtex,
  UserProfileInfo,
  addOrderMTO,
  channellistdropdowm,
  getApprovalList,
  getItemCodeMTO,
  getOrderFlowDataMTO,
  getcountofall,
  getdatedays,
  orderMTOlist,
  orderRecommandationMTOAccept,
  sendBrowseFileByCP,
  updateApproval,
  viewApproval,
} from "../../Redux/MyRedux/action/userActions";
import store from "../../Redux/MyRedux/store";
import { AiOutlineEye } from "react-icons/ai";
import Moment from "react-moment";
import { LuSearch } from "react-icons/lu";
import { FaSort } from "react-icons/fa";
import Slider from "react-slick";
import { RiEditFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import sort_logo_img from "../../Assets/table/shortarrow.png";
import { IoMdAddCircleOutline } from "react-icons/io";
import FilterModal from "./filterpopup/filter";
import { IoEyeOutline } from "react-icons/io5";

function OrderTable() {
  const navigate = useNavigate();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius: "20px",
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

  const [recordperpage, setRecordperPage] = useState(1000);
  const [total_count, setTotalCount] = useState(10);
  const [page, setPage] = useState(1);
  const [cpOrderPermission, setCpOrderPermission] = useState("PENDING");
  const [leadTime, setLeadTime] = useState("");
  const [itemcode, setItemCode] = useState("");
  const [item_description, setItemDescription] = useState("");
  const [unique_number, setUniqueNumber] = useState("");
  const [fileStatus, setFileStatus] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // Default to ascending order
  const [downloadFile, setDownloadFile] = useState("");

  const [sapNumber, setSapNumber] = useState();
  const [filterReferenceNumber, setFilterReferenceNumber] = useState([]);
  const [sapSoInput, setSapSoInput] = useState(null);
  const [editSapSo, setEditSapSo] = useState();
  const [isEditingSap, setIsEditingSap] = useState(false);
  const [disableSelect, setDisableSelect] = useState("");
  const [addremarkbyCP, setAddRemarkByCP] = useState(false);
  const [addremarkbyRotex, setAddRemarkByRotex] = useState(false);
  const [remarkbyCP, setRemarkByCP] = useState("");
  const [remarkbyrotex, setRemarkByROTEX] = useState("");
  const [partiallydispatch, setPartiallyDispatch] = useState(false);


  const [filterOrderId, setFilterOrderId] = useState([]);
  const [filterCpName, setFilterCPName] = useState([]);
  const [filterexpercteddate, setFilterExpectedDate] = useState([]);
  const [filterItemCode, setFilterItemCode] = useState([]);
  const [filterItemDescription, setFilterItemDescription] = useState([]);
  const [filterProductName, setFilterProductName] = useState([]);
  const [filterExpecteDay, setFilterExpectedDay] = useState([]);
  const [filterOrderPlacementDate, setFilterOrderPlacementDate] = useState([]);
  const [filterstatus, setFilterStatus] = useState([]);

  const [updatedAPI, setUpdateAPI] = useState("");

  const propsState= {
    filterOrderId,
    filterCpName,
    filterexpercteddate,
    filterItemCode,
    filterItemDescription,
    filterProductName,
    filterExpecteDay,
    filterOrderPlacementDate,
    filterstatus,
   
  }
  
  const StateSetters = {
    setFilterOrderId,
    setFilterCPName,
    setFilterExpectedDate,
    setFilterItemCode,
    setFilterItemDescription,
    setFilterProductName,
    setFilterExpectedDay,
    setFilterOrderPlacementDate,
    setFilterStatus,
    setUpdateAPI
   
  
  }

  const [expectedleadTime, setExpectedLeadTime] = useState({
    expectedDays: "",
    expectedDate: "",
  });

  const initialvalueforAddOrder = {
    cpname: `${
      localStorage.getItem("channelPartnerId")
        ? localStorage.getItem("channelPartnerId")
        : ""
    }`,
    itemCode: "",
    itemDescription: "",
    productName: "",
    qty: "",
  };
  const [valueforaddOrder, setValuesForAddOrder] = useState(
    initialvalueforAddOrder
  );

  const validationSchema = Yup.object().shape({
    cpname: Yup.string().required("Company name is required"),
    itemCode: Yup.string().required("Item code is required"),
    qty: Yup.number()
      .typeError("Quantity must be a number")
      .positive("Quantity must be a positive number")
      .required("Quantity is required"),
  });

  const [disabledunitbutton, Setdisableunitbtn] = useState(false);

  const handleCPOptionChange = (e) => {
    setCpOrderPermission(e.target.value);
    if (e.target.value == "ACCEPT") {
      setShowConfirmPopup(true);
    } else if (e.target.value == "REJECT") {
      EditOrderRecoommand("REJECT");
    }
  };
  const [usertype, setUserType] = useState("");
  const [isAcceptanceAtRotex, setIsAcceptanceAtRotex] = useState("");
  const [isAcceptanceAtWIP, setIsAcceptanceAtWIP] = useState("");
  const [isEditApproval, setIsEditApproval] = useState("");
  const [isApproval, setIsApproval] = useState("");

  const [rotexOrderPermission, setRotexOrderPermission] = useState("PENDING");
  const handlerotexOptionChange = (e) => {
    if (addSapNumber || sapSoInput) {
      setRotexOrderPermission(e.target.value);
      if (e.target.value == "ACCEPT") {
        setShowConfirmPopup(true);
      } else if (e.target.value == "REJECT") {
        EditOrderRecoommand("REJECT");
      }
    } else {
      toast.error("Please Add SO NO");
    }
  };
  const [wiptoggleactive, setWIPtoggleAcive] = useState(false);

  const handleWIPOptionChange = (e) => {
    setConfirmDispatch(true);
    setWIPtoggleAcive(true);
    if (e.target.value == "ACCEPT") {
      setShowConfirmPopup(true);
    }
  };
  const [intrasittoggleactive, setIntrasittoggleactive] = useState(false);
  const handleTransitOptionChange = (e) => {
    setIntrasittoggleactive(true);
    if (e.target.value == "ACCEPT") {
      setShowConfirmPopup(true);
    }
  };
  const [viewdata, setViewData] = useState([]);
  const [orderID, setOrderId] = useState("");
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const handleclodeqtyconfirmation = () => {
    setShowConfirmPopup(false);
    setWIPtoggleAcive(false);
    setIntrasittoggleactive(false);
  };

  const [showChangeqtyPopup, setShowChangeqtyPopup] = useState(false);
  const [showExpectedDaysPopup, setShowExpextedDatePopup] = useState(false);

  const [channelpartner_id, setChannelPartener_ID] = useState("");
  const [orderFlag, setOrderFlag] = useState([]);
  const [flagName, setFlagName] = useState("");
  const [quantityValue, setQuantityValue] = useState("");
  const [expectedManufacturingDay, setExpectedManufacturingDaysValue] =
    useState("");
  const [editManuffacturingDate, setEditManuffacturingDate] = useState(false);
  const [appraovaltable, setApprovalTable] = useState(false);

  const [showSONOinput, setShowSONOInput] = useState(false);

  const [Search, setSearch] = React.useState("");
  const [addSapNumber, setAddSapNumber] = useState(null);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [listData, setListData] = useState([]);

  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      flag: flagName,
      orderPlacementDate: filterOrderPlacementDate.map((res) => res?.label),
      expectedDeliveryDate: filterexpercteddate.map((res) => res?.label),
      expectedDeliveryDays: filterExpecteDay.map((res) => res?.label),
      uniqueNumber: filterOrderId.map((res) => res?.value),
      qty: [],
      productName: filterProductName.map((res) => res?.label),
      itemCode: filterItemCode.map((res) => res?.label),
      companyName: filterCpName.map((res) => res?.label),
      status: filterstatus.map((res) => res?.label),
      itemDescription: filterItemDescription.map((res) => res?.label),
      contactPersonName: [],
      channelPartnerId: channelpartner_id,
    };
    try {
      store.dispatch(orderMTOlist(data)).then((res) => {
        // if (res?.isError == false) {
        if (res?.data[0]?.docs.length > 0) {
          setListData(res?.data[0]?.docs);
          setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
        } else {
          setListData([]);
          setTotalCount(0);
        }
        setUpdateAPI('')

        // }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleFlagCount = () => {
    const data = {
      channelPartnerId: channelpartner_id,
    };
    try {
      store.dispatch(getcountofall(data)).then((res) => {
      
        setOrderFlag(res?.data);
       
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  let userid = localStorage.getItem("user_id");
  const getProfiledata = () => {
    try {
      store.dispatch(UserProfileInfo(userid)).then((res) => {
        if (res?.isError == false) {
          setIsApproval(res?.data?.isApproval);
          setIsEditApproval(res?.data?.isEditingLeadDays);
          setIsAcceptanceAtRotex(res?.data?.isAcceptanceAtRotex);
          setIsAcceptanceAtWIP(res?.data?.isAcceptanceAtWIP);
        }
      });
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getProfiledata();
  }, []);

  useEffect(() => {
    setUserType(localStorage.getItem("usertype"));
    setChannelPartener_ID(localStorage.getItem("channelPartnerId"));
    if (localStorage.getItem("usertype") == "CP") {
      if (channelpartner_id) {
        getdata();
        handleFlagCount();
      }
    } else {
      getdata();
      handleFlagCount();
    }
  }, [flagName, updatedAPI, channelpartner_id, page, recordperpage, Search]);
  const [approvalList, setApprovalList] = useState([]);

  const getApprovalsList = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      channelPartnerId: localStorage.getItem("channelPartnerId"),
    };
    try {
      store.dispatch(getApprovalList(data)).then((res) => {
        if (res?.data[0]?.docs.length > 0) {
          setApprovalList(res?.data[0]?.docs);
          setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
        } else {
          setApprovalList([]);
          setTotalCount(0);
        }
      });
    } catch (error) {
      console.log("error" + error);
    }
  };
  useEffect(() => {
    if (appraovaltable == true) {
      getApprovalsList();
    }
  }, [recordperpage, page, Search]);

  const viewOrderRecoommand = (id) => {
    setOrderId(id);
    try {
      store.dispatch(getOrderFlowDataMTO(id)).then((res) => {
        setSapNumber(res?.data[0]?.sapNumber);
        if (res?.data[0]?.sapNumber == "") {
          setEditSapSo(false);
          setShowSONOInput(false);
          setSapSoInput("");
        } else {
          setSapSoInput(res?.data[0]?.sapNumber);
          setShowSONOInput(true);
          setEditSapSo(true);
        }
        setRemarkByCP(res?.data[0]?.cpRemark);
        setRemarkByROTEX(res?.data[0]?.rotexRemark);
        setAddRemarkByRotex(res?.data[0]?.rotexRemark == "" ? false : true);
        setViewData(res?.data);
        setQuantityValue(res?.data[0]?.qty);
        setCpOrderPermission(res?.data?.cp?.stage);
        setRotexOrderPermission(res?.data?.cp?.stage);

        setLeadTime(res?.data[0]?.leadTime);
        setExpectedManufacturingDaysValue(res?.data[0]?.leadTime);
        setDownloadFile(res?.data[0]?.attachment);
        setItemCode(res?.data[0]?.itemCode);
        setItemDescription(res?.data[0]?.itemDescription);
        setUniqueNumber(res?.data[0]?.uniqueNumber);
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const EditOrderRecoommand = (value) => {
    Setdisableunitbtn(true);

    const data = {
      stage: value,
      qty: quantityValue,
      partcialDispatch: partiallydispatch,
      changeQty: partiallyquantityValue,
    };

    try {
      store
        .dispatch(orderRecommandationMTOAccept(data, orderID))
        .then((res) => {
          if (res?.data?.isError == false) {
            toast.success(res?.message);
            setShowChangeqtyPopup(false);
            setShowConfirmPopup(false);
            viewOrderRecoommand(orderID);
            Setdisableunitbtn(false);
            getdata();
            handleFlagCount();
            setPartiallyDispatch(false);
            setShowPartiallyqtyPopup(false);
            handlecloseconfirmationforPartially();
            setPartiallyQuantityValue("");
            setConfirmDispatch(false);
          } else {
            toast.success(res?.message);
            Setdisableunitbtn(false);
          }
          Addsaporremark();
        });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const Addsaporremark = () => {
    Setdisableunitbtn(true);

    const data = {
      sapNumber: addSapNumber ? addSapNumber : sapSoInput,
      cpRemark: remarkbyCP,
      rotexRemark: remarkbyrotex,
    };

    try {
      store.dispatch(AddsapSoNumberByROtex(data, orderID)).then((res) => {
        if (res?.data?.isError == false) {
          toast.success(res?.message);
          setShowChangeqtyPopup(false);
          setShowConfirmPopup(false);
          viewOrderRecoommand(orderID);
          Setdisableunitbtn(false);
          setEditSapSo(true);
          setIsEditingSap(false);
          setAddSapNumber(null);
          setRemarkByCP("");
          setRemarkByROTEX("");
          getdata();
          handleFlagCount();
        } else {
          toast.error(res?.data?.message);
          Setdisableunitbtn(false);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const AddExpextedDay = () => {
    const data = {
      manufacturedLeadTime: expectedManufacturingDay,
      isApproval: appraovaltable,
    };
    try {
      store.dispatch(AddExpectedDaysByRotes(data, orderID)).then((res) => {
        if (res?.data?.isError == false) {
          toast.success(res?.message);

          Setdisableunitbtn(false);
          setShowExpextedDatePopup(false);
          setEditManuffacturingDate(false);
          viewOrderRecoommand(orderID);
          if (!appraovaltable) {
            getdata();
          } else {
            getApprovalsList();
          }
        } else {
          toast.error(res?.message);
          setShowExpextedDatePopup(false);
          Setdisableunitbtn(false);
        }
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const [open, setOpen] = useState(false);
  const handleeditwmodelopen = () => setOpen(true);
  const handleeditmodelclose = () => setOpen(false);

  const [addOpen, setAddOpen] = useState(false);
  const handleaddwmodelopen = () => setAddOpen(true);
  const handleaddmodelclose = () => setAddOpen(false);





  const clearFilter = (filterName) => {
    switch (filterName) {
      case "CPName":
        setFilterCPName([]);
        setUpdateAPI("CPName");
        break;

      case "expecteddate":
        setFilterExpectedDate([]);
        setUpdateAPI("expecteddate");
        break;

      case "expecteddays":
        setFilterExpectedDay([]);
        setUpdateAPI("expecteddays");
        break;
      case "orderpaceDate":
        setFilterOrderPlacementDate([]);
        setUpdateAPI("9");
        break;
      case "referencenumber":
        setFilterReferenceNumber([]);
        setUpdateAPI("10");
        break;

      case "OrderId":
        setFilterOrderId([]);
        setUpdateAPI("12");
        break;

      case "status":
        setFilterStatus([]);
        setUpdateAPI("status");
        break;

        break;
      default:
        break;
    }
  };

  // Add file
  const handleFileInputChange = (event) => {
    const dataFile = event.target.files[0];
    if (dataFile) {
      handlePostFile(dataFile);
    }
  };
  const handlePostFile = (dataFile) => {
    const formData = new FormData();
    formData.append("attachment", dataFile);
    formData.append("mtoOrderId", orderID);

    try {
      store.dispatch(sendBrowseFileByCP(formData)).then((res) => {
        if (res?.isError === false) {
          toast.success(res?.message);
          setFileStatus(res?.isError);
          viewOrderRecoommand(orderID);
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleDownload = async () => {
    const cleanedFilePath = downloadFile.replace("uploaded/", "");
    if (downloadFile) {
      const completeUrl = API_URL + "/" + cleanedFilePath;
      const fileExtension = cleanedFilePath.split(".").pop().toLowerCase();
      const headers = new Headers();
      headers.append(
        "Content-Disposition",
        `attachment; filename=downloadedFile.${fileExtension}`
      );
      const response = await fetch(completeUrl, { headers });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `downloadedFile.${fileExtension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  const [cpmasterlistdropdown, setCPmasterListDropdown] = useState([]);
  const [productlistdropdown, setProductListDropdown] = useState([]);

  const getcpmasterlistdropdownlist = () => {
    try {
      store.dispatch(channellistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setCPmasterListDropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
  const getproductdropdownlist = (CPID) => {
    const data = {
      channelPartnerId: CPID,
    };
    try {
      store.dispatch(getItemCodeMTO(data)).then((res) => {
        // if (res?.isError === false) {
        setProductListDropdown(res?.data);
        // }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
  useEffect(() => {
    if (localStorage.getItem("channelPartnerId")) {
      getproductdropdownlist(localStorage.getItem("channelPartnerId"));
    }
  }, []);
  const optionListcpname = cpmasterlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.companyName,
  }));
  const optionListitemcode = productlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.itemCode,
  }));
  const optionListproduct = productlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.productName,
  }));

  const optionListitemdescription = productlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.itemDescription,
  }));

  const onAddOrder = (values, { resetForm }) => {
    const data = {
      productId: values.itemCode,
      qty: values.qty,
      channelPartnerId: values.cpname,
    };
    try {
      store.dispatch(addOrderMTO(data)).then((res) => {
        if (res?.data?.isError == false) {
          handleaddmodelclose();
          toast.success(res?.data?.message);
          getdata();
        } else {
          toast.error(res?.data?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
    resetForm();
    setExpectedLeadTime({
      expectedDate: "",
      expectedDays: "",
    });
  };

  const handleUpdateApproval = (status, id) => {
    const data = {
      status: status,
    };
    try {
      store.dispatch(updateApproval(data, id)).then((res) => {
        if (res?.data?.isError == false) {
          toast.success(res?.data?.message);
          getApprovalsList();
        } else {
          toast.error(res?.data?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleViewUpdateApproval = (id, uniqId) => {
    setOrderId(uniqId);

    try {
      store.dispatch(viewApproval(id)).then((res) => {
        //  if (res?.data?.isError == false) {

        setExpectedManufacturingDaysValue(res?.data[0]?.manufactureleadTime);

        setShowExpextedDatePopup(true);
        setEditManuffacturingDate(false);
        //  } else {
        //    toast.error(res?.data?.message);
        //  }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const getleadttimeDateDays = (itemcode, cpname) => {
    const data = {
      channelPartnerId: cpname,
      productId: itemcode,
    };
    try {
      store.dispatch(getdatedays(data)).then((res) => {
        setExpectedLeadTime({
          expectedDate: res?.data?.expectedDeliveryDate,
          expectedDays: res?.data?.expectedDeliveryDays,
        });
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });

  const sortBy = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const sortedData = [...listData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setListData(sortedData);
    setSortConfig({ key, direction });
  };

  const sortBydate = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const sortedData = [...listData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setListData(sortedData);
    setSortConfig({ key, direction });
  };
  const sortDataByName = (sortByField) => {
    const sortedData = [...listData].sort((a, b) => {
      const nameA = a[sortByField].toLowerCase();
      const nameB = b[sortByField].toLowerCase();

      if (sortOrder === "asc") {
        return nameA < nameB ? -1 : 1;
      } else {
        return nameA > nameB ? -1 : 1;
      }
    });
    setListData(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortByForApprovals = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const sortedData = [...approvalList].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setApprovalList(sortedData);
    setSortConfig({ key, direction });
  };

  const sortDataByNameForApprovals = (sortByField) => {
    const sortedData = [...approvalList].sort((a, b) => {
      const nameA = a[sortByField].toLowerCase();
      const nameB = b[sortByField].toLowerCase();

      if (sortOrder === "asc") {
        return nameA < nameB ? -1 : 1;
      } else {
        return nameA > nameB ? -1 : 1;
      }
    });
    setApprovalList(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // patrially Dispatch POP and FUNCTIONALITY
  const [partiallyquantityValue, setPartiallyQuantityValue] = useState("");

  const [confirmdispatch, setConfirmDispatch] = useState(false);
  const [showpartiallyqtyPopup, setShowPartiallyqtyPopup] = useState(false);

  const [
    showConfirmPopupforpartially,
    setShowConfirmPopupForPartiallyDispatch,
  ] = useState(false);
  const handlecloseconfirmationforPartially = () => {
    setShowConfirmPopupForPartiallyDispatch(false);
    setWIPtoggleAcive(false);
    setIntrasittoggleactive(false);
  };

  const [isHovered, setIsHovered] = useState(false);
  const handleItemsPerPageChange = (value) => {
    setRecordperPage(value);
    setPage(1); // Reset to page 1 whenever items per page changes
  };

  const calculateSerialNumber = (index) => {
    return (page - 1) * recordperpage + index + 1;
  };
  const startSerialNumber = calculateSerialNumber(0);
  const endSerialNumber = calculateSerialNumber(listData.length - 1);

  return (
    <div className="page">
      <div className="filter_list d-flex justify-content-between align-items-start   mb-1 flex-column flex-sm-row align-items-sm-center gap-2">
        <ul className="list-inline mb-0 d-flex flex-wrap gap-1">
          {orderFlag.map((item, index) => (
            <>
              <li className="list-inline-item" key={index}>
                <button
                  className="dashboard_header_btn order_btn"
                  onClick={() => {
                    getApprovalsList();
                    setApprovalTable(true);
                  }}
                >
                  {" "}
                  <span>Approvals</span>{" "}
                </button>
              </li>
              <li className="list-inline-item">
                <button
                  className=" dashboard_header_btn pending_order_btn "
                  onClick={() => {
                    setFlagName("CP_ACCEPT");
                    setPage("1");
                    setApprovalTable(false);
                  }}
                >
                  {" "}
                  <span> Accepted by CP</span>{" "}
                  <span className="intry-count">{item?.accepted_cp}</span>
                </button>
              </li>

              <li className="list-inline-item">
                <button
                  className="wip_btn dashboard_header_btn "
                  onClick={() => {
                    setFlagName("WIP");
                    setPage("1");
                    setApprovalTable(false);
                  }}
                >
                  WIP <span className="intry-count">{item?.wip}</span>
                </button>
              </li>
              <li className="list-inline-item">
                <button
                  className="dashboard_header_btn in_transmit_btn "
                  onClick={() => {
                    setFlagName("IN_TRANSIT");
                    setPage("1");
                    setApprovalTable(false);
                  }}
                >
                  In-Transit{" "}
                  <span className="intry-count">{item?.in_trasit}</span>
                </button>
              </li>
              <li className="list-inline-item">
                <button
                  className="dashboard_header_btn order_accept_btn "
                  onClick={() => {
                    setFlagName("GRN");
                    setPage("1");
                    setApprovalTable(false);
                  }}
                >
                  GRN <span className="intry-count">{item?.grn}</span>
                </button>
              </li>
              <li className="list-inline-item">
                <button
                  className="dashboard_header_btn all_list_btn "
                  onClick={() => {
                    setFlagName(" ");
                    setPage("1");
                    setApprovalTable(false);
                  }}
                >
                  ALL <span className="intry-count">{item?.all}</span>
                </button>
              </li>
            </>
          ))}
        </ul>
        <div className="d-flex align-items-center gap-2">
          {appraovaltable || usertype === "ROTEX" ? null : (
            <div
              className="button-container"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <button
                className={`button ${isHovered ? "hovered" : ""}`}
                onClick={() => {
                  handleaddwmodelopen();
                  getcpmasterlistdropdownlist();
                }}
              >
                <span>Add Order</span>
                <div className="pageheader_icons_container">
                  <IoMdAddCircleOutline className="pageheader_react_icons" />
                </div>
              </button>
            </div>
          )}
          {usertype === "CP" && (
           
<div
className="button-container"
onMouseEnter={() => setIsHovered(true)}
onMouseLeave={() => setIsHovered(false)}
>
<button
  className={`button ${isHovered ? "hovered" : ""}`}
  onClick={() => navigate("/CP/ViewLeadtime")}
>
  <span> View Lead Time</span>
  <div className="pageheader_icons_container">
    <IoEyeOutline className="pageheader_react_icons" />
  </div>
</button>
</div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-1">
          {!appraovaltable && (
            <div>
            
              <FilterModal stateSetters={StateSetters} propsState={propsState} channelPartnerId={channelpartner_id} />
            </div>
          )}
        </div>
        <div className="slider_container">
          <Slider {...settings}>
            {filterOrderId.length > 0 && (
              <div class="active-filters">
                {" "}
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    Order Id is{" "}
                    {filterOrderId.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterOrderId.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("OrderId")} />
                </button>
              </div>
            )}
            {filterCpName.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    CP Name is{" "}
                    {filterCpName.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterCpName.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("CPName")} />
                </button>
              </div>
            )}
            {filterexpercteddate.length > 0 && (
              <div class="active-filters">
                {" "}
                <button>
                  <span className="filter_text_cont">
                    {" "}
                    Expected Delivery Date is{" "}
                    {filterexpercteddate.map((item, index) => (
                      <span
                        className="ellipsis-button_contact_person"
                        key={index}
                      >
                        {item.label}
                        {index < filterexpercteddate.length - 1
                          ? ", "
                          : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("expecteddate")} />
                </button>
              </div>
            )}
            {filterExpecteDay.length > 0 && (
              <div class="active-filters">
                {" "}
                <button>
                  <span className="filter_text_cont">
                    {" "}
                    Expected Delivery days is{" "}
                    {filterExpecteDay.map((item, index) => (
                      <span
                        className="ellipsis-button_contact_person"
                        key={index}
                      >
                        {item.label}
                        {index < filterExpecteDay.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("expecteddays")} />
                </button>
              </div>
            )}
            {filterOrderPlacementDate.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    City is{" "}
                    {filterOrderPlacementDate.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterOrderPlacementDate.length - 1
                          ? ", "
                          : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("orderpaceDate")} />
                </button>
              </div>
            )}
            {filterReferenceNumber.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    State is{" "}
                    {filterReferenceNumber.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterReferenceNumber.length - 1
                          ? ", "
                          : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("referencenumber")} />
                </button>
              </div>
            )}

            {filterstatus.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    status is{" "}
                    {filterstatus.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterstatus.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("status")} />
                </button>
              </div>
            )}
          </Slider>
        </div>

        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group my-1 ">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              value={Search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>
      </div>
      {appraovaltable == false ? (
        <div className="table-container">
          <table className=" table  ">
            <thead>
              <tr>
                <td scope="col">Sr. No</td>
                <td scope="col">Action </td>
                <td scope="col">
                  {" "}
                  <div className="th_common">
                    <span> Stage </span>
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span> Status </span>
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>Order Id</span>
                 
                    <img
                      src={sort_logo_img}
                      alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("_id")}
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>CP Name</span>
                   
                    <img
                      src={sort_logo_img}
                      alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("companyName")}
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>Item Code</span>
                 
                    <img
                      src={sort_logo_img}
                      alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("itemCode")}
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>Item Description</span>
                  
                    <img
                      src={sort_logo_img}
                      alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("itemDescription")}
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>Product Name</span>
                  
                    <img
                      src={sort_logo_img}
                      alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("productName")}
                    />
                  </div>{" "}
                </td>

                <td scope="col">
                  <div className="th_common">
                    <span>Expected Delivery Date</span>
                  
                    <img
                      src={sort_logo_img}
                      alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("expectedDeliveryDate")}
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>Expected Delivery days </span>
                 
                    <img
                      src={sort_logo_img}
                      alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("expectedDeliveryDays")}
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  {" "}
                  <div className="th_common">
                    <span>Order placement Date </span>
                  
                    <img
                      src={sort_logo_img}
                      alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("orderPlacementDate")}
                    />
                  </div>{" "}
                </td>
              </tr>
              <br />
            </thead>
            <tbody>
              {listData?.map((val, i) => (
                <>
                <tr key={i}>
                                   <td>{calculateSerialNumber(i)}</td>

                  <td className="d-flex gap-1 action_td">
                    <button
                      className="btn blue-outline-btn py-1  "
                      onClick={() => {
                        handleeditwmodelopen();
                        viewOrderRecoommand(val?._id);
                        setShowConfirmPopup(false);
                        setIntrasittoggleactive(false);
                        setWIPtoggleAcive(false);
                      }}
                    >
                      <AiOutlineEye className="view_icons" />
                    </button>
                  </td>
                  {val?.grn?.stage == "ACCEPT" ? (
                    <td>
                      <button className=" order_recommend_btn recoomand">
                        {" "}
                        <b> GRN </b>
                      </button>
                    </td>
                  ) : val?.in_trasit?.stage == "ACCEPT" ? (
                    <td>
                      <button className=" order_recommend_btn recoomand ">
                        GRN
                      </button>
                    </td>
                  ) : val?.wip?.stage == "ACCEPT" ? (
                    <td>
                      <button className=" order_recommend_btn rejected ">
                        In Transit
                      </button>
                    </td>
                  ) : val?.rotex?.stage == "ACCEPT" ? (
                    <td>
                      <button className=" order_recommend_btn wip_btn">
                        WIP
                      </button>
                    </td>
                  ) : val?.cp?.stage == "ACCEPT" ? (
                    <td>
                      <button className=" order_recommend_btn pending_order_btn">
                        Accepted By CP
                      </button>
                    </td>
                  ) : val?.recommendation?.stage == "RECOMMENDATION" ? (
                    <td>
                      <button className=" order_recommend_btn recommand_btn">
                        Order recommendation
                      </button>
                    </td>
                  ) : (
                    <td></td>
                  )}

                  {val?.status == "YELLOW" ? (
                    <td>
                      <button className="btn btn-warning py-1">Yellow</button>
                    </td>
                  ) : val?.status == "RED" ? (
                    <td>
                      <button className="btn btn-danger py-1">Red</button>
                    </td>
                  ) : val?.status == "BLACK" ? (
                    <td>
                      <button className="btn btn-dark py-1">Black</button>
                    </td>
                  ) : val?.status == "GREEN" ? (
                    <td>
                      <button className="btn btn-success py-1">Green</button>
                    </td>
                  ) : (
                    <td>
                      <button className="btn border py-1">White</button>
                    </td>
                  )}
                  <td> PMS {val?.uniqueNumber} </td>
                  <td> {val?.companyName} </td>
                  <td> {val?.itemCode} </td>
                  <td> {val?.itemDescription} </td>
                  <td> {val?.productName} </td>

                  <td>
                    {" "}
                    {val.expectedDeliveryDate ? (
                      <Moment format="DD-MM-YYYY">
                        {val.expectedDeliveryDate}
                      </Moment>
                    ) : (
                      "To be added"
                    )}{" "}
                  </td>
                  <td>
                    {val?.expectedDeliveryDays
                      ? val?.expectedDeliveryDays
                      : "To be added"}
                  </td>
                  <td>
                    <Moment format="DD-MM-YYYY">
                      {val.orderPlacementDate}
                    </Moment>{" "}
                  </td>

                  {/* <td>{val?.onHandStock}</td> */}
                </tr>

              <br />
              </>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="table-container">
          <table className=" table  ">
            <thead>
              <tr>
                <td scope="col">Sr. No</td>
                <td scope="col">Action</td>
                <td scope="col">Accept</td>
                <td scope="col">Reject</td>

                <td scope="col">
                  {" "}
                  <div className="th_common">
                    {" "}
                    <span>Order Id</span>
                    <FaSort
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByForApprovals("uniqueNumber")}
                    />
                  </div>{" "}
                </td>

                <td scope="col">
                  <div className="th_common">
                    <span>CP Name</span>
                    <FaSort
                      style={{ cursor: "pointer" }}
                      onClick={() => sortDataByNameForApprovals("companyName")}
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>Contact Person Name</span>
                    <FaSort
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        sortDataByNameForApprovals("contactPersonName")
                      }
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>Item Code</span>
                    <FaSort
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByForApprovals("itemCode")}
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>Item Description</span>
                    <FaSort
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByForApprovals("itemDescription")}
                    />
                  </div>{" "}
                </td>
                <td scope="col">
                  <div className="th_common">
                    <span>Product Name</span>
                    <FaSort
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByForApprovals("productName")}
                    />
                  </div>{" "}
                </td>

                <td scope="col">
                  <div className="th_common">
                    <span>Expected Manufacturing days </span>
                    <FaSort
                      style={{ cursor: "pointer" }}
                      onClick={() => sortByForApprovals("manufactureleadTime")}
                    />
                  </div>{" "}
                </td>
              </tr>
              <br />
            </thead>
            <tbody>
              {approvalList?.map((val, i) => (
                <>
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td className="d-flex gap-1 action_td">
                    <button
                      className="btn blue-outline-btn py-1 "
                      onClick={() => {
                        handleViewUpdateApproval(val?._id, val?.mtoOrderId);
                      }}
                    >
                      <img src="/assets/images/edit.png" alt="" />
                    </button>
                  </td>
                  <td>
                    <button
                      className={`${
                        val?.status == "ACCEPTED"
                          ? "accpeted_approval_btn"
                          : "accpet_approval_btn"
                      } `}
                      onClick={() => handleUpdateApproval("ACCEPTED", val?._id)}
                      disabled={
                        val?.status == "ACCEPTED" ||
                        val?.status == "REJECTED" ||
                        (usertype == "ROTEX" && isApproval == false) ||
                        usertype == "CP"
                      }
                    >
                      Accept
                    </button>
                  </td>
                  <td>
                    <button
                      className={`${
                        val?.status == "REJECTED"
                          ? "rejected_approval_btn"
                          : "reject_approval_btn"
                      } `}
                      onClick={() => handleUpdateApproval("REJECTED", val?._id)}
                      disabled={
                        val?.status == "ACCEPTED" ||
                        val?.status == "REJECTED" ||
                        (usertype == "ROTEX" && isApproval == false) ||
                        usertype == "CP"
                      }
                    >
                      Reject
                    </button>
                  </td>
                  <td> PMS {val?.uniqueNumber}</td>

                  <td>{val?.companyName}</td>
                  <td>{val?.contactPersonName}</td>
                  <td>{val?.itemCode}</td>
                  <td>{val?.itemDescription}</td>
                  <td>{val?.productName}</td>
                  <td>{val?.manufactureleadTime}</td>
                </tr>
                <br />
                </>
              ))}
            </tbody>
          </table>
        </div>
      )}

      
<div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
        <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
           
Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results
          </p>
        </div>

        <div className="d-flex   justify-content-center align-items-center">
          <Pagination
            page={page}
            setPage={handleChange}
            total={total_count}
            className="pagination "
            perPage={recordperpage}
          />
        </div>
        <div className="d-flex align-items-center">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            Total Count
          </p>
          <select
            className="w-auto form-select form-select-sm p3 shadow-none mx-3 "
            style={{ fontFamily: "Poppins" }}
            value={recordperpage}
             onChange={(e) => handleItemsPerPageChange(e.target.value)}

            
          >
            <option value="5">5</option>

            {total_count > 5 && <option value="10">10</option>}
            {total_count > 10 && <option value="25">25</option>}
            {total_count > 25 && <option value="50">50</option>}
            {total_count > 50 && <option value="100">100</option>}
            {total_count > 100 && <option value="500">500</option>}
            {total_count > 500 && <option value="1000">1000</option>}
          </select>
        </div>
      </div>

    
      {/* //  ADD Order Master MTO  MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={addOpen}
        onClose={handleaddmodelclose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={addOpen}>
          <Box sx={styleForMediaQuery} className="model_box">
            <Formik
              initialValues={valueforaddOrder}
              validationSchema={validationSchema}
              onSubmit={onAddOrder}
            >
              {({ handleSubmit, values, handleChange }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="model_header">
                    <p className="m-0"> Add Order </p>
                    <div className="d-flex align-items-center gap-3 ">
                      <button
                        className="closebtn_cont"
                        onClick={handleaddmodelclose}
                      >
                        <RxCross2 className="closebox_logo" />{" "}
                      </button>
                    </div>
                  </div>
                  <div className="cp_list_cont_model">
                    <div className="px-4 py-3  inputbox_cont">
                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-1">
                            CP Name
                          </label>

                          <Select
                            options={optionListcpname}
                            placeholder="Select CP Name"
                            name="cpname"
                            value={
                              optionListcpname.find(
                                (option) => option.value === values.cpname
                              ) ||
                              optionListcpname.find(
                                (option) =>
                                  option.value ===
                                  localStorage.getItem("channelPartnerId")
                              ) ||
                              null
                            }
                            onChange={(selectedOption) => {
                              handleChange("cpname")(selectedOption.value);
                              getproductdropdownlist(selectedOption.value);
                            }}
                            isSearchable={true}
                            className="react_inbuild_select"
                            isDisabled={usertype == "CP" ? true : false}
                          />

                          <ErrorMessage
                            name="cpname"
                            component="p"
                            className="error-message"
                          />
                        </div>{" "}
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-1">
                            {" "}
                            Item Code
                          </label>
                          <Select
                            options={optionListitemcode}
                            placeholder="Select Item Code"
                            name="itemCode"
                            value={
                              optionListitemcode.find(
                                (option) => option.value === values.itemCode
                              ) || null
                            }
                            onChange={(selectedOption) => {
                              handleChange("itemCode")(selectedOption.value);
                              getleadttimeDateDays(
                                selectedOption.value,
                                values.cpname
                              );
                              setDisableSelect("itemcode");

                              // Find the associated product name for the selected item code
                              const associatedProduct = optionListproduct.find(
                                (product) =>
                                  product.value === selectedOption.value
                              );
                              const associatedItemDescription =
                                optionListitemdescription.find(
                                  (product) =>
                                    product.value === selectedOption.value
                                );

                              // Set the selected product name if found
                              if (associatedProduct) {
                                handleChange("productName")(
                                  associatedProduct.value
                                );
                              }
                              // Set the selected Item Descriptiom  if found
                              if (associatedItemDescription) {
                                handleChange("itemDescription")(
                                  associatedItemDescription.value
                                );
                              }
                            }}
                            // isDisabled={values.productName || values.itemDescription ? true : false && disableSelect != "itemcode"}
                            isDisabled={
                              (values.productName || values.itemDescription) &&
                              disableSelect !== "itemcode"
                            }
                            isSearchable={true}
                            className="react_inbuild_select"
                          />
                          <ErrorMessage
                            name="itemCode"
                            component="p"
                            className="error-message"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-1">
                            Item Description
                          </label>
                          <Select
                            options={optionListitemdescription}
                            placeholder="Select Item Description"
                            name="itemDescription"
                            value={
                              optionListitemdescription.find(
                                (option) =>
                                  option.value === values.itemDescription
                              ) || null
                            }
                            onChange={(selectedOption) => {
                              handleChange("itemDescription")(
                                selectedOption.value
                              );
                              getleadttimeDateDays(
                                selectedOption.value,
                                values.cpname
                              );
                              setDisableSelect("itemdescription");

                              // Find the associated product name for the selected item code
                              const associatedProduct = optionListproduct.find(
                                (product) =>
                                  product.value === selectedOption.value
                              );
                              const associatedItemCode =
                                optionListitemcode.find(
                                  (product) =>
                                    product.value === selectedOption.value
                                );

                              // Set the selected product name if found
                              if (associatedProduct) {
                                handleChange("productName")(
                                  associatedProduct.value
                                );
                              }
                              // Set the selected Item Code  if found
                              if (associatedItemCode) {
                                handleChange("itemCode")(
                                  associatedItemCode.value
                                );
                              }
                            }}
                            isSearchable={true}
                            // isDisabled={values.productName || values.itemCode ? true : false && disableSelect != 'itemdescription'}
                            isDisabled={
                              (values.productName || values.itemCode) &&
                              disableSelect !== "itemdescription"
                            }
                            className="react_inbuild_select"
                          />
                          {/* <ErrorMessage name="qty" component="p" className="error-message"  /> */}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-1">
                            {" "}
                            Product Name
                          </label>
                          <Select
                            options={optionListproduct}
                            placeholder="Select Product Name"
                            name="productName"
                            value={
                              optionListproduct.find(
                                (option) => option.value === values.productName
                              ) || null
                            }
                            onChange={(selectedOption) => {
                              handleChange("productName")(selectedOption.value);
                              getleadttimeDateDays(
                                selectedOption.value,
                                values.cpname
                              );
                              setDisableSelect("productname");

                              // Find the associated product name for the selected item code
                              const associatedItemDescription =
                                optionListitemdescription.find(
                                  (product) =>
                                    product.value === selectedOption.value
                                );
                              const associatedItemCode =
                                optionListitemcode.find(
                                  (product) =>
                                    product.value === selectedOption.value
                                );

                              // Set the selected Item Descriptiom  if found
                              if (associatedItemDescription) {
                                handleChange("itemDescription")(
                                  associatedItemDescription.value
                                );
                              }
                              // Set the selected Item Code  if found
                              if (associatedItemCode) {
                                handleChange("itemCode")(
                                  associatedItemCode.value
                                );
                              }
                            }}
                            isSearchable={true}
                            isDisabled={
                              (values.productName || values.itemCode) &&
                              disableSelect !== "productname"
                            }
                            className="react_inbuild_select"
                          />
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="" className="mb-1">
                            QTY
                          </label>
                          <input
                            type="number"
                            name="qty"
                            id=""
                            placeholder="Enter QTY"
                            className="add_inut_field number_input_fields"
                            value={values.qty}
                            onChange={handleChange}
                          />
                          <ErrorMessage
                            name="qty"
                            component="p"
                            className="error-message"
                          />
                        </div>
                      </div>

                      <div className="addbox_btn_cont my-4 mt-3">
                        <button type="submit">Place order</button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
      {/* //  ADD Order Master MTO  MODEL END  */}

      {/* //  View Order Master MTO  MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleeditmodelclose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <p className="m-0"> Order flow history </p>
              <div className="d-flex align-items-center gap-3 ">
                <button
                  className="closebtn_cont"
                  onClick={handleeditmodelclose}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>
            <div className="cp_list_cont_model">
              <div className="cp_name  px-4 py-0 ">
                <div>
                  <div
                    className="d-flex gap-2 p-2 flex-wrap"
                    style={{ whiteSpace: "pre" }}
                  >
                    <p className="m-0 about_order_val">
                      Lead Time :- {!leadTime ? 0 : leadTime}
                    </p>{" "}
                    &#10073;
                    <p className="m-0 p-0 about_order_val ">
                      {" "}
                      Item Code :- {itemcode}{" "}
                    </p>{" "}
                    &#10073;
                    <p className="m-0 about_order_val">
                      Item Description :- {item_description}
                    </p>
                    &#10073;
                  </div>
                </div>
              </div>
              <div className="cp_name  px-4 py-0 ">
                <div>
                  <div
                    className="d-flex gap-2 p-2 flex-wrap"
                    style={{ whiteSpace: "pre" }}
                  >
                    <p className="m-0 about_order_val">
                      Order Id :- PMS {unique_number}
                    </p>
                    &#10073;
                    <p className="m-0 about_order_val">SO No :- {sapNumber}</p>
                    &#10073;
                  </div>
                </div>
              </div>

              <div>
                {viewdata?.map((res) => (
                  <>
                    {res?.cp?.status == true && (
                      <div className="cp_name d-flex justify-content-between align-items-center px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">Approval at CP</p>
                            <p className="m-0">
                              <Moment format="MMM,DD HH:mm">
                                {res?.cp?.createdAt}
                              </Moment>
                            </p>
                            <p className="m-0">Qty : {res?.cp?.qty}</p>
                          </div>
                        </div>
                        <div className="d-flex flex-column-reverse gap-1 justify-content-end align-items-end   ">
                          <div className="d-flex flex-column gap-2">
                            {!(addremarkbyCP || remarkbyCP) &&
                              usertype == "CP" && (
                                <div className="btn-add">
                                  <button
                                    onClick={() => setAddRemarkByCP(true)}
                                    className="btn add-file-btn text-black"
                                  >
                                    Add Remark
                                  </button>
                                </div>
                              )}
                            {(addremarkbyCP || remarkbyCP) && (
                              <>
                                <h6 className="text-end mt-1 mb-0">Remark </h6>
                                <input
                                  type="text"
                                  name=""
                                  id=""
                                  className="add_remarksbyCP"
                                  placeholder="Add Remark"
                                  value={remarkbyCP}
                                  onChange={(e) =>
                                    setRemarkByCP(e.target.value)
                                  }
                                  disabled={usertype != "CP"}
                                />
                                {usertype == "CP" && (
                                  <div className="d-flex justify-content-end gap-2 align-items-center">
                                    <div>
                                      <button
                                        className="sap-so-btn"
                                        disabled={disabledunitbutton}
                                        onClick={() => Addsaporremark()}
                                      >
                                        <svg
                                          width="24px"
                                          height="24px"
                                          viewBox="0 -0.5 25 25"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          stroke="#00ff11"
                                        >
                                          <g
                                            id="SVGRepo_bgCarrier"
                                            stroke-width="0"
                                          ></g>
                                          <g
                                            id="SVGRepo_tracerCarrier"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></g>
                                          <g id="SVGRepo_iconCarrier">
                                            {" "}
                                            <path
                                              d="M5.5 12.5L10.167 17L19.5 8"
                                              stroke="#00ff11"
                                              stroke-width="1.5"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></path>{" "}
                                          </g>
                                        </svg>
                                      </button>
                                    </div>
                                    {remarkbyCP == "" && (
                                      <div>
                                        <button
                                          className="sap-so-btn"
                                          onClick={() => {
                                            setAddRemarkByCP(false);
                                          }}
                                        >
                                          <svg
                                            width="20px"
                                            height="20px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            stroke="#ff0000"
                                          >
                                            <g
                                              id="SVGRepo_bgCarrier"
                                              stroke-width="0"
                                            ></g>
                                            <g
                                              id="SVGRepo_tracerCarrier"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></g>
                                            <g id="SVGRepo_iconCarrier">
                                              {" "}
                                              <path
                                                d="M19 5L5 19M5.00001 5L19 19"
                                                stroke="#ff0000"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>{" "}
                                            </g>
                                          </svg>
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          </div>

                          {/* Add file functionality */}
                          <div className="btn-container">
                            {res?.cp?.stage == "ACCEPT" &&
                              res?.rotex?.stage != "ACCEPT" &&
                              usertype == "CP" &&
                              downloadFile != "" && (
                                <div>
                                  <label className="btn add-file-btn">
                                    <svg
                                      width="20px"
                                      height="20px"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <path
                                          d="M11 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40974 4.40973 4.7157 4.21799 5.09202C4 5.51985 4 6.0799 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.0799 20 7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V12.5M15.5 5.5L18.3284 8.32843M10.7627 10.2373L17.411 3.58902C18.192 2.80797 19.4584 2.80797 20.2394 3.58902C21.0205 4.37007 21.0205 5.6364 20.2394 6.41745L13.3774 13.2794C12.6158 14.0411 12.235 14.4219 11.8012 14.7247C11.4162 14.9936 11.0009 15.2162 10.564 15.3882C10.0717 15.582 9.54378 15.6885 8.48793 15.9016L8 16L8.04745 15.6678C8.21536 14.4925 8.29932 13.9048 8.49029 13.3561C8.65975 12.8692 8.89125 12.4063 9.17906 11.9786C9.50341 11.4966 9.92319 11.0768 10.7627 10.2373Z"
                                          stroke="#000000"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>{" "}
                                      </g>
                                    </svg>
                                    <input
                                      type="file"
                                      onChange={(event) =>
                                        handleFileInputChange(event, res?._id)
                                      }
                                      className="browse-file"
                                    />
                                  </label>
                                </div>
                              )}

                            {res?.cp?.stage == "ACCEPT" && (
                              <button
                                className="btn bg-white"
                                type="button"
                                onClick={() => handleDownload()}
                                disabled={
                                  downloadFile === "" || fileStatus == true
                                }
                              >
                                <img
                                  src="/assets/images/download-icon.svg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </button>
                            )}
                            <div>
                              {res?.cp?.stage == "ACCEPT" &&
                                res?.rotex?.stage != "ACCEPT" &&
                                usertype == "CP" &&
                                downloadFile == "" && (
                                  <div>
                                    <label className="btn add-file-btn">
                                      Browse Files
                                      <input
                                        type="file"
                                        onChange={(event) =>
                                          handleFileInputChange(event, res?._id)
                                        }
                                        className="browse-file"
                                      />
                                    </label>
                                  </div>
                                )}
                            </div>
                            <select
                              name=""
                              id=""
                              className={`${
                                cpOrderPermission == "ACCEPT" ||
                                res?.cp?.stage == "ACCEPT"
                                  ? "accept"
                                  : "bg-light"
                              } order_flow_select`}
                              value={res?.cp?.stage}
                              onChange={handleCPOptionChange}
                              disabled={
                                usertype != "CP" ||
                                res?.cp?.stage == "ACCEPT" ||
                                cpOrderPermission == "ACCEPT"
                              }
                            >
                              <option value="PENDING">Pending</option>
                              <option value="ACCEPT">Accept</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    {res?.rotex?.status == true && (
                      <div className="cp_name d-flex justify-content-between align-items-center px-4  ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">Acceptance at Rotex </p>

                            {res?.rotex?.stage != "PENDING" && (
                              <>
                                <p className="m-0">
                                  <Moment format="MMM,DD HH:mm">
                                    {res?.rotex?.createdAt}
                                  </Moment>
                                </p>
                                <p className="m-0">Qty : {res?.rotex?.qty}</p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                          <div className="d-flex flex-column gap-1">
                            <div className="sap-btn-container w-100">
                              <div className="col-4 w-100">
                                {editSapSo === true && (
                                  <div className="sap-btn-container w-100">
                                    <div className="d-flex align-items-end flex-column mb-4">
                                      <span>
                                        <h6>SO No</h6>{" "}
                                      </span>
                                      <input
                                        type="text"
                                        class="form-control w-25"
                                        id="inlineFormInput"
                                        placeholder="SO No"
                                        onChange={(e) =>
                                          setSapSoInput(e.target.value)
                                        }
                                        value={sapSoInput}
                                        disabled={!isEditingSap}
                                      />
                                    </div>
                                    {isEditingSap ? (
                                      <>
                                        <div className="tick-cross-btn-container">
                                          <div>
                                            <button
                                              className="sap-so-btn"
                                              disabled={disabledunitbutton}
                                              onClick={() => Addsaporremark()}
                                            >
                                              <svg
                                                width="24px"
                                                height="24px"
                                                viewBox="0 -0.5 25 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="#00ff11"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <path
                                                    d="M5.5 12.5L10.167 17L19.5 8"
                                                    stroke="#00ff11"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></path>{" "}
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              className="sap-so-btn"
                                              onClick={() => {
                                                setIsEditingSap(false);
                                                setSapSoInput(null);
                                              }}
                                            >
                                              <svg
                                                width="20px"
                                                height="20px"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="#ff0000"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <path
                                                    d="M19 5L5 19M5.00001 5L19 19"
                                                    stroke="#ff0000"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></path>{" "}
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <button
                                        onClick={() => setIsEditingSap(true)}
                                        className="sap-so-btn"
                                      >
                                        <svg
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g
                                            id="SVGRepo_bgCarrier"
                                            stroke-width="0"
                                          ></g>
                                          <g
                                            id="SVGRepo_tracerCarrier"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></g>
                                          <g id="SVGRepo_iconCarrier">
                                            {" "}
                                            <path
                                              d="M11 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40974 4.40973 4.7157 4.21799 5.09202C4 5.51985 4 6.0799 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.0799 20 7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V12.5M15.5 5.5L18.3284 8.32843M10.7627 10.2373L17.411 3.58902C18.192 2.80797 19.4584 2.80797 20.2394 3.58902C21.0205 4.37007 21.0205 5.6364 20.2394 6.41745L13.3774 13.2794C12.6158 14.0411 12.235 14.4219 11.8012 14.7247C11.4162 14.9936 11.0009 15.2162 10.564 15.3882C10.0717 15.582 9.54378 15.6885 8.48793 15.9016L8 16L8.04745 15.6678C8.21536 14.4925 8.29932 13.9048 8.49029 13.3561C8.65975 12.8692 8.89125 12.4063 9.17906 11.9786C9.50341 11.4966 9.92319 11.0768 10.7627 10.2373Z"
                                              stroke="#000000"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></path>{" "}
                                          </g>
                                        </svg>
                                      </button>
                                    )}
                                  </div>
                                )}
                                {editSapSo === false &&
                                  (showSONOinput || sapNumber != "" ? (
                                    <div className="w-50 sap-btn-container btn-add ">
                                      <div className="w-50 btn-input-sap">
                                        <h6 className="text-center">SO No</h6>

                                        <input
                                          type="text"
                                          class="form-control"
                                          id="inlineFormInput"
                                          placeholder="SO No"
                                          onChange={(e) =>
                                            setAddSapNumber(e.target.value)
                                          }
                                          value={addSapNumber}
                                        />
                                        <div className="d-flex gap-2 align-items-center mt-1">
                                          <div>
                                            <button
                                              className="sap-so-btn"
                                              disabled={disabledunitbutton}
                                              onClick={() => Addsaporremark()}
                                            >
                                              <svg
                                                width="24px"
                                                height="24px"
                                                viewBox="0 -0.5 25 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="#00ff11"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <path
                                                    d="M5.5 12.5L10.167 17L19.5 8"
                                                    stroke="#00ff11"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></path>{" "}
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              className="sap-so-btn"
                                              onClick={() => {
                                                setShowSONOInput(false);

                                                setAddSapNumber("");
                                              }}
                                            >
                                              <svg
                                                width="20px"
                                                height="20px"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="#ff0000"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <path
                                                    d="M19 5L5 19M5.00001 5L19 19"
                                                    stroke="#ff0000"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></path>{" "}
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  ))}

                                {usertype !== "CP" && !showSONOinput ? (
                                  <div className="btn-add">
                                    {" "}
                                    <button
                                      onClick={() => setShowSONOInput(true)}
                                      className="btn add-file-btn text-black px-4 "
                                    >
                                      Add SO No
                                    </button>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>

                            <div className="d-flex flex-column gap-2">
                              {!addremarkbyRotex && usertype == "ROTEX" && (
                                <div className="btn-add text-end">
                                  <button
                                    onClick={() => setAddRemarkByRotex(true)}
                                    className="btn add-file-btn  text-black px-3"
                                  >
                                    Add Remark
                                  </button>
                                </div>
                              )}

                              {(addremarkbyRotex || remarkbyrotex != "") && (
                                <>
                                  <h6 className="text-end mt-1 mb-0">Remark</h6>

                                  <input
                                    type="text"
                                    name=""
                                    id=""
                                    className="add_remarksbyCP"
                                    placeholder="Add Remark"
                                    value={remarkbyrotex}
                                    onChange={(e) =>
                                      setRemarkByROTEX(e.target.value)
                                    }
                                    disabled={usertype != "ROTEX"}
                                  />
                                  {usertype == "ROTEX" && (
                                    <div className="d-flex justify-content-end gap-2 align-items-center">
                                      <div>
                                        <button
                                          className="sap-so-btn"
                                          disabled={disabledunitbutton}
                                          onClick={() => Addsaporremark()}
                                        >
                                          <svg
                                            width="24px"
                                            height="24px"
                                            viewBox="0 -0.5 25 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            stroke="#00ff11"
                                          >
                                            <g
                                              id="SVGRepo_bgCarrier"
                                              stroke-width="0"
                                            ></g>
                                            <g
                                              id="SVGRepo_tracerCarrier"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></g>
                                            <g id="SVGRepo_iconCarrier">
                                              {" "}
                                              <path
                                                d="M5.5 12.5L10.167 17L19.5 8"
                                                stroke="#00ff11"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>{" "}
                                            </g>
                                          </svg>
                                        </button>
                                      </div>
                                      {remarkbyrotex == "" && (
                                        <div>
                                          <button
                                            className="sap-so-btn"
                                            onClick={() => {
                                              setAddRemarkByRotex(false);
                                            }}
                                          >
                                            <svg
                                              width="20px"
                                              height="20px"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              stroke="#ff0000"
                                            >
                                              <g
                                                id="SVGRepo_bgCarrier"
                                                stroke-width="0"
                                              ></g>
                                              <g
                                                id="SVGRepo_tracerCarrier"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></g>
                                              <g id="SVGRepo_iconCarrier">
                                                {" "}
                                                <path
                                                  d="M19 5L5 19M5.00001 5L19 19"
                                                  stroke="#ff0000"
                                                  stroke-width="1.5"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></path>{" "}
                                              </g>
                                            </svg>
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                          </div>

                          <div>
                            <select
                              name=""
                              id=""
                              className={`${
                                rotexOrderPermission == "ACCEPT" ||
                                res?.rotex?.stage == "ACCEPT"
                                  ? "accept"
                                  : "bg-light"
                              } order_flow_select `}
                              value={res?.rotex?.stage}
                              onChange={handlerotexOptionChange}
                              disabled={
                                usertype === "CP" ||
                                rotexOrderPermission === "ACCEPT" ||
                                res?.rotex?.stage === "ACCEPT" ||
                                (usertype === "ROTEX" &&
                                  isAcceptanceAtRotex == "false")
                              }
                            >
                              <option value="PENDING">Pending</option>
                              <option value="ACCEPT">Accept</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    {res?.wip?.status == true && (
                      <div className="cp_name  d-flex justify-content-between px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">Work In Progress</p>
                            <p className="m-0">
                              Start Date :-
                              <Moment format="MMM,DD HH:mm">
                                {res?.rotex?.createdAt}
                              </Moment>
                            </p>
                            <p className="m-0">Qty : {res?.wip?.qty}</p>
                            {(res?.wip?.isPartialDispatch ||
                              res?.partialOrder?.length > 1) &&
                              res?.partialOrder.map((date, i) => (
                                <>
                                  {i + 1} ){" "}
                                  <p className="m-0">
                                    {" "}
                                    Partial Dispatch Date:-
                                    <Moment format="MMM,DD HH:mm">
                                      {date?.wip?.createdAt}
                                    </Moment>
                                  </p>
                                  <p className="m-0">
                                    Partial Dispatch Qty :{" "}
                                    {date?.wip?.partialDispatchQty}
                                  </p>
                                  <p className="m-0">
                                    Remaining Qty : {date?.wip?.qty}
                                  </p>
                                </>
                              ))}

                            {res?.wip?.stage != "PENDING" && (
                              <>
                                {res?.wip?.stage == "ACCEPT" && (
                                  <p className="m-0">
                                    End Date :-
                                    <Moment format="MMM,DD HH:mm">
                                      {res?.wip?.createdAt}
                                    </Moment>
                                  </p>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="wip_buttons_cont">
                          <div className="btn-add">
                            <button
                              className="add_manufacturingdate_btn"
                              onClick={() => setShowExpextedDatePopup(true)}
                            >
                              {res?.isView == false
                                ? " Add Expected Total Lead Time"
                                : "View Expected Total Lead Time "}
                            </button>
                          </div>

                          <div className="d-flex gap-2 align-items-center">
                            <span>Dispatched</span>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input order_toggle"
                                type="checkbox"
                                id="flexSwitchCheckChecked"
                                checked={
                                  wiptoggleactive == true ||
                                  res?.wip?.stage == "ACCEPT"
                                }
                                value="ACCEPT"
                                disabled={
                                  res?.wip?.stage == "ACCEPT" ||
                                  usertype == "CP" ||
                                  (usertype === "ROTEX" &&
                                    isAcceptanceAtWIP == "false") ||
                                  res?.doneStatus == "PENDING"
                                }
                                onChange={handleWIPOptionChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {res?.in_trasit?.status == true && (
                      <div className="cp_name  d-flex justify-content-between px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">In-Transit</p>

                            {res?.in_trasit?.stage != "PENDING" && (
                              <>
                                <p className="m-0">
                                  <Moment format="MMM,DD HH:mm">
                                    {res?.in_trasit?.createdAt}
                                  </Moment>
                                </p>
                                <p className="m-0">
                                  Qty : {res?.in_trasit?.qty}
                                </p>
                              </>
                            )}
                          </div>
                        </div>
                        <div className="d-flex gap-2 align-items-center">
                          <span>GRN</span>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input order_toggle"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              checked={
                                intrasittoggleactive == true ||
                                res?.in_trasit?.stage == "ACCEPT"
                              }
                              value="ACCEPT"
                              disabled={
                                res?.in_trasit?.stage == "ACCEPT" ||
                                usertype != "CP"
                              }
                              onChange={handleTransitOptionChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {res?.grn?.status == true && (
                      <div className="cp_name  px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">GRN</p>
                            <p className="m-0">
                              <Moment format="MMM,DD HH:mm">
                                {res?.grn?.createdAt}
                              </Moment>
                            </p>
                            <p className="m-0">Qty : {res?.grn?.qty}</p>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* //  View Order Master MTO  MODEL END  */}

      {/* //  CONFIRMATION Order Master MTO  MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showConfirmPopup}
        onClose={() => handleclodeqtyconfirmation()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showConfirmPopup}>
          <Box
            sx={styleForMediaQuery}
            className="model_box order_confirmation_box"
          >
            <div>
              <h4 className="mb-4"> Confirmation</h4>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
              <p className="m-0">
                Are you sure you want to process {quantityValue} Qtys?
              </p>
            </div>
            {/* <div className="d-flex gap-2 align-items-center justify-content-end ">
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  setShowConfirmPopup(false);

                  EditOrderRecoommand("ACCEPT");
                }}
                disabled={disabledunitbutton}
              >
                Yes
              </button>
              <button
                className="btn btn-primary "
                onClick={() => setShowChangeqtyPopup(true)}
              >
                Change Qty
              </button>
            </div> */}

            <div className="d-flex gap-2 align-items-center justify-content-end ">
              {confirmdispatch ? (
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setShowConfirmPopupForPartiallyDispatch(true);
                  }}
                  disabled={disabledunitbutton}
                >
                  Yes
                </button>
              ) : (
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setShowConfirmPopup(false);

                    EditOrderRecoommand("ACCEPT");
                  }}
                  disabled={disabledunitbutton}
                >
                  Yes
                </button>
              )}

              <button
                className="btn btn-primary "
                onClick={() => setShowChangeqtyPopup(true)}
              >
                Change Qty
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* //  CONFIRMATION Order Master MTO  MODEL END  */}

      {/* //  ADD QTY Order Master MTO  MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showChangeqtyPopup}
        onClose={() => setShowChangeqtyPopup(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showChangeqtyPopup}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <p className="m-0">Add QTY </p>
              <div className="d-flex align-items-center gap-3 ">
                <button
                  className="closebtn_cont"
                  onClick={() => setShowChangeqtyPopup(false)}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                <div className="col- md-12">
                  <label htmlFor="" className="mb-1">
                    QTY{" "}
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter QTY "
                    className="add_inut_field"
                    value={quantityValue}
                    onChange={(e) =>
                      setQuantityValue(
                        e.target.value === "" ? "" : parseInt(e.target.value)
                      )
                    }
                  />
                </div>
              </div>
              <div className="addbox_btn_cont">
                <button
                  onClick={() => {
                    Setdisableunitbtn(true);
                    EditOrderRecoommand("ACCEPT");
                  }}
                  disabled={disabledunitbutton}
                >
                  Save
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* //  ADD QTY Order Master MTO  MODEL END  */}

      {/* //  ADD Expexted Manufacturing Days Pop_UP MTO  MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showExpectedDaysPopup}
        onClose={() => setShowExpextedDatePopup(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showExpectedDaysPopup}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <p className="m-0">Add Expected Total Lead Time </p>
              <div className="d-flex align-items-center gap-3 ">
                <button
                  className="closebtn_cont"
                  onClick={() => setShowExpextedDatePopup(false)}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                <div className="col- md-12">
                  <label htmlFor="" className="mb-1">
                    Enter Expected Total Lead Time
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter Expected ManuFacturing Days "
                    className="add_inut_field"
                    value={expectedManufacturingDay}
                    onChange={(e) =>
                      setExpectedManufacturingDaysValue(
                        e.target.value == "" ? "" : parseInt(e.target.value)
                      )
                    }
                    disabled={!editManuffacturingDate}
                  />
                </div>
              </div>
              <div className="d-flex gap-2">
                <div className="d-flex">
                  {!editManuffacturingDate && (
                    <button
                      className="Edit_Buttons_manfucatureDay"
                      disabled={
                        (usertype == "ROTEX" &&
                          (isEditApproval == false || isApproval == false)) ||
                        usertype == "CP"
                      }
                      onClick={() => {
                        Setdisableunitbtn(true);
                        AddExpextedDay();
                        // setEditManuffacturingDate(true)
                      }}
                    >
                      + Add
                    </button>
                  )}
                </div>
                <div className="addbox_btn_cont">
                  {editManuffacturingDate && (
                    <button
                      onClick={() => {
                        Setdisableunitbtn(true);
                        AddExpextedDay();
                      }}
                      disabled={
                        (usertype == "ROTEX" && isEditApproval == false) ||
                        usertype == "CP" ||
                        disabledunitbutton
                      }
                    >
                      Send For Approval
                    </button>
                  )}
                </div>

                {!editManuffacturingDate && (
                  <div>
                    <button
                      className="Edit_Buttons_manfucatureDay"
                      disabled={
                        (usertype == "ROTEX" && isEditApproval == false) ||
                        usertype == "CP"
                      }
                      onClick={() => {
                        setEditManuffacturingDate(true);
                      }}
                    >
                      <RiEditFill /> Edit
                    </button>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* //   ADD Expexted Manufacturing Days Pop_UP MTO  MODEL  END  */}

      {/* MODAL START FOR PARTIALLY DISPATCH FUNCTIONALITY */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showConfirmPopupforpartially}
        onClose={() => handlecloseconfirmationforPartially()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showConfirmPopupforpartially}>
          <Box
            sx={styleForMediaQuery}
            className="model_box order_confirmation_box"
          >
            <div>
              <h4 className="mb-4"> Confirmation</h4>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
              <p className="m-0">Do you want to Dispatch partially ?</p>
            </div>
            <div className="d-flex gap-2 align-items-center justify-content-end ">
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  setShowConfirmPopup(false);
                  setShowPartiallyqtyPopup(true);
                  setPartiallyDispatch(true);
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-primary "
                onClick={() => {
                  setShowConfirmPopup(false);
                  Setdisableunitbtn(true);

                  setShowPartiallyqtyPopup(false);
                  EditOrderRecoommand("ACCEPT");
                }}
                disabled={disabledunitbutton}
              >
                No
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showpartiallyqtyPopup}
        onClose={() => setShowPartiallyqtyPopup(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showpartiallyqtyPopup}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              {/* <p className="m-0">Add Unit </p> */}
              <div className="d-flex align-items-center gap-3 ">
                <button
                  className="closebtn_cont"
                  onClick={() => setShowPartiallyqtyPopup(false)}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                <div className="col- md-12">
                  <label htmlFor="" className="mb-1">
                    Enter partial dispatch qty
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter QTY "
                    className="add_inut_field"
                    value={partiallyquantityValue}
                    onChange={(e) =>
                      setPartiallyQuantityValue(
                        e.target.value === "" ? "" : parseInt(e.target.value)
                      )
                    }
                  />
                </div>
              </div>
              <div className="addbox_btn_cont">
                <button
                  onClick={() => {
                    Setdisableunitbtn(true);
                    EditOrderRecoommand("ACCEPT");
                  }}
                  disabled={disabledunitbutton}
                >
                  Save
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default OrderTable;
