import React, { useState, useEffect } from "react";
import "../Dashboard/MainTable/MainTable.css";
import store from "../../Redux/MyRedux/store";
import {
  Addconsumptionmaster,
  Editconsumptionmaster,
  Showlistconsumptionmaster,
  UpdateConsumptionExcelSheet,
  UpdateConsumptionStatus,
  Viewconsumptionmaster,
  channellistdropdowm,
  inventorylistdropdowm,
  inventoryProductListDropdowm,
} from "../../Redux/MyRedux/action/userActions";
import { LuSearch } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Pagination from "react-mui-pagination";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Slider from "react-slick";
import * as moment from "moment";
import sorting_logo_img from '../../Assets/table/shortarrow.png'
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegRectangleList } from "react-icons/fa6";
import { TbPencil } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";
import FilterModal from "./filterpopup/filter";

function ConsumptionMasterTable() {
  const navigate = useNavigate();

  const [usertype, setUserType] = useState("");

  const formatDateForInput = (apiDate) => {
    const dateObject = new Date(apiDate);
    const year = dateObject.getFullYear();
    const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(dateObject.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius: "20px",
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };


  const [productlistdropdown, setProductListDropdown] = useState([]);
  const [cpmasterlistdropdown, setCPmasterListDropdown] = useState([]);


  const [updatedAPI, setUpdateAPI] = useState("");

  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [consumptionid, setConsumptionId] = useState("");
  const [total_count, setTotalCount] = useState(0);
  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const initialvalueforadd = {
    customerid: "",
    itemcode: "",
    itemdesc: "",
    Productname: "",
    subcategory_name: "",
    subcategory_id: "",
    category: "",
    tog: "",
    onhandstock: "",
    qualifieddemand: "",
    qty: "",
    Date: "",
  };
  const [addfromdata, setAddFormData] = useState(initialvalueforadd);
  const [cpname, setCpName] = useState("");
  const [itemcode, setItemcode] = useState("");

  const [consunptionStatus, setConsumptionStatus] = useState(false);
  const handleStatusUpdate = () => {
    const updatestatus = !consunptionStatus;
    setConsumptionStatus(updatestatus);
    handleUpdateConsumptionStatus(consumptionid, updatestatus);
  };
  const hanlechangeitemcode = (e) => {
    setItemcode(e);
    const selectedProduct = productlistdropdown.find(
      (product) => product._id == e.value
    ); // Use the updated value
    if (selectedProduct) {
      setAddFormData((prevState) => ({
        ...prevState,
        // itemcode: selectedProduct.itemCode,
        itemdesc: selectedProduct.itemDescription,
        Productname: selectedProduct.productName,
        subcategory_name: selectedProduct.subcategoryName,
        subcategory_id: selectedProduct.subcategoryid,
        category: selectedProduct.categoryName,
      }));
    }
  };
  const addhandlechange = (e) => {
    const { name, value } = e.target;
    const parsedValue =
      name == "qty" ? (value == "" ? null : parseInt(value)) : value;

    setAddFormData((prevData) => ({ ...prevData, [name]: parsedValue }));
  };
  const initialvalueforupdate = {
    customerid: "",
    productid: "",
    cpname: "",
    itemcode: "",
    itemdesc: "",
    Productname: "",
    subcategory_name: "",
    subcategory_id: "",
    category: "",
    tog: "",
    onhandstock: "",
    qualifieddemand: "",
    qty: "",
    Date: "",
  };
  const [updateformdata, setupdateFormData] = useState(initialvalueforupdate);
  const updatehandlechange = (e) => {
    const { name, value } = e.target;
    const parsedValue =
      name == "qty" ? (value == "" ? null : parseInt(value)) : value;
    setupdateFormData((prevData) => ({ ...prevData, [name]: parsedValue }));

    const selectedProduct = productlistdropdown.find(
      (product) => product._id == value
    ); // Use the updated value

    if (selectedProduct) {
      setupdateFormData((prevState) => ({
        ...prevState,

        itemdesc: selectedProduct.itemDescription,
        Productname: selectedProduct.productName,
        subcategory_name: selectedProduct.subcategoryName,
        subcategory_id: selectedProduct.subcategoryid,
        category: selectedProduct.categoryName,
      }));
    }
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [filtercpname, setFilterCpName] = useState([]);
  const [filterItemCode,  setFilteItemCode] = useState([]);
  const [filteritemDesc, setFilterItemDesc] = useState([]);
  const [filterProductName, setFilterProsuctName] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterSubCategory, setFilterSubCategory] = useState([]);
  const [filterqty, setFilterQTY] = useState([]);
  const [filterdate, setFilterDate] = useState([]);
  const [filterdescription, setFilterDescription] = useState([]);

  const statesetter = {
    setFilterCpName,
    setFilteItemCode,
    setFilterProsuctName,
    setFilterDescription,
    setFilterCategory,
    setFilterSubCategory,
    setFilterQTY,
    setFilterDate,
    setUpdateAPI



  }

  const propsState = {
    filtercpname,
    filterItemCode,
    filterProductName,
    filterdescription,
    filterCategory,
    filterSubCategory,
    filterqty,
    filterdate
 }


  const [listdata, setListdata] = useState([]);
  const [channelPartnerId, setChannelPartnerId] = useState("");
  const [productId, setProductId] = useState("");
  const [inventorychannelpartnetId, setInventoryChannelPartnerId] =
    useState("");

  const getdata = () => {
    const data = {
      search: search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      itemCode: filterItemCode.map((item) => item.label),
      itemDescription: filteritemDesc.map((item) => item.label),
      productName: filterProductName.map((item) => item.label),
      subCategoryName: filterSubCategory.map((item) => item.label),
      categoryName: filterCategory.map((item) => item.label),
      qty: filterqty.map((item) => item.label),
      cpName: filtercpname.map((item) => item.label),
      channelPartnerId: channelPartnerId,
      active: true,
      date:filterdate.map((item) => item.label),
    };

    try {
      store.dispatch(Showlistconsumptionmaster(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data[0]?.docs.length > 0) {
            setListdata(res?.data[0]?.docs);
            setTotalCount(res?.data[0]?.paginate[0]?.totalDocs || 0);
          } else {
            setListdata([]);
            setTotalCount(0);
          }
        }
        setUpdateAPI('')
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    setUserType(localStorage.getItem("usertype"));
    setChannelPartnerId(localStorage.getItem("channelPartnerId"));
    if (localStorage.getItem("usertype") == "CP") {
      if (channelPartnerId) {
        getdata();
      }
    } else {
      getdata();
    }
  }, [recordperpage, page, search, updatedAPI, channelPartnerId]);

  const handledduser = () => {
    const data = {
      channelPartnerId: cpname.value,
      productId: itemcode.value,
      itemId: "",
      qty: addfromdata.qty,
      date: addfromdata.Date,
    };
    console.log(data , 'data')

    try {
      store.dispatch(Addconsumptionmaster(data)).then((res) => {
        if (res?.isError == false) {
          handleClose();
          toast.success(res?.message);
          getdata();
          setAddFormData(initialvalueforadd);
          setCpName("");
          setItemcode("");
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handleupdateuser = () => {
    const data = {
      channelPartnerId: updateformdata.customerid,
      productId: updateformdata.productid,
      qty: updateformdata.qty,
      date: updateformdata.Date,
      consumptionId: consumptionid,
    };
    try {
      store.dispatch(Editconsumptionmaster(data)).then((res) => {
        if (res?.isError == false) {
          handleUpdateBoxClose();
          toast.success(res?.message);
          getdata();
          setupdateFormData(initialvalueforadd);
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleviewconsumptionmaster = (id) => {
    setConsumptionId(id);
    const data = {
      id: id,
    };
    try {
      store.dispatch(Viewconsumptionmaster(data)).then((res) => {
        if (res?.isError == false) {
          setInventoryChannelPartnerId(res?.data?.channelPartnerId);
          setProductId(res?.data?.productId);
          setupdateFormData((prevState) => ({
            ...prevState,
            cpname: res?.data?.cpName,
            itemcode: res?.data?.itemCode,
            qty: res?.data?.qty,
            Date: formatDateForInput(res?.data?.date),
            itemdesc: res?.data?.itemDescription,
            Productname: res?.data?.productName,
            subcategory_name: res?.data?.subCategoryName,
            subcategory_id: res?.data?.subcategoryid,
            category: res?.data?.categoryName,
            customerid: res?.data?.channelPartnerId,
            productid: res?.data?.productId,
          }));
          setConsumptionStatus(res?.data?.active);
               getproductdropdownlist(res?.data?.channelPartnerId);
          const selectedProduct = productlistdropdown.find(
            (product) => product._id == res?.data?.productId
          ); // Use the updated value

          if (selectedProduct) {
            setupdateFormData((prevState) => ({
              ...prevState,
              itemdesc: selectedProduct.itemDescription,
              Productname: selectedProduct.productName,
              subcategory_name: selectedProduct.subcategoryName,
              subcategory_id: selectedProduct.subcategoryid,
              category: selectedProduct.categoryName,
            }));
          }
        } else {
          setupdateFormData(initialvalueforupdate);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const getcpmasterlistdropdownlist = () => {
    try {
      store.dispatch(channellistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setCPmasterListDropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
  const getinventorylistdropdownlist = () => {
    const data = {
      productId: itemcode.value || productId,
      channelPartnerId: cpname.value || inventorychannelpartnetId,
    };
    try {
      store.dispatch(inventorylistdropdowm(data)).then((res) => {
        if (res?.isError === false) {
          setAddFormData((prevState) => ({
            ...prevState,
            // itemcode: selectedProduct.itemCode,
            tog: res?.data.tog,
            onhandstock: res?.data.onHandStock,
            qualifieddemand: res?.data.qualifiedDemand,
          }));
        } else {
          setAddFormData((prevState) => ({
            ...prevState,
            // itemcode: selectedProduct.itemCode,
            tog: "NaN",
            onhandstock: "NaN",
            qualifieddemand: "NaN",
          }));
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
  useEffect(() => {
    if (
      (itemcode.value && cpname.value) ||
      (productId && inventorychannelpartnetId)
    ) {
      getinventorylistdropdownlist();
    }
  }, [itemcode.value, cpname.value, productId, inventorychannelpartnetId]);


  const getproductdropdownlist = (id) => {
    const requestOption = {
      channelPartnerId:id

    }
    try {
      store.dispatch(inventoryProductListDropdowm(requestOption)).then((res) => {
        if (res?.isError === false) {
          setProductListDropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
 

  const handleUpdateConsumptionStatus = (id, value) => {
    const data = {
      active: value,
    };
    store.dispatch(UpdateConsumptionStatus(data, id)).then((res) => {
      if (res?.isError == false) {
        toast.success(res?.message);
        getdata();
      } else {
        toast.error(res?.message);
      }
    });
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [updateboxopen, setUpdateBoxOpen] = React.useState(false);
  const handleUpdateBoxOpen = () => setUpdateBoxOpen(true);
  const handleUpdateBoxClose = () => setUpdateBoxOpen(false);

  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      "Customer Id",
      "CP Name",
      " Item Code",
      " Item Description",
      "Product Name ",
      "Product Id",
      " Sub-category ",
      " Sub-category Id",
      "Category",
      "Category Id",
      "Qty",
      "Date (DD-MM-YYYY)",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [
        item.channelPartnerId,
        item.cpName,
        item.itemCode,
        item.itemDescription,
        item.productName,
        item?._id,
        item.subCategoryName,
        item.subcategoryid,
        item.categoryName,
        item.categoryid,
        item.qty,
         moment(item?.date).format("DD-MM-YYYY")
      ]),
    ];

    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "data.xlsx");
  };


  const optionListcpname = cpmasterlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.companyName,
  }));

  const optionListproduct = productlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.itemCode,
  }));





  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handleUpdateConsumptionStatus(userId, updatedStatus);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        // Parse the Excel file to JSON
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Define a mapping between old header names and new parameter names
        const headerMapping = {
          "Item Code": "itemCode",
          Qty: "qty",
          "Date (YYYY-MM-DD)": "date",
          "CP Name": "companyName",
        };

        // Map the old header names to the new parameter names in the JSON data
        const mappedJsonData = jsonData.map((item) => {
          const mappedItem = {};
          for (const oldHeader in item) {
            if (oldHeader in headerMapping) {
              mappedItem[headerMapping[oldHeader]] = item[oldHeader];
            }
          }
          return mappedItem;
        });

        updateNewExcel(mappedJsonData);
      };
      reader.readAsBinaryString(file);
    }
  };

  const updateNewExcel = (json) => {
    try {
      store.dispatch(UpdateConsumptionExcelSheet(json)).then((res) => {
        if (res?.isError == false) {
          toast.success(res?.message);
          if (res?.data?.errorArrray.length > 0) {
            toast.error(
              ` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`
            );
            const headers = [
              "CP Name",
              "Item Code",
              "Qty",
              "Date (YYYY-MM-DD)",
              "Error",
            ];

            // Map data to a new array with custom headers
            const dataWithCustomHeaders = [
              headers,
              ...res?.data?.errorArrray.map((item) => [
                item.companyName,
                item.itemCode,
                item.qty,
                item.date,
                item.error,
              ]),
            ];
            // Generate Excel file
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelBuffer = XLSX.write(workbook, {
              bookType: "xlsx",
              type: "array",
            });
            // Save file
            const fileData = new Blob([excelBuffer], {
              type: "application/octet-stream",
            });
            saveAs(fileData, "Errordata.xlsx");
          } else {
            // Display an alert when errorArray is empty
            toast.success("No error data found. All data upload Successfully");
          }
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      return error;
    }
  };

  const clearFilterHeader = (filterName) => {
    switch (filterName) {
      case "CPName":
        setFilterCpName([]);
        setUpdateAPI("1");
        break;
      case "ItemCode":
        setFilteItemCode([]);
        setUpdateAPI("1");
        break;
      case "ProductName":
        setFilterProsuctName([]);
        setUpdateAPI("2");
        break;
      case "Category":
        setFilterCategory([]);
        setUpdateAPI("3");
        break;
      case "SubCategory":
        setUpdateAPI("4");
        setFilterSubCategory([]);
        break;
      case "QTY":
        setFilterQTY([]);
        setUpdateAPI("5");
        break;
      case "date":
        setFilterDate([]);
        setUpdateAPI("date");
          break;
          case "description":
            setFilterDescription([]);
            setUpdateAPI("description");
              break;
          

      default:
        break;
    }
  };

  const handleTemplateDownload = () => {
    // Custom headers
    const headers = ["CP Name", "Item Code", "Qty", "Date (YYYY-MM-DD)"];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [headers, []];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "Consumption template.xlsx");
  };


const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};




const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
const sortBy = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  const sortedData = [...listdata].sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  setListdata(sortedData);
  setSortConfig({ key, direction });
};

const [isHovered , setIsHovered] = useState(false)


const handleItemsPerPageChange = (value) => {
  setRecordPerPage(value);
  setPage(1); // Reset to page 1 whenever items per page changes
};

const calculateSerialNumber = (index) => {
  return (page - 1) * recordperpage + index + 1;
};
const startSerialNumber = calculateSerialNumber(0);
const endSerialNumber = calculateSerialNumber(listdata.length - 1);

  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading">Consumption Master</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>

<div className="d-flex gap-2 flex-wrap ms-2 ">
       
       <div className="button-container">
       {usertype != "CP" && 
      <button
        className={`button ${isHovered ? 'hovered' : ''} import_export_btns`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>Excel Import</span>
        <div className='pageheader_icons_container'>
          <RiAttachment2 className='import_attach_icon' />
        </div>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          className="import_excel_input"
          style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
        />
      </button>
}
    </div>
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
          onClick={() => handleDownloadEXcel()}
      >
        <span>Excel Export</span>
        <div className='pageheader_icons_container' >
        <MdOutlineCloudDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
    {usertype != "CP" && 
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`} 
       onClick={() => handleTemplateDownload()} 
      >
        <span>Import Template</span>
        <div className='pageheader_icons_container' >
        <TbFileDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
}
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
         onClick={() => {
          handleOpen();
          getcpmasterlistdropdownlist();

        }} 
      >
        <span>Add Consumption</span>
        <div className='pageheader_icons_container' >
        <IoMdAddCircleOutline className='pageheader_react_icons' />
        </div>
      </button>
    </div>
    
         
        </div>
      </div>

      <div className="page_header_slider_container">
      <div className="d-flex align-items-center gap-3" style={{ width:"100%" }}  >
        <div className="">
          
          <FilterModal statesetter={statesetter}  propsState={propsState} />

        </div>
        <div className="slider_container ms-5">
          {" "}
          <Slider {...settings}>
            {filtercpname.length > 0 && (

              <div class="active-filters">
                
            <button>
            <span className='filter_text_cont' >   CP Name is 
              {filtercpname.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filtercpname.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilterHeader("CPName")} /> </button>
              </div>
            )}
            {filterItemCode.length > 0 && (
              <div class="active-filters">
                    <button>  <span className='filter_text_cont' > Item Code is   {filterItemCode.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterItemCode.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilterHeader("ItemCode")}/> </button>

              </div>
            )} 
            {filterProductName.length > 0 && (
              <div class="active-filters">
                     <button>  <span className='filter_text_cont' > Product Name  is {filterProductName.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterProductName.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilterHeader("ProductName")}/> </button>
              </div>
            )}
            
            {filterdescription.length > 0 && (
              <div class="active-filters">
                     <button>  <span className='filter_text_cont' > Item Description is  {filterdescription.map((item, index) => ( <span  className="ellipsis-button_QD" key={index}>
      {item.label}
      {index < filterdescription.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilterHeader("description")}/> </button>
              </div>
            )}
            {filterCategory.length > 0 && (
              <div class="active-filters">
                  <button>  <span className='filter_text_cont' > Category is {filterCategory.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterCategory.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))}  </span> <RxCross2 onClick={() => clearFilterHeader("Category")}/> </button>
              </div>
            )}
            {filterSubCategory.length > 0 && (
              <div class="active-filters">
                 <button> <span className='filter_text_cont' >  Sub-Category is {filterSubCategory.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterSubCategory.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilterHeader("SubCategory")}/> </button>

              </div>
            )}
            {filterqty.length > 0 && (
              <div class="active-filters">
                  <button> <span className='filter_text_cont' >  QTY is  {filterqty.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterqty.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))}</span> <RxCross2 onClick={() => clearFilterHeader("QTY")}/> </button>
              </div>
            )}  
             {filterdate.length > 0 && (
              <div class="active-filters">
                  <button> <span className='filter_text_cont' > Date is  {filterdate.map((item, index) => ( <span  className="ellipsis-button_date" key={index}>
      {item.label}
      {index < filterdate.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilterHeader("date")}/> </button>
              </div>
            )}  
           
          </Slider>
        </div>
        </div>

      
          

        <button className='inactive_page_btn' 
        onClick={() => navigate("/inactiveconsumptionlist")} > <FaRegRectangleList className='list_icons' /> <span> Inactive Consumptions List </span> </button>
       
      </div>

      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">Action</td>

              <td scope="col">
                {" "}
                <div className="th_common">
                  <span> CP Name </span>
                 
                  <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("cpName")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span> Item Code</span>
                 
                    <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("itemCode")}
                  />
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span> Item Description</span>
                 
                   <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("itemDescription")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Product Name</span>
                 
                  <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("productName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Category</span>
                 
                  <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("categoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Sub-Category</span>
                  
                    <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("subCategoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> QTY</span>
                 
                  <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("qty")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common"> 
                  <span> Status</span>
                 
                </div>
              </td>
              <td>
                <div className="th_common">
                  <span> Date</span>
                 
                    <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("date")}
                  />
                </div>
              </td>
            </tr>
          <br />
          </thead>
          <tbody>
            {listdata?.map((value, i) => (
              <>
                <tr key={i}>
                <td>{calculateSerialNumber(i)}</td>

                  <td>
                   
                    <button  className='table_edit_btn'
                  
                  onClick={() => {
                  
                    getcpmasterlistdropdownlist();
                    handleUpdateBoxOpen();
                    handleviewconsumptionmaster(value?._id);
                  }}
                  
                  >
                         <TbPencil />
                         </button>
                  </td>
                  <td>{value?.cpName}</td>
                  <td>{value?.itemCode}</td>
                  <td>{value?.itemDescription}</td>
                  <td>{value?.productName}</td>
                  <td>{value?.categoryName}</td>
                  <td>{value?.subCategoryName}</td>
                  <td>{value?.qty}</td>
                  <td>
                    {" "}
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input addbox_radio"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={value?.active}
                        onChange={() => toggleStatus(value?._id)}
                      />
                    </div>
                  </td>
                  <td>
                    <Moment format="DD-MM-YYYY">{value.date}</Moment>
                  </td>
                </tr>
                <br />
                </>
              ))}
          </tbody>
        </table>
      </div>

    


      <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} >   Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => handleItemsPerPageChange(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>

     
      {/* //  Add Consumption Master MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
            <div className='d-flex align-items-center gap-3' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Add Consumption Master </p>
            </div>
              <div className="d-flex align-items-center gap-3 ">
                <button className="closebtn_cont" onClick={handleClose}>
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                {usertype !== "CP" && (
                  <div className="col-md-4">
                    <label htmlFor="" className="mb-1">
                      CP Name
                    </label>
                    <Select
                      options={optionListcpname}
                      placeholder="Select CP Name"
                      value={cpname}
                      onChange={(e) =>  {setCpName(e); getproductdropdownlist(e.value)} }
                      isSearchable={true}
                      className="react_inbuild_select"
                    />
                  </div>
                )}

                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    {" "}
                    Item Code
                  </label>
                  <Select
                    options={optionListproduct}
                    placeholder="Select Item Code"
                    value={itemcode}
                    onChange={hanlechangeitemcode}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Item Description
                  </label>
                  <input
                    type="text"
                    name="itemdesc"
                    id=""
                    placeholder="Enter Item Description"
                    className="add_inut_field"
                    value={addfromdata.itemdesc}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Product Name
                  </label>
                  <input
                    type="text"
                    name="Productname"
                    id=""
                    placeholder=" Entet Product Name"
                    className="add_inut_field"
                    value={addfromdata.Productname}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Category
                  </label>
                  <input
                    type="text"
                    name="category"
                    id=""
                    placeholder="Enter Category Name"
                    className="add_inut_field"
                    value={addfromdata.category}
                    disabled
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Sub-Category
                  </label>
                  <input
                    type="text"
                    name="subcategory_name"
                    id=""
                    placeholder="Enter Sub-Category Name"
                    className="add_inut_field"
                    value={addfromdata.subcategory_name}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    QTY
                  </label>
                  <input
                    type="text"
                    name="qty"
                    id=""
                    placeholder="Enter QTY"
                    className="add_inut_field"
                    value={addfromdata.qty}
                    onChange={addhandlechange}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Date
                  </label>
                  <input
                    type="date"
                    name="Date"
                    id=""
                    placeholder="dd/mm/yyyy"
                    className="add_inut_field"
                    value={addfromdata.Date}
                    onChange={addhandlechange}
                    min={getCurrentDate()} // Set min and max to today's date
                    max={getCurrentDate()}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    TOG
                  </label>
                  <input
                    type="text"
                    name="tog"
                    id=""
                    placeholder=" Enter TOG"
                    className="add_inut_field"
                    value={addfromdata.tog}
                    onChange={addhandlechange}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    On Hand Stock
                  </label>
                  <input
                    type="text"
                    name="onhandstock"
                    id=""
                    placeholder="On Hand Stock"
                    className="add_inut_field"
                    value={addfromdata.onhandstock}
                    onChange={addhandlechange}
                    disabled
                  />
                </div>{" "}
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Qualified Demand
                  </label>
                  <input
                    type="text"
                    name="qualifieddemand"
                    id=""
                    placeholder="Qualified Demand"
                    className="add_inut_field"
                    value={addfromdata.qualifieddemand}
                    onChange={addhandlechange}
                    disabled
                  />
                </div>
              </div>

              <div className="addbox_btn_cont my-4 mt-3">
                <button onClick={() => handledduser()}>Save</button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* //  Add Consumption Master MODEL END  */}

      {/* //  Edit Consumption Master MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={updateboxopen}
        onClose={handleUpdateBoxClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={updateboxopen}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
            
              <div className="d-flex gap-3 align-items-center" >
          <button  className='table_edit_btn'>
                         <TbPencil />
                         </button>
            <p className="m-0">Edit Consumption Master</p>
            </div>
              <div className="d-flex align-items-center gap-3 ">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input addbox_radio"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={consunptionStatus == true}
                    onChange={handleStatusUpdate}
                  />
                </div>
                <button
                  className="closebtn_cont"
                  onClick={handleUpdateBoxClose}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                {usertype !== "CP" && (
                  <div className="col-md-4">
                    <label htmlFor="" className="mb-1">
                      CP Name
                    </label>

                    <input
                      type="text"
                      name="cpname"
                      id=""
                      placeholder="Maggi noodles"
                      className="add_inut_field"
                      value={updateformdata.cpname}
                      disabled
                    />
                  </div>
                )}

                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    {" "}
                    Item Code
                  </label>

                  <input
                    type="text"
                    name="itemcode"
                    id=""
                    placeholder="Maggi noodles"
                    className="add_inut_field"
                    value={updateformdata.itemcode}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Item Description
                  </label>
                  <input
                    type="text"
                    name="itemdesc"
                    id=""
                    placeholder="Maggi noodles"
                    className="add_inut_field"
                    value={updateformdata.itemdesc}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Product Name
                  </label>
                  <input
                    type="text"
                    name="Productname"
                    id=""
                    placeholder="product name"
                    className="add_inut_field"
                    value={updateformdata.Productname}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Category
                  </label>
                  <input
                    type="text"
                    name="category"
                    id=""
                    placeholder="600"
                    className="add_inut_field"
                    value={updateformdata.category}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Sub-Category
                  </label>
                  <input
                    type="text"
                    name="subcategory_name"
                    id=""
                    placeholder="Cup Noodles"
                    className="add_inut_field"
                    value={updateformdata.subcategory_name}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    QTY
                  </label>
                  <input
                    type="text"
                    name="qty"
                    id=""
                    placeholder="20"
                    className="add_inut_field"
                    value={updateformdata.qty}
                    onChange={updatehandlechange}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Date
                  </label>
                  <input
                    type="date"
                    name="Date"
                    id=""
                    placeholder="dd/mm/yyyy"
                    className="add_inut_field"
                    value={updateformdata.Date}
                    onChange={updatehandlechange}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    TOG
                  </label>
                  <input
                    type="text"
                    name="tog"
                    id=""
                    placeholder="TOG"
                    className="add_inut_field"
                    value={addfromdata.tog}
                    onChange={addhandlechange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    On Hand Stock
                  </label>
                  <input
                    type="text"
                    name="onhandstock"
                    id=""
                    placeholder="On Hand Stock "
                    className="add_inut_field"
                    value={addfromdata.onhandstock}
                    onChange={addhandlechange}
                  />
                </div>{" "}
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Qualified Demand
                  </label>
                  <input
                    type="text"
                    name="qualifieddemand"
                    id=""
                    placeholder="Qualified demand "
                    className="add_inut_field"
                    value={addfromdata.qualifieddemand}
                    onChange={addhandlechange}
                  />
                </div>
              </div>

              <div className="addbox_btn_cont my-4 mt-3">
                <button onClick={() => handleupdateuser()}>Save</button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* //  Edit Consumption Master MODEL END  */}
    </div>
  );
}

export default ConsumptionMasterTable;
