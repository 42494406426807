import React, { useEffect, useState } from "react";
import Pagination from "react-mui-pagination";
import store from "../../../Redux/MyRedux/store";
import { Addunitlist, ShowIndustrylist, ShowUnitlist, UpdateIndsutryStatus, UpdateUnitStatus, Updateunitlist, Viewunitlist, } from "../../../Redux/MyRedux/action/userActions";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineEye } from"react-icons/ai"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaRegRectangleList } from "react-icons/fa6";

function InactiveIndustryListTable() {
  const [unit , setUnit] = useState("")
  const [unit_id , setUnitId] = useState("")

  const [newunit , setNewUnit] = useState("")
  const [unitstatus , setUnitStatus] = useState(false)


  const [page, setPage] = React.useState(1);
  const [total_count, setTotalCount] = React.useState(0);
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [Search, setSearch] = useState("");
  const [listdata, setListdata] = useState([{

  }]);


 const navigate = useNavigate();
  
  const handleChange = (event, value) => {
    setPage(value);
  };
  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      active:false
    };
    try {
      store.dispatch(ShowIndustrylist(data)).then((res) => {
        if (res?.isError == false) {
          if(res?.data[0]?.docs.length >0) {
          setListdata(res?.data[0]?.docs);
          setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          }
          else {
            setListdata([]);
            setTotalCount(0);
          }
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getdata();
  }, [recordperpage ,page,Search]);

  const handleupdateindustrystatus = (id ,value) =>{
    const data = {
      active:value
    };
    try {
      store.dispatch(UpdateIndsutryStatus(data ,id)).then((res) => {
        if (res?.isError == false) {
           toast.success(res?.message)
           getdata();
        }
        else {
          toast.error(res?.message)

        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  const toggleStatus = (userId) => {
 
    const userToUpdate = listdata.find((user) => user._id === userId);
  
    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handleupdateindustrystatus(userId,updatedStatus )
    }
  };
  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading "> Inactive Industry Applications</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
            <div className="input-group">
                <input type="text" className="form-control bg-white border-0 small page_search" placeholder="Search" aria-label="Search" aria-describedby="basic-addon2"  onChange={(e) =>setSearch(e.target.value)  } />
                <div className="input-group-append">
                    <button className="btn bg-white" type="button">
                        <img src="/assets/images/search-icon.svg" alt="" className="img-fluid" />
                    </button>
                </div>
            </div>
        </form>
      
      </div>
      <div className="page_header_slider_container justify-content-end ">
      

        <div className="">
        
        
          <button className='inactive_page_btn' 
        onClick={() => navigate(-1)} > <FaRegRectangleList className='list_icons' /> <span> Active Industry List </span> </button>
         
        </div>
      </div>


      <div className="table-container ">
        <table className="table ">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">Industries Applications Name</td>
              <td scope="col">Status </td>
          
            </tr>
            <br />
          </thead>
          <tbody>
            {listdata.map((value, i) => (
              <>
              <tr key={i}>
                <td>{i + 1}</td>
               
                <td>{value?.industry}</td>
              
                <td>   <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={value?.active}
                      onChange={() => toggleStatus(value?._id)}
                    />
                 
                  </div></td>
          
              </tr>
              <br />
              </>
            ))}
          </tbody>
        </table>
      </div>




<div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} > Showing 01 - {recordperpage > total_count ? total_count : recordperpage } 
            
              &nbsp; of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => setRecordPerPage(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>
    

      
     
      
    </div>
  );
}

export default InactiveIndustryListTable;
