import React, { useState } from 'react'
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import Customtable from './table';

function CPInventoryList() {
  const [sidebaropen , setSidebarOpen] = useState(false)

  return (
   
    <div className='d-flex'>  
    <Sidebar setSidebarOpen={setSidebarOpen} />
    <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
    <Header  />
    <Customtable />
    </div> 
</div>
  )
}

export default CPInventoryList;
