  import React, { useState, useEffect } from "react";
  import store from "../../../../Redux/MyRedux/store";
  import Pagination from "react-mui-pagination";
  import { useNavigate } from "react-router-dom";
  import { toast } from "react-toastify";
  import {LuSearch} from "react-icons/lu"
  import {FaSort} from "react-icons/fa"
  import {FaRegRectangleList} from 'react-icons/fa6'
  import sort_logo_img from '../../../../Assets/table/shortarrow.png'
import { rotexInventoryFlagShow, rotexInventoryList, updateRotexInvetoryStatus } from "../../../../Redux/MyRedux/action/phase3";

  function InactiveDashboradtable() {
    const [dashboardFlag, setDashBoardFlag] = useState([]);

    const [page, setPage] = React.useState(1);
    const [list, setList] = useState([]);
    const [recordperpage, setRecordperPage] = useState(1000);
    const [total_count, setTotalCount] = React.useState(0);
    const [Search, setSearch] = React.useState("");
    const [colourflag, setColourFlag] = useState("");

    const handleChange = (event, value) => {
      setPage(value);
    };

    const getdata = () => {
      const data = {
        search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      onHandStock :[],
      qualifiedDemand : [],
      leadTime :[],
      factorOfSafety :[],
      active:false,
      avgWeeklyConsumption:[],
      flag:[],
      contactPersonName:[],
      itemCode:[],
      netFlow:[],
       tog:[],
       state:[],
       moq :[],
       orderRecommendation :[],
      active:false ,
      city:[],
      companyName:[],
      status :colourflag
        
      };

      try {
        store.dispatch(rotexInventoryList(data)).then((res) => {
          if (res?.isError == false) {
            if (res?.data[0]?.docs.length > 0) {
              setList(res?.data[0]?.docs);
              setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
            }
            else {
              setList([]);
              setTotalCount(0);
            }
          }
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };
    const handleFlagCount = () => {
      const data = {
        active :false ,
      };
      try {
        store.dispatch(rotexInventoryFlagShow(data)).then((res) => {
          if (res?.isError === false) {
            const existingFlags = res?.data.map((item) => item.flag);
            const flagsToAdd = ["RED", "WHIGHT", "BLACK", "YELLOW","GREEN"]
              .filter((flag) => !existingFlags.includes(flag))
              .map((flag) => ({ count: 0, flag }));
      
            const updatedData = [...res?.data, ...flagsToAdd];
            setDashBoardFlag(updatedData);
          } 
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };
    useEffect(() => {
      getdata();
      handleFlagCount();
    }, [recordperpage, page , Search,colourflag]);

 
    const handlUpdateStatus  =(value ,id) =>{
      const data = {
        active :value
      }
      store.dispatch(updateRotexInvetoryStatus(data , id)).then((res)=>{
        if (res?.isError == false) {
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message)
        }

      })
    }
    const toggleStatus = (userId) => {
      // Find the user by userId in the 'data' state
      const userToUpdate = list.find((user) => user._id === userId);

      if (userToUpdate) {
        const updatedStatus = !userToUpdate.active;
        handlUpdateStatus(updatedStatus, userId);

      }
    };

    const navigate = useNavigate();

    const sortedData = dashboardFlag.slice().sort((a, b) => {
      const order = ["BLACK", "RED", "YELLOW", "GREEN" ,"WHIGHT"];
      return order.indexOf(a.flag) - order.indexOf(b.flag);
    });
    
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const sortBy = (key) => {
      let direction = 'ascending';
      if (sortConfig.key === key && sortConfig.direction === 'ascending') {
        direction = 'descending';
      }
      const sortedData = [...list].sort((a, b) => {
        if (a[key] < b[key]) {
          return direction === 'ascending' ? -1 : 1;
        }
        if (a[key] > b[key]) {
          return direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
      setList(sortedData);
      setSortConfig({ key, direction });
    };
  const [sortOrder, setSortOrder] = useState("asc"); // Default to ascending order
    
   
    


    return (
      <div className="page">
        <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0 ">

{sortedData.map((item, index) => (
  <>
      {
        item.flag == "all" && 
      
  <li key={index} className="list-inline-item"> <button className= ' dashboard_header_btn_all' onClick={() => {setColourFlag("")  ; setPage("1")}} > <span>ALL</span> <span className='all_count_container' >{item?.count}</span> </button>
  </li>
} 
{item?.flag == "RED" && 
  <li key={index} className="list-inline-item"> <button className='dashboard_header_btn_red' onClick={() => {setColourFlag("RED") ; setPage("1")}}> <span> RED </span> <span className='all_count_container' >{item?.count}</span></button>
  </li>
} 
{item?.flag == "YELLOW" && 
  <li key={index} className="list-inline-item"> <button className=' dashboard_header_btn_yellow' onClick={() => {setColourFlag("YELLOW"); setPage("1")} } >  <span> YELLOW </span>  <span className='all_count_container'  >{item?.count}</span></button>
  </li> } 
{item?.flag == "GREEN" && 
  <li key={index} className="list-inline-item"> <button className='dashboard_header_btn_green ' onClick={() => {setColourFlag("GREEN");setPage("1")}}>  <span> GREEN </span> <span className='all_count_container'  >{item?.count}</span></button>
  </li>
} 
{item?.flag == "WHIGHT" && 
  <li key={index} className="list-inline-item"> <button className=' dashboard_header_btn_all' onClick={() => {setColourFlag("WHIGHT");setPage("1")}}>  <span   > WHITE </span> <span className='all_count_container' >{item?.count}</span></button>
  </li> } 
{item?.flag == "BLACK" && 
  <li key={index} className="list-inline-item"> <button className='dashboard_header_btn_black' onClick={() => {setColourFlag("BLACK");setPage("1")}}> <span> BLACK </span> <span className='all_count_container' >{item?.count}</span></button>
  </li>
} 
 

 
  </> ))}

</ul>
        
          
        </div>
    
        <div className="page_header_slider_container justify-content-end gap-1 ">
        
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={(e) => setSearch(e.target.value)}
              value={Search}

            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>
        <button className='inactive_page_btn' 
        onClick={() => navigate(-1)} > <FaRegRectangleList className='list_icons' /> <span>  Active Inventory List </span> </button>
             
        </div>
   

        <div className="table-container ">
          <table className="table ">
            <thead>
              <tr>
                <td scope="col">Sr. No</td>
                <td scope="col">
                On Hand Priority
                 
                </td>
           
            
                <td scope="col">  <div className="th_common">
                  <span>TOG </span>
                
                  <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("tog")}
                  />
                </div> </td>
                <td scope="col">
                   <div className="th_common">
                  <span> On Hand Stock </span>
              
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("onHandStock")}
                  />
                </div> 
                </td>
                <td scope="col">  <div className="th_common">
                  <span>Rotex Name </span>
                
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("companyName")}
                  />
                </div></td>
           
              
               
                <td scope="col">  <div className="th_common">
                  <span>  Item Code </span>
                 
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("itemCode")}
                  />
                </div> 
                
                </td>
                <td scope="col">  <div className="th_common">
                  <span> Item Description</span>
                  
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("itemDescription")}
                  />
                </div> </td>
                <td scope="col">  <div className="th_common">
                  <span> Avg<br />Weekly
                  Consumption </span>
                  
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("avgWeeklyConsumption")}
                  />
                </div> 
                  
                </td>
                <td scope="col">  <div className="th_common">
                  <span>  Lead Time <br />
                  (weekly) </span>
                 
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("leadTime")}
                  />
                </div> 
                 
                </td>
                <td scope="col">  <div className="th_common">
                  <span> Factor of Safety </span>
                 
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("factorOfSafety")}
                  />
                </div> 
                  
                </td>
                <td scope="col">  <div className="th_common">
                  <span>  Open Order </span>
                 
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("openOrder")}
                  />
                </div> 
                
                </td>
                <td scope="col">  <div className="th_common">
                  <span>  Qualified  Demand </span>
            
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("qualifiedDemand")}
                  />
                </div> 
                
                </td>
                <td scope="col">  <div className="th_common">
                  <span> Net Flow </span>
                
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("netFlow")}
                  />
                </div> </td>
                <td scope="col">  <div className="th_common">
                  <span> MOQ</span>
                 
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("moq")}
                  />
                </div> </td>
                <td scope="col">  <div className="th_common">
                  <span>  Order
                  <br /> Recommendation </span>
              
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("orderRecommendation")}
                  />
                </div> 
                
                </td>
                <td scope="col">  <div className="th_common">
                  <span>  Order
                  <br /> Recommendation Status</span>
                
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("orderRecommendationStatus")}
                  />
                </div> 
                 
                </td>

                <td scope="col">  <div className="th_common">
                  <span>   On Hand Status </span>
                
                    <img src={sort_logo_img} alt="sort_logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("onHandStatus")}
                  />
                </div> 
                
                </td>
                <td scope="col">
                Status
                </td>
              </tr>
              <br />
            </thead>
            <tbody>
              {list?.map((val, i) => (
                <>
                <tr key={i}>
                  <td>{i + 1}</td>
                
                  {val?.flag == "YELLOW" ? (
                    <td>
                      <button className="btn btn-warning py-1">Yellow</button>
                    </td>
                  ) : val?.flag == "RED" ? (
                    <td>
                      <button className="btn btn-danger py-1">Red</button>
                    </td>
                  ) : val?.flag == "BLACK" ? (
                    <td>
                      <button className="btn btn-dark py-1">Black</button>
                    </td>
                  ) : val?.flag == "GREEN" ? (
                    <td>
                      <button className="btn btn-success py-1">Green</button>
                    </td>
                  ) : (
                    <td>
                      <button className="btn border py-1">White</button>
                    </td>
                  )}

                  <td>{  Math.round(val?.tog)}</td>
                  <td>{val?.onHandStock}</td>
                  <td>{val?.companyName}</td>
                 
                  <td>{val?.itemCode}</td>
                  <td>{val?.itemDescription}</td>
                
                  <td>{val?.avgWeeklyConsumption}</td>
                  <td>{val?.leadTime}</td>
                  <td>{val?.factorOfSafety}</td>

                  <td>{val?.openOrder}</td>
                  <td>{val?.qualifiedDemand}</td>
                  <td>{val?.netFlow}</td>
                  <td>{val?.moq}</td>
                  <td>{val?.orderRecommendation}</td>
                  <td>
                    {val?.orderRecommendationStatus !== undefined ||
                    val?.orderRecommendationStatus != 0
                      ? parseFloat(val?.orderRecommendationStatus).toFixed(2)
                      : "0"}
                  </td>
                  <td>
                    {val?.onHandStatus !== undefined || val?.onHandStatus != 0
                      ? parseFloat(val?.onHandStatus).toFixed(2)
                      : "0"}
                  </td>

  <td>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input addbox_radio"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={val?.active}
                        onChange={() => toggleStatus(val?._id)}
                      />
                    </div>
                  </td>
                </tr>
                <br />
                </>
              ))}
            </tbody>
          </table>
        </div>

      
        <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} > Showing 01 - {recordperpage > total_count ? total_count : recordperpage} 
            {/* {recordperpage > total_count ? total_count : recordperpage } */}
              &nbsp; of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => setRecordperPage(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>

      </div>
    );
  }

  export default InactiveDashboradtable;
