import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LuFilter } from "react-icons/lu";
import Select from "react-select";
import store from "../../../Redux/MyRedux/store";
import { UserlistDropdown } from "../../../Redux/MyRedux/action/userActions";
import { MultiSelect } from "react-multi-select-component";
import { AiOutlineClose } from "react-icons/ai";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#F4F6FA",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
 

  pb: 3,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
};

export default function FilterModal({stateSetters , propsStateSetters}) {
 
 
const [userDropDown ,setUserDropDown] = React.useState([])

const getUserDropDown = () => {
  try {
    store.dispatch(UserlistDropdown()).then((res) => {
      if (res?.isError === false) {
        setUserDropDown(res?.data);
      }
    });
  } catch (error) {
    console.log("Error Fetching :", error);
  }
};
const optionlistuserType = userDropDown?.map((val, i) => ({
  value: val?._id,
  label: val?.userType,
}));
const uniqueUsertypeLabels = [...new Set(optionlistuserType.map(obj => obj.label))];
const uniqueUsertypeLabelObjects = uniqueUsertypeLabels.map(label => {
  const matchingObject = optionlistuserType.find(obj => obj.label === label);
  return { label, value: matchingObject.value };
});
const optionlistname = userDropDown?.map((val, i) => ({
  value: val?._id,
  label: val?.firstName,
}));
const uniquenameLabels = [...new Set(optionlistname.map(obj => obj.label))];
const uniquenameLabelObjects = uniquenameLabels.map(label => {
  const matchingObject = optionlistname.find(obj => obj.label === label);
  return { label, value: matchingObject.value };
});
const optionlistfirmname = userDropDown?.map((val, i) => ({
  value: val?._id,
  label: val?.firmName,
}));
const uniquefirmnameLabels = [...new Set(optionlistfirmname.map(obj => obj.label))];
const uniquefirmnameLabelObjects = uniquefirmnameLabels.map(label => {
  const matchingObject = optionlistfirmname.find(obj => obj.label === label);
  return { label, value: matchingObject.value };
});
const optionlistemail = userDropDown?.map((val, i) => ({
  value: val?._id,
  label: val?.email,
}));
const uniqueemailLabels = [...new Set(optionlistemail.map(obj => obj.label))];

const uniqueemailLabelObjects = uniqueemailLabels.map(label => {
  const matchingObject = optionlistemail.find(obj => obj.label === label);
  console.log(matchingObject , 'matchingObject')
  return { label, value: matchingObject.value };
});

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const clearfilter = () => {
    stateSetters.setFilterUserType([]);
    stateSetters.setFilterUserName([]);
    stateSetters.setFilterFirmName([]);
    stateSetters.setFilterEmail([]);
  };
  
  return (
    <div>
      <button
        className="filter_btn"
        type="button"
        onClick={() => {
            getUserDropDown();
          handleOpen();
        }}
      >
        <LuFilter />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <div className="filter_modal_header">
            <div className="d-flex justify-content-between ">
              <div className="modal_heading d-flex align-items-center gap-2">
                <button
                  className="filter_btn"
                  type="button"
                
                 
                >
                  <LuFilter />
                </button>{" "}
                <span> Filter </span>
              </div>
              <div className="close_modal_btn"   onClick={() => handleClose()}>
                <AiOutlineClose />
              </div>
            </div>
          </div>
          <div className="filter_select_container">
            <div className="row">
            <div className="form-group col-md-6">
              
              <Select
                options={uniqueUsertypeLabelObjects}
                placeholder="User Type"
                value={propsStateSetters.filterUserType}
                onChange={(e) => {  stateSetters.setFilterUserType(e);}}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>


            <div className="form-group col-md-6">
             
              <Select
                options={uniquenameLabelObjects}
                placeholder="Name"
                value={propsStateSetters.filterUserName}
                onChange={(e) => {stateSetters.setFilterUserName(e);}}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            </div>
            <div className="row">
            <div className="form-group col-md-6">
              
              <Select
                options={uniquefirmnameLabelObjects}
                placeholder="Firm"
                value={propsStateSetters.filterFirmName}
                onChange={(e) => {stateSetters.setFilterFirmName(e);}}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
         
            <div className="form-group col-md-6">
          
              <Select
                options={uniqueemailLabelObjects}
                placeholder="Email"
                value={propsStateSetters.filterEmail}
                onChange={(e) => { stateSetters.setFilterEmail(e);}}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
               
            </div>  
            </div>
            <div className="drawer-foot-btns mt-5 ">
            <button className="btn clear_btn" 
            onClick={() => clearfilter()}
            >
              Clear
            </button>
            <div className="">
              <button
                className="btn blue-btn ms-2 py-1"
                onClick={() =>  {stateSetters.setUpdateAPI('1212') ; handleClose(); } }
              >
                Apply
              </button>
            </div>
          </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
