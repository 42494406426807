import React, { useState } from "react";
import Header from "../../../Header/Header";
import Sidebar from "../../../Sidebar/Sidebar";
import IndustryTable from "./table";

const ChildIndustryList = () =>{
  const [sidebaropen , setSidebarOpen] = useState(false)
    
    return (
        <>
    <div className='d-flex'>
     <Sidebar setSidebarOpen={setSidebarOpen} />
     <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
     <Header  />
     <IndustryTable />
     </div>
     </div>
        </>
    )
}
export default ChildIndustryList ; 