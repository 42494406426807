import React , {useState} from "react";
import Header from "../../../Header/Header";
import Sidebar from "../../../Sidebar/Sidebar";
import CategoryTable from "./table";

const ChildPartCategoryList = () =>{
  const [sidebaropen , setSidebarOpen] = useState(false)
    
    return (
        <>
        
    <div className='d-flex'>
     <Sidebar setSidebarOpen={setSidebarOpen} />
     <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
     <Header  />
     <CategoryTable />
     </div>
     </div>
        </>
    )
}
export default ChildPartCategoryList ;