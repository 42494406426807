import React , { useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import './supplier.css'
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

import store from "../../../../Redux/MyRedux/store";
import { AiOutlinePlus } from "react-icons/ai";

import { RxCross2 } from "react-icons/rx";

import {  addSupplierForRotexOrder, supplierForRotexOrder } from "../../../../Redux/MyRedux/action/phase3";

import { toast } from "react-toastify";


 const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#F4F6FA",
  boxShadow: 24,
  borderRadius:"20px",
  height:'100%',
  maxHeight:'90%',
  overflowY:'auto',
  overflowX:'hidden',


};
const mediaQuery = `@media (max-width: 768px)`; 
const styleForMediaQuery = {
  ...style, 
  [mediaQuery]: {
    width: "80%", // Example: Make the width 100% for screens matching the media query
  },
};

export default function SupplierList({id , qty , orderId , setUpdateAPI}) {
   const [open ,setOpen] = useState(false)

   const handleOpen = () =>  setOpen(true)
   const handleClose = () =>  setOpen(false)

  // API FOR SP MASTER LIST DROPDWON IN VIEW MODAL
const [supplierDropDownList , setSupplierDropDownList] = useState([]);
const getSpDropDownList = () => {
  const data = { 
    productId : id
  }
  try {
    store.dispatch(supplierForRotexOrder(data)).then((res) => {
      if (res?.isError === false) {
        setSupplierDropDownList(res?.data)
        
      } else {
        toast.error(res?.message);
      }
    });

  } catch (error){
    console.log(error)
  }
} 

const handleAddSupplier = (spId) => {
  const requestiOption = {
    supplierPartnerId:spId , 
    productId:id,
    qty:qty
  } 
  try {
    store.dispatch(addSupplierForRotexOrder(requestiOption , orderId)).then((res)=>{
      if(res?.isError === false) {
        handleClose();
        toast.success(res?.message)
        setUpdateAPI('addsp')
      } else {
        toast.error(res?.message)
      }
    })
  } catch (error) {
    console.log(error , 'error when add SP master')
  }
}


  return (
    <div>

      <button 
         className="ass_sp_btn"
        onClick={() => {
            handleOpen();
            getSpDropDownList(id)
        
          
          }} 
      >
        <span>Add Supplier Master</span>
    
        
      </button>
   
    <Modal
     aria-labelledby="transition-modal-title"
     aria-describedby="transition-modal-description"
     open={open}
     onClose={handleClose}
     closeAfterTransition
     slots={{ backdrop: Backdrop }}
     slotProps={{
       backdrop: {
         timeout: 500,
       },
     }}
   >
     <Fade in={open}>
       <Box sx={styleForMediaQuery} className="model_box">
         <div className="model_header">
         <div className='d-flex align-items-center gap-1' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Supplier Master List </p>
            </div>
           <div className="d-flex align-items-center gap-3 ">
             <button className="mtoclosebtn_cont" onClick={handleClose}>
               <RxCross2 className="closebox_logo" />{" "}
             </button>
           </div>
         </div>
         <div className="table container supplierModal_table">
            <table className="w-100">
                <thead>
                    <tr>
                    <td>Supplier Name</td>
                    <td>TOG</td>
                    <td>On Hand Stock</td>
                    <td>Status</td>
                    <td>Select</td>
                    </tr>
                    <br />

                </thead>
                <tbody>
                    {
supplierDropDownList?.map((val , i)=>(
  <>
      <tr key={i}>
        <td>{val?.supplierName}</td>
        <td>{val?.tog}</td>
        <td>{val?.onHandStock}</td>
        <td> <button 
          className={`${val?.RYG === "YELLOW" ? "btn btn-warning py-1" :
          val?.RYG === "RED" ? "btn btn-danger py-1 px-3" :
          val?.RYG === "BLACK" ? "btn btn-dark py-1" :
          val?.RYG === "GREEN" ? "btn btn-success py-1" : ""}`}

         >
        {val?.RYG}
          </button> </td>
        <td> <input type="radio" name="suppliermaster" id="" onChange={() => handleAddSupplier(val?._id)} /> </td>
      </tr>
      <br />
      </>
))
                    }
                </tbody>
            </table>
         </div>

       </Box>
     </Fade>
   </Modal>
    </div>
  );
}
