import React, { useState ,useEffect } from 'react'
import './MainTable.css';
import store from '../../../Redux/MyRedux/store';
import { Addconsumptionmaster, Addinventory, Editinventory, InventorylistDropdown, ShowFlagCount, UpdateInventoryExcelSheet, UpdateInventoryStatus, Viewinventory, channellistdropdowm, inventorylist, inventoryProductListDropdowm, UpdateInventoryToggle } from '../../../Redux/MyRedux/action/userActions';
import {RxCross2} from "react-icons/rx"
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Pagination from "react-mui-pagination";
import { toast } from 'react-toastify';
import Swal from 'sweetalert2'
import Select from "react-select"
import { useNavigate } from 'react-router-dom';
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {LuSearch} from "react-icons/lu"
import Slider from "react-slick";
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegRectangleList } from "react-icons/fa6";
import shortarrow_img from '../../../Assets/table/shortarrow.png'
import { AiOutlinePlus } from "react-icons/ai";
import { TbPencil } from "react-icons/tb";
import FilterModal from '../filterpopup/filter';
import consumption_img from '../../../Assets/table/Group 82549.png'


function MainTable() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius:"20px",

  };
  const mediaQuery = `@media (max-width: 768px)`; 
  const styleForMediaQuery = {
    ...style, 
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

  const consumtionstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius:"20px",
  };
  const consumptionStyleForMediaQuery = {
    ...consumtionstyle, 
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };
  
  const [productlistdropdown , setProductListDropdown] = useState([])
  const [cpmasterlistdropdown , setCPmasterListDropdown] = useState([])
  const [dashboardFlag , setDashBoardFlag] = useState([])
  const [inventoryStatus , setInventoryStatus] = useState(false)
  const [channelpartner_id , setChannelPartener_ID] = useState("")

  const [filtertog, setFilterTOG] = useState([]);
  const [filterinHandStock, setFilterInHandStock] = useState([]); 
  const [filterNetflow, setFilterNetFlow] = useState([]); 
  const [filterCpName, setFilterCPName] = useState([]); 
  const [filterContactPerson, setFilterContactPerson] = useState([]); 
  const [filterCity, setFilterCiy] = useState([]); 
  const [filterState, setFilterState] = useState([]);
  const [filterItemCode, setFilterItemCode] = useState([]); 
  const [filterAvgConsumption , setFilterAvgConsumption] = useState([]);
  const [filterQualifiedDemand , setFilterQualifiedDemand] = useState([]);
  const [filterLeadTime , setFilterLeadTime] = useState([]);
  const [filterFactorySafety , setFilterFactorySafety] = useState([]);
  const [filterMoq , setFilterMOQ] = useState([]);
  const [filterOrderRecommendation , setFilterOrderRecommendation] = useState([]);
  const [filterUnit , setFilterUnit] = useState([]);
  const [filterOpenOrder , setFilterOpenOrder] = useState([]);
  const [filterRealAWC , setFilterRealAWC] = useState([]);
  const [filterItemDesc , setFilterItemDesc] = useState([]);
  const [filterOrderRecommendationStatus , setFilterOrderRecommendationStatus] = useState([]);
  const [updatedAPI, setUpdateAPI] = useState("");


  const stateSetters = {
    setFilterTOG,
    setFilterInHandStock,
    setFilterNetFlow,
    setFilterCPName,
    setFilterContactPerson,
    setFilterCiy,
    setFilterState,
    setFilterItemCode,
    setFilterAvgConsumption,
    setFilterQualifiedDemand,
    setFilterLeadTime,
    setFilterFactorySafety,
    setFilterMOQ,
    setFilterOrderRecommendation,
    setFilterUnit,
    setFilterOpenOrder,
    setFilterRealAWC,
    setFilterItemDesc,
    setFilterOrderRecommendationStatus,
    setUpdateAPI,
   }
  
   const propsState ={
  
    filtertog,
    filterinHandStock,
    filterNetflow,
    filterCpName,
    filterContactPerson,
    filterCity,
    filterState,
    filterItemCode,
    filterAvgConsumption,
    filterQualifiedDemand,
    filterLeadTime,
    filterFactorySafety,
    filterMoq,
    filterOrderRecommendation,
    filterUnit,
    filterOpenOrder,
    filterRealAWC,
    filterItemDesc,
    filterOrderRecommendationStatus
   }
  
  
  const [disableAddbutton , setDisableAddButton] = useState(false)
  const [usertype, setUserType] = useState(""); 
  const [colourflag, setColourFlag] = useState("");



  const[ Inventoy_id , setInventory_ID]= useState("")
  const [cpname,setCpname] = useState(null)
const [itemcode,setItemCode] = useState(null)
const [page, setPage] = useState("1");
const [list , setList] = useState([])
const [recordperpage , setRecordperPage] = useState(1000)
const [total_count, setTotalCount] = React.useState(0);
const [Search, setSearch] = React.useState("");

const handleChange = (event, value) => {
  const valueAsString = value.toString(); // Convert value to string
  setPage(valueAsString); // Now `value` is a string
};
const [consumptionval ,setConsumptionval] = useState({})
const setConsumptionvalue = (val) =>{
   setConsumptionval(val )
}
const initialvalueforadd = {
    
  cpname: "",
  contactperson: "",
  city: "",
  state: "",
  itemcode: "",
  itemdesc: "",
  itemtype: "",
  weeklyconsumption: "",
  leadtime: "",
  factorysafety: "",
  tog: "",
  onhandstock: "",
  openorder: "",
  qualifieddemand1: "",
  qualifieddemand2: "",
  netflow: "",
  moq: "",
  orderrecommandation: "",
  orderrecommandationstatus: "",
  onhandpriority: "",
  consumption: "",


};
const [addfromdata, setAddFormData] = useState(initialvalueforadd);
  const addhandlechange = (e) => {
    const { name, value } = e.target; 
    const parsedValue = name == 'onhandstock' || "qualifieddemand2" || "tog" ? (value == '' ? null : parseInt(value)) : value;
    setAddFormData((prevData) => ({ ...prevData, [name]: parsedValue }));
  };
  const initialvalueforupdate = {
    channelid :"",
    product_id :"",
    cpname: "",
    contactperson: "",
    city: "",
    state: "",
    itemcode: "",
    itemdesc: "",
    itemtype: "",
    weeklyconsumption: "",
    leadtime: "",
    factorysafety: "",
    tog: "",
    onhandstock: "",
    openorder: "",
    qualifieddemand1: "",
    qualifieddemand2: "",
    netflow: "",
    moq: "",
    orderrecommandation: "",
    orderrecommandationstatus: "",
    onhandpriority: "",
    consumption: "",


  };
  const [updatefromdata, setUpdateFormData] = useState(initialvalueforupdate);
  const updatehandlechange = (e) => {
    const { name, value } = e.target; 
    const parsedValue = name == 'onhandstock' || "qualifieddemand2" || "tog" || 'leadtime' ? (value == '' ? null : parseInt(value)) : value;
    setUpdateFormData((prevData) => ({ ...prevData, [name]: parsedValue }));
  };
  const initialforconsumtion = {
    qty :"" ,
    date :new Date().toISOString().slice(0, 10),
  }
  const [addconsumption  ,setAddConsumption] = useState(initialforconsumtion)
  const handleconsumption = (e) =>{
    const { name, value } = e.target; 
    const parsedValue = name == 'qty' ? (value == '' ? null : parseInt(value)) : value;
    setAddConsumption((prevData) => ({ ...prevData, [name]: parsedValue }));
  }

  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      onHandStock :filterinHandStock.map(item => item.label),
      qualifiedDemand : filterQualifiedDemand.map(item => item.label),
      leadTime : filterLeadTime.map(item => item.label),
      factorOfSafety :filterFactorySafety.map(item => item.label),
      active:true,
      avgWeeklyConsumption:filterAvgConsumption.map(item => item.label),
      flag:[],
      contactPersonName:filterContactPerson.map(item => item.label),
      itemCode:filterItemCode.map(item => item.label),
      netFlow:filterNetflow.map(item => item.label),
       tog:filtertog.map(item => item.label),
       state:filterState.map(item => item.label),
       moq :filterMoq.map(item => item.label),
       orderRecommendation :filterOrderRecommendation.map(item => item.label),
      active:true ,
      city:filterCity.map(item => item.label),
      companyName:filterCpName.map(item => item.label),
      status :colourflag,
      unit:filterUnit.map(item => item.label),
      avg:filterRealAWC.map(item => item.label),
      openOrder:filterOpenOrder.map(item => item.label),
      itemDescription:filterItemDesc.map(item => item.label),
      orderRecommendationStatus:filterOrderRecommendationStatus.map(item => item.label),
    };

    try {
      store.dispatch(inventorylist(data)).then((res) => {
        if (res?.isError == false) {
          if(res?.data[0]?.docs.length > 0) {
          setList(res?.data[0]?.docs);
          setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          }
          else {
            setList([]);
          setTotalCount(0);
         
          }
        }
        setUpdateAPI('')

      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handleFlagCount = () =>{
    const data = {
      channelPartnerId:channelpartner_id ,
      active :true
    }
    try {
      store.dispatch(ShowFlagCount(data)).then((res) => {
        if (res?.isError === false) {
          const existingFlags = res?.data.map((item) => item.flag);
          const flagsToAdd = ["RED", "WHIGHT", "BLACK", "YELLOW","GREEN"]
            .filter((flag) => !existingFlags.includes(flag))
            .map((flag) => ({ count: 0, flag }));
    
          const updatedData = [...res?.data, ...flagsToAdd];
          setDashBoardFlag(updatedData);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  
  }
  useEffect(() => {
    setUserType(localStorage.getItem("usertype"))
    setChannelPartener_ID(localStorage.getItem("channelPartnerId"))
    if(localStorage.getItem("usertype") == "CP" ){
      if(channelpartner_id){
        getdata();
        handleFlagCount();
      }
    } else {
      getdata();
      handleFlagCount();
    }
   
  }, [recordperpage, page, updatedAPI ,Search ,colourflag]);

const addconsumtionbyinventory = (onhandstock) =>{
  if(addconsumption.qty > onhandstock){
    toast.error("Your Consumption is more than On Hand Stock")
  }
else{
  Swal.fire({
    imageUrl: consumption_img,
    imageWidth:'231px',
    title: 'Do you want to save the <span style="color:#34B1E2"> Consumption ?</span>',
    showDenyButton: true,
    confirmButtonText: 'Save',
    denyButtonText: `Don't save`,

  }).then((result) => {
    /* Read more about isConfirmed, isDenied below */
    if (result.isConfirmed) {
      handleadconsumption()
    } else if (result.isDenied) {
      // Swal.fire('Changes are not saved', '', 'info')
    }
  })
}
}
const handleadconsumption = () => {


  const data = {
    
    channelPartnerId:consumptionval?.channelPartnerId,
    productId:consumptionval?.productId,
    itemId:"",
    qty:addconsumption.qty,
    date:addconsumption.date
}

  try {
    store.dispatch(Addconsumptionmaster(data)).then((res) => {
      if (res?.isError == false) {
        handleCloseConsumption()
        toast.success(res?.message)
        getdata()
       setConsumptionval({})
       setAddConsumption(initialforconsumtion)
      }
      else{
        toast.error(res?.message)
      }
    });
  } catch (error) {
    console.error("Error occurred:", error);
  }
};
  const getcpmasterlistdropdownlist = () =>{
    try{
      store.dispatch(channellistdropdowm()).then((res)=>{
        if(res?.isError === false){
               setCPmasterListDropdown(res?.data)
        }
      })
    }catch (error){
      console.log("Error Fetching :", error)
    }
  }
  const getproductdropdownlist = (id) =>{
    const requestOption = {
      channelPartnerId:id
      
    }
    try{
      store.dispatch(inventoryProductListDropdowm(requestOption)).then((res)=>{
        if(res?.isError === false){
               setProductListDropdown(res?.data)
        }
      })
    }catch (error){
      console.log("Error Fetching :", error)
    }
  }
const optionlistcpname = cpmasterlistdropdown?.map((val, i) => ({
  value: val?._id,
  label: val?.companyName,
}));
const optionListproduct = productlistdropdown?.map((val, i) => ({
  value: val?._id,
  label: val?.itemCode,
}));
 const hanlechangeitemcode = (e)=>{
  setItemCode(e)
  const selectedProduct = productlistdropdown.find(product => product._id == e.value); // Use the updated value
    if (selectedProduct) {
      setAddFormData(prevState => ({
        ...prevState,
        itemdesc: selectedProduct.itemDescription,
        moq:selectedProduct?.moq,
        factorysafety:selectedProduct?.factorOfSafety

      
      }));
    }
  }
  const hanlechangecpname = (e)=>{
    setCpname(e)
    const selectedProduct = cpmasterlistdropdown.find(product => product._id == e.value); // Use the updated value
      if (selectedProduct) {
        setAddFormData(prevState => ({
          ...prevState,
          contactperson: selectedProduct.contactPersonName,
           city:selectedProduct?.city ,
           state:selectedProduct?.state,
           leadtime:selectedProduct?.LeadTime,
  
        
        }));
      }
    }
    const handleaddinventory  = () =>{
      setDisableAddButton(true)
      const data = {
        channelPartnerId:cpname?.value,
        productId:itemcode?.value,
        onHandStock:addfromdata.onhandstock,
        qualifiedDemand:addfromdata.qualifieddemand2,
        tog:addfromdata.tog

      }
      try{
        store.dispatch(Addinventory(data)).then((res)=>{
          setDisableAddButton(false)

          if(res?.isError == false){
            toast.success(res?.message)
            handleClose();
            setAddFormData(initialvalueforadd)
            setCpname("")
            setItemCode("")
            getdata()


          }
          else{
            const errorMessage = res.message.includes(":") ? res.message.split(":")[1] : res.message;
            toast.error(errorMessage);
         
          }
        })
      }catch (error){
        return error
      }
    }
    const handleviewInventory =  (id) => {
      setInventory_ID(id)
      try {
        store.dispatch(Viewinventory(id)).then((res) => {
          if (res?.isError == false) {
            setUpdateFormData(prevState => ({
              ...prevState,
              cpname:res?.data?.companyName,
              contactperson:res?.data?.contactPersonName,
              city:res?.data?.city,
              state:res?.data?.state,
              itemcode : res?.data?.itemCode,
              itemdesc: res?.data?.itemDescription,
              leadtime:res?.data?.leadTime,
              factorysafety:res?.data?.factorOfSafety,
              tog:res?.data?.tog,
              onhandstock:res?.data?.onHandStock,
              qualifieddemand1:res?.data?.qualifiedDemand,
              moq:res?.data?.moq,
              channelid :res?.data?.channelPartnerId,
              product_id:res?.data?.productId
        
          
            }));
            setInventoryStatus(res?.data?.active)
  
           
          }
         
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };
    const handleupdateInventory = () => {
      const data = {
        
        channelPartnerId:updatefromdata.channelid,
        productId:updatefromdata.product_id,
        onHandStock:updatefromdata.onhandstock,
        qualifiedDemand:updatefromdata.qualifieddemand1,
        tog:updatefromdata.tog ,
        leadTime:updatefromdata.leadtime
    
   }
      try {
        store.dispatch(Editinventory(data ,Inventoy_id)).then((res) => {
          if (res?.isError == false) {
            handleUpdateBoxClose()
            toast.success(res?.message)
            getdata()
           setUpdateFormData(initialvalueforadd)
          }
          else{
            toast.error(res?.message)
          }
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [updateboxopen, setUpdateBoxOpen] = useState(false);
  const handleUpdateBoxOpen = () => setUpdateBoxOpen(true);
  const handleUpdateBoxClose = () => setUpdateBoxOpen(false);

  const [openconsumption, setOpenConsumption] = useState(false);
  const handleopenConsumption = () => setOpenConsumption(true);
  const handleCloseConsumption = () => setOpenConsumption(false);

  const [openqulifiedDemand, setOpenQualifiedDemand] = useState(false);
  const handleopenQualifiedDemand = () => setOpenQualifiedDemand(true);
  const handleCloseQualifiedDemand = () => setOpenQualifiedDemand(false);



  
  const handleStatusUpdate = () =>{
    const updatestatus = !inventoryStatus ;
    setInventoryStatus(updatestatus)
    handlUpdateStatus( Inventoy_id, updatestatus)
   }

   const handlUpdateStatus  =( id ,value) =>{
    const data = {
      active :value
    }
    store.dispatch(UpdateInventoryStatus(data , id)).then((res)=>{
      if(res?.isError == false){
        toast.success(res?.message)
        getdata();
      }
      else {
        toast.error(res?.messsage)
      }

    })
   }
   const handlUpdatetogtoggle  =( id ,value) =>{
    const data = {
      isUpdateTog :value
    }
    store.dispatch(UpdateInventoryToggle(data , id)).then((res)=>{
      if(res?.isError == false){
        toast.success(res?.message)
        getdata();
      }
      else {
        toast.error(res?.messsage)
      }

    })
   }

   const navigate = useNavigate();
   const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = list.find((user) => user._id === userId);
  
    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handlUpdateStatus(userId,updatedStatus )
    }
  };
  const toggleTogUpdate= (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = list.find((user) => user._id == userId);
  
    if (userToUpdate) {
      const updatedStatus = !userToUpdate.isUpdateTog;
      handlUpdatetogtoggle(userId,updatedStatus )
    }
  };

 
 

  const clearHeaderFilter = (filterName) => {
    switch (filterName) {
      case 'TOG':
        setFilterTOG([]);
        setUpdateAPI("TOG")
        break;
      case 'OnHandStock':
        setFilterInHandStock([]);
        setUpdateAPI("OnHandStock")
        break;
      case 'CPName':
        setFilterCPName([]);
        setUpdateAPI("CPName")
        break;
      case 'ContactPerson':
        setFilterContactPerson([]);
        setUpdateAPI("ContactPerson")
        break;
      case 'City':
        setFilterCiy([]);
        setUpdateAPI("City")
        break;
      case 'State':
        setFilterState([]);
        setUpdateAPI("State")
        break;
      case 'Consumption':
        setFilterAvgConsumption([]);
        setUpdateAPI("Consumption")
        break;
      case 'LeadTime':
        setUpdateAPI("LeadTime")
        setFilterLeadTime([]);
        break;
      case 'FactorySafety':
        setUpdateAPI("FactorySafety")
        setFilterFactorySafety([]);
        break;
      case 'QualifiedDemand':
        setUpdateAPI("QualifiedDemand")
        setFilterQualifiedDemand([]);
        break;
      case 'NetFlow':
        setUpdateAPI("NetFlow")
        setFilterNetFlow([]);
        break;
      case 'MOQ':
        setFilterMOQ([]);
        setUpdateAPI("MOQ")
        break;
      case 'OrderRecommendation':
        setUpdateAPI("OrderRecommendation")
        setFilterOrderRecommendation([]);
        break;
      case 'TOG':
        setFilterTOG([]);
        setUpdateAPI("TOG")
        break;
      case 'ItemCode':
        setFilterItemCode([]);
        setUpdateAPI("TOG")
        break;
      case 'CPName':
        setFilterCPName([]);
        setUpdateAPI("CPName")
        break;
      case 'unit':
        setFilterUnit([]);
        setUpdateAPI("unit")
        break;
      case 'realAWC':
        setFilterRealAWC([]);
        setUpdateAPI("realAWC")
        break;
      case 'openOrder':
        setFilterOpenOrder([]);
        setUpdateAPI("openOrder")
        break;
      case 'OrderRecommendationstatus':
        setFilterOrderRecommendationStatus([]);
        setUpdateAPI("OrderRecommendationstatus")
        break;
     case 'itemdesc':
        setFilterItemDesc([]);
        setUpdateAPI("itemdesc")
        break;
              
              
      default:
        break;
    }

    // Call getdata with the updated filters
    // getdata();
  };

 
  
  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      "TOG",
      "On Hand Stock",
      "CP Name",
      "Contact Person Name",
      "City",
      "State",
      "Item Code",
      "Item Description",
      "Avg Weekly Consumption",
      "Real-time AWC",
      "Lead Time",
      "Factor of Safety",
      "Open Order",
      "Qualified Demand",
      "Net Flow",
      "MOQ",
      "Order Recommendation",
      "Order Recommendation Status",
      "On Hand Status",
      "Status"
    ];
  
    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...list.map((item) => [
        Math.round(item.tog),
        item.onHandStock,
        item.companyName,
        item.contactPersonName,
        item.city,
        item.state,
        item.itemCode,
        item.itemDescription,
        Math.round(item.avgWeeklyConsumption),
        Math.round(item.avg),
        item.leadTime,
        item.factorOfSafety,
        item.openOrder,
        Math.round(item.qualifiedDemand),
        item.netFlow,
        item.moq,
        item.orderRecommendation,
       ` ${ Math.round(item.orderRecommendationStatus)}%`,
        `${item.onHandStatus}%`,
        item.active == true ? "Active" : item.active == false ? "Inactive" : ""

      ]),
    ];
  
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
  
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "data.xlsx");
  };

  const handleTemplateDownload = () => {
    // Custom headers
    const headers = [
     "TOG",
     "On Hand Stock",
     "CP Name",
     "Item Code",
     "Qualified Demand",
     "Leadtime",

    ];
  
    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,[]
      
    ];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, " Inventory template.xlsx");
  }

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        // Parse the Excel file to JSON
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Define a mapping between old header names and new parameter names
        const headerMapping = {
          "TOG" : "tog",
          "On Hand Stock" :"onHandStock",
          "CP Name" : "companyName" ,
          "Item Code":"itemCode",
          "Qualified Demand" :"qualifiedDemand",
          "Leadtime":'leadTime'

        };

        // Map the old header names to the new parameter names in the JSON data
        const mappedJsonData = jsonData.map((item) => {
          const mappedItem = {};
          for (const oldHeader in item) {
            if (oldHeader in headerMapping) {
              mappedItem[headerMapping[oldHeader]] = item[oldHeader];
            }
          }
          return mappedItem;
        });


        updateNewExcel(mappedJsonData)
      };
      reader.readAsBinaryString(file);
    }
  };

const updateNewExcel = (json) =>{

  try {
    store.dispatch(UpdateInventoryExcelSheet(json)).then((res)=>{
      if(res?.isError == false){
        toast.success(res?.message)
        if (res?.data?.errorArrray.length > 0) {
          toast.error(` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`);
          const headers = [
            "Item Code" ,
            "company Name" ,
            "Tog" ,
            "on HandStock" ,
            "Qualified Demand",
            'leadtime',
            "Error"
          ];
        
          // Map data to a new array with custom headers
          const dataWithCustomHeaders = [
            headers,
            ...res?.data?.errorArrray.map((item) => [
             item.itemCode,
             item.companyName,
             item.tog,
             item.onHandStock,
             item.qualifiedDemand,
             item.leadTime,

             item.error
  
            ]),
          ];
          // Generate Excel file
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          // Save file
          const fileData = new Blob([excelBuffer], {
            type: "application/octet-stream",
          });
          saveAs(fileData,"Errordata.xlsx");
  
          
        } else {
          // Display an alert when errorArray is empty
          toast.success("No error data found. All data upload Successfully");
        }
      }
      else{
        toast.error(res?.message)
      }
    })
  } catch (error) {
    return error
  }

}

const sortedData = dashboardFlag.slice().sort((a, b) => {
  const order = ["BLACK", "RED", "YELLOW", "GREEN" ,"WHIGHT"];
  return order.indexOf(a.flag) - order.indexOf(b.flag);
});

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1
};

const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
const sortBy = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  const sortedData = [...list].sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  setList(sortedData);
  setSortConfig({ key, direction });
};


const [isHovered, setIsHovered] = useState(false);

const handleItemsPerPageChange = (value) => {
  setRecordperPage(value);
  setPage(1); // Reset to page 1 whenever items per page changes
};

const calculateSerialNumber = (index) => {
  return (page - 1) * recordperpage + index + 1;
};
const startSerialNumber = calculateSerialNumber(0);
const endSerialNumber = calculateSerialNumber(list.length - 1);


  return (
    <div className='inventory_page page' >


        <div className="filter_list d-flex  gap-2 align-items-center justify-content-between mb-1  ">
            <ul className="list-inline mb-0 ">

                {sortedData.map((item, index) => (
                  <>
                      {
                        item.flag == "all" && 
                      
                  <li key={index} className="list-inline-item"> <button className= ' dashboard_header_btn_all' onClick={() => {setColourFlag("")  ; setPage("1")}} > <span>ALL</span> <span className='all_count_container' >{item?.count}</span> </button>
                  </li>
} 
{item?.flag == "RED" && 
                  <li key={index} className="list-inline-item"> <button className='dashboard_header_btn_red' onClick={() => {setColourFlag("RED") ; setPage("1")}}> <span> RED </span> <span className='all_count_container' >{item?.count}</span></button>
                  </li>
} 
{item?.flag == "YELLOW" && 
                  <li key={index} className="list-inline-item"> <button className=' dashboard_header_btn_yellow' onClick={() => {setColourFlag("YELLOW"); setPage("1")} } >  <span> YELLOW </span>  <span className='all_count_container'  >{item?.count}</span></button>
                  </li> } 
{item?.flag == "GREEN" && 
                  <li key={index} className="list-inline-item"> <button className='dashboard_header_btn_green ' onClick={() => {setColourFlag("GREEN");setPage("1")}}>  <span> GREEN </span> <span className='all_count_container'  >{item?.count}</span></button>
                  </li>
 } 
{item?.flag == "WHIGHT" && 
                  <li key={index} className="list-inline-item"> <button className=' dashboard_header_btn_all' onClick={() => {setColourFlag("WHIGHT");setPage("1")}}>  <span   > WHITE </span> <span className='all_count_container' >{item?.count}</span></button>
                  </li> } 
{item?.flag == "BLACK" && 
                  <li key={index} className="list-inline-item"> <button className='dashboard_header_btn_black' onClick={() => {setColourFlag("BLACK");setPage("1")}}> <span> BLACK </span> <span className='all_count_container' >{item?.count}</span></button>
                  </li>
 } 
                 
           
                 
                  </> ))}
 
             </ul>
           

        <div className="d-flex gap-2 flex-wrap ">
       
       <div className="button-container">
      <button
        className={`button ${isHovered ? 'hovered' : ''} import_export_btns`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>Excel Import</span>
        <div className='pageheader_icons_container'>
          <RiAttachment2 className='import_attach_icon' />
        </div>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          className="import_excel_input"
          style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
        />
      </button>
    </div>
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
          onClick={() => handleDownloadEXcel()}
      >
        <span>Excel Export</span>
        <div className='pageheader_icons_container' >
        <MdOutlineCloudDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
  
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`} 
       onClick={() => handleTemplateDownload()} 
      >
        <span>Import Template</span>
        <div className='pageheader_icons_container' >
        <TbFileDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
         onClick={() => {
          handleOpen();
          getcpmasterlistdropdownlist();

        }} 
      >
        <span>Add Inventory</span>
        <div className='pageheader_icons_container' >
        <IoMdAddCircleOutline className='pageheader_react_icons' />
        </div>
      </button>
    </div>
    
         
        </div>

        </div>
        <div className='  page_header_slider_container' >
        <div className='d-flex align-items-center gap-1'>  
        <FilterModal  propsState={propsState} stateSetters={stateSetters} />
        </div>

     
     
  
  <div className='slider_container' >    
    <Slider {...settings}>

  {filtertog.length > 0 && 
      <div class="active-filters"><button> <span className='filter_text_cont' >
            TOG is {filtertog.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filtertog.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearHeaderFilter('TOG')} />
          </button>
      </div>
      }
        {filterinHandStock.length > 0 && 
 <div class="active-filters">
          <button><span className='filter_text_cont' >
            On Hand Stock is {filterinHandStock.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterinHandStock.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearHeaderFilter('OnHandStock')} />
          </button>
      </div>
        }
        {filterCpName.length > 0 && 
<div class="active-filters">
<button> <span className='filter_text_cont' > CP Name is {filterCpName.map((item, index) => ( <span  className="ellipsis-button_contact_person" key={index}>
      {item.label}
      {index < filterCpName.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearHeaderFilter('CPName')} />
          </button>
      </div>
        }
        {filterContactPerson.length > 0 && 
   <div class="active-filters"> <button>  <span className='filter_text_cont' >
            Contact Person  is {filterContactPerson.map((item, index) => ( <span  className="ellipsis-button_contact_person" key={index}>
      {item.label}
      {index < filterContactPerson.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearHeaderFilter('ContactPerson')} />
          </button>
      </div>
        }
        {filterCity.length > 0 && 
<div class="active-filters">
 <button>  <span className='filter_text_cont' > City is {filterCity.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterCity.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearHeaderFilter('City')} />
          </button>
      </div>
        }
        {filterState.length > 0 && 
<div class="active-filters">
 <button>  <span className='filter_text_cont' > State is {filterState.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterState.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))}  </span> <RxCross2 onClick={() => clearHeaderFilter('State')} />
          </button>
      </div>

}
{filterItemCode.length > 0 && 
<div class="active-filters">
 <button>  <span className='filter_text_cont' >  Item Code is {filterItemCode.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterItemCode.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearHeaderFilter('ItemCode')} />
          </button>
      </div>
        }
        {filterAvgConsumption.length > 0 && 
 <div class="active-filters">
 <button>  <span className='filter_text_cont' > Avg. Consumption is {filterAvgConsumption.map((item, index) => ( <span  className="ellipsis-button_QD " key={index}>
      {item.label}
      {index < filterAvgConsumption.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearHeaderFilter('Consumption')} />
          </button>
      </div>
        }
        {filterLeadTime.length > 0 && 
  <div class="active-filters">
 <button>  <span className='filter_text_cont' > Lead Time is {filterLeadTime.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterLeadTime.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))}  </span> <RxCross2 onClick={() => clearHeaderFilter('LeadTime')} />
          </button>
      </div>
        }
        {filterFactorySafety.length > 0 && 

      <div class="active-filters">
  <button>  <span className='filter_text_cont' > Factor of Safety is {filterFactorySafety.map((item, index) => ( <span  className="ellipsis-button_QD " key={index}>
      {item.label}
      {index < filterFactorySafety.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearHeaderFilter('FactorySafety')} />
          </button>
      </div>
        }
        {filterQualifiedDemand.length > 0 && 
 <div class="active-filters"> 
 <button>  <span className='filter_text_cont' > Qualified Demand is {filterQualifiedDemand.map((item, index) => ( <span  className="ellipsis-button_QD " key={index}>
      {item.label}
      {index < filterQualifiedDemand.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearHeaderFilter('QualifiedDemand')} />
          </button>
      </div>
        }
        {filterNetflow.length > 0 && 
  <div class="active-filters">
 <button>  <span className='filter_text_cont' >  NetFlow is {filterNetflow.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterNetflow.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearHeaderFilter('NetFlow')} />
          </button>
      </div>
        }
        {filterMoq.length > 0 && 
<div class="active-filters">
<button>   <span className='filter_text_cont' >  MOQ is {filterMoq.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterMoq.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearHeaderFilter('MOQ')} /> </button>
      </div>
        }
        {filterOrderRecommendation.length > 0 && 
<div class="active-filters">
<button>   <span className='filter_text_cont' >   Order Recomme... {filterOrderRecommendation.map((item, index) => ( <span  className="ellipsis-button_contact_person" key={index}>
      {item.label}
      {index < filterOrderRecommendation.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearHeaderFilter('OrderRecommendation')} />
          </button>
      </div>
        }
           {filterUnit.length > 0 && 
<div class="active-filters">
<button>   <span className='filter_text_cont' >   unit is {filterUnit.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterUnit.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearHeaderFilter('unit')} />
          </button>
      </div>
        }
           {filterRealAWC.length > 0 && 
<div class="active-filters">
<button>    <span className='filter_text_cont' >  Real Time AWC is {filterRealAWC.map((item, index) => ( <span  className="ellipsis-button_QD" key={index}>
      {item.label}
      {index < filterRealAWC.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearHeaderFilter('realAWC')} />
          </button>
      </div>
        }
           {filterOpenOrder.length > 0 && 
<div class="active-filters">
<button>   <span className='filter_text_cont' >   Open Order is {filterOpenOrder.map((item, index) => ( <span  className="ellipsis-button_contact_person" key={index}>
      {item.label}
      {index < filterOpenOrder.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearHeaderFilter('openOrder')} />
          </button>
      </div>
        }
           {filterOrderRecommendationStatus.length > 0 && 
<div class="active-filters">
<button>   <span className='filter_text_cont' >   Order Recomme.. {filterOrderRecommendationStatus.map((item, index) => ( <span  className="ellipsis-button_contact_person" key={index}>
      {item.label}
      {index < filterOrderRecommendationStatus.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearHeaderFilter('OrderRecommendationstatus')} />
          </button>
      </div>
        }
               {filterItemDesc.length > 0 && 
<div class="active-filters">
<button>   <span className='filter_text_cont' >   Item Description is {filterItemDesc.map((item, index) => ( <span  className="ellipsis-button_QD" key={index}>
      {item.label}
      {index < filterItemDesc.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearHeaderFilter('itemdesc')} />
          </button>
      </div>
        }

            </Slider>
            </div>
      
        <div class="d-flex align-items-center flex-wrap justify-content-end gap-1 ms-auto ">

        <form className="d-none d-lg-inline-block form-inline navbar-search ">
          <div className="input-group align-items-center ">
            <input
              type="search"
              className="form-control p-0 bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              value={Search}
              onChange={(e) => setSearch(e.target.value)}
            />
         
                <LuSearch className="search_logo" />
            
          </div>
        </form>

      
        <button className='inactive_page_btn' 
        onClick={() => navigate("/inactiveinventory")} > <FaRegRectangleList className='list_icons' /> <span>  Inactive Inventory List </span> </button>
             
               <div>
 
          
               </div>
 
        </div>

</div>
        
        <div className='table-container'>
            <table className="table ">
                <thead>
                    <tr>
                    
                        <td scope="col">Sr. No</td>
                        <td scope="col">Action</td>
                        <td scope="col">  On Hand Priority</td>
                        { usertype != "ROTEX" &&  <>
                        <td scope="col">Qualified Demand</td>
                        <td scope="col">Consumption </td>
                        </> }
                        <td scope="col"> 
                        <div className="th_common">
                  <span>TOG</span>
                
                  <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("tog")} />
                </div> </td>
                        <td scope="col"> <div className="th_common">
                  <span>On Hand Stock</span>
                 
                     <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("onHandStock")} />
                </div>
                 </td>
                 <td scope="col"> <div className="th_common">
                  <span>Unit</span>
               
                     <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("unit")} />
                </div>
                 </td>
                        <td scope="col"> <div className="th_common">
                  <span>CP Name</span>
                 
                            <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("companyName")} />
                </div> </td>
                        <td scope="col"> <div className="th_common">
                  <span>Contact Person</span>
               
                     <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("contactPersonName")} />
                </div> </td>
                        <td scope="col"> <div className="th_common">
                  <span>City</span>
                 
                  <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("city")} />
                </div> </td>
                        <td scope="col"> <div className="th_common">
                  <span>State</span>
                
                    <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("state")} />
                </div> </td>
                        <td scope="col"> <div className="th_common">
                  <span>Item Code</span>
                 
                      <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("itemCode")} />
                </div>  </td>
                        <td scope="col"> <div className="th_common">
                  <span>Item Description</span>
                
                    <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("itemDescription")} />
                </div> </td>
                <td scope="col"> <div className="th_common">
                  <span>Real-time AWC</span>
                  
                     <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("avg")} />
                </div> </td>
                
                        <td scope="col"> <div className="th_common">
                  <span>Avg Weekly Consumption</span>
                 
                  <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("avgWeeklyConsumption")} />
                </div> </td>
                        <td scope="col"> <div className="th_common">
                  <span> Lead Time(weekly)</span>
                 

<img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("leadTime")} />
                </div></td>
                        <td scope="col"> <div className="th_common">
                  <span>Factor of Safety</span>
                  
                  <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("factorOfSafety")} />
                </div> </td>
                        <td scope="col"> <div className="th_common">
                  <span>Open Order</span>
                
                         <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("openOrder")} />
                </div> </td>
                        <td scope="col"> <div className="th_common">
                  <span> Qualified Demand</span>
                
                    <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("qualifiedDemand")} />
                </div></td>
                        <td scope="col"> <div className="th_common">
                  <span>Net Flow</span>
                  
                   <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("netFlow")} />
                </div></td>

                        <td scope="col"> <div className="th_common">
                  <span>MOQ</span>
                  
                   <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("moq")} />
                </div> </td>

                        <td scope="col"> <div className="th_common">
                  <span>Order<br/> Recommendation</span>
                 
                  <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("orderRecommendation")} />
                </div> </td>

                        <td scope="col"> <div className="th_common">
                  <span> Order<br/> Recommendation Status</span>
                  
                   <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("orderRecommendationStatus")} />
                </div></td>

                        <td scope="col"> <div className="th_common">
                  <span>On Hand Status</span>
                  
                     <img src={shortarrow_img} alt=''   style={{ cursor: "pointer" }}
                    onClick={() => sortBy("onHandStatus")} />
                </div> </td>
                
                        <td scope="col"> Status</td>
                        <td scope="col"> TOG Auto Update </td>


                    </tr>
                  <br />
                </thead>
                <tbody>
                  {
                    list?.map((val,i)=>(
                      <>
                      <tr key={i}  >
                      <td>{calculateSerialNumber(i)}</td>

                        <td>
                          <button  className='table_edit_btn' onClick={() => {handleUpdateBoxOpen() ;handleviewInventory(val?._id)}} >
                            <TbPencil />
                            </button> </td>
                        { val?.flag == "YELLOW" ? 
                          <td>
                            <button  className='btn btn-warning py-1'>Yellow</button>
                            </td>: 
                          val?.flag == "RED" ? 
                         <td><button  className='btn btn-danger py-1 px-3'>Red</button></td> : 
                           val?.flag == "BLACK" ?
                           <td><button  className='btn btn-dark py-1'>Black</button></td> : 
                          val?.flag == "GREEN" ? 
                          <td><button className='btn btn-success py-1'>Green</button></td> : 
                          <td><button  className='btn border py-1'>White</button></td>
                        }
                        {
                          usertype != "ROTEX" && <>
                              <td><button  className='btn blue-btn p-0 px-2 pb-1 fs-5' onClick={() => {handleopenQualifiedDemand() ;handleviewInventory(val?._id)}} >< AiOutlinePlus /></button></td>
                        <td><button onClick={() => {handleopenConsumption(); setConsumptionvalue(val)} }  className='btn  blue-btn p-0 px-2 pb-1 fs-5'>< AiOutlinePlus /></button></td>
                          </>
                        }
                    
                        <td>{ Math.round(val?.tog)}</td>
                        <td>{val?.onHandStock}</td>
                        <td>{val?.unit}</td>

                        <td>{val?.companyName}</td>
                        <td>{val?.contactPersonName}</td>
                        <td>{val?.city}</td>
                        <td>{val?.state}</td>
                        <td>{val?.itemCode}</td>
                        <td>{val?.itemDescription}</td>
                        <td>{  Math.round(val?.avg)}</td>
                        <td>{ Math.round(val?.avgWeeklyConsumption)}</td>
                        <td>{val?.leadTime}</td>
                        <td>{val?.factorOfSafety}</td>
                        <td>{val?.openOrder}</td>
                        <td>{val?.qualifiedDemand}</td>
                        <td>{val?.netFlow}</td>
                        <td>{val?.moq}</td>
                        <td>{val?.orderRecommendation}</td>
                        <td>{val?.orderRecommendationStatus !== undefined || val?.orderRecommendationStatus != 0 ? parseFloat(val?.orderRecommendationStatus).toFixed(2) : '0'}%</td>
                          <td>{val?.onHandStatus !== undefined || val?.onHandStatus != 0 ? parseFloat(val?.onHandStatus).toFixed(2) : '0'}%</td>
                <td>   <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={val?.active}
                      onChange={() => toggleStatus(val?._id)}
                    />
                 
                  </div></td>
                  <td>   <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={val?.isUpdateTog}
                      onChange={() => toggleTogUpdate(val?._id)}
                    />
                 
                  </div></td>
                      
                      </tr>
                      <br />
                      </>
                    ))
                  }
                    
                </tbody>
            </table>
        </div>

        <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} >  Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => handleItemsPerPageChange(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>
        
     


    




           {/* //  Add INVENTORY Master MODEL START  */}
     <Modal
     aria-labelledby="transition-modal-title"
     aria-describedby="transition-modal-description"
     open={open}
     onClose={handleClose}
     closeAfterTransition
     slots={{ backdrop: Backdrop }}
     slotProps={{
       backdrop: {
         timeout: 500,
       },
     }}
   >
     <Fade in={open}>
       <Box sx={styleForMediaQuery} className="model_box">
         <div className="model_header">
         <div className='d-flex align-items-center gap-1' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Add Inventory </p>
            </div>
           <div className="d-flex align-items-center gap-3 ">
             <button className="closebtn_cont" onClick={handleClose}>
               <RxCross2 className="closebox_logo" />{" "}
             </button>
           </div>
         </div>

         <div className="inputbox_cont">
           <div className="row">
           <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               CP Name
               </label>
              

<Select

options={optionlistcpname}
placeholder="Select"
value={cpname}
onChange={(e) => {hanlechangecpname(e) ; getproductdropdownlist(e.value)}}
isSearchable={true}
className='react_inbuild_select'
/>
       
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Contact Person
               </label>
               <input
                 type="text"
                 name="contactperson"
                 id=""
                 placeholder="Enter Contact Person "
                 className="add_inut_field"
                 value={addfromdata.contactperson}
      disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
            City
               </label>
               <input
                 type="text"
                 name="city"
                 id=""
                 placeholder="Enter City"
                 className="add_inut_field"
                 value={addfromdata.city}
                 disabled
               />
             </div>
           </div>

           <div className='row' >
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
            State
               </label>
               <input
                 type="text"
                 name="state"
                 id=""
                 placeholder="Enter State"
                 className="add_inut_field"
                 value={addfromdata.state}
                 disabled

               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
                 {" "}
                 Item Code
               </label>
             
               <Select

options={optionListproduct}
placeholder="Select"
value={itemcode}
onChange={hanlechangeitemcode}
isSearchable={true}
className='react_inbuild_select'
/>
                 
               
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Item Description
               </label>
               <input
                 type="text"
                 name="itemdesc"
                 id=""
                 placeholder="Enter Item description"
                 className="add_inut_field"
                 value={addfromdata.itemdesc}
                 disabled
               />
             </div>
             </div>
           <div className="row">
            
           
             
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Lead Time 
               </label>
               <input
                 type="text"
                 name="leadtime"
                 id=""
                 placeholder="Enter Lead Time"
                 className="add_inut_field"
                 value={addfromdata.leadtime}
                 disabled
               />
                  
             </div>
           
          
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Factor of Safety
               </label>
               <input
                 type="text"
                 name="factorysafety"
                 id=""
                 placeholder="Enter Factor of Safety"
                 className="add_inut_field"
                 value={addfromdata.factorysafety}
                 disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               TOG
               </label>
               <input
                 type="text"
                 name="tog"
                 id=""
                 placeholder="Enter TOG (Optional) "
                 className="add_inut_field"
                 value={addfromdata.tog}
                 onChange={addhandlechange}
               />
             </div>
             </div>
           <div className="row">
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               On Hand Stock
               </label>
               <input
                 type="text"
                 name="onhandstock"
                 id=""
                 placeholder="Enter On hand Stock"
                 className="add_inut_field"
                 value={addfromdata.onhandstock}
                 onChange={addhandlechange}
               />
             </div>
                
          <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            Qualified Demand
            </label>
            <input
              type="text"
              name="qualifieddemand2"
              id=""
              placeholder="Enter Qualified Demand"
              className="add_inut_field"
              value={addfromdata.qualifieddemand2}
              onChange={addhandlechange}
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            MOQ
            </label>
            <input
              type="text"
              name="moq"
              id=""
              placeholder="Enter MOQ"
              className="add_inut_field"
              value={addfromdata.moq}
              onChange={addhandlechange}
              disabled
            />
          </div>
           </div>
       
      
      
        
           <div className="addbox_btn_cont my-4 mt-3">
             <button onClick={() => handleaddinventory()  } disabled={disableAddbutton == true} >Save</button>
           </div>
         </div>
       </Box>
     </Fade>
   </Modal>
   {/* //  Add INVENTORY Master MODEL END  */}

        {/* //  EDIT INVENTORY Master MODEL START  */}
        <Modal
     aria-labelledby="transition-modal-title"
     aria-describedby="transition-modal-description"
     open={updateboxopen}
     onClose={handleUpdateBoxClose}
     closeAfterTransition
     slots={{ backdrop: Backdrop }}
     slotProps={{
       backdrop: {
         timeout: 500,
       },
     }}
   >
     <Fade in={updateboxopen}>
       <Box sx={styleForMediaQuery} className="model_box">
         <div className="model_header">
        <div className='d-flex gap-3 align-items-center '  >
         <button  className='table_edit_btn'  >
                            <TbPencil />
                            </button>
           <p className="m-0"> Edit Inventory </p>
           </div>
           <div className="d-flex align-items-center gap-3 ">

           <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={inventoryStatus == true}
                      onChange={handleStatusUpdate}
                    />
                  </div>
             <button className="closebtn_cont" onClick={handleUpdateBoxClose}>
               <RxCross2 className="closebox_logo" />{" "}
             </button>
           </div>
         </div>

         <div className="inputbox_cont">
           <div className="row">
           <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               CP Name
               </label>
               <input
                 type="text"
                 name="cpname"
                 id=""
                 placeholder="Enter CP Name"
                 className="add_inut_field"
                 value={updatefromdata.cpname}
                 disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Contact Person
               </label>
               <input
                 type="text"
                 name="contactperson"
                 id=""
                 placeholder="Enter Contact Person "
                 className="add_inut_field"
                 value={updatefromdata.contactperson}
      disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
            City
               </label>
               <input
                 type="text"
                 name="city"
                 id=""
                 placeholder="Enter City"
                 className="add_inut_field"
                 value={updatefromdata.city}
                 disabled
               />
             </div>
           </div>

           <div className='row' >
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
            State
               </label>
               <input
                 type="text"
                 name="state"
                 id=""
                 placeholder="Enter State"
                 className="add_inut_field"
                 value={updatefromdata.state}
                 disabled

               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
                 {" "}
                 Item Code
               </label>
               <input
                 type="text"
                 name="itemcode"
                 id=""
                 placeholder="Enter Item Code"
                 className="add_inut_field"
                 value={updatefromdata.itemcode}
                 disabled

               />
                  
               
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Item Description
               </label>
               <input
                 type="text"
                 name="itemdesc"
                 id=""
                 placeholder="Enter Item description"
                 className="add_inut_field"
                 value={updatefromdata.itemdesc}
                 disabled
                 
               />
             </div>
             </div>
           <div className="row">
            
           

             
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Lead Time 
               </label>
               <input
                 type="number"
                 name="leadtime"
                 id=""
                 placeholder="Enter Lead Time "
                 className="add_inut_field"
                 value={updatefromdata.leadtime}
                 
                 onChange={updatehandlechange}
                 
               />
                  
             </div>

             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Factor of Safety
               </label>
               <input
                 type="text"
                 name="factorysafety"
                 id=""
                 placeholder="Enter Factor of Safety"
                 className="add_inut_field"
                 value={updatefromdata.factorysafety}
                 disabled
                 
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               TOG
               </label>
               <input
                 type="text"
                 name="tog"
                 id=""
                 placeholder="Enter TOG"
                 className="add_inut_field"
                 value={ Math.round(updatefromdata.tog)}
                 onChange={updatehandlechange}
               />
             </div>
           </div>
           <div className="row">
          
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               On Hand Stock
               </label>
               <input
                 type="text"
                 name="onhandstock"
                 id=""
                 placeholder="Enter On hand Stock"
                 className="add_inut_field"
                 value={updatefromdata.onhandstock}
                 onChange={updatehandlechange}
               />
             </div>
              
          <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            Qualified Demand
            </label>
            <input
              type="text"
              name="qualifieddemand1"
              id=""
              placeholder="Enter Qualified Demand"
              className="add_inut_field"
              value={updatefromdata.qualifieddemand1}
              onChange={updatehandlechange}
            />
          </div>
           <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            MOQ
            </label>
            <input
              type="text"
              name="moq"
              id=""
              placeholder="Enter MOQ"
              className="add_inut_field"
              value={updatefromdata.moq}
              disabled
            />
          </div>
           </div>
          
        
           <div className="addbox_btn_cont my-4 mt-3">

             <button onClick={() => handleupdateInventory()} >Save</button>
           </div>
         </div>
       </Box>
     </Fade>
   </Modal>
   {/* //  EDIT INVENTORY Master MODEL END  */}

  {/* //  EDIT INVENTORY Master MODEL START  */}
  <Modal
     aria-labelledby="transition-modal-title"
     aria-describedby="transition-modal-description"
     open={openqulifiedDemand}
     onClose={handleCloseQualifiedDemand}
     closeAfterTransition
     slots={{ backdrop: Backdrop }}
     slotProps={{
       backdrop: {
         timeout: 500,
       },
     }}
   >
     <Fade in={openqulifiedDemand}>
       <Box sx={styleForMediaQuery} className="model_box">
         <div className="model_header">
         <div className='d-flex gap-3 align-items-center '  >
         <button  className='table_edit_btn'  >
                            <TbPencil />
                            </button>
           <p className="m-0"> Edit Qualified Demand  </p>
           </div>
           <div className="d-flex align-items-center gap-3 ">

             <button className="closebtn_cont" onClick={handleCloseQualifiedDemand}>
               <RxCross2 className="closebox_logo" />{" "}
             </button>
           </div>
         </div>

         <div className="inputbox_cont">
           <div className="row">
           <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               CP Name
               </label>
               <input
                 type="text"
                 name="cpname"
                 id=""
                 placeholder="Enter CP Name"
                 className="add_inut_field"
                 value={updatefromdata.cpname}
                 disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Contact Person
               </label>
               <input
                 type="text"
                 name="contactperson"
                 id=""
                 placeholder="Enter Contact Person "
                 className="add_inut_field"
                 value={updatefromdata.contactperson}
      disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
            City
               </label>
               <input
                 type="text"
                 name="city"
                 id=""
                 placeholder="Enter City"
                 className="add_inut_field"
                 value={updatefromdata.city}
                 disabled
               />
             </div>
           </div>

           <div className='row' >
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
            State
               </label>
               <input
                 type="text"
                 name="state"
                 id=""
                 placeholder="Enter State"
                 className="add_inut_field"
                 value={updatefromdata.state}
                 disabled

               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
                 {" "}
                 Item Code
               </label>
               <input
                 type="text"
                 name="itemcode"
                 id=""
                 placeholder="Enter Item Code"
                 className="add_inut_field"
                 value={updatefromdata.itemcode}
                 disabled

               />
                  
               
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Item Description
               </label>
               <input
                 type="text"
                 name="itemdesc"
                 id=""
                 placeholder="Enter Item description"
                 className="add_inut_field"
                 value={updatefromdata.itemdesc}
                 disabled
                 
               />
             </div>
             </div>
           <div className="row">
            
           

             
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Lead Time 
               </label>
               <input
                 type="text"
                 name="leadtime"
                 id=""
                 placeholder="Enter Lead Time "
                 className="add_inut_field"
                 value={updatefromdata.leadtime}
                 disabled
                 
               />
                  
             </div>

             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Factor of Safety
               </label>
               <input
                 type="text"
                 name="factorysafety"
                 id=""
                 placeholder="Enter Factor of Safety"
                 className="add_inut_field"
                 value={updatefromdata.factorysafety}
                 disabled
                 
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               TOG
               </label>
               <input
                 type="text"
                 name="tog"
                 id=""
                 placeholder="Enter TOG"
                 className="add_inut_field"
                 value={updatefromdata.tog}
                 onChange={updatehandlechange}
                 disabled
               />
             </div>
           </div>
           <div className="row">
          
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               On Hand Stock
               </label>
               <input
                 type="text"
                 name="onhandstock"
                 id=""
                 placeholder="Enter On hand Stock"
                 className="add_inut_field"
                 value={updatefromdata.onhandstock}
                 onChange={updatehandlechange}
                 disabled
               />
             </div>
              
          <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            Qualified Demand
            </label>
            <input
              type="text"
              name="qualifieddemand1"
              id=""
              placeholder="Enter Qualified Demand"
              className="add_inut_field"
              value={updatefromdata.qualifieddemand1}
              onChange={updatehandlechange}
            />
          </div>
           <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            MOQ
            </label>
            <input
              type="text"
              name="moq"
              id=""
              placeholder="Enter MOQ"
              className="add_inut_field"
              value={updatefromdata.moq}
              disabled
            />
          </div>
           </div>
          
        
           <div className="addbox_btn_cont my-4 mt-3">
             <button onClick={() => handleupdateInventory()} >Save</button>
           </div>
         </div>
       </Box>
     </Fade>
   </Modal>
   {/* //  EDIT INVENTORY Master MODEL END  */}

      {/* //  Add  Consumption  MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openconsumption}
        onClose={handleCloseConsumption}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },

        }}
        sx={{ zIndex: 9 }}
      >
        <Fade in={openconsumption}>
          <Box sx={consumptionStyleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className='d-flex align-items-center gap-1' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Add Consumption Master</p>
            </div>

              <div className="d-flex align-items-center gap-3 ">
                <button className="closebtn_cont" onClick={handleCloseConsumption}>
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
              <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                  CP Name
                  </label>
                  <input
                    type="text"
                    name="itemdesc"
                    id=""
                    placeholder="Maggi noodles"
                    className="add_inut_field"
                    value={consumptionval?.companyName}
                    disabled
                  />
              
                </div>
               
            
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    {" "}
                    Item Code
                  </label>
                   
                       <input
                    type="text"
                    name="itemdesc"
                    id=""
                    placeholder="Maggi noodles"
                    className="add_inut_field"
                    value={consumptionval?.itemCode}
                    disabled
                  />
                  
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                  Item Description
                  </label>
                  <input
                    type="text"
                    name="itemdesc"
                    id=""
                    placeholder="Maggi noodles"
                    className="add_inut_field"
                    value={consumptionval?.itemDescription}

                    disabled
                  />
                </div>
              </div>
              <div className="row">
               
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                  Product Name
                  </label>
                  <input
                    type="text"
                    name="Productname"
                    id=""
                    placeholder="product name"
                    className="add_inut_field"
                    value={consumptionval?.productName}

                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                  Category
                  </label>
                  <input
                    type="text"
                    name="category"
                    id=""
                    placeholder="600"
                    className="add_inut_field"
                    value={consumptionval?.categoryName}

disabled
/>
                  
                </div>
                
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                  Sub-Category
                  </label>
                  <input
                    type="text"
                    name="subcategory_name"
                    id=""
                    placeholder="Cup Noodles"
                    className="add_inut_field"
                    value={consumptionval?.subCategoryName}

                    disabled
                  />
                     
                </div>
              </div>
              <div className="row">
             
                
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                  QTY
                  </label>
                  <input
                    type="text"
                    name="qty"
                    id=""
                    placeholder="20"
                    className="add_inut_field"
                    value={addconsumption.qty}
                    onChange={handleconsumption}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                  Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    id=""
                    placeholder="dd/mm/yyyy"
                    className="add_inut_field"
                    value={addconsumption.date}
                    onChange={handleconsumption}
                  />
                </div>
              </div>
           
              <div className="addbox_btn_cont my-4 mt-3">
                <button onClick={() =>
                
                  addconsumtionbyinventory(consumptionval?.onHandStock)
                   }>Save</button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
   {/* //  EDIT Consumption Master MODEL END  */}

   
    </div>

  )
}

export default MainTable
