
//  export let API_URL =  "http://52.66.118.200:5000"

// export let API_URL = "http://192.168.0.179:5000"//


export let API_URL = "https://api.fieldkonnect.io" 



// API CALL FOR USER LIST 
export const Userlist = (data) => dispatch  => {
return  fetch(`${API_URL}/api/user/auth/all`, {
    method: 'POST',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("token")}`,
    },
     body :JSON.stringify(data)
}).then(res => {
    
    return res.json()
})
}
// API CALL FOR ADD USER  
export const AddUser= (data) => dispatch  => {
    return  fetch(`${API_URL}/api/user/auth/createUser`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
          // API CALL FOR  VIEW  USER  
export const ViewUser = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/user/auth/${data?.id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
         // API FOR UPDATE User STATUS
 export const UpdateUserStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/auth/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

             // API FOR  USER PROFILE INFO
 export const UserProfileInfo  = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/auth/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
       
    }).then(res => {
        
        return res.json()
    })
    }
   // API FOR UPDATE USER EXCEL SHEET STATUS
   export const updateUserExcelSheet  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/auth/import`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
    


         // API CALL FOR  UPDATE NEW USER  
export const UpdateUser = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/auth/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
// API CALL FOR SUBCATEGORY LIST 
export const Subcategorylist = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/user/subcategory/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
// API CALL FOR CATEGORY LIST DROPDOWN
export const Categorylistdropdowm = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/user/category/dropdown`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
    // API CALL FOR SUB CATEGORY LIST DROPDOWN ACCORDNG TO CATEGORY 
export const Subcategorylistdropdowm = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/subcategory/category/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
        // API CALL FOR CHANNEL PARTNER LIST DROPDOWN 
export const channellistdropdowm = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/channelpartner/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
    export const inventorylistdropdowm = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/inventory/view`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body : JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
         // API CALL FOR PRODUCT  LIST DROPDOWN 
export const productmasterlistdropdowm = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/products/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }

       // API CALL FOR PRODUCT  LIST DROPDOWN ACCORDING TO channelPartnerId
export const inventoryProductListDropdowm = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/inventory/product/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


     // API CALL FOR UNIT  LIST DROPDOWN 
export const Unitlistdropdowm = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/unit/dropdown/list`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }


     // API CALL FOR INDUSTRY  LIST DROPDOWN 
export const Industrylistdropdowm = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/industry/dropdown/list`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
      // API CALL FOR CONSUMPTION    LIST DROPDOWN 
export const ConsumptionlistDropdown = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/consumption/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }

     // API CALL FOR ORDER RECOMMANDATION  LIST DROPDOWN 
export const OrderRecommandationlistDropdown = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/order/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }

       // API CALL FOR USER   LIST DROPDOWN 
export const UserlistDropdown = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/auth/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
        // API CALL FOR ORDER RECOMMANDATION  LIST DROPDOWN 
export const InventorylistDropdown = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/inventory/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
    
    
    // API CALL FOR CREATE NEW SUB CATEGORY 
export const AddSubCategory = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/user/subcategory`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

      // API CALL FOR  VIEW  SUBCATEGORY  
export const ViewSubCategory = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/user/subcategory/info/${data?.id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
    


         // API CALL FOR  UPDATE NEW SUBCATEGORY  
export const UpdateSubCategory = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/subcategory/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

     // API FOR UPDATE SUB-CATEGORY STATUS
 export const UpdateSubCategoryStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/subcategory/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
    // API FOR IMPORT SUB CATEGORY LIST 
    export const updateSUbCategoryExcelSheet  = (data ,id) => dispatch  => {
        return  fetch(`${API_URL}/api/user/subcategory/importSubCategory`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
    

    // API CALL FOR CATEGORY LIST 
export const Categorylist = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/user/category/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
        // API CALL FOR  ADD NEW CATEGORY LIST 
export const AddCategory = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/user/category`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
         // API CALL FOR  VIEW  CATEGORY  
export const ViewCategory = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/user/category/info/${data?.id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
         // API CALL FOR  UPDATE NEW CATEGORY  
export const UpdateCategory = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/category/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
      // API FOR UPDATE CATEGORY STATUS
 export const UpdateCategoryStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/user/category/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
// API FOR IMPORT CATEGORY LIST 
    export const updateCategoryExcelSheet  = (data ,id) => dispatch  => {
        return  fetch(`${API_URL}/api/user/category/importCategory`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
    

 // API CALL FOR CP MASTER LIST 
 export const CPMaterList = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/channelpartner/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
         // API FOR ADD CPMASTER IN CP MASTER LIST 
         export const Addcpmaster  = (data) => dispatch  => {
            return  fetch(`${API_URL}/api/channelpartner`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                 body :JSON.stringify(data)
            }).then(res => {
                
                return res.json()
            })
            }
               // API FOR VIEW  CP MASTRE IN CP MASTER LIST 
        export const Viewcpmaster  = (data) => dispatch  => {
            return  fetch(`${API_URL}/api/channelpartner/${data?.id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
            }).then(res => {
                
                return res.json()
            })
            }
               // API FOR EDIT CP MASTER IN CP MASTER LIST 
        export const Updatecpmaster  = ( data ,id) => dispatch  => {
            return  fetch(`${API_URL}/api/channelpartner/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                 body :JSON.stringify(data)
            }).then(res => {
                
                return res.json()
            })
            }
                  // API FOR UPDATE  STATUS IN CP MASTER
 export const UpdateCpmasterStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/channelpartner/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
     
    // API FOR UPDATE CP MASTER EXCEL SHEET STATUS
 export const updateCpExcelSheet  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/channelpartner/import`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
            


    // API FOR SHOW LIST IN PRODUCT MASTER LIST 
    export const Productmasterlist  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/products/all`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body :JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }

         // API FOR ADD PRODUCT IN PRODUCT MASTER LIST 
    export const Addproductmaster  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/products`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body :JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
           // API FOR ADD PRODUCT IN PRODUCT MASTER LIST 
    export const Viewproductmaster  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/products/${data?.id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
        }).then(res => {
            
            return res.json()
        })
        }
           // API FOR ADD PRODUCT IN PRODUCT MASTER LIST 
    export const Updateproductmaster  = ( data ,id) => dispatch  => {
        return  fetch(`${API_URL}/api/products/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body :JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }

         // API FOR UPDATE PRODUCT MASTERSTATUS
 export const UpdateProductMasterStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/products/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
       // API FOR UPDATE PRODUCT MASTER EXCEL SHEET STATUS
 export const updateProductExcelSheet  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/products/importProducts`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


           

 // API FOR SHOW LIST IN CONSUMPTION MASTER
  export const Showlistconsumptionmaster  = ( data) => dispatch  => {
            return  fetch(`${API_URL}/api/consumption/all`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                 body :JSON.stringify(data)
            }).then(res => {
                
                return res.json()
            })
            }



             // API FOR ADD SHOW LIST IN CONSUMPTION MASTER
  export const Addconsumptionmaster  = ( data) => dispatch  => {
    return  fetch(`${API_URL}/api/consumption`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

         // API FOR VIEW  LIST IN CONSUMPTION MASTER
  export const Viewconsumptionmaster  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/consumption/${data?.id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
    
         // API FOR EDIT   IN CONSUMPTION MASTER
  export const Editconsumptionmaster  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/consumption/update`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        return res.json()
    })
    }

       // API FOR UPDATE CONSUMPTION STATUS
 export const UpdateConsumptionStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/consumption/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
    // API FOR UPDATE CONSUMPTION STATUS
 export const UpdateConsumptionExcelSheet  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/consumption/import`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

             // API FOR VIEW  LIST IN UNIT PAGE
  export const ShowUnitlist  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/unit/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

             // API FOR ADD UNITS
  export const Addunitlist  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/unit`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

     // API FOR VIEW UNit NAME
 export const Viewunitlist  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/unit/${data?.id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }

       // API FOR UPDATE UNIT NAME
 export const Updateunitlist  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/unit/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
     // API FOR UPDATE UNIT STATUS
 export const UpdateUnitStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/unit/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


 // API FOR VIEW  LIST IN INDUSTRY PAGE
  export const ShowIndustrylist  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/industry/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

 // API FOR ADD INDUSTRY
  export const Addindustrylist  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/industry`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
 // API FOR VIEW INDUSTRY NAME
 export const Viewindustrylist  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/industry/${data?.id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }


       // API FOR UPDATE INDUSTRY NAME
 export const Updateindsutrylist  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/industry/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
       // API FOR UPDATE INDUSTRY STATUS
 export const UpdateIndsutryStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/industry/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
   



     // API FOR SHOW INVENTORY BUFFER LISTS
 export const inventorylist  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/inventory/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
        // API FOR ADD INVENTORY BUFFER LISTS
 export const Addinventory  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/inventory`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

       // API FOR VIEW INVENTORY BUFFER LISTS
 export const Viewinventory  = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/inventory/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
      
    }).then(res => {
        
        return res.json()
    })
    }
       // API FOR EDIT INVENTORY BUFFER LISTS
       export const Editinventory  = (data,id) => dispatch  => {
        return  fetch(`${API_URL}/api/inventory/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
          
        }).then(res => {
            
            return res.json()
        })
        }

         // API FOR UPDATE INVENTORY BUFFER STATUS
       export const UpdateInventoryStatus  = (data,id) => dispatch  => {
        return  fetch(`${API_URL}/api/inventory/status/${id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
          
        }).then(res => {
            
            return res.json()
        })
        }
          // API FOR UPDATE INVENTORY BUFFER NEW TOGGLE 
       export const UpdateInventoryToggle  = (data,id) => dispatch  => {
        return  fetch(`${API_URL}/api/inventory/${id}/tog-toggle`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
          
        }).then(res => {
            
            return res.json()
        })
        }


          // API FOR LIST INVENTORY BY CP NAME
       export const listcpinventory  = (data,id) => dispatch  => {
        return  fetch(`${API_URL}/api/inventory/all`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
          
        }).then(res => {
            
            return res.json()
        })
        }

 // API FOR SHOW FLAGS COUNT IN DASHBOARD
 export const ShowFlagCount  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/inventory/dashboard`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
      
    }).then(res => {
        
        return res.json()
    })
    }
       // API FOR UPDATE INVENTORY  EXCEL SHEET
 export const UpdateInventoryExcelSheet  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/inventory/import`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

        // API FOR SHOW ORDER RECOMMONDATION LISTS
 export const orderRecommandationList  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/order/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
   
         // API FOR SHOW ORDER RECOMMONDATION LISTS
 export const orderRecommandationView  = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/order/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }

         // API FOR SHOW ORDER RECOMMONDATION LISTS
 export const orderRecommandationEdit  = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/order/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
           // API FOR SHOW ORDER STATUS LISTS IN HEADER
 export const orderRecommandationStatusList  = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/order/dashboard/count`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
   
    // Send file to api
    export const FileToApi= (formData) => dispatch  => {
        return  fetch(`${API_URL}/api/order/attachment`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body:formData
        }).then(res => {
            return res.json()
        })
        }


                // API CALL FOR Sap So number
export const sapSoNumber = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/order/sap/number`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


// ORDER MASRER MTO API'S

// API FOR GET DATE AND DAYS LEAD TIME 
export const getdatedays = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/lead-time`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

    // API FOR ADD ORDER RECOMMENDATION MTO 
export const addOrderMTO = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/mto`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

    // API FOR ADD ORDER RECOMMENDATION MTO 
export const orderMTOlist = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

     // API TO GET ITEMCODE FOR PRODUCT ID
export const getItemCodeMTO = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
     // API TO GET COUNT OF ALL APPROVED
     export const getcountofall  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/mto/dashboard/count`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body :JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
    
          // API TO GET ORDER FLOW DATA IN MTO
     export const getOrderFlowDataMTO  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/mto/${data}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
        }).then(res => {
            
            return res.json()
        })
        }


                  // API TO ACCEPT ORDER FLOW RECOMMENDATION
 export const orderRecommandationMTOAccept  = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


       // API TO ADD REMARK BY CP IN OREDER MTA
 export const addRemarkByCPMTA  = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/order/remark/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


     // API CALL FOR ORDER RECOMMANDATION MTO  LIST DROPDOWN 
export const OrderRecommandationMTOlistDropdown = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/filter/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }

      // Send Browsefile BY CP IN ORDER MTO
      export const sendBrowseFileByCP= (formData) => dispatch  => {
        return  fetch(`${API_URL}/api/mto/attachment`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body:formData
        }).then(res => {
            return res.json()
        })
    }

        // API CALL FOR Sap So number IN MTO BY ROTEX 
export const AddsapSoNumberByROtex = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/Details/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

    // API CALL FOR Add EXPECTED DAYS BY ROTEX OR ADMIN IN MTO 
export const AddExpectedDaysByRotes = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/manufacturing-time/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }



    // API CALL FOR SHOW APPROVAL API IN  MTO 
export const getApprovalList = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/all-approval`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

       // API CALL FOR SHOW APPROVAL API IN  MTO 
export const updateApproval = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/manufacturing-time-update/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

      // API CALL FOR SHOW APPROVAL DATAAPI IN  MTO 
export const viewApproval = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/approval/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }



    // API CALL FOR SUBCATEGORY LIST 
export const ViewCPLeadTime = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/mto/product-lead-time`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

    