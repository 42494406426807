import React, { useState, useEffect } from "react";
import store from "../../../Redux/MyRedux/store";
import {
  CPMaterList,
  UpdateCpmasterStatus,
} from "../../../Redux/MyRedux/action/userActions";
import Pagination from "react-mui-pagination";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { BiCloudUpload } from "react-icons/bi";

import { LuSearch } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import sort_logo_img from '../../../Assets/table/shortarrow.png';
import {FaRegRectangleList} from 'react-icons/fa6'

function InactiveCustomtable() {
  const channelid = window.location.href.split("/")[4];

  const [Search, setSearch] = useState("");
  const [page, setPage] = React.useState(1);
  const [listdata, setList] = useState([]);
  const [recordperpage, setRecordPerPage] = useState(1000);
  const [total_count, setTotalCount] = React.useState(0);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      companyName: [],
      contactPersonName: [],
      phone: [],
      email: [],
      city: [],
      state: [],
      address: [],
      leadTime: [],
      isActive: false,
    };

    try {
      store.dispatch(CPMaterList(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data[0]?.docs.length > 0) {
            setList(res?.data[0]?.docs);
            setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          } else {
            setList([]);
            setTotalCount(0);
          }
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  useEffect(() => {
    getdata();
  }, [recordperpage, page]);

  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      "Cp name",
      "Contact Person ",
      "City ",
      "State",
      "Address",
      "Phone",
      "Email",
      "Lead Time",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [
        item.companyName,
        item.contactPersonName,
        item.city,
        item.state,
        item.address,
        item.phone,
        item.email,
        item.leadTime,
      ]),
    ];

    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "data.xlsx");
  };

  const handleupdateCPstatus = (value, id) => {
    const data = {
      active: value,
    };
    try {
      store.dispatch(UpdateCpmasterStatus(data, id)).then((res) => {
        if (res?.isError == false) {
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message)
        }

      });
    } catch (error) {
      console.error("Error occurred:", error);
      return error;
    }
  };

  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.isActive;
      handleupdateCPstatus(updatedStatus, userId);
    }
  };

  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
const sortBy = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  const sortedData = [...listdata].sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  setList(sortedData);
  setSortConfig({ key, direction });
};

const [isHovered , setIsHovered] = useState(false)

  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading">Inactive CP Master</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={(e) => setSearch(e.target.value)}
              value={Search}

            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>

        <div className="d-flex gap-2 flex-wrap ms-1">
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
          onClick={() => handleDownloadEXcel()}
      >
        <span>Excel Export</span>
        <div className='pageheader_icons_container' >
        <BiCloudUpload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
     </div>
      </div>
      <div className="d-flex justify-content-end align-items-center">
       

        <div class=""> 
          
          <button className='inactive_page_btn' 
       onClick={() => navigate(-1)} > <FaRegRectangleList className='list_icons' /> <span> Active CP List </span> </button>

       
        </div>
      </div>

      <div className="table-container mt-1">
        <table className="table ">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col" >
              <div className="th_common">
                  <span>CP Name</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("companyName")}
                  /> */}
                  <img src={sort_logo_img}  alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("companyName")}
                  />
                </div>
                
              </td>

              
              <td scope="col">
                <div className="th_common">
                  <span>Contact Person</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("contactPersonName")}
                  /> */}
                     <img src={sort_logo_img}  alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("contactPersonName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>City</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("city")}
                  /> */}
                     <img src={sort_logo_img}  alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("city")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>State </span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("state")}
                  /> */}
                     <img src={sort_logo_img}  alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("state")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Address </span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("address")}
                  /> */}
                     <img src={sort_logo_img}  alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("address")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Phone</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("phone")}
                  /> */}
                     <img src={sort_logo_img}  alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("phone")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Email</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("email")}
                  /> */}
                   <img src={sort_logo_img}  alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("email")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Lead Time</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("leadTime")}
                  /> */}
                     <img src={sort_logo_img}  alt=""
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("leadTime")}
                  />
                </div>
              </td>

              <td scope="col">Status</td>

            </tr>
            <br />
          </thead>
          <tbody>
            {listdata?.map((value, i) => (
              <>
              <tr key={i}>
                <td> {i + 1} </td>

                <td>{value?.companyName}</td>

              
                <td>{value?.contactPersonName}</td>
                <td>{value?.city}</td>
                <td>{value?.state}</td>
                <td>{value?.address}</td>
                <td>{value?.phone}</td>
                <td>{value?.email}</td>
                <td>{value?.leadTime}</td>
                <td>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={value?.isActive}
                      onChange={() => toggleStatus(value?._id)}
                    />
                  </div>
                </td>
              </tr>
              <br />
              </>
            ))}
          </tbody>
        </table>
      </div>

      {/* <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
        <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
          <p className="p3 m-0 link-btn" style={{ fontFamily: "Poppins" }}>
            Total Count
          </p>
          <select
            className="w-auto form-select form-select-sm p3 shadow-none mx-3 "
            style={{ fontFamily: "Poppins" }}
            value={recordperpage}
            onChange={(e) => setRecordperPage(e.target.value)}
          >
             <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
          </select>
          <p className="p3 m-0" style={{ fontFamily: "Poppins" }}>
          Displaying {recordperpage > total_count ? total_count : recordperpage } out of {total_count}
          </p>
        </div>

        <div className="d-flex border  justify-content-center align-items-center">
          <Pagination
            page={page}
            setPage={handleChange}
            total={total_count}
            className="pagination"
            perPage={recordperpage} 
          />
        </div>
      </div> */}

<div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} > Showing 01 - {recordperpage > total_count ? total_count : recordperpage } 
            
              &nbsp; of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => setRecordPerPage(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>

    </div>
  );
}

export default InactiveCustomtable;
