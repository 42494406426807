import React, { useState } from 'react'
// import './Dashboard.css'
import InactiveDashboradtable from './inactivetable'
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';

function InactiveDashboard() {
  const [sidebaropen , setSidebarOpen] = useState(false)

  return (
    <div className='d-flex'>
    <Sidebar setSidebarOpen={setSidebarOpen} />
    <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
    <Header  />
    <InactiveDashboradtable />
    </div> 
</div> 
  )
}

export default InactiveDashboard;
