import React, { useEffect, useState } from "react";
import "./table.css";
import "../Dashboard/MainTable/MainTable.css";
import Pagination from "react-mui-pagination";
import store from "../../Redux/MyRedux/store";
import {
  AddUser,
  Unitlistdropdowm,
  UpdateUser,
  UpdateUserStatus,
  Userlist,
  ViewUser,
  channellistdropdowm,
  updateUserExcelSheet,
} from "../../Redux/MyRedux/action/userActions";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import Slider from "react-slick";
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegRectangleList } from "react-icons/fa6";
import FilterModal from "./filterpopup/filter";
import { TbPencil } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";
import { spMasterDropDownList } from "../../Redux/MyRedux/action/phase3";

function Usertable() {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const [user_id, setUserId] = useState("");

  const [firmname, setFirmName] = useState("");
  const [rotexunit, setRotexUnit] = useState([]);

  const [firmError, setfirmError] = useState(false);

  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [Search, setSearch] = useState("");
  const [listdata, setListdata] = useState([]);

  const [page, setPage] = React.useState(1);
  const [total_count, setTotalCount] = React.useState(0);
  const [cpmasterlistdropdown, setCPmasterListDropdown] = useState([]);
  const [spmasterlistdropdown, setSPmasterListDropdown] = useState([]);

  const [unitdropdown, setUnitdropdown] = useState([]);

  const [unitstatus, setUnitStatus] = useState(true);
  const [filterUserType, setFilterUserType] = useState([]);
  const [filterUserName, setFilterUserName] = useState([]);
  const [filterFirmName, setFilterFirmName] = useState([]);
  const [filterEmail, setFilterEmail] = useState([]);
  const [updateAPI, setUpdateAPI] = useState([]);

  const stateSetters = {
    setFilterUserType,
    setFilterUserName,
    setFilterFirmName,
    setFilterEmail,
    setUpdateAPI,
  };
  const propsState = {
    filterUserType,
    filterUserName,
    filterFirmName,
    filterEmail,
  };

  const [acceptpermissionisChecked, setAcceptPermissionIsChecked] = useState(false);
  const [acceptpermissionValue, setAcceptPermissionValue] = useState(false);

  const AcceptpermissionhandleToggle = () => {
    setAcceptPermissionIsChecked(!acceptpermissionisChecked);
    setAcceptPermissionValue(acceptpermissionisChecked ? false : true);
  };

  const [wippermissionisChecked, setWIPPermissionIsChecked] = useState(false);
  const [wippermissionValue, setWIPPermissionValue] = useState(false);

  const WIPpermissionhandleToggle = () => {
    setWIPPermissionIsChecked(!wippermissionisChecked);
    setWIPPermissionValue(wippermissionisChecked ? false : true);
  };

  const [ acceptmanufactureCheckedforaddApproval, setAcceptManufactureCheckedForAddApproval,] = useState(false);
  const [acceptManufactureAddApprovalValue, setAcceptManuFactureAddValueValue] = useState(false);

  const AcceptManufactureadhandleToggle = () => {
    setAcceptManufactureCheckedForAddApproval(
      !acceptmanufactureCheckedforaddApproval
    );
    setAcceptManuFactureAddValueValue(
      acceptmanufactureCheckedforaddApproval ? false : true
    );
  };

  const [
    acceptmanufactureCheckedforApproval,
    setAcceptManufactureCheckedForApproval,
  ] = useState(false);
  const [acceptManufactureApprovalvalue, setAcceptManuFactureApprovalValue] =
    useState(false);

  const AcceptManufactureApprovalhandleToggle = () => {
    setAcceptManufactureCheckedForApproval(
      !acceptmanufactureCheckedforApproval
    );
    setAcceptManuFactureApprovalValue(
      acceptmanufactureCheckedforApproval ? false : true
    );
  };

  const [cpTabsApproval,setCpTabsApproval] = useState(false);
  const [spTabsApproval,setSpTabsApproval] = useState(false);



  const initialvalueforupdate = {
    usertype: "",
    firstname: "",
    lastname: "",
    firm: "",
    email: "",
    password: "",
  };
  const [updatefromdata, setUpdateFormData] = useState(initialvalueforupdate);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#F4F6FA",
    boxShadow: 24,
  };

  const mediaQuery = `@media (max-width: 768px)`;

  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      active: true,
      userType: filterUserType.map((item) => item.label),
      firstName: filterUserName.map((item) => item.label),
      firmName: filterFirmName.map((item) => item.label),
      email: filterEmail.map((item) => item.label),
    };

    try {
      store.dispatch(Userlist(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data[0]?.docs.length > 0) {
            setListdata(res?.data[0]?.docs);
            setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          }
        }

      setUpdateAPI('')
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getdata();
  }, [recordperpage, Search, page, updateAPI]);

  const handleviewuser = (id) => {
    setUserId(id);
    const data = {
      id: id,
    };
    try {
      store.dispatch(ViewUser(data)).then((res) => {
        if (res?.isError === false) {
          setUpdateFormData((prevState) => ({
            ...prevState,
            usertype: res?.data?.userType,
            firstname: res?.data?.firstName,
            lastname: res?.data?.lastName,
            firm: res?.data?.firmName,
            email: res?.data?.email,
            password: res?.data?.password,
            // You might not want to update the password field directly from API response
          }));
          setFirmName({
            value: res?.data?.channelPartnerId,
            label: res?.data?.firmName,
          });
          setUnitStatus(res?.data?.active);
          setRotexUnit(res?.data?.unitInfo);
          setWIPPermissionValue(res?.data?.isAcceptanceAtWIP);
          setAcceptPermissionValue(res?.data?.isAcceptanceAtRotex);
          setAcceptPermissionIsChecked(res?.data?.isAcceptanceAtRotex);
          setWIPPermissionIsChecked(res?.data?.isAcceptanceAtWIP);

          setAcceptManufactureCheckedForApproval(res?.data?.isApproval);
          setAcceptManuFactureApprovalValue(res?.data?.isApproval);
          setAcceptManufactureCheckedForAddApproval(
            res?.data?.isEditingLeadDays
          );

          setAcceptManuFactureAddValueValue(res?.data?.isEditingLeadDays);
          setSpTabsApproval(res?.data?.IsRotexViewSupplierModule);
          setCpTabsApproval(res?.data?.IsRotexViewCpModule);


          handleUpdateBoxOpen();
        } else {
          setUpdateFormData(initialvalueforupdate);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [updateboxopen, setUpdateBoxOpen] = React.useState(false);
  const handleUpdateBoxOpen = () => setUpdateBoxOpen(true);
  const handleUpdateBoxClose = () => setUpdateBoxOpen(false);

  const getcpmasterlistdropdownlist = () => {
    try {
      store.dispatch(channellistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setCPmasterListDropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

  const getSpMasterdropdownList = () => {
    try {
      store.dispatch(spMasterDropDownList()).then((res) => {
        if (res?.isError === false) {
          setSPmasterListDropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

  const getunitdropdownlist = () => {
    try {
      store.dispatch(Unitlistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setUnitdropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

  const optionListcpname = cpmasterlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.companyName,
  }));
  const optionListspname = spmasterlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.supplierName,
  }));
  const optionListunit = unitdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.unit,
  }));

  const handleupdateuserstatus = (id, value) => {
    const data = {
      active: value,
      userid: id,
    };
    try {
      store.dispatch(UpdateUserStatus(data, id)).then((res) => {
        if (res?.isError == false) {
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const navigate = useNavigate();

  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handleupdateuserstatus(userId, updatedStatus);
    }
  };

  const handleTemplateDownload = () => {
    // Custom headers
    const headers = [
      "User Type",
      "First Name",
      "Last Name",
      "Firm Name",
      "Email",
      "Password",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [headers, []];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "UserList template.xlsx");
  };

  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      "User Type",
      "First Name",
      "Last Name",
      "Firm Name",
      "Email",
      "Password",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [
        item.userType,
        item.firstName,
        item.lastName,
        item.firmName,
        item.email,
        item.password,
      ]),
    ];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "data.xlsx");
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        // Parse the Excel file to JSON
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Define a mapping between old header names and new parameter names
        const headerMapping = {
          "User Type": "userType",
          "First Name": "firstName",
          "Last Name": "lastName",
          "Firm Name": "firmName",
          Email: "email",
          Password: "password",
        };

        // Map the old header names to the new parameter names in the JSON data
        const mappedJsonData = jsonData.map((item) => {
          const mappedItem = {};
          for (const oldHeader in item) {
            if (oldHeader in headerMapping) {
              mappedItem[headerMapping[oldHeader]] = item[oldHeader];
            }
          }
          return mappedItem;
        });

        // setExcelJson(mappedJsonData);

        updateNewExcel(mappedJsonData);
      };
      reader.readAsBinaryString(file);
    }
  };

  const updateNewExcel = async (json) => {
    // Mark the function as async
    try {
      const res = await store.dispatch(updateUserExcelSheet(json));

      if (res?.isError === false) {
        toast.success(res?.message);

        if (res?.data?.errorArrray.length > 0) {
          toast.error(
            ` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`
          );
          const headers = [
            "User Type",
            "First Name",
            "Last Name",
            "Firm Name",
            "Email",
            "Password",
            "Error",
          ];

          // Map data to a new array with custom headers
          const dataWithCustomHeaders = [
            headers,
            ...res?.data?.errorArrray.map((item) => [
              item.userType,
              item.firstName,
              item.lastName,
              item.firmName,
              item.email,
              item.error,
            ]),
          ];
          // Generate Excel file
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          // Save file
          const fileData = new Blob([excelBuffer], {
            type: "application/octet-stream",
          });
          saveAs(fileData, "Errordata.xlsx");
        } else {
          // Display an alert when errorArray is empty
          toast.success("No error data found. All data upload Successfully");
        }
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };

  const validationSchema = Yup.object().shape({
    usertype: Yup.string().required("User Type is required"),
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      )
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });
  const initialValues = {
    usertype: "",
    firstname: "",
    lastname: "",
    email: "",
    password: "",
  };
  const handleFormSubmit = (values, { setSubmitting }) => {
    handledduser(values);
    setSubmitting(false);
  };
  const handledduser = (values) => {
    if (
      values.usertype === "CP" &&
      (firmname.label === "" ||
        firmname.label === null ||
        firmname.label === undefined)
    ) {
      setfirmError(true);
    } else if (values.usertype === "ROTEX" && rotexunit.length == 0) {
      setfirmError(true);
    } else {
      const data = {
        firstName: values?.firstname,
        lastName: values?.lastname,
        firmName: firmname.label,
        email: values?.email,
        password: values?.password,
        userType: values?.usertype,
        isAcceptanceAtRotex: acceptpermissionValue,
        isAcceptanceAtWIP: wippermissionValue,
        isEditingLeadDays: acceptManufactureAddApprovalValue,
        isApproval: acceptManufactureApprovalvalue,
        IsRotexViewSupplierModule:spTabsApproval,
        IsRotexViewCpModule:cpTabsApproval,
        ...(values.usertype === "CP" && {
          channelPartnerId: firmname.value,
        }),
        ...(values.usertype === "SP" && {
          supplierPartnerId: firmname.value,
        }),
        ...(values.usertype === "ROTEX" && {
          units: rotexunit.map((res) => res?.value),
        }),
      };
      try {
        store.dispatch(AddUser(data)).then((res) => {
          if (res?.isError == false) {
            handleClose();
            toast.success(res?.message);
            getdata();
            setFirmName({
              value: "",
              label: "",
            });
            setRotexUnit([]);
            setWIPPermissionValue(false);
            setAcceptPermissionValue(false);
            setAcceptPermissionIsChecked(false);
            setWIPPermissionIsChecked(false);
            setAcceptManufactureCheckedForApproval(false);
            setAcceptManuFactureApprovalValue(false);
            setAcceptManufactureCheckedForAddApproval(false);
            setAcceptManuFactureAddValueValue(false);
            setCpTabsApproval(false);
            setSpTabsApproval(false);

          } else {
            toast.error(res?.message);
          }
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };

  const handleFormupdate = (values, { setSubmitting }) => {
    handleupdateUser(values);
    setSubmitting(false);
  };

  const handleupdateUser = (values) => {
    if (
      values.usertype === "CP" &&
      (firmname.label === "" ||
        firmname.label === null ||
        firmname.label === undefined)
    ) {
      setfirmError(true);
    } else if (values.usertype === "ROTEX" && rotexunit.length == 0) {
      setfirmError(true);
    } else {
      const data = {
        firstName: values?.firstname,
        lastName: values?.lastname,
        firmName: firmname.label,
        email: values?.email,
        password: values?.password,
        isAcceptanceAtRotex: acceptpermissionValue,
        isAcceptanceAtWIP: wippermissionValue,
        isEditingLeadDays: acceptManufactureAddApprovalValue,
        isApproval: acceptManufactureApprovalvalue,
        IsRotexViewSupplierModule:spTabsApproval,
        IsRotexViewCpModule:cpTabsApproval,
        userType: values?.usertype,
        ...(values.usertype === "CP" && {
          channelPartnerId: firmname.value,
        }),
        ...(values.usertype === "SP" && {
          supplierPartnerId: firmname.value,
        }),
        ...(values.usertype === "ROTEX" && {
          units: rotexunit?.map((res) => res?.value),
        }),
      };
      try {
        store.dispatch(UpdateUser(data, user_id)).then((res) => {
          if (res?.isError == false) {
            handleUpdateBoxClose();
            toast.success(res?.message);
            getdata();
            setFirmName({
              value: "",
              label: "",
            });
            setRotexUnit([]);

            setWIPPermissionValue(false);
            setAcceptPermissionValue(false);
            setAcceptPermissionIsChecked(false);
            setWIPPermissionIsChecked(false);

            setAcceptManufactureCheckedForApproval(false);
            setAcceptManuFactureApprovalValue(false);
            setAcceptManufactureCheckedForAddApproval(false);
            setAcceptManuFactureAddValueValue(false);
          } else {
            toast.error(res?.message);
          }
        });
      } catch (error) {
        console.error("Error occurred:", error);
        return error;
      }
    }
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const clearFilter = (filterName) => {
    switch (filterName) {
      case "userType":
        setFilterUserType([]);
        setUpdateAPI("1");
        break;
      case "name":
        setFilterUserName([]);
        setUpdateAPI("2");
        break;
      case "firm":
        setFilterFirmName([]);
        setUpdateAPI("3");
        break;
      case "email":
        setUpdateAPI("4");
        setFilterEmail([]);
        break;

      default:
        break;
    }
  };

  const falsealltoggleforOpen = () => {
    setWIPPermissionValue(false);
    setAcceptPermissionValue(false);
    setAcceptPermissionIsChecked(false);
    setWIPPermissionIsChecked(false);

    setCpTabsApproval(false);
    setSpTabsApproval(false);


    setAcceptManufactureCheckedForApproval(false);
    setAcceptManuFactureApprovalValue(false);
    setAcceptManufactureCheckedForAddApproval(false);
    setAcceptManuFactureAddValueValue(false);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleItemsPerPageChange = (value) => {
    setRecordPerPage(value);
    setPage(1); // Reset to page 1 whenever items per page changes
  };

  const calculateSerialNumber = (index) => {
    return (page - 1) * recordperpage + index + 1;
  };
  const startSerialNumber = calculateSerialNumber(0);
  const endSerialNumber = calculateSerialNumber(listdata.length - 1);

  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading">User List</li>
        </ul>
        <div className="d-flex gap-1 align-items-center">
          <form className="d-none d-lg-inline-block form-inline navbar-search ">
            <div className="input-group">
              <input
                type="text"
                className="form-control bg-white border-0 small page_search"
                placeholder="Search here"
                aria-label="Search"
                aria-describedby="basic-addon2"
                onChange={(e) => setSearch(e.target.value)}
              />
              <div className="input-group-append">
                <button className="btn bg-white" type="button">
                  <img
                    src="/assets/images/search-icon.svg"
                    alt=""
                    className="img-fluid"
                  />
                </button>
              </div>
            </div>
          </form>

          <div className="d-flex gap-2 flex-wrap ms-1">
            <div className="button-container">
              <button
                className={`button ${
                  isHovered ? "hovered" : ""
                } import_export_btns`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <span>Excel Import</span>
                <div className="pageheader_icons_container">
                  <RiAttachment2 className="import_attach_icon" />
                </div>
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={handleFileUpload}
                  className="import_excel_input"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
            <div
              className="button-container"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <button
                className={`button ${isHovered ? "hovered" : ""}`}
                onClick={() => handleDownloadEXcel()}
              >
                <span>Excel Export</span>
                <div className="pageheader_icons_container">
                  <MdOutlineCloudDownload className="pageheader_react_icons" />
                </div>
              </button>
            </div>

            <div
              className="button-container"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <button
                className={`button ${isHovered ? "hovered" : ""}`}
                onClick={() => handleTemplateDownload()}
              >
                <span>Import Template</span>
                <div className="pageheader_icons_container">
                  <TbFileDownload className="pageheader_react_icons" />
                </div>
              </button>
            </div>
            <div
              className="button-container"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <button
                className={`button ${isHovered ? "hovered" : ""}`}
                onClick={() => {
                  handleOpen();
                  getcpmasterlistdropdownlist();
                  getSpMasterdropdownList();
                  getunitdropdownlist();
                  falsealltoggleforOpen();
                }}
              >
                <span>Add User</span>
                <div className="pageheader_icons_container">
                  <IoMdAddCircleOutline className="pageheader_react_icons" />
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="  page_header_slider_container">
        <div>
          <FilterModal
            stateSetters={stateSetters}
            propsStateSetters={propsState}
          />
        </div>
        <div className="slider_container ms-5">
          {" "}
          <Slider {...settings}>
            {filterUserType.length > 0 && (
              <div class="active-filters">
                <button>
                  <span className="filter_text_cont">
                    {" "}
                    UserType is
                    {filterUserType.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterUserType.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("userType")} />{" "}
                </button>
              </div>
            )}
            {filterUserName.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    User Name is{" "}
                    {filterUserName.map((item, index) => (
                      <span className="ellipsis-button_stock" key={index}>
                        {item.label}
                        {index < filterUserName.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("name")} />{" "}
                </button>
              </div>
            )}
            {filterFirmName.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    Firm is{" "}
                    {filterFirmName.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterFirmName.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2
                    onClick={() => clearFilter("firm")}
                    className="ms-1"
                  />{" "}
                </button>
              </div>
            )}
            {filterEmail.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    Email is{" "}
                    {filterEmail.map((item, index) => (
                      <span className="ellipsis-button_email" key={index}>
                        {item.label}
                        {index < filterEmail.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("email")} />{" "}
                </button>
              </div>
            )}
          </Slider>
        </div>
        <div className="d-flex ms-auto">
          <button
            className="inactive_page_btn"
            onClick={() => navigate("/inactiveuserlist")}
          >
            {" "}
            <FaRegRectangleList className="list_icons" />{" "}
            <span> Inactive User List </span>{" "}
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="table ">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">Action</td>
              <td scope="col">User Type</td>
              <td scope="col">Name </td>
              <td scope="col">Firm</td>
              <td scope="col">Status</td>

              <td scope="col">Email</td>
              <td scope="col">Password</td>
            </tr>
            <br />
          </thead>

          <tbody>
            {listdata.map((value, i) => (
              <>
                <tr key={i}>
                  <td>{calculateSerialNumber(i)}</td>
                  <td>
                    <button
                      className="table_edit_btn"
                      onClick={() => {
                        handleviewuser(value?._id);
                        getcpmasterlistdropdownlist();
                        getunitdropdownlist();
                      }}
                    >
                      <TbPencil />
                    </button>
                  </td>
                  <td>{value?.userType}</td>
                  <td>
                    {value?.firstName} {value?.lastName}
                  </td>
                  <td>{value?.firmName}</td>
                  <td>
                    {" "}
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input addbox_radio"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={value?.active}
                        onChange={() => toggleStatus(value?._id)}
                      />
                    </div>
                  </td>

                  <td>{value?.email}</td>
                  <td>{value?.password}</td>
                </tr>
                <br />
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
        <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
           
Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results
          </p>
        </div>

        <div className="d-flex   justify-content-center align-items-center">
          <Pagination
            page={page}
            setPage={handleChange}
            total={total_count}
            className="pagination "
            perPage={recordperpage}
          />
        </div>
        <div className="d-flex align-items-center">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            Total Count
          </p>
          <select
            className="w-auto form-select form-select-sm p3 shadow-none mx-3 "
            style={{ fontFamily: "Poppins" }}
            value={recordperpage}
             onChange={(e) => handleItemsPerPageChange(e.target.value)}

            
          >
            <option value="5">5</option>

            {total_count > 5 && <option value="10">10</option>}
            {total_count > 10 && <option value="25">25</option>}
            {total_count > 25 && <option value="50">50</option>}
            {total_count > 50 && <option value="100">100</option>}
            {total_count > 100 && <option value="500">500</option>}
            {total_count > 500 && <option value="1000">1000</option>}
          </select>
        </div>
      </div>

      {/* // ADD USER MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleFormSubmit}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <div className="model_header">
                    <div className="d-flex align-items-center gap-3">
                      <button className="btn  blue-btn  px-1 py-0 fs-6">
                        <AiOutlinePlus className="mb-1" />
                      </button>
                      <p className="m-0"> Add User </p>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <button
                        className="closebtn_cont"
                        type="button"
                        onClick={handleClose}
                      >
                        <RxCross2 className="closebox_logo" />{" "}
                      </button>
                    </div>
                  </div>
                  <div className="inputbox_cont">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="usertype" className="mb-1">
                          User Type*
                        </label>
                        <Field
                          as="select"
                          name="usertype"
                          className="sub_category_select"
                        >
                          <option value="">Select</option>
                          <option value="ADMIN">ADMIN</option>
                          <option value="ROTEX">ROTEX</option>
                          <option value="CP">CP</option>
                          <option value="SP">Supplier</option>
                        </Field>
                        <ErrorMessage
                          name="usertype"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      {values.usertype === "CP" && (
                        <div className="col-md-6">
                          <label htmlFor="firmname" className="mb-1">
                            Firm*
                          </label>
                          <Select
                            options={optionListcpname}
                            placeholder="Select"
                            value={firmname}
                            onChange={setFirmName}
                            isSearchable={true}
                            className="react_inbuild_select"
                          />
                          {firmError &&
                            (firmname.label == "" ||
                              firmname.label == null ||
                              firmname.label == undefined) && (
                              <p className="error-message m-0">
                                {" "}
                                Firm Name is required when you select CP{" "}
                              </p>
                            )}
                        </div>
                      )}
                      {values.usertype === "SP" && (
                        <div className="col-md-6">
                          <label htmlFor="firmname" className="mb-1">
                            Supplier*
                          </label>
                          <Select
                            options={optionListspname}
                            placeholder="Select"
                            value={firmname}
                            onChange={setFirmName}
                            isSearchable={true}
                            className="react_inbuild_select"
                          />
                          {firmError &&
                            (firmname.label == "" ||
                              firmname.label == null ||
                              firmname.label == undefined) && (
                              <p className="error-message m-0">
                                {" "}
                                Firm Name is required when you select CP{" "}
                              </p>
                            )}
                        </div>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="firstname" className="mb-1">
                          First Name*
                        </label>
                        <Field
                          type="text"
                          name="firstname"
                          id="firstname"
                          placeholder="First Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="firstname"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastname" className="mb-1">
                          Last Name*
                        </label>
                        <Field
                          type="text"
                          name="lastname"
                          id="lastname"
                          placeholder="Last Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="lastname"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="email" className="mb-1">
                          Email*
                        </label>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                          className="add_inut_field"
                          autoComplete="off"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="password" className="mb-1">
                          Password*
                        </label>

                        <FormControl
                          variant="outlined"
                          className="user_add_password_field_outer"
                        >
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values?.password}
                            onChange={handleChange("password")}
                            className="user_add_password_field"
                            placeholder="Password"
                            autoComplete="off"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  className="user_add_password_text"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    {values.usertype === "ROTEX" && (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="firmname" className="mb-1">
                              Unit*
                            </label>
                            <Select
                              options={optionListunit}
                              placeholder="Select"
                              value={rotexunit}
                              onChange={setRotexUnit}
                              isSearchable={true}
                              className="react_inbuild_select"
                              isMulti
                            />
                            {firmError && rotexunit.length == 0 && (
                              <p className="error-message  m-0">
                                {" "}
                                Unit is required when you select ROTEX{" "}
                              </p>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-3 mt-1">
                              <div className="d-flex justify-content-between align-items-center">
                                {" "}
                                <label className="m-0">
                                  {" "}
                                  Order Acceptance at Rotex :-{" "}
                                </label>{" "}
                                <div>
                                  <div class="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="flexSwitchCheckChecked"
                                      checked={acceptpermissionisChecked}
                                      onChange={AcceptpermissionhandleToggle}
                                    />
                                  </div>
                                </div>{" "}
                              </div>

                              <div className="d-flex justify-content-between align-items-center">
                                {" "}
                                <label className="m-0">
                                  {" "}
                                  Dispatch :-{" "}
                                </label>{" "}
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={wippermissionisChecked}
                                    onChange={WIPpermissionhandleToggle}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-md-6">
                            <div className="d-flex justify-content-between align-items-center">
                              {" "}
                              <label className="m-0">
                                {" "}
                                Add MTO Lead Time :-{" "}
                              </label>{" "}
                              <div>
                                <div class="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={
                                      acceptmanufactureCheckedforaddApproval
                                    }
                                    onChange={AcceptManufactureadhandleToggle}
                                  />
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-3 mt-1">
                              <div className="d-flex justify-content-between align-items-center">
                                {" "}
                                <label className="m-0">
                                  Approval MTO Lead Days :-{" "}
                                </label>{" "}
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={
                                      acceptmanufactureCheckedforApproval
                                    }
                                    onChange={
                                      AcceptManufactureApprovalhandleToggle
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        <div className="row">
                          <div className="col-md-6">
                            <div className="d-flex justify-content-between align-items-center">
                              {" "}
                              <label className="m-0">
                                Supplier Module :-{" "}
                              </label>{" "}
                              <div>
                                <div class="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                
                                    checked={spTabsApproval}
                                    onChange={() =>  setSpTabsApproval(!spTabsApproval)}
                                  />
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-3 mt-1">
                              <div className="d-flex justify-content-between align-items-center">
                                {" "}
                                <label className="m-0">
                                  {" "}
                                  Channel Partner Module :-{" "}
                                </label>{" "}
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={cpTabsApproval}
                                    onChange={() =>  setCpTabsApproval(!cpTabsApproval)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="addbox_btn_cont">
                      <button type="submit" disabled={isSubmitting}>
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
      {/* // ADD USER MODEL END  */}
      {/* // EDIT USER MODEL END  */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={updateboxopen}
        onClose={handleUpdateBoxClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={updateboxopen}>
          <Box sx={styleForMediaQuery} className="model_box">
            <Formik
              initialValues={updatefromdata}
              validationSchema={validationSchema}
              onSubmit={handleFormupdate}
            >
              {({ isSubmitting, values, handleChange }) => (
                <Form>
                  <div className="model_header">
                    <div className="d-flex gap-3 align-items-center">
                      <button className="table_edit_btn">
                        <TbPencil />
                      </button>
                      <p className="m-0">Edit User</p>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <button
                        className="closebtn_cont"
                        type="button"
                        onClick={handleUpdateBoxClose}
                      >
                        <RxCross2 className="closebox_logo" />{" "}
                      </button>
                    </div>
                  </div>
                  <div className="inputbox_cont">
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="usertype" className="mb-1">
                          User Type*
                        </label>
                        <Field
                          as="select"
                          name="usertype"
                          className="sub_category_select"
                        >
                          <option value="">Select</option>
                          <option value="ADMIN">ADMIN</option>
                          <option value="ROTEX">ROTEX</option>
                          <option value="CP">CP</option>
                          <option value="SP">Supplier</option>
                        </Field>
                        <ErrorMessage
                          name="usertype"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="firmname" className="mb-1">
                          {values.usertype === "CP" ? "Firm" : "Supplier *"}
                        </label>
                        {values.usertype === "CP" ? (
                          <>
                            <Select
                              options={optionListcpname}
                              placeholder="Select"
                              value={firmname}
                              onChange={setFirmName}
                              isSearchable={true}
                              className="react_inbuild_select"
                            />
                            <ErrorMessage
                              name="firmname"
                              component="div"
                              className="error-message"
                            />
                          </>
                        ) : values.usertype === "SP" ? (
                          <>
                            <Select
                              options={optionListspname}
                              placeholder="Select"
                              value={firmname}
                              onChange={setFirmName}
                              isSearchable={true}
                              className="react_inbuild_select"
                            />
                            <ErrorMessage
                              name="firmname"
                              component="div"
                              className="error-message"
                            />
                          </>
                        ) : (
                          <Field
                            type="text"
                            name=""
                            id="firstname"
                            placeholder="Firm Name"
                            value="ROTEX"
                            className="add_inut_field"
                            disabled
                          />
                        )}
                      </div>

                      {/* } */}
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="firstname" className="mb-1">
                          First Name*
                        </label>
                        <Field
                          type="text"
                          name="firstname"
                          id="firstname"
                          placeholder="First Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="firstname"
                          component="div"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="lastname" className="mb-1">
                          Last Name*
                        </label>
                        <Field
                          type="text"
                          name="lastname"
                          id="lastname"
                          placeholder="Last Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="lastname"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="email" className="mb-1">
                          Email*
                        </label>
                        <Field
                          type="email"
                          name="email"
                          id="email"
                          placeholder="Email"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error-message"
                        />
                      </div>

                      <div className="col-md-6">
                        <label htmlFor="password" className="mb-1">
                          Password*
                        </label>

                        <FormControl
                          variant="outlined"
                          className="user_add_password_field_outer"
                        >
                          <OutlinedInput
                            id="outlined-adornment-password"
                            type={showPassword ? "text" : "password"}
                            name="password"
                            value={values?.password}
                            onChange={handleChange("password")}
                            className="user_add_password_field"
                            placeholder="Password"
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  className="user_add_password_text"
                                  onClick={handleClickShowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="error-message"
                        />
                      </div>
                    </div>
                    {values.usertype === "ROTEX" && (
                      <>
                        <div className="row">
                          <div className="col-md-6">
                            <label htmlFor="firmname" className="mb-1">
                              Unit*
                            </label>
                            <Select
                              options={optionListunit}
                              placeholder="Select"
                              value={rotexunit}
                              onChange={setRotexUnit}
                              isSearchable={true}
                              className="react_inbuild_select"
                              isMulti
                            />
                            {firmError && rotexunit.length == 0 && (
                              <p className="error-message  m-0">
                                {" "}
                                Unit is required when you select ROTEX{" "}
                              </p>
                            )}
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-3 mt-1">
                              <div className="d-flex justify-content-between align-items-center">
                                {" "}
                                <label className="m-0">
                                  {" "}
                                  Order Acceptance at Rotex :-{" "}
                                </label>{" "}
                                <div>
                                  <div class="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      id="flexSwitchCheckChecked"
                                      checked={acceptpermissionisChecked}
                                      onChange={AcceptpermissionhandleToggle}
                                    />
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="d-flex justify-content-between align-items-center">
                                {" "}
                                <label className="m-0">
                                  {" "}
                                  Dispatch :-{" "}
                                </label>{" "}
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={wippermissionisChecked}
                                    onChange={WIPpermissionhandleToggle}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="row">
                          <div className="col-md-6">
                            <div className="d-flex justify-content-between align-items-center">
                              {" "}
                              <label className="m-0">
                                {" "}
                                Add MTO Lead Time :-{" "}
                              </label>{" "}
                              <div>
                                <div class="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={
                                      acceptmanufactureCheckedforaddApproval
                                    }
                                    onChange={AcceptManufactureadhandleToggle}
                                  />
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-3 mt-1">
                              <div className="d-flex justify-content-between align-items-center">
                                {" "}
                                <label className="m-0">
                                  {" "}
                                  Approval MTO Lead Days :-{" "}
                                </label>{" "}
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    id="flexSwitchCheckChecked"
                                    checked={
                                      acceptmanufactureCheckedforApproval
                                    }
                                    onChange={
                                      AcceptManufactureApprovalhandleToggle
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}


                        <div className="row">
                          <div className="col-md-6">
                            <div className="d-flex justify-content-between align-items-center">
                              {" "}
                              <label className="m-0">
                                Supplier Module :-{" "}
                              </label>{" "}
                              <div>
                                <div class="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={spTabsApproval}
                                    onChange={() => setSpTabsApproval(!spTabsApproval)}
                                  />
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="d-flex flex-column gap-3 mt-1">
                              <div className="d-flex justify-content-between align-items-center">
                                {" "}
                                <label className="m-0">
                                  {" "}
                                  Channel Partner Module :-{" "}
                                </label>{" "}
                                <div class="form-check form-switch">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={cpTabsApproval}
                                    onChange={() => setCpTabsApproval(!cpTabsApproval)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="addbox_btn_cont">
                      <button type="submit" disabled={isSubmitting}>
                        Save
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
      {/* // EDIT USER MODEL END  */}
    </div>
  );
}

export default Usertable;
