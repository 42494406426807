import React, { useState } from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import OrderTable from './table';
function OrderList() {
  const [sidebaropen , setSidebarOpen] = useState(false)

  return (
    
     <div className='d-flex'>
     <Sidebar setSidebarOpen={setSidebarOpen} />
     <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
     <Header  />
     <OrderTable />
     </div> 
 </div>
  )
}

export default OrderList;
