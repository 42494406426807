import React , { useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Select from "react-select";
import store from "../../../../../Redux/MyRedux/store";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { ChildIndustrydropdown, ChildSubCategorydropdown, ChildUnitdropdown, addChildMaster, childCategorylistdropdowm } from "../../../../../Redux/MyRedux/action/phase3";

import * as Yup from "yup";
import { toast } from "react-toastify";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius: "20px",
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };


export default function AddModal({setUpdateAPI }) {
  


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [isHovered , setIsHovered] = useState(false)
  const [categorydropdown, setCategorydropdown] = useState([]);
  const [subcategorydropdown, setSubCategorydropdown] = useState([]);
  const [unitdropdown, setUnitdropdown] = useState([]);
  const [indutrydropdown, setIndustrydropdown] = useState([]);
  


 

  const getindustrydropdownlist = () => {
    try {
      store.dispatch(ChildIndustrydropdown()).then((res) => {
        if (res?.isError === false) {
          setIndustrydropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
  
  const getcategorydropdownlist = () => {
    try {
      store.dispatch(childCategorylistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setCategorydropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

  const getunitdropdownlist = () => {
    try {
      store.dispatch(ChildUnitdropdown()).then((res) => {
        if (res?.isError === false) {
          setUnitdropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
  const getsubcategorydropdownlist = (e) => {
    try {
      store
        .dispatch(
          ChildSubCategorydropdown(e)
        )
        .then((res) => {
          if (res?.isError === false) {
            setSubCategorydropdown(res?.data);
          }
        });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

  const optionlistcategory = categorydropdown?.map((res, i) => ({
    value: res?.value,
    label: res?.label,
  }));

  const optionlistsubcategory = subcategorydropdown?.map((res, i) => ({
    value: res?._id,
    label: res?.subcategoryName,
  }));
  const optionlistunit = unitdropdown?.map((res, i) => ({
    value: res?._id,
    label: res?.unit,
  }));
  const optionlistindustry = indutrydropdown?.map((res, i) => ({
    value: res?._id,
    label: res?.industry,
  }));


  const initialvalueforupdate = {
    itemcode: "",
    itemdesc: "",
    Productname: "",
    subcategory_name: "",
    category: "",
    moq: "",
    factorysafety: "",
    unit: "",
    industry: "",
    manufacturingleadtime :"",
  };
  const validationSchema = Yup.object().shape({
    itemcode: Yup.string()
    .matches(/^\d+$/, "Item Code must contain only digits")
    .required("Item Code is required"),
    itemdesc: Yup.string().required("Item Description is required"),
    category: Yup.object().required("Category is required"),
    subcategory_name: Yup.object().required("Sub Category is required"),
    unit: Yup.object().required("Unit is required"),
    industry: Yup.object().required("Industry is required"),


    Productname: Yup.string().required("Product Name is required"),
    moq: Yup.string()
      .matches(/^\d+$/, "MOQ must be a valid number")
      .min(0, "MOQ must be greater than or equal to 0")
      .required("MOQ is required"),
    factorysafety: Yup.string()
      .matches(/^\d+$/, "Factor of Safety must be a valid number")
      .min(0, "Factor of Safety must be greater than or equal to 0")
      .required("Factor of Safety is required"),
      manufacturingleadtime : Yup.string()
      .matches(/^\d+$/, "MOQ must be a valid number")
      .min(0, "MOQ must be greater than or equal to 0")
      .required("Manufacturing lead time is required"),
  });

  const onSubmit = (values, { resetForm }) => {
    // Handle form submission here, e.g., send data to an API
  
  
   
   const data = {
    itemCode: values.itemcode,
    itemDescription: values.itemdesc,
    productName: values.Productname,
    categoryid: values.category?.value,
    subcategoryid: values.subcategory_name?.value,
    moq: values.moq,
    factorOfSafety: values.factorysafety,
    unitid: values.unit?.value,
    Industryid: values.industry?.value,
    manufactureleadTime :values.manufacturingleadtime
  };

  try {
    store.dispatch(addChildMaster(data)).then((res) => {
      if (res?.isError == false) {
        handleClose();
        toast.success(res?.message);
resetForm();
setUpdateAPI('add')
      } else {
        toast.error(res?.message);
      }
    });
  } catch (error) {
    console.error("Error occurred:", error);
  }
   
  
  };
  return (
    <div>
       <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
           onClick={() => {
            handleOpen();
            getcategorydropdownlist();
            getunitdropdownlist();
            getindustrydropdownlist();
          }} 
      >
        <span>Add Child Part Master</span>
        <div className='pageheader_icons_container' >
        <IoMdAddCircleOutline className='pageheader_react_icons' />
        </div>
      </button>
    </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
            <div className='d-flex align-items-center gap-3' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Add Product </p>
            </div>
              <div className="d-flex align-items-center gap-3 ">
                <button className="closebtn_cont" onClick={handleClose}>
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

 
            <Formik
        initialValues={initialvalueforupdate}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
              <div className="inputbox_cont">
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="itemcode" className="mb-1">itemcode*</label>
                <Field
                  type="text"
                  name="itemcode"
                  id="itemcode"
                  placeholder="Enter itemcode Name"
                  className="add_inut_field "
                />
                <ErrorMessage name="itemcode" component="p" className="error-message"  />
              </div>
              <div className="col-md-4">
                <label htmlFor="itemdesc" className="mb-1">Item Description*</label>
                <Field
                  type="text"
                  name="itemdesc"
                  id="itemdesc"
                  placeholder="Enter Item Description"
                  className="add_inut_field"
                />
                <ErrorMessage name="itemdesc" component="p" className="error-message"  />
              </div>
              <div className="col-md-4">
                <label htmlFor="Productname" className="mb-1">Product Name*</label>
                <Field
                  type="text"
                  name="Productname"
                  id="Productname"
                  placeholder="Enter Product Name"
                  className="add_inut_field"
                />
                <ErrorMessage name="Productname" component="p" className="error-message"  />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="city" className="mb-1">Category*</label>
                <Select
                    options={optionlistcategory}
                    placeholder="Select Category"
                    name='category'
                    isSearchable={true}
                    className="react_inbuild_select"
                    onChange={(e) => { formik.setFieldValue('category' , e) ; getsubcategorydropdownlist(e.value) }}
                  />
               
                <ErrorMessage name="category" component="p" className=" error-message"  />

              </div>
              <div className="col-md-4">
                <label htmlFor="state" className="mb-1">Sub-Category*</label>
                <Select
                    options={optionlistsubcategory}
                    placeholder="Select Sub-Category" 
                    name="subcategory_name"
                    isSearchable={true}
                    className="react_inbuild_select"
                    onChange={(e) => { formik.setFieldValue('subcategory_name' , e)  }}

                    
                  />
                <ErrorMessage name="subcategory_name" component="p" className=" error-message"  />

              </div>
              <div className="col-md-4">
              <label htmlFor="address" className="mb-1">MOQ*</label>
                <Field
                  type="text"
                  name="moq"
                  id="moq"
                  placeholder="Enter MOQ"
                  className="add_inut_field "
                />
                <ErrorMessage name="moq" component="p" className=" error-message"  />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="factorysafety" className="mb-1">Factor of Safety*</label>
                <Field
                  type="text"
                  name="factorysafety"
                  id="factorysafety"
                  placeholder="Enter Factor of Safety"
                  className="add_inut_field "
                />
                <ErrorMessage name="factorysafety" component="p" className=" error-message"  />
              </div>
              <div className="col-md-4">
                <label htmlFor="phone" className="mb-1">Unit*</label>
                <Select
                    options={optionlistunit}
                    placeholder="Select Unit"
                    name="unit"
                    isSearchable={true}
                    className="react_inbuild_select"
                    onChange={(e) => { formik.setFieldValue('unit' , e)  }}

                  />
            
                <ErrorMessage name="unit" component="p" className=" error-message"  />

              </div>
              <div className="col-md-4">
                <label htmlFor="email" className="mb-1">Industry Application*</label>
                <Select
                    options={optionlistindustry}
                    placeholder="Select Industry "
                    isSearchable={true}
                    className="react_inbuild_select"
                    name="industry"
                    onChange={(e) => { formik.setFieldValue('industry' , e)  }}

                  />
            
                <ErrorMessage name="industry" component="p" className=" error-message"  />

              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="itemcode" className="mb-1">lead time *</label>
                <Field
                  type="number"
                  name="manufacturingleadtime"
                  id="manufacturingleadtime"
                  placeholder="Enter lead time "
                  className="add_inut_field "
                />
                <ErrorMessage name="manufacturingleadtime" component="p" className="error-message"  />
              </div>
              </div>
          
            <div className="addbox_btn_cont mt-3 ">
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
</div>

          </Form>
        )}
      </Formik>


          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
