import React, { useEffect, useState } from 'react'
import './Header.css';
import store from '../../Redux/MyRedux/store';
import { UserProfileInfo } from '../../Redux/MyRedux/action/userActions';
import { useNavigate } from 'react-router-dom';
function Header() {
const userid = localStorage.getItem("user_id")
const [first_name , setFirstName]=useState("")

  const getdata = () =>{
    try{
      store.dispatch(UserProfileInfo(userid)).then((res)=>{
        if(res?.isError == false){
          setFirstName(res?.data?.firstName)
        }
        
      })
    } catch(error){
      return error 
    }
  }
  useEffect(()=>{
getdata();
  },[])
  const firstletterCapital = (string) => {
    if (typeof string === 'string' && string.length > 0) {
      return string.replace(/^\w/, (match) => match.toUpperCase());
    }
    // Handle the case where string is undefined or not a string.
    return string; // Or handle it differently based on your requirements.
  };
  const navigate = useNavigate();
  const logout = () => {
    localStorage.clear("token")
    localStorage.clear("usertype")
    localStorage.clear("channelPartnerId")
    navigate("/")

  }

  return (
    
      <nav className="navbar topbar">
      

        
        <div class="dropdown">
  <button class="btn  dropdown-toggle border-0 header_buttonfortoggle " type="button" data-bs-toggle="dropdown" aria-expanded="false">
  <span style={{ fontFamily :"Poppins" }} >Hello <b>  {firstletterCapital(first_name)}</b></span>
               
            <img src="/assets/images/Group 81936.png" alt="" className="img-fluid ms-1" style={{ width:"40px" }} />

  </button>
  <ul class="dropdown-menu">
    <li onClick={()=> logout()}>Logout</li>
   
  </ul>
</div>

       
      </nav>
   
  )
}

export default Header
