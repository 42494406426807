import React, { useState } from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import MainTable from './MainTable/MainTable'

import './Dashboard.css'

function Dashboard() {
  const [sidebaropen , setSidebarOpen] = useState(false)
  return (
 <div className='d-flex'>
            <Sidebar setSidebarOpen={setSidebarOpen} />
            <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
            <Header  />
            <MainTable />
            </div> 
        </div>
  )
}

export default Dashboard
