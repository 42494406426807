import React, { useState, useEffect } from "react";
import store from "../../../Redux/MyRedux/store";
import { LuSearch } from "react-icons/lu";
import { RxCross2 } from "react-icons/rx";
import Pagination from "react-mui-pagination";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Slider from "react-slick";
import * as moment from "moment";
import sorting_logo_img from '../../../Assets/table/shortarrow.png'
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import { FaRegRectangleList } from "react-icons/fa6";
import FilterModal from "./filterpopup/filter";
import {   spConsumptionExcelImport,  spConsumptionList, spConsumptionStatusUpdate } from "../../../Redux/MyRedux/action/phase3";
import AddModal from "./Add/add";
import EditModal from "./Edit/edit";

function ConsumptionMasterTable() {
  const navigate = useNavigate();

  const [usertype, setUserType] = useState("");

  


 


  const [updatedAPI, setUpdateAPI] = useState("");

  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
 
  const [total_count, setTotalCount] = useState(0);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [filterItemCode,  setFilteItemCode] = useState([]);
  const [filterSpName,  setFilteSpName] = useState([]);
  const [filterProductName, setFilterProsuctName] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterSubCategory, setFilterSubCategory] = useState([]);
  const [filterqty, setFilterQTY] = useState([]);
  const [filterdate, setFilterDate] = useState([]);
  const [filterdescription, setFilterDescription] = useState([]);
  const [poNumber, setpoNumber] = useState([]);


  const statesetter = {
    setFilteSpName,
    setFilteItemCode,
    setFilterProsuctName,
    setFilterDescription,
    setFilterCategory,
    setFilterSubCategory,
    setFilterQTY,
    setFilterDate,
    setUpdateAPI,
    setpoNumber



  }

  const propsState = {
    filterSpName,
    filterItemCode,
    filterProductName,
    filterdescription,
    filterCategory,
    filterSubCategory,
    filterqty,
    filterdate,
    poNumber
 }


  const [listdata, setListdata] = useState([]);
  const [channelPartnerId, setChannelPartnerId] = useState("");

  const getdata = () => {
    const data = {
      search: search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      itemCode: filterItemCode.map((item) => item.label),
      itemDescription: filterdescription.map((item) => item.label),
      productName: filterProductName.map((item) => item.label),
      subCategoryName: filterSubCategory.map((item) => item.label),
      categoryName: filterCategory.map((item) => item.label),
      qty: filterqty.map((item) => item.label),
      active: true,
      date:filterdate.map((item) => item.label),
      opNumber:poNumber.map((item) => item.label),
    };

    try {
      store.dispatch(spConsumptionList(data)).then((res) => {
        setUpdateAPI('')
        if (res?.isError == false) {
          if (res?.data[0]?.docs.length > 0) {
            setListdata(res?.data[0]?.docs);
            setTotalCount(res?.data[0]?.paginate[0]?.totalDocs || 0);
          } else {
            setListdata([]);
            setTotalCount(0);
          }
        }
        setUpdateAPI('')

      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    setUserType(localStorage.getItem("usertype"));
    setChannelPartnerId(localStorage.getItem("channelPartnerId"));
    if (localStorage.getItem("usertype") == "CP") {
      if (channelPartnerId) {
        getdata();
      }
    } else {
      getdata();
    }
  }, [recordperpage, page, search, updatedAPI, channelPartnerId]);


  const handleUpdateConsumptionStatus = (id, value) => {
    const data = {
      active: value,
    };
    store.dispatch(spConsumptionStatusUpdate(data, id)).then((res) => {
      if (res?.isError == false) {
        toast.success(res?.message);
        getdata();
      } else {
        toast.error(res?.message);
      }
    });
  };

  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      'Supplier Name',
      "PO Number",
      "Item Code",
      "Item Description",
      "Product Name ",
      "Product Id",
      "Sub-category ",
      "Sub-category Id",
      "Category",
      "Category Id",
      "Qty",
      "Date (DD-MM-YYYY)",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [
        item.spName,
        item.opNumber,
        item.itemCode,
        item.itemDescription,
        item.productName,
        item?._id,
        item.subCategoryName,
        item.subcategoryid,
        item.categoryName,
        item.categoryid,
        item.qty,
         moment(item?.date).format("DD-MM-YYYY")
      ]),
    ];

    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "SupplierConsumptions.xlsx");
  };

  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handleUpdateConsumptionStatus(userId, updatedStatus);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        // Parse the Excel file to JSON
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Define a mapping between old header names and new parameter names
        const headerMapping = {
          "Item Code": "itemCode",
          Qty: "qty",
          "Date (YYYY-MM-DD)": "date",
        };

        // Map the old header names to the new parameter names in the JSON data
        const mappedJsonData = jsonData.map((item) => {
          const mappedItem = {};
          for (const oldHeader in item) {
            if (oldHeader in headerMapping) {
              mappedItem[headerMapping[oldHeader]] = item[oldHeader];
            }
          }
          return mappedItem;
        });

        updateNewExcel(mappedJsonData);
      };
      reader.readAsBinaryString(file);
    }
  };

  const updateNewExcel = (json) => {
    try {
      store.dispatch(spConsumptionExcelImport(json)).then((res) => {
        if (res?.isError == false) {
          toast.success(res?.message);
          if (res?.data?.errorArrray.length > 0) {
            toast.error(
              ` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`
            );
            const headers = [
              "supplier Name",
              "Item Code",
              "Qty",
              "Date (YYYY-MM-DD)",
              "Error",
            ];

            // Map data to a new array with custom headers
            const dataWithCustomHeaders = [
              headers,
              ...res?.data?.errorArrray.map((item) => [
                item.supplierName,
                item.itemCode,
                item.qty,
                item.date,
                item.error,
              ]),
            ];
            // Generate Excel file
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelBuffer = XLSX.write(workbook, {
              bookType: "xlsx",
              type: "array",
            });
            // Save file
            const fileData = new Blob([excelBuffer], {
              type: "application/octet-stream",
            });
            saveAs(fileData, "Errordata.xlsx");
          } else {
            // Display an alert when errorArray is empty
            toast.success("No error data found. All data upload Successfully");
          }
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      return error;
    }
  };

  const clearFilterHeader = (filterName) => {
    switch (filterName) {
      case "spName":
        setFilteSpName([]);
        setUpdateAPI("00");
        break;
        case "poNumber":
        setpoNumber([]);
        setUpdateAPI("0022");
        break;
      case "ItemCode":
        setFilteItemCode([]);
        setUpdateAPI("1");
        break;
      case "ProductName":
        setFilterProsuctName([]);
        setUpdateAPI("2");
        break;
      case "Category":
        setFilterCategory([]);
        setUpdateAPI("3");
        break;
      case "SubCategory":
        setUpdateAPI("4");
        setFilterSubCategory([]);
        break;
      case "QTY":
        setFilterQTY([]);
        setUpdateAPI("5");
        break;
      case "date":
        setFilterDate([]);
        setUpdateAPI("date");
          break;
          case "description":
            setFilterDescription([]);
            setUpdateAPI("description");
              break;
          

      default:
        break;
    }
  };

  const handleTemplateDownload = () => {
    // Custom headers
    const headers = ["Supplier Name",  "Item Code", "Qty", "Date (YYYY-MM-DD)"];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [headers, []];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "SupplierConsumptiontemplate.xlsx");
  };


const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};




const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
const sortBy = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  const sortedData = [...listdata].sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  setListdata(sortedData);
  setSortConfig({ key, direction });
};

const [isHovered , setIsHovered] = useState(false)

const handleItemsPerPageChange = (value) => {
  setRecordPerPage(value);
  setPage(1); // Reset to page 1 whenever items per page changes
};

const calculateSerialNumber = (index) => {
  return (page - 1) * recordperpage + index + 1;
};
const startSerialNumber = calculateSerialNumber(0);
const endSerialNumber = calculateSerialNumber(listdata.length - 1);

  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading"> Supplier Consumption Master</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>

<div className="d-flex gap-2 flex-wrap ms-2 ">
       
       <div className="button-container">
       {usertype != "CP" && 
      <button
        className={`button ${isHovered ? 'hovered' : ''} import_export_btns`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>Excel Import</span>
        <div className='pageheader_icons_container'>
          <RiAttachment2 className='import_attach_icon' />
        </div>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          className="import_excel_input"
          style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
        />
      </button>
}
    </div>
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
          onClick={() => handleDownloadEXcel()}
      >
        <span>Excel Export</span>
        <div className='pageheader_icons_container' >
        <MdOutlineCloudDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
    
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`} 
       onClick={() => handleTemplateDownload()} 
      >
        <span>Import Template</span>
        <div className='pageheader_icons_container' >
        <TbFileDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>

   
    <AddModal setUpdateAPI={setUpdateAPI} />
    
         
        </div>
      </div>

      <div className="page_header_slider_container">
      <div className="d-flex align-items-center gap-3" style={{ width:"100%" }}  >
        <div className="">
          
          <FilterModal statesetter={statesetter}  propsState={propsState} />

        </div>
        <div className="slider_container ms-5">
          {" "}
          <Slider {...settings}>
          {poNumber.length > 0 && (
              <div class="active-filters">
                    <button>  <span className='filter_text_cont' > PONumber is   {poNumber.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < poNumber.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilterHeader("poNumber")}/> </button>

              </div>
            )} 
          {filterItemCode.length > 0 && (
              <div class="active-filters">
                    <button>  <span className='filter_text_cont' > Sp Name is   {filterSpName.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterSpName.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilterHeader("spName")}/> </button>

              </div>
            )} 
            {filterItemCode.length > 0 && (
              <div class="active-filters">
                    <button>  <span className='filter_text_cont' > Item Code is   {filterItemCode.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterItemCode.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilterHeader("ItemCode")}/> </button>

              </div>
            )} 
            {filterProductName.length > 0 && (
              <div class="active-filters">
                     <button>  <span className='filter_text_cont' > Product Name  is {filterProductName.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterProductName.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilterHeader("ProductName")}/> </button>
              </div>
            )}
            
            {filterdescription.length > 0 && (
              <div class="active-filters">
                     <button>  <span className='filter_text_cont' > Item Description is  {filterdescription.map((item, index) => ( <span  className="ellipsis-button_QD" key={index}>
      {item.label}
      {index < filterdescription.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilterHeader("description")}/> </button>
              </div>
            )}
            {filterCategory.length > 0 && (
              <div class="active-filters">
                  <button>  <span className='filter_text_cont' > Category is {filterCategory.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterCategory.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))}  </span> <RxCross2 onClick={() => clearFilterHeader("Category")}/> </button>
              </div>
            )}
            {filterSubCategory.length > 0 && (
              <div class="active-filters">
                 <button> <span className='filter_text_cont' >  Sub-Category is {filterSubCategory.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterSubCategory.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilterHeader("SubCategory")}/> </button>

              </div>
            )}
            {filterqty.length > 0 && (
              <div class="active-filters">
                  <button> <span className='filter_text_cont' >  QTY is  {filterqty.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterqty.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))}</span> <RxCross2 onClick={() => clearFilterHeader("QTY")}/> </button>
              </div>
            )}  
             {filterdate.length > 0 && (
              <div class="active-filters">
                  <button> <span className='filter_text_cont' > Date is  {filterdate.map((item, index) => ( <span  className="ellipsis-button_date" key={index}>
      {item.label}
      {index < filterdate.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilterHeader("date")}/> </button>
              </div>
            )}  
           
          </Slider>
        </div>
        </div>

      
          

        <button className='inactive_page_btn' 
        onClick={() => navigate("/supplier/consumption/inactive")} > <FaRegRectangleList className='list_icons' /> <span> Inactive Consumptions List </span> </button>
       
      </div>

      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">Action</td>

              <td scope="col">
                {" "}
                <div className="th_common">
                  <span> Supplier Name </span>
                 
                  <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("spName")}
                  />
                </div>{" "}
              </td>

              <td scope="col">
                {" "}
                <div className="th_common">
                  <span> PO Number </span>
                 
                  <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("opNumber")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span> Item Code</span>
                 
                    <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("itemCode")}
                  />
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span> Item Description</span>
                 
                   <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("itemDescription")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Product Name</span>
                 
                  <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("productName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Category</span>
                 
                  <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("categoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Sub-Category</span>
                  
                    <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("subCategoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> QTY</span>
                 
                  <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("qty")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common"> 
                  <span> Status</span>
                 
                </div>
              </td>
              <td>
                <div className="th_common">
                  <span> Date</span>
                 
                    <img src={sorting_logo_img} alt="sort" className="img-fluid"  
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("date")}
                  />
                </div>
              </td>
            </tr>
          <br />
          </thead>
          <tbody>
            {listdata?.map((value, i) => (
              <>
                <tr key={i}>
                <td>{calculateSerialNumber(i)}</td>
                  <td>
                    <EditModal id={value?._id} setUpdateAPI={setUpdateAPI} />
                   
                   
                  </td>
                  <td>{value?.spName}</td>
                  <td>{value?.opNumber}</td>

                  <td>{value?.itemCode}</td>
                  <td>{value?.itemDescription}</td>
                  <td>{value?.productName}</td>
                  <td>{value?.categoryName}</td>
                  <td>{value?.subCategoryName}</td>
                  <td>{value?.qty}</td>
                  <td>
                    {" "}
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input addbox_radio"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={value?.active}
                        onChange={() => toggleStatus(value?._id)}
                      />
                    </div>
                  </td>
                  <td>
                    <Moment format="DD-MM-YYYY">{value.date}</Moment>
                  </td>
                </tr>
                <br />
                </>
              ))}
          </tbody>
        </table>
      </div>

    


      <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} >   Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} 
             onChange={(e) => handleItemsPerPageChange(e.target.value)}
            
            >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>

     
  
    </div>
  );
}

export default ConsumptionMasterTable;
