import React , { useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Select from "react-select";
import store from "../../../../Redux/MyRedux/store";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {  addConsumptionProductList, addRotexConsumption, editRotexConsumption, rotexConsumptionStatusUpdate, viewRotexConsumption } from "../../../../Redux/MyRedux/action/phase3";
import * as Yup from "yup";
import { toast } from "react-toastify";
import * as moment from 'moment'
import { TbPencil } from "react-icons/tb";

 const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#F4F6FA",
  boxShadow: 24,
  borderRadius:"20px",

};
const formatDateForInput = (apiDate) => {
  const dateObject = new Date(apiDate);
  const year = dateObject.getFullYear();
  const month = String(dateObject.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const day = String(dateObject.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
const mediaQuery = `@media (max-width: 768px)`; 
const styleForMediaQuery = {
  ...style, 
  [mediaQuery]: {
    width: "80%", // Example: Make the width 100% for screens matching the media query
  },
};

export default function EditModal({id ,setUpdateAPI }) {
  
  const [productlistdropdown , setProductListDropdown] = useState([])
  const [disableAddButton , setDisableAddButton] = useState(false)
  const [consumptionStatus , seConsumptionStatus] = useState(false)

  
  const getChildMasterproduct = () =>{
    
    try{
      store.dispatch(addConsumptionProductList()).then((res)=>{
        if(res?.isError === false){
               setProductListDropdown(res?.data)
        }
        handleviewconsumptionmaster();
      })
    }catch (error){
      console.log("Error Fetching :", error)
    }
  }

 
  
  const optionListproduct = productlistdropdown?.map((val, i) => ({
    value: val?.productId,
    label: val?.itemCode,
  }));
  const [initialValue  ,setInitialValue] = useState({
   product_Id :'',
   itemDescription:'',
   product_name:'',
   category:'',
   sub_category_name:'',
   qty:'',
   tog:'',
   onHandStock:'',
   qualifiedDemand:'',
   date:'',
   
  
  });


 
  const handleviewconsumptionmaster = () => {
   
    
    try {
      store.dispatch(viewRotexConsumption(id)).then((res) => {
        if (res?.isError == false) 
        { setInitialValue(prevState => ({
                ...prevState,
                itemDescription:res?.data.itemDescription,
                product_name:res?.data.productName,
                category:res?.data.categoryName,
                sub_category_name:res?.data.subCategoryName,
                tog:res?.data.tog,
                onHandStock:res?.data.onHandStock,
                qualifiedDemand:res?.data.qualifiedDemand,
                product_Id : { value:res?.data?.productId || '' , label: res?.data?.itemCode || ''},
                qty:res?.data.qty,
                date:formatDateForInput(res?.data?.date),
              
              }));  
              seConsumptionStatus(res?.data?.active)
            } 
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

    const hanlechangeitemcode = (e)=>{
 
      const selectedProduct = productlistdropdown.find(product => product.productId == e.value); // Use the updated value
  
        if (selectedProduct) {
          setInitialValue(prevState => ({
            ...prevState,
            itemDescription:selectedProduct.itemDescription,
            product_name:selectedProduct.productName,
            category:selectedProduct.categoryName,
            sub_category_name:selectedProduct.subCategoryName,
            tog:selectedProduct.tog,
            onHandStock:selectedProduct.onHandStock,
            qualifiedDemand:selectedProduct.qualifiedDemand,
          
          }));

        }
      }

  const handleSubmit = (values , {resetForm})=>{
    setDisableAddButton(true)


    const data = {
      productId:values?.product_Id?.value,
      date:moment(values?.date).format('YYYY-MM-DD'),
      qty:values?.qty
    }
    try{
      store.dispatch(editRotexConsumption(data , id)).then((res)=>{
        setDisableAddButton(false)

        if(res?.isError == false){
          toast.success(res?.message)
          handleClose();
          setUpdateAPI('add')
          resetForm();
        }
        else{
          const errorMessage = res.message.includes(":") ? res.message.split(":")[1] : res.message;
          toast.error(errorMessage);
       
        }

        

      })
    }catch (error){
      return error
    }
  }






  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }



  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);

    //clear the initialValue If the value is not submitted and user close the modal 
    setInitialValue(initialValue)

  };

  const handleStatusUpdate = () => {
    const updatestatus = !consumptionStatus;
    seConsumptionStatus(updatestatus);
    handleUpdateConsumptionStatus( updatestatus);
  };
  const handleUpdateConsumptionStatus = ( value) => {
    const data = {
      active: value,
    };
    store.dispatch(rotexConsumptionStatusUpdate(data, id)).then((res) => {
      if (res?.isError == false) {
        toast.success(res?.message);
       setUpdateAPI('status-update')
      } else {
        toast.error(res?.message);
      }
    });
  };
  return (
    <div>
     
      <button className='table_edit_btn'
           onClick={() => {
            handleOpen();
       
            getChildMasterproduct();
          
          }} 
      >
      
      <TbPencil />
      </button>
    
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
            <div className='d-flex align-items-center gap-3' >
             <button className='table_edit_btn'> <TbPencil />  </button>
              <p className="m-0"> Edit Rotex Consumption Master </p>
            </div>
            <div className="d-flex align-items-center gap-3 ">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input addbox_radio"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={consumptionStatus == true}
                    onChange={handleStatusUpdate}
                  />
                </div>
                <button
                  className="closebtn_cont"
                  onClick={handleClose}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>


            <Formik
             initialValues={initialValue}
             onSubmit={handleSubmit}
            >
              {(formik)=>(

              <Form>

            <div className="inputbox_cont">
              <div className="row">
               

                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    {" "}
                    Item Code
                  </label>
                  <Select
                    options={optionListproduct}
                    placeholder="Select Item Code"
                    name="product_Id"
                    value={formik.values.product_Id}
                    onChange={(e) => { formik.setFieldValue('product_Id' , e) ; hanlechangeitemcode(e)}}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
                    
 
  
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Item Description
                  </label>
                  <Field
                    type="text"
                    name="itemDescription"
                    id=""
                    placeholder="Enter Item Description"
                    className="add_inut_field"
                    value={initialValue.itemDescription}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Product Name
                  </label>
                  <Field
                    type="text"
                    name="product_name"
                    id=""
                    placeholder=" Entet Product Name"
                    className="add_inut_field"
                    value={initialValue.product_name}

                    disabled
                  />
                </div>
              </div>
     
   
 
              <div className="row">
               
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Category
                  </label>
                  <Field
                    type="text"
                    name="category"
                    id=""
                    placeholder="Enter Category Name"
                    className="add_inut_field"
                    disabled
                    value={initialValue.category}

                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Sub-Category
                  </label>
                  <Field
                    type="text"
                    name="sub_category_name"
                    id=""
                    placeholder="Enter Sub-Category Name"
                    className="add_inut_field"
                    disabled
                    value={initialValue.sub_category_name}

                  />
                </div>

 
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    QTY
                  </label>
                  <Field
                    type="number"
                    name="qty"
                    placeholder="Enter QTY"
                    className="add_inut_field"
                    
                  />
                </div>
              </div>
              <div className="row">
        
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Date
                  </label>
                  <Field
                    type="date"
                    name="date"
                    id=""
                    placeholder="dd/mm/yyyy"
                    className="add_inut_field"
                    min={getCurrentDate()} // Set min and max to today's date
                    max={getCurrentDate()}
                    value={initialValue.date}

                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    TOG
                  </label>
                  <Field
                    type="text"
                    name="tog"
                    id=""
                    placeholder=" Enter TOG"
                    className="add_inut_field"
                    disabled
                    value={initialValue.tog}

                  />
                </div>
                
 
 
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    On Hand Stock
                  </label>
                  <Field
                    type="text"
                    name="onHandStock"
                    id=""
                    placeholder="On Hand Stock"
                    className="add_inut_field"
                    disabled
                    value={initialValue.onHandStock}

                  />
                </div>{" "}
              </div>
              <div className="row">
              
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Qualified Demand
                  </label>
                  <Field
                    type="text"
                    name="qualifiedDemand"
                    id=""
                    placeholder="Qualified Demand"
                    className="add_inut_field"
                    disabled
                    value={initialValue.qualifiedDemand}

                  />
                </div>
              </div>

              <div className="addbox_btn_cont my-4 mt-3">
                <button disabled={disableAddButton === true} >Save</button>
              </div>
            </div>
            </Form>
            )}
            </Formik>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
