import React  , {useState , useEffect} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LuFilter } from "react-icons/lu";
import Select from "react-select";
import store from "../../../Redux/MyRedux/store";
import { OrderRecommandationlistDropdown } from "../../../Redux/MyRedux/action/userActions";
import "./filter.css";
import { AiOutlineClose } from "react-icons/ai";
import * as moment from 'moment'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
 import DateRangePicker from '@wojtekmaj/react-daterange-picker';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "#F4F6FA",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  pb: 3,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
};

export default function FilterModal({stateSetters , propsState}) {
  const [orderDropdown, setOrderDropdown] = useState([]);



  const [usertype, setUserType] = useState("");

  useEffect(()=>{
    setUserType(localStorage.getItem("usertype"));

  },[])

  const getOrderListDropDown = () => {
    try {
      store.dispatch(OrderRecommandationlistDropdown()).then((res) => {
        if (res?.isError === false) {
          setOrderDropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

 



  
  const uniqueLabels = [...new Set(orderDropdown.map((obj) => obj.qty))];
  const uniqueLabelObjects = uniqueLabels.map((label) => ({
    value: label,
    label: label
  }));
  

  
  const uniqueorderIdLabels = [
    ...new Set(orderDropdown.map((obj) => obj.uniqueNumber)),
  ];
  const uniqueOrderidLabelObjects = uniqueorderIdLabels.map((label) =>({
    value:  `PMS ${label}`,
    label: `PMS ${label}`,
  }));

 
  const sapSoLable = [...new Set(orderDropdown.map((obj) => obj.sapNumber))];
  const uniqueSapSoLabelObjects = sapSoLable.map((label) => ({
    value: label,
    label: Math.round(label) 
  }));


 
  const uniquetogLabels = [...new Set(orderDropdown.map((obj) => obj.tog))];
  const uniquetogLabelObjects = uniquetogLabels.map((label) => ({
    value: label,
    label: Math.round(label)
  }));

 
  const uniquehandstockLabels = [
    ...new Set(orderDropdown.map((obj) => obj.onHandStock)),
  ];
  const uniquehandstockLabelObjects = uniquehandstockLabels.map((label) => ({
    value: label,
    label: Math.round(label)
  }));
 
  const uniquenetflowLabels = [
    ...new Set(orderDropdown.map((obj) => obj.netFlow)),
  ];
  const uniquenetflowLabelObjects = uniquenetflowLabels.map((label) => ({
    value: label,
    label: Math.round(label)
  }));

  const uniquecpnameLabels = [
    ...new Set(orderDropdown.map((obj) => obj.companyName)),
  ];
  const uniquecpnameLabelObjects = uniquecpnameLabels.map((label) => ({
    value: label,
    label:label, 
  }));

  const uniquecontactpersonLabels = [
    ...new Set(orderDropdown.map((obj) => obj.cpName)),
  ];
  const uniquecontactpersonLabelObjects = uniquecontactpersonLabels.map(
    (label) => ({
      value: label,
      label: label, 
    }));
  
  const uniquecityLabels = [...new Set(orderDropdown.map((obj) => obj.city))];
  const uniquecityLabelObjects = uniquecityLabels.map((label) => ({
    value: label,
    label: label, 
  }));
 
  const uniquestateLabels = [
    ...new Set(orderDropdown.map((obj) => obj.state)),
  ];
  const uniquestateLabelObjects = uniquestateLabels.map((label) => ({
    value: label,
    label: label, 
  }));


  const uniqueitemcodeLabels = [
    ...new Set(orderDropdown.map((obj) => obj.itemCode)),
  ];
  const uniqueitemcodeLabelObjects = uniqueitemcodeLabels.map((label) =>({
    value: label,
    label: label, 
  }));
 
  const uniqueproductnameLabels = [
    ...new Set(orderDropdown.map((obj) => obj.productName)),
  ];
  const uniqueproductnameLabelObjects = uniqueproductnameLabels.map((label) => ({
    value: label,
    label:label, 
  }));

  const uniquedateLabels = [...new Set(orderDropdown.map((obj) => obj.createdAt))];
  const uniquedateLabelObjects = uniquedateLabels.map((label) => ({
    value: moment(label).format("DD-MM-YYYY"),
    label: moment(label).format("DD-MM-YYYY"),
  }));

  const uniqueunitLabels = [...new Set(orderDropdown.map((obj) => obj.unit))];
  const uniqueunitObjects = uniqueunitLabels.map((label) => ({
    value: label,
    label: Math.round(label)
  }));

  const uniqueQDLabels = [...new Set(orderDropdown.map((obj) => obj.qualifiedDemand))];
  const uniqueQDObjects = uniqueQDLabels.map((label) => ({
    value: label,
    label: Math.round(label)
  }));

  const uniqueopenorederLabels = [
    ...new Set(orderDropdown.map((obj) => obj.openOrder)),
  ];
  const uniqueopenorederObjects = uniqueopenorederLabels.map((label) => ({
    value: label,
    label: Math.round(label)
  }));

 
  const uniquestattusLabels = [
    ...new Set(orderDropdown.map((obj) => obj.status)),
  ];
  const uniquestatusObjects = uniquestattusLabels.map((label) => ({
    value: label,
    label: label, 
  }));


  const uniquedescLabels = [
    ...new Set(orderDropdown.map((obj) => obj.itemDescription)),
  ];
  const uniquedescObjects = uniquedescLabels.map((label) => ({
    value: label,
    label: label,
  }));






  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


    const clearFilter = () => {
      stateSetters.setFilterQTY([]);
      stateSetters.setFilterOrderId([]);
      stateSetters.setFilterSapSo([]);
      stateSetters.setFilterTOG([]);
      stateSetters.setFilterInHandStock([]);
      stateSetters.setFilterNetFlow([]);
      stateSetters.setFilterCPName([]);
      stateSetters.setFilterContactPerson([]);
      stateSetters.setFilterCiy([]);
      stateSetters.setFilterState([]);
      stateSetters.setFilterItemCode([]);
      stateSetters.setFilterItemDesc([]);
      stateSetters.setFilterStatus([]);
      stateSetters.setFilterProductName([]);
      stateSetters.setFilterDate([]);
      stateSetters.setFilterUnit([]);
      stateSetters.setFilterQD([]);
      stateSetters.setFilterOpenOrder([]);
      stateSetters.setUpdateAPI("253")
  
  };
  const [filterCpDateStartEnd, setFilterCpDateStartEnd] = useState(["", ""]);
  const handleSelectDateRange  = (e) => {
    console.log(e)
    setFilterCpDateStartEnd(e)
    stateSetters.setFilterCpDateStartEnd([{
      from:moment(e[0]).format("YYYY-MM-DD"),
      to:moment(e[1]).format("YYYY-MM-DD")
    }])
    
  }
   console.log(filterCpDateStartEnd , 'filterCpDateStartEnd filterCpDateStartEnd filterCpDateStartEndf')
  
  return (
    <div>
      <button
        className="filter_btn"
        type="button"
        onClick={() => {
          getOrderListDropDown();
          handleOpen();
        }}
      >
        <LuFilter />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <div className="filter_modal_header">
            <div className="d-flex justify-content-between ">
              <div className="modal_heading d-flex align-items-center gap-2">
                <button
                  className="filter_btn"
                  type="button"
                
                 
                >
                  <LuFilter />
                </button>{" "}
                <span> Filter </span>
              </div>
              <div className="close_modal_btn"   onClick={() => handleClose()}>
                <AiOutlineClose />
              </div>
            </div>
          </div>
          <div className="filter_select_container gap-2">
            
            
       

         
            <div className="row">
            <div className="form-group col-md-3">
              <Select
                options={uniqueOrderidLabelObjects}
                placeholder="Order Id"
                value={ propsState.filterOrderId}
                onChange={(e) => stateSetters.setFilterOrderId(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-3">
              <Select
                options={uniqueSapSoLabelObjects}
                placeholder="SO No"
                value={propsState.filterSapSo}
                onChange={(e) => stateSetters.setFilterSapSo(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-3">
              <Select
                options={uniqueLabelObjects}
                placeholder="QTY"
                value={propsState.filterqty}
                onChange={(e) => stateSetters.setFilterQTY(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
          
            <div className="form-group col-md-3">
              <Select
                options={uniquetogLabelObjects}
                placeholder="TOG"
                value={propsState.filtertog}
                onChange={(e) => stateSetters.setFilterTOG(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            </div>
            <div className="row">
            <div className="form-group col-md-3">
              <Select
                options={uniquehandstockLabelObjects}
                placeholder="On HandStock"
                value={propsState.filterinHandStock}
                onChange={(e) => stateSetters.setFilterInHandStock(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-3">
              <Select
                options={uniquenetflowLabelObjects}
                placeholder="NetFlow"
                value={propsState.filterNetflow}
                onChange={(e) => stateSetters.setFilterNetFlow(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>

            {usertype != "CP" && (
              <>
                <div className="form-group col-md-3">
                  <Select
                    options={uniquecpnameLabelObjects}
                    placeholder="CP Name"
                    value={propsState.filterCpName}
                    onChange={(e) => stateSetters.setFilterCPName(e)}
                    isSearchable={true}
                    className="react_inbuild_select"
                    isMulti
                  />{" "}
                </div>
                <div className="form-group col-md-3">
                  <Select
                    options={uniquecontactpersonLabelObjects}
                    placeholder="Contact Person"
                    value={propsState.filterContactPerson}
                    onChange={(e) => stateSetters.setFilterContactPerson(e)}
                    isSearchable={true}
                    className="react_inbuild_select"
                    isMulti
                  />{" "}
                </div>
              </>
            )}
            </div>

            <div className="row">

            <div className="form-group col-md-3">
              <Select
                options={uniquecityLabelObjects}
                placeholder="City"
                value={propsState.filterCity}
                onChange={(e) => stateSetters.setFilterCiy(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-3">
              <Select
                options={uniquestateLabelObjects}
                placeholder="State"
                value={propsState.filterState}
                onChange={(e) => stateSetters.setFilterState(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-3">
              <Select
                options={uniqueitemcodeLabelObjects}
                placeholder="Item Code"
                value={propsState.filterItemCode}
                onChange={(e) => stateSetters.setFilterItemCode(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>

            <div className="form-group col-md-3">
              <Select
                options={uniqueproductnameLabelObjects}
                placeholder="Product Name"
                value={propsState.filterProductName}
                onChange={(e) => stateSetters.setFilterProductName(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            </div>
            <div className="row">
            <div className="form-group col-md-3">
              <Select
                options={uniquedateLabelObjects}
                placeholder="Date"
                value={propsState.filterDate}
                onChange={(e) => stateSetters.setFilterDate(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-3">
              <Select
                options={uniqueunitObjects}
                placeholder="Unit"
                value={propsState.filterunit}
                onChange={(e) => stateSetters.setFilterUnit(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-3">
              <Select
                options={uniqueQDObjects}
                placeholder="Qualified Demand"
                value={propsState.filterQD}
                onChange={(e) => stateSetters.setFilterQD(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-3">
              <Select
                options={uniqueopenorederObjects}
                placeholder="Open Order"
                value={propsState.filterOpenOrder}
                onChange={(e) => stateSetters.setFilterOpenOrder(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            </div>
            <div className="row">
            <div className="form-group col-md-3">
              <Select
                options={uniquestatusObjects}
                placeholder="Status"
                value={propsState.filterstatus}
                onChange={(e) => stateSetters.setFilterStatus(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-3">
             
              <Select
                options={uniquedescObjects}
                placeholder="Item Description"
                value={propsState.filterItemDesc}
                onChange={(e) => stateSetters.setFilterItemDesc(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>

        <div className="form-group col-md-3">
          <DateRangePicker onChange={handleSelectDateRange} value={filterCpDateStartEnd} 
                className="react_dateRangePicker"
          
          />
         
            
           </div> 
           
            </div>
          
          
      
     
              <div className="drawer-foot-btns ">
                <button className="btn clear_btn" onClick={() => clearFilter()}>
                  Clear
                </button>
                <div className="">
                  <button
                    className="btn blue-btn p-2 px-3"
                   onClick={() => {handleClose() ; stateSetters.setUpdateAPI('update')}}
                  >
                    Apply
                  </button>
                </div>
              </div>
            
          </div>
        </Box>
      </Modal>
    </div>
  );
}
