import React , {useEffect, useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Select from "react-select";
import store from "../../../../Redux/MyRedux/store";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {  addRotexInventory,rotexProductDropdownList } from "../../../../Redux/MyRedux/action/phase3";
import * as Yup from "yup";
import { toast } from "react-toastify";


 const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#F4F6FA",
  boxShadow: 24,
  borderRadius:"20px",

};
const mediaQuery = `@media (max-width: 768px)`; 
const styleForMediaQuery = {
  ...style, 
  [mediaQuery]: {
    width: "80%", // Example: Make the width 100% for screens matching the media query
  },
};

export default function AddModal({setUpdateAPI }) {
  
  const [productlistdropdown , setProductListDropdown] = useState([])
  const [disableAddButton , setDisableAddButton] = useState([])



  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [isHovered , setIsHovered] = useState(false)
 
 

  const getChildMasterproduct = (id) =>{
    
    try{
      store.dispatch(rotexProductDropdownList()).then((res)=>{
        if(res?.isError === false){
               setProductListDropdown(res?.data)
        }
      })
    }catch (error){
      console.log("Error Fetching :", error)
    }
  }

  
  const optionListproduct = productlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.itemCode,
  }));
  const [initialValue , setInitialValue ] = useState({
   product_Id :'',
   itemDescription:'',
   leadTime:'',
   factoryOfSafety:'',
   tog:'',
   onHandStock:'',
   qualifiedDemand:'',
   moq:''
  })

 


    const hanlechangeitemcode = (e)=>{
 
      const selectedProduct = productlistdropdown.find(product => product._id == e.value); // Use the updated value
  
        if (selectedProduct) {
          setInitialValue(prevState => ({
            ...prevState,
            itemDescription: selectedProduct.itemDescription,
            moq:selectedProduct?.moq,
            factoryOfSafety:selectedProduct?.factorOfSafety,
            leadTime:selectedProduct?.leadTime
          
          }));
        }
      }

  const handleSubmit = (values , {resetForm})=>{
    setDisableAddButton(true)


    const data = {

      productId:values?.product_Id?.value,
      onHandStock:values.onHandStock,
      qualifiedDemand:values.qualifiedDemand,
      tog:values.tog

    }
    try{
      store.dispatch(addRotexInventory(data)).then((res)=>{
        setDisableAddButton(false)

        if(res?.isError == false){
          toast.success(res?.message)
          handleClose();
          resetForm();
          setUpdateAPI('add')
        }
        else{
          const errorMessage = res.message.includes(":") ? res.message.split(":")[1] : res.message;
          toast.error(errorMessage);
       
        }
      })
    }catch (error){
      return error
    }
  }



  return (
    <div>
       <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
           onClick={() => {
            handleOpen();
            getChildMasterproduct();

          
          }} 
      >
        <span>Add Rotex Inventory</span>
        <div className='pageheader_icons_container' >
        <IoMdAddCircleOutline className='pageheader_react_icons' />
        </div>
      </button>
    </div>
    <Modal
     aria-labelledby="transition-modal-title"
     aria-describedby="transition-modal-description"
     open={open}
     onClose={handleClose}
     closeAfterTransition
     slots={{ backdrop: Backdrop }}
     slotProps={{
       backdrop: {
         timeout: 500,
       },
     }}
   >
     <Fade in={open}>
       <Box sx={styleForMediaQuery} className="model_box">
         <div className="model_header">
         <div className='d-flex align-items-center gap-1' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Add Rotex Inventory </p>
            </div>
           <div className="d-flex align-items-center gap-3 ">
             <button className="closebtn_cont" onClick={handleClose}>
               <RxCross2 className="closebox_logo" />{" "}
             </button>
           </div>
         </div>

     <Formik 
     initialValues={initialValue}
     onSubmit={handleSubmit}
     >
      {(formik ,values)=>(
      <Form>
        
         <div className="inputbox_cont">
        

           <div className='row' >
         
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
                 {" "}
                 Item Code
               </label>
             
               <Select

options={optionListproduct}
placeholder="Select"

onChange={(e) => { formik.setFieldValue('product_Id' , e) ; hanlechangeitemcode(e) }}
name='product_Id'
isSearchable={true}
className='react_inbuild_select'
/>
                 
               
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Item Description
               </label>
               <Field
                 type="text"
                 name="itemDescription"
                 value={initialValue.itemDescription}
                 placeholder="Enter Item description"
                 className="add_inut_field"
                 disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Lead Time 
               </label>
               <Field
                 type="text"
                 name="leadTime"
                 placeholder="Enter Lead Time"
                 className="add_inut_field"
                 value={initialValue.leadTime}

                 disabled
               />
                  
             </div>
             </div>
           <div className="row">
            
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Factor of Safety
               </label>
               <Field
                 type="text"
                 name="factoryOfSafety"
                 placeholder="Enter Factor of Safety"
                 className="add_inut_field"
                 value={initialValue.factoryOfSafety}

                 disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               TOG
               </label>
               <Field
                 type="number"
                 name="tog"
                 placeholder="Enter TOG (Optional) "
                 className="add_inut_field"
                
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               On Hand Stock
               </label>
               <Field
                 type="number"
                 name="onHandStock"
                 placeholder="Enter On hand Stock"
                 className="add_inut_field"
           

               
               />
             </div>
             </div>
           <div className="row">
            
                
          <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            Qualified Demand
            </label>
            <Field
              type="number"
              name="qualifiedDemand"
              placeholder="Enter Qualified Demand"
              className="add_inut_field"
             
            />
          </div>
          <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            MOQ
            </label>
            <Field
              type="text"
              name="moq"
              placeholder="Enter MOQ"
              className="add_inut_field"
              disabled
              value={initialValue.moq}

            />
          </div>
           </div>
       
      
      
        
           <div className="addbox_btn_cont my-4 mt-3">
             <button  
               disabled={disableAddButton == true} type='submit'
              >Save</button>
           
           </div>
         </div>
        
         </Form>
          )}
         </Formik>
       </Box>
     </Fade>
   </Modal>
    </div>
  );
}
