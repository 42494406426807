import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LuFilter } from "react-icons/lu";
import Select from "react-select";
import store from "../../../../Redux/MyRedux/store";
import { AiOutlineClose } from "react-icons/ai";
import { spMaterFilterList } from "../../../../Redux/MyRedux/action/phase3";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "#F4F6FA",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",

  pb: 3,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
};

export default function FilterModal({stateSetters , propsState }) {
    const [spmasterlistdropdown, setspmasterlistdropdown] = React.useState([]);


    const getspmasterlistdropdownlist = () => {
      try {
        store.dispatch(spMaterFilterList()).then((res) => {
          if (res?.isError === false) {
            setspmasterlistdropdown(res?.data);
          }
        });
      } catch (error) {
        console.log("Error Fetching :", error);
      }
    };

 
      const uniquecpnameLabels = [...new Set(spmasterlistdropdown.map((obj) => obj.supplierName))];
      const uniquecpnameLabelObjects = uniquecpnameLabels.map((label) => ({
        value: label,
        label: label, // Convert to string if necessary
      }));


     
      const uniquecontactpersonLabels = [
        ...new Set(spmasterlistdropdown.map((obj) => obj.contactPersonName)),
      ];
      const uniquecontactpersonLabelObjects = uniquecontactpersonLabels.map(
        (label) =>({
          value: label,
          label: label, 
        }));


      const optionListphone = spmasterlistdropdown?.map((val, i) => ({
        value: val?._id,
        label: val?.phone,
      }));
    
      const optionListemail = spmasterlistdropdown?.map((val, i) => ({
        value: val?._id,
        label: val?.email,
      }));
    
     
      const uniquecityLabels = [...new Set(spmasterlistdropdown.map((obj) => obj.city))];
      const uniquecityLabelObjects = uniquecityLabels.map((label) =>  ({
        value: label,
        label: label,
      }));

      const uniquestateLabels = [
        ...new Set(spmasterlistdropdown.map((obj) => obj.state)),
      ];
      const uniquestateLabelObjects = uniquestateLabels.map((label) =>  ({
        value: label,
        label: label, 
      }));

     
      const uniqueaddressLabels = [
        ...new Set(spmasterlistdropdown.map((obj) => obj.address)),
      ];
      const uniqueaddressObjects = uniqueaddressLabels.map((label) =>  ({
        value: label,
        label: label, 
      }));

 

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  
  const clearFilter = () => {
    stateSetters.setFilterCity([]);
    stateSetters.setFilterContactPerson([]);
    stateSetters.setFilterCpName([]);
    stateSetters.setFilterContactPerson([]);
    stateSetters.setFilterEmail([]);
    stateSetters.setFilterPhone([]);
    stateSetters.setFilterState([]);
    stateSetters.setFilterAddress([]);
    stateSetters.setUpdateAPI("15");
  };

  return (
    <div>
      <button
        className="filter_btn"
        type="button"
        onClick={() => {
          getspmasterlistdropdownlist();
          handleOpen();
        }}
      >
        <LuFilter />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <div className="filter_modal_header">
            <div className="d-flex justify-content-between ">
              <div className="modal_heading d-flex align-items-center gap-2">
                <button className="filter_btn" type="button">
                  <LuFilter />
                </button>{" "}
                <span> Filter </span>
              </div>
              <div className="close_modal_btn" onClick={() => handleClose()}>
                <AiOutlineClose />
              </div>
            </div>
          </div>
          <div className="filter_select_container">
            <div className="">
          <div className="filter_select_container">

            
            <div className="row">
            <div className="form-group col-md-4">
              <Select
                options={uniquecpnameLabelObjects}
                placeholder="Supplier"
                value={propsState.filterCpName}
                onChange={(e) => stateSetters.setFilterCpName(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>
            <div className="form-group col-md-4">
              <Select
                options={uniquecontactpersonLabelObjects}
                placeholder="Contact Person "
                value={propsState.filterContactPerson}
                onChange={(e) => stateSetters.setFilterContactPerson(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>
            <div className="form-group col-md-4">
              <Select
                options={optionListphone}
                placeholder="Phone"
                value={propsState.filterPhone}
                onChange={(e) => stateSetters.setFilterPhone(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>
            </div>
            <div className="row">
            <div className="form-group col-md-4">
              <Select
                options={optionListemail}
                placeholder="Email"
                value={propsState.filterEmail}
                onChange={(e) => stateSetters.setFilterEmail(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>
            <div className="form-group col-md-4">
              <Select
                options={uniquecityLabelObjects}
                placeholder="City"
                value={propsState.filterCity}
                onChange={(e) =>stateSetters.setFilterCity(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>
            <div className="form-group col-md-4">
              <Select
                options={uniquestateLabelObjects}
                placeholder="State"
                value={propsState.filterState}
                onChange={(e) => stateSetters.setFilterState(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>
            </div>
            <div className="row">
            <div className="form-group col-md-4">
              <Select
                options={uniqueaddressObjects}
                placeholder="Address"
                value={propsState.filteraddress}
                onChange={(e) => stateSetters.setFilterAddress(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>
          
            </div>
          </div>
        
     

              <div className="drawer-foot-btns mt-5">
                <button className="btn clear_btn" onClick={() => clearFilter()}>
                  Clear
                </button>
                <div className="">
                  <button
                    className="btn blue-btn p-2 px-3"
                    onClick={() => { handleClose(); stateSetters.setUpdateAPI('update')}}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
