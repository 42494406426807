import React, { useState } from 'react'
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';
import InactiveCustomtable from './table';

function CPInactiveInventoryList() {
  const [sidebaropen , setSidebarOpen] = useState(false)

  return (
    
      <div className='d-flex'>  
      <Sidebar setSidebarOpen={setSidebarOpen} />
      <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
      <Header  />
      <InactiveCustomtable />
      </div> 
  </div>
  )
}

export default CPInactiveInventoryList;
