import { API_URL } from "./userActions"

//// SP MASTER API START 
// API CALL FOR SP MASTER LIST DROPDOWN 
export const spMasterDropDownList = (id) => dispatch  => {
return  fetch(`${API_URL}/api/user/auth/sp/all`, {
method: 'GET',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
}).then(res => {

return res.json()
})
}


 // API CALL FOR SP MASTER LIST ALL
 export const spMaterList = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-partner/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


 // API CALL FOR SP MASTER FILTER LIST ALL
 export const spMaterFilterList = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-partner/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


     // API CALL FOR SP MASTER CHANGE STATUS  ACTIVE AND INACTIVE
 export const spMasterChangeStatus = (id , data) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-partner/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


        // API FOR ADD SP MASTER 
        export const addSpMaster  = (data) => dispatch  => {
            return  fetch(`${API_URL}/api/supplier-partner`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                 body :JSON.stringify(data)
            }).then(res => {
                
                return res.json()
            })
            }


               // API FOR VIEW  CP MASTER 
        export const viewSpMaster  = (id) => dispatch  => {
            return  fetch(`${API_URL}/api/supplier-partner/${id}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
            }).then(res => {
                
                return res.json()
            })
            }
               // API FOR EDIT CP MASTER 
        export const updateSpMaster  = ( data ,id) => dispatch  => {
            return  fetch(`${API_URL}/api/supplier-partner/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                 body :JSON.stringify(data)
            }).then(res => {
                
                return res.json()
            })
            }
              
     
    // API FOR UPDATE CP MASTER EXCEL SHEET STATUS
 export const updateSpExcelSheet  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-partner/import`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

    // API FOR SP IS END

    // CHILD CTAEGORY API START 

        // API CALL FOR CATEGORY LIST 
export const childCategorylist = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-category/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
        // API CALL FOR  ADD NEW CATEGORY LIST 
export const addChildCategory = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-category`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
         // API CALL FOR  VIEW  CATEGORY  
export const viewChildCategory = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-category/${data?.id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
         // API CALL FOR  UPDATE NEW CATEGORY  
export const updateChildCategory = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-category/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
      // API FOR UPDATE CHILD CATEGORY STATUS
 export const UpdateChildCategoryStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-category/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
// API FOR IMPORT CATEGORY LIST 
    export const updateChildCategoryExcelSheet  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/child-part-category/importCategory`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }


        // API CALL FOR CHIILD CATEGORY LIST DROPDOWN
export const childCategorylistdropdowm = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-category/drop-down/list`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

        //////CATEGORY API IS END  


        ///// SUB_CATEGORY API IS START

    // API CALL FOR SUB CATEGORY LIST 
    export const childSubCategorylist = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/child-part-sub-category/all`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body :JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
         
    // API CALL FOR CREATE NEW SUB CATEGORY 
export const addChildSubCategory = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-sub-category`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

      // API CALL FOR  VIEW  SUBCATEGORY  
export const viewChildSubCategory = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-sub-category/${data?.id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
    


         // API CALL FOR  UPDATE NEW SUBCATEGORY  
export const updateChildSubCategory = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-sub-category/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

     // API FOR UPDATE SUB-CATEGORY STATUS
 export const updateChildSubCategoryStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-sub-category/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
    // API FOR IMPORT SUB CATEGORY LIST 
    export const updateChildSUbCategoryExcelSheet  = (data ,id) => dispatch  => {
        return  fetch(`${API_URL}/api/child-part-sub-category/importSubCategory`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }

         // API FOR  SUB CATEGORY DROPDOWN
         export const ChildSubCategorydropdown  = (id) => dispatch  => {
            return  fetch(`${API_URL}/api/child-part-sub-category/${id}/category`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
            
            }).then(res => {
                
                return res.json()
            })
            }
    
        ////// CHILD SUBCATEGORY API END 


 ////// CHILD UNIT LIST API IS START



             // API FOR VIEW  LIST IN UNIT PAGE
             export const childUnitList  = (data) => dispatch  => {
                return  fetch(`${API_URL}/api/child-part-unit/all`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    },
                    body:JSON.stringify(data)
                }).then(res => {
                    
                    return res.json()
                })
                }
            
                         // API FOR ADD UNITS
              export const addChildUnitList  = (data) => dispatch  => {
                return  fetch(`${API_URL}/api/child-part-unit`, {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    },
                    body:JSON.stringify(data)
                }).then(res => {
                    
                    return res.json()
                })
                }
            
                 // API FOR VIEW UNit NAME
             export const viewChildUnitList  = (data) => dispatch  => {
                return  fetch(`${API_URL}/api/child-part-unit/${data?.id}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    },
                }).then(res => {
                    
                    return res.json()
                })
                }
            
                   // API FOR UPDATE UNIT NAME
             export const updateChildUnitist  = (data ,id) => dispatch  => {
                return  fetch(`${API_URL}/api/child-part-unit/${id}`, {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    },
                    body:JSON.stringify(data)
                }).then(res => {
                    
                    return res.json()
                })
                }
                 // API FOR UPDATE UNIT STATUS
             export const UpdateChildUnitStatus  = (data ,id) => dispatch  => {
                return  fetch(`${API_URL}/api/child-part-unit/status/${id}`, {
                    method: 'PATCH',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    },
                    body:JSON.stringify(data)
                }).then(res => {
                    
                    return res.json()
                })
                }


           // API FOR  UNIT DROPDOWN
           export const ChildUnitdropdown  = (data ,id) => dispatch  => {
            return  fetch(`${API_URL}/api/child-part-unit/dropdown/list`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                body:JSON.stringify(data)
            }).then(res => {
                
                return res.json()
            })
            }

///// CHILD PARTS MASTER UNIT API ENDS


 // API FOR VIEW  LIST IN INDUSTRY PAGE
 export const childIndustryList  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-industry/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

 // API FOR ADD INDUSTRY
  export const addChildIndustryList  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-industry`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
 // API FOR VIEW INDUSTRY NAME
 export const viewChildIndustryList  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-industry/${data?.id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }


       // API FOR UPDATE INDUSTRY NAME
 export const updateChildIndustryList  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-industry/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
       // API FOR UPDATE INDUSTRY STATUS
 export const updateChildIndustryStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-industry/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

           // API FOR  INDUSTRY DROPDOWN
 export const ChildIndustrydropdown  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-industry/dropdown/list`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
   
    ////CHILD PART MASTER API END FOR INDUSTRY

    ///CHILD PART MASTER API START 



    // API FOR SHOW LIST IN PRODUCT MASTER LIST 
    export const childMasterList  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/child-part-master/all`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body :JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }

         // API FOR ADD PRODUCT IN PRODUCT MASTER LIST 
    export const addChildMaster  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/child-part-master`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body :JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
           // API FOR ADD PRODUCT IN PRODUCT MASTER LIST 
    export const viewChildMaster  = (id) => dispatch  => {
        return  fetch(`${API_URL}/api/child-part-master/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
        }).then(res => {
            
            return res.json()
        })
        }
           // API FOR ADD PRODUCT IN PRODUCT MASTER LIST 
    export const updateChildMaster  = ( data ,id) => dispatch  => {
        return  fetch(`${API_URL}/api/child-part-master/${id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body :JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }

         // API FOR UPDATE PRODUCT MASTERSTATUS
 export const updateChildMasterStatus  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-master/status/${id}`, {
        method: 'PATCH',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
       // API FOR UPDATE PRODUCT MASTER EXCEL SHEET STATUS
 export const updateChildExcelSheet  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-master/importProducts`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }
// API CALL FOR PRODUCT  LIST DROPDOWN 
export const childMasterListDropdowm = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/child-part-master/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }

    /////CHILD PART MASTER API END 

    /// API FOR ROTEX INVENTORY

    // API FOR SHOW ROTEX INVENTORY  LISTS
 export const rotexInventoryList  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-inventory/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


      // API FOR ADD ROTEX INVENTORY BUFFER LISTS
 export const addRotexInventory  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-inventory`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }



      // API FOR SHOW PRODUCT IN ADD ROTEX INVEMTORY MODAL
 export const rotexProductDropdownList  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-inventory/product/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


     // API FOR UPDATE INVENTORY BUFFER STATUS
     export const updateRotexInvetoryStatus  = (data,id) => dispatch  => {
        return  fetch(`${API_URL}/api/rotex-inventory/status/${id}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
          
        }).then(res => {
            
            return res.json()
        })
        }
          // API FOR UPDATE INVENTORY BUFFER NEW TOGGLE 
       export const updateRotexInvetoryTogStatus  = (data,id) => dispatch  => {
        return  fetch(`${API_URL}/api/rotex-inventory/${id}/tog-toggle`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
          
        }).then(res => {
            
            return res.json()
        })
        }

       // API FOR VIEW INVENTORY BUFFER LISTS
       export const viewRotexInventory  = (id) => dispatch  => {
        return  fetch(`${API_URL}/api/rotex-inventory/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
          
        }).then(res => {
            
            return res.json()
        })
        }
           // API FOR EDIT INVENTORY BUFFER LISTS
           export const editRotexInventory  = (data,id) => dispatch  => {
            return  fetch(`${API_URL}/api/rotex-inventory/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                body:JSON.stringify(data)
              
            }).then(res => {
                
                return res.json()
            })
            }

  // API FOR UPDATE INVENTORY  EXCEL SHEET
  export const rotexInventoryExcel  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-inventory/import`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


 // API FOR SHOW FLAGS COUNT IN DASHBOARD
 export const rotexInventoryFlagShow  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-inventory/dashboard`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
      
    }).then(res => {
        
        return res.json()
    })
    }



    // API CALL FOR ORDER RECOMMANDATION  LIST DROPDOWN 
export const rotexInventoryDropdown = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-inventory/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }


    //// ROTEX INVENTORY API END 


    ////ROTEX ORDER API START 


     // API FOR SHOW ORDER RECOMMONDATION LISTS
 export const  rotexOrderAPI  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-order/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


      // API FOR SHOW ORDER STATUS LISTS IN HEADER
 export const rotexOrderFlags  = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-order/dashboard/count`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }



     // API CALL FOR ORDER RECOMMANDATION  LIST DROPDOWN 
export const rotexOrderDropdown = () => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-order/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }



         // API FOR SHOW ROTEX ORDER RECOMMONDATION LISTS
 export const rotexOrderView  = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-order/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }


             // API FOR SHOW SUPPLIER WHEN VIEW ORDER LOW OR ADD SP 
 export const supplierForRotexOrder  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-order/supplier/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


           // API FOR ADD SUPPLIER MASTER IN ROTEX ORDER
 export const addSupplierForRotexOrder  = (data , id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-order/${id}/add-supplier`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


         // API CALL ADD FOR Sap So number
export const addRotexSAPNo = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-order/sap/number`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }




       // API TO ADD REMARK BY Rotex in ROTEX ORDER VIEW
 export const addRemarkByRotex  = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-order/remark//${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

       // API TO ADD ATTACHEMENT BY Rotex in ROTEX ORDER VIEW

      export const addAttchmentByRotexOrder= (formData) => dispatch  => {
        return  fetch(`${API_URL}/api/rotex-order/attachment/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
             body:formData
        }).then(res => {
            return res.json()
        })
        }

    // API TO CONFORM ORDER BY ROTEX

 export const rotexOrderEdit  = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-order/${id}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


    ///// API FOR ROTEX ORDER IS END  



//// API FOR SUPLLIER MASTER FG INVENTORY IS START 


     // API FOR SHOW INVENTORY BUFFER LISTS
     export const spFgInventoryList  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/supplier-inventory/all`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
            // API FOR ADD INVENTORY BUFFER LISTS
     export const addSPInventory  = (data ,id) => dispatch  => {
        return  fetch(`${API_URL}/api/supplier-inventory`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
    
           // API FOR VIEW INVENTORY BUFFER LISTS
     export const viewSpInventory  = (id) => dispatch  => {
        return  fetch(`${API_URL}/api/supplier-inventory/${id}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
          
        }).then(res => {
            
            return res.json()
        })
        }
           // API FOR EDIT INVENTORY BUFFER LISTS
           export const editSpInventory  = (data,id) => dispatch  => {
            return  fetch(`${API_URL}/api/supplier-inventory/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                body:JSON.stringify(data)
              
            }).then(res => {
                
                return res.json()
            })
            }
    
             // API FOR UPDATE INVENTORY BUFFER STATUS
           export const updateSpInventoryStatus  = (data,id) => dispatch  => {
            return  fetch(`${API_URL}/api/supplier-inventory/status/${id}`, {
                method: 'PATCH',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                body:JSON.stringify(data)
              
            }).then(res => {
                
                return res.json()
            })
            }
              // API FOR UPDATE INVENTORY BUFFER NEW TOGGLE 
           export const updateSpInventoryTogToggle  = (data,id) => dispatch  => {
            return  fetch(`${API_URL}/api/supplier-inventory/${id}/tog-toggle`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                body:JSON.stringify(data)
              
            }).then(res => {
                
                return res.json()
            })
            }


          //API FOR SHOW COUNT IN SP FG INVENTORY HEADER PAGE 
 export const spInventoryFlagCount  = (data) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-inventory/dashboard`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
      
    }).then(res => {
        
        return res.json()
    })
    }


     // API CALL FOR SP INVENTORY LIST DROPDOWN FILTER MOIDAL
export const spInventoryListDropdown = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-inventory/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }


      // API FOR UPDATE INVENTORY  EXCEL SHEET
  export const spInventoryExcel  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-inventory/import`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }

/// SP PRODUCION RECOMMENDATION API IS START 



/// SP PRODUCTION RECOMMENDATION API START 
    export const  spProductRecommendation  = (data) => dispatch  => {
        return  fetch(`${API_URL}/api/supplier-order/all`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }




         // API FOR SHOW Supplier  PRODUCTION RECOMMENDATION VIEW
 export const spOrderView  = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-order/${id}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }





         // API FOR EDIT AND ACCEPT Supplier  PRODUCTION RECOMMENDATION 
         export const spOrderEdit  = (data,id) => dispatch  => {
            return  fetch(`${API_URL}/api/supplier-order/${id}`, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                },
                body:JSON.stringify(data)
            }).then(res => {
                
                return res.json()
            })
            }



      // API FOR SHOW COUNT IN PRODUCTION RECOMMENDATION PAGE 
 export const spOrderFlag  = (data,id) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-order/dashboard/count`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }




    // API FOR  ROTEX CONSUMPTION MASTER START 


    // API FOR SHOW LIST IN ROTEX CONSUMPTION MASTER
  export const rotexConsumptionList  = ( data) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-consumption/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


     // API FOR SHOW PRODUCT IN ADD ROTEX INVEMTORY MODAL
 export const addConsumptionProductList  = (data ,id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-consumption/inventory-dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
        body:JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }




     // API FOR ADD SHOW LIST IN CONSUMPTION MASTER
export const addRotexConsumption  = ( data) => dispatch  => {
return  fetch(`${API_URL}/api/rotex-consumption`, {
method: 'POST',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
 body :JSON.stringify(data)
}).then(res => {

return res.json()
})
}

 // API FOR VIEW  ROTEX CONSUMPTION MASTER
export const viewRotexConsumption  = (id) => dispatch  => {
return  fetch(`${API_URL}/api/rotex-consumption/${id}`, {
method: 'GET',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
}).then(res => {
return res.json()
})
}

 // API FOR EDIT   IN CONSUMPTION MASTER
export const editRotexConsumption  = (data , id) => dispatch  => {
return  fetch(`${API_URL}/api/rotex-consumption/${id}`, {
method: 'PUT',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
body:JSON.stringify(data)
}).then(res => {
return res.json()
})
}

// API FOR UPDATE CONSUMPTION STATUS
export const rotexConsumptionStatusUpdate  = (data ,id) => dispatch  => {
return  fetch(`${API_URL}/api/rotex-consumption/status/${id}`, {
method: 'PATCH',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
body:JSON.stringify(data)
}).then(res => {

return res.json()
})
}
// API FOR UPDATE CONSUMPTION STATUS
export const rotexConsumptionExcelImport  = (data ,id) => dispatch  => {
return  fetch(`${API_URL}/api/rotex-consumption/import`, {
method: 'POST',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
body:JSON.stringify(data)
}).then(res => {

return res.json()
})
}
// API CALL FOR CONSUMPTION    LIST DROPDOWN 
export const rotexConsumptionsDropdown = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/rotex-consumption/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }

    /// API FOR ROTEX CONSUMPTION IS END  



     // API FOR  SUPPLIER MASTER  CONSUMPTION  START 


    // API FOR SHOW LIST IN ROTEX CONSUMPTION MASTER
  export const spConsumptionList  = ( data) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-consumption/all`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
         body :JSON.stringify(data)
    }).then(res => {
        
        return res.json()
    })
    }


     // API FOR ADD SHOW LIST IN CONSUMPTION MASTER
export const addSpConsumption  = ( data) => dispatch  => {
return  fetch(`${API_URL}/api/supplier-consumption`, {
method: 'POST',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
 body :JSON.stringify(data)
}).then(res => {

return res.json()
})
}


     // API FOR SHOW SP IN ADD ROTEX INVEMTORY MODAL
     export const addConsumptionSpList  = (data ,id) => dispatch  => {
        return  fetch(`${API_URL}/api/supplier-consumption/dropdown`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem("token")}`,
            },
            body:JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }
    

 // API FOR VIEW  ROTEX CONSUMPTION MASTER
export const viewSpConsumption  = (id) => dispatch  => {
return  fetch(`${API_URL}/api/supplier-consumption/${id}`, {
method: 'GET',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
}).then(res => {
return res.json()
})
}

 // API FOR EDIT   IN CONSUMPTION MASTER
export const editSpConsumption  = (data , id) => dispatch  => {
return  fetch(`${API_URL}/api/supplier-consumption/${id}`, {
method: 'PUT',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
body:JSON.stringify(data)
}).then(res => {
return res.json()
})
}

// API FOR UPDATE CONSUMPTION STATUS
export const spConsumptionStatusUpdate  = (data ,id) => dispatch  => {
return  fetch(`${API_URL}/api/supplier-consumption/status/${id}`, {
method: 'PATCH',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
body:JSON.stringify(data)
}).then(res => {

return res.json()
})
}
// API FOR UPDATE CONSUMPTION STATUS
export const spConsumptionExcelImport  = (data ,id) => dispatch  => {
return  fetch(`${API_URL}/api/supplier-consumption/import`, {
method: 'POST',
headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem("token")}`,
},
body:JSON.stringify(data)
}).then(res => {

return res.json()
})
}
// API CALL FOR CONSUMPTION    LIST DROPDOWN 
export const spConsumptionsDropdown = (id) => dispatch  => {
    return  fetch(`${API_URL}/api/supplier-consumption/dropdown`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem("token")}`,
        },
    }).then(res => {
        
        return res.json()
    })
    }
