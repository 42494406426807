import React, { useState, useEffect } from "react";
import store from "../../../../Redux/MyRedux/store";
import Pagination from "react-mui-pagination";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { LuSearch } from "react-icons/lu";
import "./table.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";

import { TbFileDownload } from "react-icons/tb";
import { FaRegRectangleList } from "react-icons/fa6";
import sort_logo_img from '../../../../Assets/table/shortarrow.png'
import FilterModal from "./filterpopup/filter";
import { childMasterList, updateChildExcelSheet, updateChildMasterStatus } from "../../../../Redux/MyRedux/action/phase3";
import AddModal from "./Add/Add";
import EditModal from "./Edit/edit";


function ProductMasterTable() {
  const [page, setPage] = React.useState(1);

  const [userType, setUserType] = useState("");
  const [updatedAPI, setUpdateAPI] = useState("");


  const [filterItemCode, setFilterItemCode] = useState([]);
  const [filterItemdesc, setFilterItemDesc] = useState([]);
  const [filterProductName, setFilterProductName] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterSubCategory, setFilterSubCategory] = useState([]);
  const [filterMoq, setFilterMOQ] = useState([]);
  const [filterfacorysafety, setFilterFactorySafety] = useState([]);
  const [filterUnit, setFilterUnit] = useState([]);
  const [filterIndustry, setFilterIndustry] = useState([]);


  const stateSetters = {
    setFilterItemCode,
    setFilterItemDesc,
    setFilterProductName,
    setFilterCategory,
    setFilterSubCategory,
    setFilterMOQ,
    setFilterFactorySafety,
    setFilterUnit,
    setFilterIndustry,
    setUpdateAPI
  }
  const propsState = {
    filterItemCode,
    filterItemdesc,
    filterProductName,
    filterCategory,
    filterSubCategory,
    filterMoq,
    filterfacorysafety,
    filterUnit,
    filterIndustry,
  }








  const [total_count, setTotalCount] = React.useState(0);
  
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [Search, setSearch] = useState("");
  const [listdata, setListdata] = useState([]);




  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      itemCode: filterItemCode.map((item) => item.label),
      itemDescription: filterItemdesc.map((item) => item.label),
      productName: filterProductName.map((item) => item.label),
      subcategoryName: filterSubCategory.map((item) => item.label),
      categoryName: filterCategory.map((item) => item.label),
      active: true,
      unit: filterUnit.map((item) => item.label),
      industry: filterIndustry.map((item) => item.label),
      moq: filterMoq.map((item) => item.label),
      factorOfSafety:filterfacorysafety.map((item) => item.label),
    };

    try {
      store.dispatch(childMasterList(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data?.docs.length > 0) {
            setListdata(res?.data?.docs);
            setTotalCount(res?.data?.paginate[0]?.totalDocs);
          }
          else{
            setListdata([]);
            setTotalCount(0);
          }
        }
        setUpdateAPI('')

      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    setUserType(localStorage.getItem("usertype"));
    getdata();
  }, [recordperpage, page, Search , updatedAPI]);



  const handleProductStatusUpdate = (id, value) => {
    const data = {
      active: value,
      productid: id,
    };
    try {
      store.dispatch(updateChildMasterStatus(data, id)).then((res) => {
        if (res?.isError == false) {
          //  handleUpdateBoxClose()
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const navigate = useNavigate();

  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handleProductStatusUpdate(userId, updatedStatus);
    }
  };

const handleDownloadEXcel = () => {
  // Custom headers
  const headers = [
    "Item code",
    "Item Description ",
    "Product Name",
    "Sub-Category",
    "Sub-catrgory Id ",
    "Category Id",
    "Category",
    "MOQ",
    "Factor of Safety",
    "Unit",
    "Industry",
  ];

  // Map data to a new array with custom headers
  const dataWithCustomHeaders = [
    headers,
    ...listdata.map((item) => [
      item.itemCode,
      item.itemDescription,
      item.productName,
      item.subcategoryName,
      item.subcategoryid,
      item.categoryid,
      item.categoryName,
      item.moq,
      item.factorOfSafety,
      item.unit,
      item.industry,
    ]),
  ];
  // Generate Excel file
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  // Save file
  const fileData = new Blob([excelBuffer], {
    type: "application/octet-stream",
  });
  saveAs(fileData, "ChildMasterList.xlsx");
};
const handleTemplateDownload = () => {
  // Custom headers
  const headers = [
    "Item code",
    "Item Description ",
    "Product Name",
    "Sub-Category",
    "Category",
    "MOQ",
    "Factor of Safety",
    "Unit",
    "Industry",
  ];

  // Map data to a new array with custom headers
  const dataWithCustomHeaders = [
    headers,[]
    
  ];
  // Generate Excel file
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  // Save file
  const fileData = new Blob([excelBuffer], {
    type: "application/octet-stream",
  });
  saveAs(fileData, "Child Master template.xlsx");
};

const handleFileUpload = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      // Parse the Excel file to JSON
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      // Define a mapping between old header names and new parameter names
      const headerMapping = {
"Item code":"itemCode",
"Category" : "categoryName",
"Sub-Category" : "subcategoryName",
"Item Description " : "itemDescription",
"Product Name" :"productName",
"MOQ":"moq",
"Factor of Safety":"factorOfSafety",
"Industry":"industry",
"Unit":"unit",
      };

      // Map the old header names to the new parameter names in the JSON data
      const mappedJsonData = jsonData.map((item) => {
        const mappedItem = {};
        for (const oldHeader in item) {
          if (oldHeader in headerMapping) {
            mappedItem[headerMapping[oldHeader]] = item[oldHeader];
          }
        }
        return mappedItem;
      });

      // setExcelJson(mappedJsonData);

      updateNewExcel(mappedJsonData)
      // console.log(mappedJsonData , 'mappedJsonData')
    };
    reader.readAsBinaryString(file);
  }
};

const updateNewExcel = async (json) => { // Mark the function as async
  try {
    const res = await store.dispatch(updateChildExcelSheet(json));

    if (res?.isError === false) {
      toast.success(res?.message);
      getdata();

      if (res?.data?.errorArrray.length > 0) {
        toast.error(` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`);
        const headers = [
          "Item code",
          "Item Description ",
          "Sub-Category",
          "Category",
          "MOQ",
          "Factor of Safety",
          "Unit",
          "Industry",
          "Product Name",
          "Error"
        ];
      
        // Map data to a new array with custom headers
        const dataWithCustomHeaders = [
          headers,
          ...res?.data?.errorArrray.map((item) => [
            item.itemCode,
            item.itemDescription,
            item.subcategoryName,
            item.categoryName,
            item.moq,
            item.factorOfSafety,
            item.productName,
            item.unit,
            item.industry,
            item.error
          ]),
        ];
        // Generate Excel file
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        // Save file
        const fileData = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        saveAs(fileData,"Errordata.xlsx");

        
      } else {
        // Display an alert when errorArray is empty
        toast.success("No error data found. All data upload Successfully");
      }
    } else {
      toast.error(res?.message);
    }
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle the error as needed
  }
}

const clearFilter = (filterName) => {
  switch (filterName) {
    case 'ItemCode':
      setFilterItemCode([]);
      setUpdateAPI("1")
      break;
    case 'ProductName':
      setFilterProductName([]);
      setUpdateAPI("2")
      break;
    case 'Category':
      setFilterCategory([]);
      setUpdateAPI("3")
      break;
    case 'SubCategory':
      setUpdateAPI("4")
      setFilterSubCategory([]);
      break;
    case 'MOQ':
      setFilterMOQ([]);
      setUpdateAPI("5")
      break;
    case 'FactorySafety':
      setFilterFactorySafety([]);
      setUpdateAPI("6")
      break;
    case 'Unit':
      setFilterUnit([]);
      setUpdateAPI("7")
      break;
      case 'IndustryApplication':
        setFilterIndustry([]);
        setUpdateAPI("7")
        break;
        case 'itemDesc':
          setFilterItemDesc([]);
          setUpdateAPI("itemDesc")
          break;
        
    default:
      break;
  }


};




const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};


const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
const sortBy = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  const sortedData = [...listdata].sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  setListdata(sortedData);
  setSortConfig({ key, direction });
};
const [isHovered , setIsHovered] = useState(false)

const handleItemsPerPageChange = (value) => {
  setRecordPerPage(value);
  setPage(1); // Reset to page 1 whenever items per page changes
};

const calculateSerialNumber = (index) => {
  return (page - 1) * recordperpage + index + 1;
};
const startSerialNumber = calculateSerialNumber(0);
const endSerialNumber = calculateSerialNumber(listdata.length - 1);

  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading">child Part Master</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              value={Search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>

       

<div className="d-flex gap-2 flex-wrap ms-1">
{
            userType != "CP" &&
       <div className="button-container">
      <button
        className={`button ${isHovered ? 'hovered' : ''} import_export_btns`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>Excel Import</span>
        <div className='pageheader_icons_container'>
          <RiAttachment2 className='import_attach_icon' />
        </div>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          className="import_excel_input"
          style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
        />
      </button>
    </div>
}
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
          onClick={() => handleDownloadEXcel()}
      >
        <span>Excel Export</span>
        <div className='pageheader_icons_container' >
        <MdOutlineCloudDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
    {
            userType != "CP" &&
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`} 
       onClick={() => handleTemplateDownload()} 
      >
        <span>Import Template</span>
        <div className='pageheader_icons_container' >
        <TbFileDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
}

   
   
    <AddModal setUpdateAPI={setUpdateAPI} />

    
         
        </div>
      </div>



      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3" style={{ width:"100%" }}  >
        <div className="page_header_slider_container">
         
          <FilterModal stateSetters={stateSetters} propsState={propsState} />
        </div>
        <div className="slider_container ms-5">
          {" "}
          <Slider {...settings}>
            {filterItemCode.length > 0 && (
              <div class="active-filters">
               <button>
               <span className='filter_text_cont' >  Item Code is  {filterItemCode.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterItemCode.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter('ItemCode')} />{" "}
              </button>
              </div>
            )}
            {filterProductName.length > 0 && (
              <div class="active-filters">
                   <button>
                   <span className='filter_text_cont' >     Product Name is  {filterProductName.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterProductName.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter('ProductName')} />{" "}
              </button>
              </div>
            )}
            {filterCategory.length > 0 && (
              <div class="active-filters">
                    <button>
                    <span className='filter_text_cont' >    Category is {filterCategory.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterCategory.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("Category")} />{" "}
              </button>
              </div>
            )}
            {filterSubCategory.length > 0 && (
              <div class="active-filters">
                 <button>
                 <span className='filter_text_cont' >   Sub-Category is {filterSubCategory.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterSubCategory.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("SubCategory")}/>{" "}
              </button>
              </div>
            )}
            {filterMoq.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' >     MOQ is {filterMoq.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterMoq.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilter("MOQ")} />{" "}
              </button>
              </div>
            )}
            {filterfacorysafety.length > 0 && (
              <div class="active-filters">
                 <button>
                 <span className='filter_text_cont' >     Factor of Safety is {filterfacorysafety.map((item, index) => ( <span  className="ellipsis-button_QD" key={index}>
      {item.label}
      {index < filterfacorysafety.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("FactorySafety")} />{" "}
              </button>
              </div>
            )}
            {filterUnit.length > 0 && (
              <div class="active-filters">
                 <button>
                 <span className='filter_text_cont' >     Unit is {filterUnit.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterUnit.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("Unit")} />{" "}
              </button>
              </div>
            )}
             {filterIndustry.length > 0 && (
              <div class="active-filters">
                   <button>
                   <span className='filter_text_cont' >      Industry Applica.. {filterIndustry.map((item, index) => ( <span  className="ellipsis-button_industry" key={index}>
      {item.label}
      {index < filterIndustry.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilter("IndustryApplication")} />{" "}
              </button>
              </div>
            )}
             {filterItemdesc.length > 0 && (
              <div class="active-filters">
                   <button>
                   <span className='filter_text_cont' >     Item Description is {filterItemdesc.map((item, index) => ( <span  className="ellipsis-button_industry" key={index}>
      {item.label}
      {index < filterItemdesc.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("itemDesc")} />{" "}
              </button>
              </div>
            )}
          </Slider>
        </div>
        </div>
        <div className="d-flex">
        

          <button className='inactive_page_btn' 
        onClick={() => navigate("/inactivechildpartmaster")} > <FaRegRectangleList className='list_icons' /> <span>  Inactive Product Master List </span> </button>

       
        </div>
      </div>

      <div className="table-container">
        <table className="table ">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              {userType != "CP" && <td scope="col">Action</td>}
              <td scope="col">
                <div className="th_common">
                  <span>Item Code</span>
                  
                  <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("itemCode")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Manufacturing lead time</span>
                 
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("manufactureleadTime")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Item Description</span>
                 
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("itemDescription")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Product Name</span>
                 
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("productName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Category</span>
                  
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("categoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Sub-Category</span>
                  
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("subcategoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>MOQ</span>
                  
                  <img src={sort_logo_img} alt="sort" className="img-fluid"
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("moq")}
                 />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Factor of Safety</span>
                 
                   <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("factorOfSafety")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Unit</span>
                
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("unit")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Industries Applications</span>
                 
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("industry")}
                  />
                </div>
              </td>
              {
                  userType != "CP" &&
              <td scope="col">
                <div className="th_common">
                  <span>Status</span>
                
                </div>
              </td>
}
            </tr>
            <br />
          </thead>
          <tbody>
            {listdata?.map((value, i) => (
              <>
              <tr key={i}>
              <td>{calculateSerialNumber(i)}</td>
                {userType != "CP" && (
                  <td>
                   
                         <EditModal id={value?._id} setUpdateAPI={setUpdateAPI}/>
                  </td>
                )}
                <td>{value?.itemCode}</td>
                <td>{value?.manufactureleadTime}</td>

                <td>{value?.itemDescription}</td>
                <td>{value?.productName}</td>
                <td>{value?.categoryName}</td>
                <td>{value?.subcategoryName}</td>
                <td>{value?.moq}</td>
                <td>{value?.factorOfSafety}</td>
                <td>{value?.unit}</td>
                <td>{value?.industry}</td>
                {
                  userType != "CP" &&
                <td>
                  {" "}
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={value?.active}
                      onChange={() => toggleStatus(value?._id)}
                    />
                  </div>
                </td>
}
              </tr>
              <br />
              </>
            ))}
          </tbody>
        </table>
      </div>

    
        <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} >   Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results </p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => handleItemsPerPageChange(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>
   


    
    </div>
  );
}

export default ProductMasterTable;
