import React, { useState , useEffect } from "react";
import "./login.css";
import { HiOutlineMail } from "react-icons/hi";
import { SlLock } from "react-icons/sl";
import { loginuser } from "../../Redux/MyRedux/action/userauth";
import { useNavigate } from "react-router-dom";
import store from "../../Redux/MyRedux/store";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form, Field, ErrorMessage, Formik } from "formik";


const LoginPage = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
 
      .required("Password is required"),
  });
 
  const navigate = useNavigate();;



  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (localStorage.getItem("usertype") == "CP") {
        navigate("/orderrecommandation");
      } else {
        navigate("/dashboard");
      }
     
    }
  }, [navigate])


 

  return (
    <>
      <section className="login_section">
        <div className="d-flex justify-content-center p-3">
          {" "}
         
          <img src="/assets/images/logo.png" alt="" className="img-fluid" />
          
        </div>
        <div className="d-flex justify-content-center align-items-center gap-3 outer_cont ">
          <div className="d-flex flex-column justify-content-center align-items-center gap-4 inner_cont">
            <div className="login_txt_box">
              <h4 className="m-0">LOGIN</h4>
            </div>
            <Formik
  initialValues={{
    email: "",
    password: "",
    
   
  }}
  validationSchema={validationSchema} // Replace with your validation schema
  onSubmit={(values) => {
            const data = {
              username: values.email,
             password: values.password,
            }
      try {
        store.dispatch(loginuser(data)).then((res) => {
          if (res?.isError == false) {
            localStorage.setItem("token", res?.data?.token);
            localStorage.setItem("usertype", res?.data?.userType);
            {
            res?.data?.userType == 'SP' ? 
            localStorage.setItem("channelPartnerId", res?.data?.supplierPartnerId)
            :
            localStorage.setItem("channelPartnerId", res?.data?.channelPartnerId)
            }
            
            localStorage.setItem("user_id", res?.data?._id);
            localStorage.setItem("isAcceptanceAtRotex", res?.data?.isAcceptanceAtRotex);
            localStorage.setItem("isAcceptanceAtWIP", res?.data?.isAcceptanceAtWIP);
      


  
            toast.success(res?.message);
          //  if(res?.data?.userType == "ROTEX") {
          //   localStorage.setItem("spTabAccess", res?.data?.IsRotexViewSupplierModule);
          //   localStorage.setItem("cpTabAccess", res?.data?.IsRotexViewCpModule);
          //  }
             
            if (res?.data?.userType == "SP") {
              navigate(`/suppliermaster/productionrecommendation/${res?.data?.supplierPartnerId}`);
      
            }
            else if (res?.data?.userType == "CP"){
              navigate("/dashboard");
            }
            
            else {
              navigate("/dashboard");
            }
            
          } else {
            toast.error(res?.message);
          }
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
  }}
> 
  {(formik) => (
    <Form onSubmit={formik.handleSubmit}>
    
      <div className="email_field_cont">
        <HiOutlineMail className="login_page_icons" />
        <input
          type="email"
          name="email"
          id="email"
          placeholder="E-Mail Address"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className="form-control"
        />
      </div>
      {formik.touched.email && formik.errors.email && (
        <div className="error-message">{formik.errors.email}</div>
      )}

      <div className="email_field_cont">
        <SlLock className="login_page_icons lock_icons" />
        <FormControl variant="outlined" className="material_pass_field">
          <OutlinedInput
            id="outlined-adornment-password"
            type={formik.values.showPassword ? "text" : "password"}
            className="material_pass_field form-control p-0"

            name="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    formik.setFieldValue(
                      "showPassword",
                      !formik.values.showPassword
                    )
                  }
                  edge="end"
                >
                  {formik.values.showPassword ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
      {formik.touched.password && formik.errors.password && (
        <div className="error-message">{formik.errors.password}</div>
      )}

      <button type="submit" className="login_btn">
        LOGIN
      </button>
    </Form>
  )}
</Formik>
           
          </div>
        </div>
      </section>
    </>
  );
};
export default LoginPage;
