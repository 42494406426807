import React, { useState, useEffect } from "react";
import store from "../../../../../Redux/MyRedux/store";
import { Addconsumptionmaster } from "../../../../../Redux/MyRedux/action/userActions";
import { RxCross2 } from "react-icons/rx";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Pagination from "react-mui-pagination";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { LuSearch } from "react-icons/lu";
import Slider from "react-slick";
import { BiCloudUpload } from "react-icons/bi";
import { FaRegRectangleList } from "react-icons/fa6";
import { AiOutlinePlus } from "react-icons/ai";
import sort_logo_image from "../../../../../Assets/table/shortarrow.png";
import FilterModal from "../filterpopup/filter";
import {
  spFgInventoryList,
  spInventoryExcel,
  spInventoryFlagCount,
  updateSpInventoryStatus,
  updateSpInventoryTogToggle,
} from "../../../../../Redux/MyRedux/action/phase3";
import AddModal from "../Add/add";
import EditModal from "../Edit/edit";
import EditQDModal from "../QDEdit/qdedit";
import { TbFileDownload } from "react-icons/tb";
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";

function Customtable() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius: "20px",
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

  const consumtionstyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius: "20px",
  };
  const consumptionStyleForMediaQuery = {
    ...consumtionstyle,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

  const [dashboardFlag, setDashBoardFlag] = useState([]);

  const [spId, setSpId] = useState("");

  const [filtertog, setFilterTOG] = useState([]);
  const [filterinHandStock, setFilterInHandStock] = useState([]);
  const [filterNetflow, setFilterNetFlow] = useState([]);
  const [filterSpName, setfilterSpName] = useState([]);
  const [filterContactPerson, setFilterContactPerson] = useState([]);
  const [filterCity, setFilterCiy] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [filterItemCode, setFilterItemCode] = useState([]);
  const [filterAvgConsumption, setFilterAvgConsumption] = useState([]);
  const [filterQualifiedDemand, setFilterQualifiedDemand] = useState([]);
  const [filterLeadTime, setFilterLeadTime] = useState([]);
  const [filterFactorySafety, setFilterFactorySafety] = useState([]);
  const [filterMoq, setFilterMOQ] = useState([]);
  const [filterOrderRecommendation, setFilterOrderRecommendation] = useState(
    []
  );
  const [filterUnit, setFilterUnit] = useState([]);
  const [filterOpenOrder, setFilterOpenOrder] = useState([]);
  const [filterRealAWC, setFilterRealAWC] = useState([]);
  const [filterItemDesc, setFilterItemDesc] = useState([]);
  const [filterOrderRecommendationStatus, setFilterOrderRecommendationStatus] =
    useState([]);
  const [updatedAPI, setUpdateAPI] = useState("");

  const stateSetters = {
    setFilterTOG,
    setFilterInHandStock,
    setFilterNetFlow,
    setfilterSpName,
    setFilterContactPerson,
    setFilterCiy,
    setFilterState,
    setFilterItemCode,
    setFilterAvgConsumption,
    setFilterQualifiedDemand,
    setFilterLeadTime,
    setFilterFactorySafety,
    setFilterMOQ,
    setFilterOrderRecommendation,
    setFilterUnit,
    setFilterOpenOrder,
    setFilterRealAWC,
    setFilterItemDesc,
    setFilterOrderRecommendationStatus,
    setUpdateAPI,
  };

  const propsState = {
    filtertog,
    filterinHandStock,
    filterNetflow,
    filterSpName,
    filterContactPerson,
    filterCity,
    filterState,
    filterItemCode,
    filterAvgConsumption,
    filterQualifiedDemand,
    filterLeadTime,
    filterFactorySafety,
    filterMoq,
    filterOrderRecommendation,
    filterUnit,
    filterOpenOrder,
    filterRealAWC,
    filterItemDesc,
    filterOrderRecommendationStatus,
  };

  const [usertype, setUserType] = useState(""); // Default to ascending order
  const [colourflag, setColourFlag] = useState("");

  const [search, setSearch] = useState("");
  const [page, setPage] = React.useState(1);
  const [list, setList] = useState([]);
  const [recordperpage, setRecordperPage] = useState(1000);
  const [total_count, setTotalCount] = React.useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [consumptionval, setConsumptionval] = useState({});
  const setConsumptionvalue = (val) => {
    setConsumptionval(val);
  };

  const initialforconsumtion = {
    qty: "",
    date: new Date().toISOString().slice(0, 10),
  };
  const [addconsumption, setAddConsumption] = useState(initialforconsumtion);
  const handleconsumption = (e) => {
    const { name, value } = e.target;
    const parsedValue =
      name == "qty" ? (value == "" ? null : parseInt(value)) : value;
    setAddConsumption((prevData) => ({ ...prevData, [name]: parsedValue }));
  };

  const getdata = () => {
    const data = {
      search: search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      onHandStock: filterinHandStock.map((item) => item.label),
      qualifiedDemand: filterQualifiedDemand.map((item) => item.label),
      leadTime: filterLeadTime.map((item) => item.label),
      factorOfSafety: filterFactorySafety.map((item) => item.label),
      active: true,
      avgWeeklyConsumption: filterAvgConsumption.map((item) => item.label),
      flag: [],
      contactPersonName: filterContactPerson.map((item) => item.label),
      itemCode: filterItemCode.map((item) => item.label),
      netFlow: filterNetflow.map((item) => item.label),
      tog: filtertog.map((item) => item.label),
      state: filterState.map((item) => item.label),
      moq: filterMoq.map((item) => item.label),
      orderRecommendation: filterOrderRecommendation.map((item) => item.label),
      active: true,
      city: filterCity.map((item) => item.label),
      supplierName: filterSpName.map((item) => item.label),
      supplierPartnerId: spId,
      status: colourflag,
      unit: filterUnit.map((item) => item.label),
      avg: filterRealAWC.map((item) => item.label),
      openOrder: filterOpenOrder.map((item) => item.label),
      itemDescription: filterItemDesc.map((item) => item.label),
      orderRecommendationStatus: filterOrderRecommendationStatus.map(
        (item) => item.label
      ),
    };

    try {
      store.dispatch(spFgInventoryList(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data[0]?.docs.length > 0) {
            setList(res?.data[0]?.docs);
            setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          } else {
            setList([]);
            setTotalCount(0);
          }
        }
      setUpdateAPI('')
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handleFlagCount = () => {
    const data = {
      supplierPartnerId: spId,
      active: true,
    };
    try {
      store.dispatch(spInventoryFlagCount(data)).then((res) => {
        if (res?.isError === false) {
          const existingFlags = res?.data.map((item) => item.flag);
          const flagsToAdd = ["RED", "WHIGHT", "BLACK", "", "YELLOW", "GREEN"]
            .filter((flag) => !existingFlags.includes(flag))
            .map((flag) => ({ count: 0, flag }));

          const updatedData = [...res?.data, ...flagsToAdd];
          setDashBoardFlag(updatedData);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  useEffect(() => {
    setUserType(localStorage.getItem("usertype"));
    setSpId(
      localStorage.getItem("channelPartnerId") == null ||
        localStorage.getItem("channelPartnerId") == undefined ||
        localStorage.getItem("channelPartnerId") == ""
        ? window.location.href.split("/")[5]
        : localStorage.getItem("channelPartnerId")
    );

    if (localStorage.getItem("usertype") == "SP") {
      if (spId) {
        getdata();
        handleFlagCount();
      }
    } else {
      getdata();
      handleFlagCount();
    }
  }, [recordperpage, page, updatedAPI, spId, colourflag, search]);

  const addconsumtionbyinventory = (onhandstock) => {
    if (addconsumption.qty > onhandstock) {
      toast.error("Your Consumption is more than On Hand Stock");
    } else {
      Swal.fire({
        title: "Do you want to save the Consumption ?",
        showDenyButton: true,
        // showCancelButton: true,
        confirmButtonText: "Save",
        denyButtonText: `Don't save`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          handleadconsumption();
        } else if (result.isDenied) {
          // Swal.fire('Changes are not saved', '', 'info')
        }
      });
    }
  };
  const handleadconsumption = () => {
    const data = {
      channelPartnerId: consumptionval?.channelPartnerId,
      productId: consumptionval?.productId,
      itemId: "",
      qty: addconsumption.qty,
      date: addconsumption.date,
    };

    try {
      store.dispatch(Addconsumptionmaster(data)).then((res) => {
        if (res?.isError == false) {
          handleCloseConsumption();
          toast.success(res?.message);
          getdata();
          setConsumptionval({});
          setAddConsumption(initialforconsumtion);
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const [openconsumption, setOpenConsumption] = useState(false);
  const handleopenConsumption = () => setOpenConsumption(true);
  const handleCloseConsumption = () => setOpenConsumption(false);

  const handlUpdateStatus = (id, value) => {
    const data = {
      active: value,
    };
    store.dispatch(updateSpInventoryStatus(data, id)).then((res) => {
      if (res?.isError == false) {
        toast.success(res?.message);
        getdata();
      } else {
        toast.error(res?.messsage);
      }
    });
  };

  const handlUpdatetogtoggle = (id, value) => {
    const data = {
      isUpdateTog: value,
    };
    store.dispatch(updateSpInventoryTogToggle(data, id)).then((res) => {
      if (res?.isError == false) {
        toast.success(res?.message);
        getdata();
      } else {
        toast.error(res?.messsage);
      }
    });
  };

  const navigate = useNavigate();
  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = list.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handlUpdateStatus(userId, updatedStatus);
    }
  };

  const toggleTogUpdate = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = list.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.isUpdateTog;
      handlUpdatetogtoggle(userId, updatedStatus);
    }
  };

  const clearHeaderFilter = (filterName) => {
    switch (filterName) {
      case "TOG":
        setFilterTOG([]);
        setUpdateAPI("1");
        break;
      case "OnHandStock":
        setFilterInHandStock([]);
        setUpdateAPI("3");
        break;
      case "CPName":
        setfilterSpName([]);
        setUpdateAPI("5");
        break;
      case "ContactPerson":
        setFilterContactPerson([]);
        setUpdateAPI("6");
        break;
      case "City":
        setFilterCiy([]);
        setUpdateAPI("7");
        break;
      case "State":
        setFilterState([]);
        setUpdateAPI("8");
        break;
      case "Consumption":
        setFilterAvgConsumption([]);
        setUpdateAPI("9");
        break;
      case "LeadTime":
        setUpdateAPI("10");
        setFilterLeadTime([]);
        break;
      case "FactorySafety":
        setUpdateAPI("11");
        setFilterFactorySafety([]);
        break;
      case "QualifiedDemand":
        setUpdateAPI("12");
        setFilterQualifiedDemand([]);
        break;
      case "NetFlow":
        setUpdateAPI("13");
        setFilterNetFlow([]);
        break;
      case "MOQ":
        setFilterMOQ([]);
        setUpdateAPI("14");
        break;
      case "OrderRecommendation":
        setUpdateAPI("15");
        setFilterOrderRecommendation([]);
        break;
      case "ItemCode":
        setUpdateAPI("ItemCode");
        setFilterItemCode([]);
        break;
      case "realAWC":
        setUpdateAPI("realAWC");
        setFilterRealAWC([]);
        break;
      case "itemdesc":
        setUpdateAPI("itemdesc");
        setFilterItemDesc([]);
        break;
      case "OrderRecommendationstatus":
        setUpdateAPI("OrderRecommendationstatus");
        setFilterOrderRecommendationStatus([]);
        break;
      case "openOrder":
        setUpdateAPI("openOrder");
        setFilterOpenOrder([]);
        break;
      case "unit":
        setUpdateAPI("unit");
        setFilterUnit([]);
        break;

      default:
        break;
    }

    // Call getdata with the updated filters
    // getdata();
  };

  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [

      "Item Code",
      "Item Description",
      "Avg Weekly Consumption",
      "Lead Time(weekly)",
      "Factor of Safety",
      "TOG",
      "On Hand Stock",
      "Net Flow",
      "MOQ",
      "Production Recommendation",
      "Production Recommendation Status",
      "On Hand Status",
      "Open Production Order",
      "Qualified Demand",
      "Unit",
      "Supplier Name",
      "Status"

      

    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...list.map((item) => [
        item.itemCode,
        item.itemDescription,
        item.avgWeeklyConsumption,
        item.leadTime,
        item.factorOfSafety,
        Math.round(item.tog),
        item.onHandStock,
        item.netFlow,
        item.moq,
        item.orderRecommendation,
        `${Math.round(item.orderRecommendationStatus)}%`,
        `${Math.round(item.onHandStatus)}%`,
        item.openOrder,
        item.qualifiedDemand,
        item.unit,
        item.supplierName,
        item.active == true ? "Active" : item.active == false ? "Inactive" : ""

      ]),
    ];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "SupplierFGInventories.xlsx");
  };

  const sortedData = dashboardFlag.slice().sort((a, b) => {
    const order = ["BLACK", "RED", "YELLOW", "GREEN", "WHIGHT"];
    return order.indexOf(a.flag) - order.indexOf(b.flag);
  });

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const sortBy = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const sortedData = [...list].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setList(sortedData);
    setSortConfig({ key, direction });
  };

  const [isHovered, setIsHovered] = useState(false);



  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        // Parse the Excel file to JSON
        const workbook = XLSX.read(data, { type: 'binary' });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Define a mapping between old header names and new parameter names
        const headerMapping = {
          "Item Code":'itemCode',
          "TOG":'tog',
          "Lead Time":'leadTime',
          "Factor of Safety":'factorOfSafety',
          "OnHandStock":'onHandStock',
          "Supplier Name":'supplierName',
          'MOQ':'moq'
        };

        // Map the old header names to the new parameter names in the JSON data
        const mappedJsonData = jsonData.map((item) => {
          const mappedItem = {};
          for (const oldHeader in item) {
            if (oldHeader in headerMapping) {
              mappedItem[headerMapping[oldHeader]] = item[oldHeader];
            }
          }
          return mappedItem;
        });


        updateNewExcel(mappedJsonData)
      };
      reader.readAsBinaryString(file);
    }
  };
  const updateNewExcel = (json) =>{

    try {
      store.dispatch(spInventoryExcel(json)).then((res)=>{
        getdata();
        if(res?.isError == false){
  
          toast.success(res?.message)
          if (res?.data?.errorArrray.length > 0) {
            toast.error(` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`);
            const headers = [
              "Item Code",
              "TOG",
              "Lead Time",
              "Factor of Safety",
              "OnHandStock",
              "Supplier Name",
              'MOQ',
              "Error"
            ];
          
            // Map data to a new array with custom headers
            const dataWithCustomHeaders = [
              headers,
              ...res?.data?.errorArrray.map((item) => [
               item.itemCode,
               item.tog,
               item.onHandStock,
               item.leadTime,
               item.factorOfSafety,
               item.supplierName,
               item.moq,
               item.error
    
              ]),
            ];
            // Generate Excel file
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
            const excelBuffer = XLSX.write(workbook, {
              bookType: "xlsx",
              type: "array",
            });
            // Save file
            const fileData = new Blob([excelBuffer], {
              type: "application/octet-stream",
            });
            saveAs(fileData,"Errordata.xlsx");
    
            
          } else {
            // Display an alert when errorArray is empty
            toast.success("No error data found. All data upload Successfully");
          }
        }
        else{
          toast.error(res?.message)
        }
      })
    } catch (error) {
      return error
    }
  
  }

  const handleTemplateDownload = () => {
    // Custom headers
    const headers = [
     "Item Code",
     "TOG",
     "Lead Time",
     "Factor of Safety",
     "OnHandStock",
     "Supplier Name",
     'MOQ'
    ];
  
    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,[]
      
    ];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "Supplier Inventory template.xlsx");
  }
  const handleItemsPerPageChange = (value) => {
    setRecordperPage(value);
    setPage(1); // Reset to page 1 whenever items per page changes
  };
  
  const calculateSerialNumber = (index) => {
    return (page - 1) * recordperpage + index + 1;
  };
  const startSerialNumber = calculateSerialNumber(0);
  const endSerialNumber = calculateSerialNumber(list.length - 1);
  
  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          {sortedData.map((item, index) => (
            <>
              {item.flag == "all" && (
                <li key={index} className="list-inline-item">
                  {" "}
                  <button
                    className=" dashboard_header_btn_all"
                    onClick={() => {
                      setColourFlag("");
                      setPage("1");
                    }}
                  >
                    {" "}
                    <span>ALL</span>{" "}
                    <span className="all_count_container">{item?.count}</span>{" "}
                  </button>
                </li>
              )}
              {item?.flag == "RED" && (
                <li key={index} className="list-inline-item">
                  {" "}
                  <button
                    className="dashboard_header_btn_red"
                    onClick={() => {
                      setColourFlag("RED");
                      setPage("1");
                    }}
                  >
                    {" "}
                    <span> RED </span>{" "}
                    <span className="all_count_container">{item?.count}</span>
                  </button>
                </li>
              )}
              {item?.flag == "YELLOW" && (
                <li key={index} className="list-inline-item">
                  {" "}
                  <button
                    className=" dashboard_header_btn_yellow"
                    onClick={() => {
                      setColourFlag("YELLOW");
                      setPage("1");
                    }}
                  >
                    {" "}
                    <span> YELLOW </span>{" "}
                    <span className="all_count_container">{item?.count}</span>
                  </button>
                </li>
              )}
              {item?.flag == "GREEN" && (
                <li key={index} className="list-inline-item">
                  {" "}
                  <button
                    className="dashboard_header_btn_green "
                    onClick={() => {
                      setColourFlag("GREEN");
                      setPage("1");
                    }}
                  >
                    {" "}
                    <span> GREEN </span>{" "}
                    <span className="all_count_container">{item?.count}</span>
                  </button>
                </li>
              )}
              {item?.flag == "WHIGHT" && (
                <li key={index} className="list-inline-item">
                  {" "}
                  <button
                    className=" dashboard_header_btn_all"
                    onClick={() => {
                      setColourFlag("WHIGHT");
                      setPage("1");
                    }}
                  >
                    {" "}
                    <span> WHITE </span>{" "}
                    <span className="all_count_container">{item?.count}</span>
                  </button>
                </li>
              )}
              {item?.flag == "BLACK" && (
                <li key={index} className="list-inline-item">
                  {" "}
                  <button
                    className="dashboard_header_btn_black"
                    onClick={() => {
                      setColourFlag("BLACK");
                      setPage("1");
                    }}
                  >
                    {" "}
                    <span> BLACK </span>{" "}
                    <span className="all_count_container">{item?.count}</span>
                  </button>
                </li>
              )}
            </>
          ))}
        </ul>

        <div className="d-flex align-items-center gap-2 ">
        <div className="button-container">
      <button
        className={`button ${isHovered ? 'hovered' : ''} import_export_btns`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>Excel Import</span>
        <div className='pageheader_icons_container'>
          <RiAttachment2 className='import_attach_icon' />
        </div>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          className="import_excel_input"
          style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
        />
      </button>
    </div>
          <div
            className="button-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <button
              className={`button ${isHovered ? "hovered" : ""}`}
              onClick={() => handleDownloadEXcel()}
            >
              <span>Excel Export</span>
              <div className="pageheader_icons_container">
                <MdOutlineCloudDownload className="pageheader_react_icons" />
              </div>
            </button>
          </div>
          <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`} 
       onClick={() => handleTemplateDownload()} 
      >
        <span>Import Template</span>
        <div className='pageheader_icons_container' >
        <TbFileDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
          <AddModal spId={spId} setUpdateAPI={setUpdateAPI} />

     

          <div></div>
        </div>
      </div>
      <div className="page_header_slider_container justify-content-between  ">
        <div className="page_header_slider_container ps-0">
          <div className="d-flex align-items-center gap-1">
          <p className=" page_heading mb-0" style={{ whiteSpace:'pre' }}>Supplier inventory</p>
<div>
            <FilterModal stateSetters={stateSetters} propsState={propsState} />
            </div>
          </div>
          <div className="slider_container">
            <Slider {...settings}>
              {filtertog.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      TOG is{" "}
                      {filtertog.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filtertog.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("TOG")} />
                  </button>
                </div>
              )}
              {filterinHandStock.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      On Hand Stock is{" "}
                      {filterinHandStock.map((item, index) => (
                        <span className="ellipsis-button_stock" key={index}>
                          {item.label}
                          {index < filterinHandStock.length - 1
                            ? ", "
                            : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2
                      onClick={() => clearHeaderFilter("OnHandStock")}
                    />
                  </button>
                </div>
              )}
              {filterSpName.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Supplier Name is{" "}
                      {filterSpName.map((item, index) => (
                        <span className="ellipsis-button_QD" key={index}>
                          {item.label}
                          {index < filterSpName.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("CPName")} />
                  </button>
                </div>
              )}
              {filterContactPerson.length > 0 && (
                <div class="active-filters">
                  {" "}
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      Contact Person Name is{" "}
                      {filterContactPerson.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterContactPerson.length - 1
                            ? ", "
                            : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2
                      onClick={() => clearHeaderFilter("ContactPerson")}
                    />
                  </button>
                </div>
              )}
              {filterCity.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    City is{" "}
                    {filterCity.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterCity.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("City")} />
                  </button>
                </div>
              )}

              {filterState.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      State is{" "}
                      {filterState.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterState.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("State")} />
                  </button>
                </div>
              )}
              {filterItemCode.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      Item Code is{" "}
                      {filterItemCode.map((item, index) => (
                        <span className="ellipsis-button_stock" key={index}>
                          {item.label}
                          {index < filterItemCode.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("ItemCode")} />
                  </button>
                </div>
              )}
              {filterAvgConsumption.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Avg. Consumption is{" "}
                      {filterAvgConsumption.map((item, index) => (
                        <span className="ellipsis-button_QD" key={index}>
                          {item.label}
                          {index < filterAvgConsumption.length - 1
                            ? ", "
                            : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2
                      onClick={() => clearHeaderFilter("Consumption")}
                    />
                  </button>
                </div>
              )}
              {filterLeadTime.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Lead Time is{" "}
                      {filterLeadTime.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterLeadTime.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("LeadTime")} />
                  </button>
                </div>
              )}
              {filterFactorySafety.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Factor of Safety is{" "}
                      {filterFactorySafety.map((item, index) => (
                        <span className="ellipsis-button_QD" key={index}>
                          {item.label}
                          {index < filterFactorySafety.length - 1
                            ? ", "
                            : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2
                      onClick={() => clearHeaderFilter("FactorySafety")}
                    />
                  </button>
                </div>
              )}
              {filterQualifiedDemand.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Qualified Demand is{" "}
                      {filterQualifiedDemand.map((item, index) => (
                        <span className="ellipsis-button_QD" key={index}>
                          {item.label}
                          {index < filterQualifiedDemand.length - 1
                            ? ", "
                            : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2
                      onClick={() => clearHeaderFilter("QualifiedDemand")}
                    />
                  </button>
                </div>
              )}
              {filterNetflow.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      NetFlow is{" "}
                      {filterNetflow.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterNetflow.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("NetFlow")} />
                  </button>
                </div>
              )}
              {filterMoq.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      MOQ is{" "}
                      {filterMoq.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterMoq.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("MOQ")} />{" "}
                  </button>
                </div>
              )}
              {filterOrderRecommendation.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Order Recommen..{" "}
                      {filterOrderRecommendation.map((item, index) => (
                        <span className="ellipsis-button_QD" key={index}>
                          {item.label}
                          {index < filterOrderRecommendation.length - 1
                            ? ", "
                            : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2
                      onClick={() => clearHeaderFilter("OrderRecommendation")}
                    />
                  </button>
                </div>
              )}
              {filterUnit.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      unit is{" "}
                      {filterUnit.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterUnit.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("unit")} />
                  </button>
                </div>
              )}
              {filterRealAWC.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Real Time AWC is{" "}
                      {filterRealAWC.map((item, index) => (
                        <span className="ellipsis-button_QD" key={index}>
                          {item.label}
                          {index < filterRealAWC.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("realAWC")} />
                  </button>
                </div>
              )}
              {filterOpenOrder.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Open Order is{" "}
                      {filterOpenOrder.map((item, index) => (
                        <span
                          className="ellipsis-button_contact_person"
                          key={index}
                        >
                          {item.label}
                          {index < filterOpenOrder.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("openOrder")} />
                  </button>
                </div>
              )}
              {filterOrderRecommendationStatus.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Order Recomme..{" "}
                      {filterOrderRecommendationStatus.map((item, index) => (
                        <span
                          className="ellipsis-button_contact_person"
                          key={index}
                        >
                          {item.label}
                          {index < filterOrderRecommendationStatus.length - 1
                            ? ", "
                            : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2
                      onClick={() =>
                        clearHeaderFilter("OrderRecommendationstatus")
                      }
                    />
                  </button>
                </div>
              )}
              {filterItemDesc.length > 0 && (
                <div class="active-filters">
                  <button>
                    {" "}
                    <span className="filter_text_cont">
                      {" "}
                      Item Description is{" "}
                      {filterItemDesc.map((item, index) => (
                        <span className="ellipsis-button_QD" key={index}>
                          {item.label}
                          {index < filterItemDesc.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearHeaderFilter("itemdesc")} />
                  </button>
                </div>
              )}
            </Slider>
          </div>
        </div>

        <div class="d-flex align-items-center flex-wrap justify-content-end gap-1  ">
          <form className="d-none d-lg-inline-block form-inline navbar-search ">
            <div className="input-group align-items-center ">
              <input
                type="search"
                className="form-control p-0 bg-white border-0 small page_search"
                placeholder="Search here"
                aria-label="Search"
                aria-describedby="basic-addon2"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />

              <LuSearch className="search_logo" />
            </div>
          </form>

          <button
            className="inactive_page_btn"
            onClick={() => {
              navigate(
                `/suppliermaster/fginventory/inactive/${
                  window.location.href.split("/")[5] ||
                  localStorage.getItem("channelPartnerId")
                }`
              );
            }}
          >
            {" "}
            <FaRegRectangleList className="list_icons" />{" "}
            <span> Inactive Inventory List </span>{" "}
          </button>

          <div></div>
        </div>
      </div>

      <div className="table-container mt-1">
        <table className="table ">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              {usertype != "CP" && <td scope="col">Action </td>}
              <td scope="col">
                {" "}
                On Hand
                <br /> Priority
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Item Code</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("itemCode")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Item Description</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("itemDescription")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>
                    Avg <br /> Weekly Consumption
                  </span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("avgWeeklyConsumption")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>
                    {" "}
                    Lead Time <br />
                    (weekly)
                  </span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("leadTime")}
                  />
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Factor of Safety</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("factorOfSafety")}
                  />
                </div>{" "}
              </td>
             
              <td scope="col">
                <div className="th_common">
                  <span>TOG</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("tog")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>On Hand Stock</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("onHandStock")}
                  />
                </div>{" "}
              </td>{" "}


              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Open Production Order</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("openOrder")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span> Qualified Demand</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("qualifiedDemand")}
                  />
                </div>
              </td>



              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Net Flow</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("netFlow")}
                  />
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>MOQ</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("moq")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>
                    Production
                    <br /> Recommendation
                  </span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("orderRecommendation")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>
                    {" "}
                    Production
                    <br /> Recommendation Status
                  </span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("orderRecommendationStatus")}
                  />
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>On Hand Status</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("onHandStatus")}
                  />
                </div>{" "}
              </td>
           
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Unit</span>

                  <img
                    src={sort_logo_image}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("unit")}
                  />
                </div>{" "}
              </td>
              {/* {usertype != "SP" && ( */}
                <td scope="col">
                  {" "}
                  <div className="th_common">
                    <span>Supplier</span>

                    <img
                      src={sort_logo_image}
                      alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("companyName")}
                    />
                  </div>{" "}
                </td>
              {/* )} */}
              <td scope="col"> Status</td>
              <td scope="col"> TOG Auto Update </td>
            </tr>
            <br />
          </thead>
          <tbody>
            {list?.map((val, i) => (
              <>
                <tr key={i}>
                <td>{calculateSerialNumber(i)}</td>

                  {usertype != "CP" && (
                    <td>
                      <EditModal
                        id={val?._id}
                        setUpdateAPI={setUpdateAPI}
                        spId={spId}
                      />
                    </td>
                  )}
                  {val?.flag == "YELLOW" ? (
                    <td>
                      <button className="btn btn-warning py-1">Yellow</button>
                    </td>
                  ) : val?.flag == "RED" ? (
                    <td>
                      <button className="btn btn-danger py-1">Red</button>
                    </td>
                  ) : val?.flag == "BLACK" ? (
                    <td>
                      <button className="btn btn-dark py-1">Black</button>
                    </td>
                  ) : val?.flag == "GREEN" ? (
                    <td>
                      <button className="btn btn-success py-1">Green</button>
                    </td>
                  ) : (
                    <td>
                      <button className="btn border py-1">White</button>
                    </td>
                  )}
                    <td>{val?.itemCode}</td>
                  <td>{val?.itemDescription}</td>
                  <td>{Math.round(val?.avgWeeklyConsumption)}</td>
                  <td>{val?.leadTime}</td>
                  <td>{val?.factorOfSafety}</td>
                  <td>{Math.round(val?.tog)}</td>
                  <td>{val?.onHandStock}</td>
                  <td>{val?.openOrder}</td>
                  <td>{val?.qualifiedDemand}</td>
                  <td>{val?.netFlow}</td>
                  <td>{val?.moq}</td>
                  <td>{val?.orderRecommendation}</td>
                  <td>
                    {val?.orderRecommendationStatus !== undefined ||
                    val?.orderRecommendationStatus != 0
                      ? parseFloat(val?.orderRecommendationStatus).toFixed(2)
                      : "0"}
                    %
                  </td>
                  <td>
                    {val?.onHandStatus !== undefined || val?.onHandStatus != 0
                      ? parseFloat(val?.onHandStatus).toFixed(2)
                      : "0"}
                    %
                  </td>
 
               
                  <td>{val?.unit}</td>

                 
                      <td>{val?.supplierName}</td>
                  
                  <td>
                    {" "}
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input addbox_radio"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={val?.active}
                        onChange={() => toggleStatus(val?._id)}
                      />
                    </div>
                  </td>
                  <td>
                    {" "}
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input addbox_radio"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={val?.isUpdateTog}
                        onChange={() => toggleTogUpdate(val?._id)}
                      />
                    </div>
                  </td>
                </tr>
                <br />
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
        <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            
            Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results
          </p>
        </div>

        <div className="d-flex   justify-content-center align-items-center">
          <Pagination
            page={page}
            setPage={handleChange}
            total={total_count}
            className="pagination "
            perPage={recordperpage}
          />
        </div>
        <div className="d-flex align-items-center">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            Total Count
          </p>
          <select
            className="w-auto form-select form-select-sm p3 shadow-none mx-3 "
            style={{ fontFamily: "Poppins" }}
            value={recordperpage}
            onChange={(e) => handleItemsPerPageChange(e.target.value)}
          >
            <option value="5">5</option>

            {total_count > 5 && <option value="10">10</option>}
            {total_count > 10 && <option value="25">25</option>}
            {total_count > 25 && <option value="50">50</option>}
            {total_count > 50 && <option value="100">100</option>}
            {total_count > 100 && <option value="500">500</option>}
            {total_count > 500 && <option value="1000">1000</option>}
          </select>
        </div>
      </div>

      {/* //  Add  Consumption  MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openconsumption}
        onClose={handleCloseConsumption}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{ zIndex: 9 }}
      >
        <Fade in={openconsumption}>
          <Box sx={consumptionStyleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex gap-3 align-items-center ">
                <button className="btn  blue-btn py-1 px-2  fs-6">
                  <AiOutlinePlus />
                </button>
                <p className="m-0"> Add Consumption Master</p>
              </div>
              <div className="d-flex align-items-center gap-3 ">
                <button
                  className="closebtn_cont"
                  onClick={handleCloseConsumption}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    CP Name
                  </label>
                  <input
                    type="text"
                    name="itemdesc"
                    id=""
                    placeholder="Maggi noodles"
                    className="add_inut_field"
                    value={consumptionval?.companyName}
                    disabled
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    {" "}
                    Item Code
                  </label>

                  <input
                    type="text"
                    name="itemdesc"
                    id=""
                    placeholder="Maggi noodles"
                    className="add_inut_field"
                    value={consumptionval?.itemCode}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Item Description
                  </label>
                  <input
                    type="text"
                    name="itemdesc"
                    id=""
                    placeholder="Maggi noodles"
                    className="add_inut_field"
                    value={consumptionval?.itemDescription}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Product Name
                  </label>
                  <input
                    type="text"
                    name="Productname"
                    id=""
                    placeholder="product name"
                    className="add_inut_field"
                    value={consumptionval?.productName}
                    disabled
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Category
                  </label>
                  <input
                    type="text"
                    name="category"
                    id=""
                    placeholder="600"
                    className="add_inut_field"
                    value={consumptionval?.categoryName}
                    disabled
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Sub-Category
                  </label>
                  <input
                    type="text"
                    name="subcategory_name"
                    id=""
                    placeholder="Cup Noodles"
                    className="add_inut_field"
                    value={consumptionval?.subCategoryName}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    QTY
                  </label>
                  <input
                    type="text"
                    name="qty"
                    id=""
                    placeholder="20"
                    className="add_inut_field"
                    value={addconsumption.qty}
                    onChange={handleconsumption}
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor="" className="mb-1">
                    Date
                  </label>
                  <input
                    type="date"
                    name="date"
                    id=""
                    placeholder="dd/mm/yyyy"
                    className="add_inut_field"
                    value={addconsumption.date}
                    onChange={handleconsumption}
                  />
                </div>
              </div>

              <div className="addbox_btn_cont my-4 mt-3">
                <button
                  onClick={() =>
                    addconsumtionbyinventory(consumptionval?.onHandStock)
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* //  EDIT Consumption Master MODEL END  */}
    </div>
  );
}

export default Customtable;
