import React, { useEffect, useState } from "react";
import Pagination from "react-mui-pagination";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import store from "../../../Redux/MyRedux/store";
import {  ViewCPLeadTime } from "../../../Redux/MyRedux/action/userActions";
import { MdOutlineCloudDownload } from "react-icons/md";




function ViewCPLeadTimeTable() {

  const [Search, setSearch] = useState("");
  const [usertype, setUserType] = useState("");

  const [recordperpage, setRecordPerPage] = React.useState(1000);
  
  const [listdata, setListdata] = useState([]);
  const [page, setPage] = React.useState(1);
  const [total_count, setTotalCount] = React.useState(0);
  
  const handleChange = (event, value) => {
    setPage(value);
  };

  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: [],
      active:"true"

    };


    try {
      store.dispatch(ViewCPLeadTime(data)).then((res) => {
        
          if(res?.data[0]?.docs.length >0) {
          setListdata(res?.data[0]?.docs);
          setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          } else {
            setListdata([])
            setTotalCount(0)
          }
          
        
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    setUserType(localStorage.getItem("usertype"))
    getdata();
  }, [recordperpage ,page ,Search]);


 

 

 

  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
  'Item Code',
  'Item Description',
  'Product Name',
  'Product Type',
  'Total Lead Time'

    ];
  
    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [
        item?.itemCode,
       item?.itemDescription,
       item?.productName,
       item?.productType,
       item?.manufactureleadTime,

      ]),
    ];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "ViewLeadTime.xlsx");
  };
  const [isHovered, setIsHovered] = useState(false);

 
  const handleItemsPerPageChange = (value) => {
    setRecordPerPage(value);
    setPage(1); // Reset to page 1 whenever items per page changes
  };

  const calculateSerialNumber = (index) => {
    return (page - 1) * recordperpage + index + 1;
  };
  const startSerialNumber = calculateSerialNumber(0);
  const endSerialNumber = calculateSerialNumber(listdata.length - 1);


  return (
    <div>
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading "> View LeadTime</li>
        </ul>
        <div className="d-flex flex-wrap align-items-center gap-2 ">
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
            <div className="input-group">
                <input type="text" className="form-control bg-white border-0 small page_search" placeholder="Search" aria-label="Search" aria-describedby="basic-addon2"  onChange={(e) =>setSearch(e.target.value)  } />
                <div className="input-group-append">
                    <button className="btn bg-white" type="button">
                        <img src="/assets/images/search-icon.svg" alt="" className="img-fluid" />
                    </button>
                </div>
            </div>
        </form>
       



          <div
              className="button-container"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <button
                className={`button ${isHovered ? "hovered" : ""}`}
                onClick={() => handleDownloadEXcel()}
              >
                <span>Excel Export</span>
                <div className="pageheader_icons_container">
                  <MdOutlineCloudDownload className="pageheader_react_icons" />
                </div>
              </button>
            </div>
        
      
        </div>
      </div>
     

      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
             
              <td scope="col">Item Code</td>
              <td scope="col">Item Description</td>
              <td scope="col">Product Name</td>
              <td scope="col">Product Type</td>

              <td scope="col">Total LeadTime</td>
            </tr>
            <br />
          </thead>
          <tbody>
            {listdata.map((value, i) => (
              <>
              <tr key={i}>
              <td>{calculateSerialNumber(i)}</td>

               
                <td>{value?.itemCode}</td>
                <td>{value?.itemDescription}</td>

                <td>{value?.productName}</td>
          
                <td>{value?.productType}</td>
                <td>{value?.manufactureleadTime}</td>

             
            
                {
                  usertype != "CP" &&
                <td>   <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    
                    />
                 
                  </div></td> }
          
              </tr>
              <br />
              </>
            ))}
          </tbody>
        </table>
      </div>
      
      <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
        <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
           
Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results
          </p>
        </div>

        <div className="d-flex   justify-content-center align-items-center">
          <Pagination
            page={page}
            setPage={handleChange}
            total={total_count}
            className="pagination "
            perPage={recordperpage}
          />
        </div>
        <div className="d-flex align-items-center">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            Total Count
          </p>
          <select
            className="w-auto form-select form-select-sm p3 shadow-none mx-3 "
            style={{ fontFamily: "Poppins" }}
            value={recordperpage}
             onChange={(e) => handleItemsPerPageChange(e.target.value)}

            
          >
            <option value="5">5</option>

            {total_count > 5 && <option value="10">10</option>}
            {total_count > 10 && <option value="25">25</option>}
            {total_count > 25 && <option value="50">50</option>}
            {total_count > 50 && <option value="100">100</option>}
            {total_count > 100 && <option value="500">500</option>}
            {total_count > 500 && <option value="1000">1000</option>}
          </select>
        </div>
      </div>

     
    </div>
  );
}

export default ViewCPLeadTimeTable;
