import React, { useEffect, useState } from "react";
import Pagination from "react-mui-pagination";
import store from "../../../../Redux/MyRedux/store";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineEye } from"react-icons/ai"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { MdOutlineCloudDownload } from "react-icons/md";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegRectangleList } from "react-icons/fa6";
import { TbPencil } from "react-icons/tb";
import {AiOutlinePlus} from "react-icons/ai";
import { addChildIndustryList, childIndustryList, updateChildIndustryList, updateChildIndustryStatus, viewChildIndustryList } from "../../../../Redux/MyRedux/action/phase3";


function IndustryTable() {
  const [industryname , setIndustryName] = useState("")
  const [industryid , setIndustryId] = useState("")
  const [newindustryname , setNewIndustryName] = useState("")
  const [industryStatus , setIndustryStatus] = useState(false)
  const [usertype , setUserType] = useState("")


  const [page, setPage] = React.useState(1);
  const [total_count, setTotalCount] = React.useState(0);
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [Search, setSearch] = useState("");
  const [listdata, setListdata] = useState([{

  }]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [updateboxopen, setUpdateBoxOpen] = React.useState(false);
  const handleUpdateBoxOpen = () => setUpdateBoxOpen(true);
  const handleUpdateBoxClose = () => setUpdateBoxOpen(false);

  const [viewboxopen, setViewBoxOpen] = React.useState(false);
  const handleViewBoxOpen = () => setViewBoxOpen(true);
  const handleViewBoxClose = () => setViewBoxOpen(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 450,
    bgcolor: "#F4F6FA",
    boxShadow: 24,

  };
  const mediaQuery = `@media (max-width: 768px)`; 
  const styleForMediaQuery = {
    ...style, 
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

  const handleChange = (event, value) => {
    setPage(value);
  };
  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      active:true

    };
    try {
      store.dispatch(childIndustryList(data)).then((res) => {
        if (res?.isError == false) {
          if(res?.data[0]?.docs.length >0) {
          setListdata(res?.data[0]?.docs);
          setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          }
          else {
            setListdata([]);
            setTotalCount([]);
          }
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    setUserType(localStorage.getItem("usertype"))
    getdata();
  }, [recordperpage ,page,Search]);

  const handleaddindustry = () =>{
    const data = {
      industry :industryname
    };
    try {
      store.dispatch(addChildIndustryList(data)).then((res) => {
        if (res?.isError == false) {
          handleClose()
          toast.success(res?.message)
          getdata()
          setIndustryName("")

        }
        else {
          toast.error(res?.message)
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  const handleviewindustry = (id) =>{
    setIndustryId(id)
    const data = {
      id :id
    };
    try {
      store.dispatch(viewChildIndustryList(data)).then((res) => {
        if (res?.isError == false) {
         setNewIndustryName(res?.data[0]?.industry)
         setIndustryStatus(res?.data[0]?.active)

        }
        else {
          setNewIndustryName("")
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  const handleupdateindustry = (id) =>{
    const data = {
      industry : newindustryname
    };
    try {
      store.dispatch(updateChildIndustryList(data ,id)).then((res) => {
        if (res?.isError == false) {
           handleUpdateBoxClose()
           toast.success(res?.message)
           getdata();
        }
        else {
          toast.error(res?.message)

        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }
  const handleupdateindustrystatus = (id ,value) =>{
    const data = {
      active:value
    };
    try {
      store.dispatch(updateChildIndustryStatus(data ,id)).then((res) => {
        if (res?.isError == false) {
       
           toast.success(res?.message)
           getdata();
        }
        else {
          toast.error(res?.message)

        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  }

  const handleSwitchChange = () => {
    const updatedValue = !industryStatus; // Toggle the value
    setIndustryStatus(updatedValue);
    handleupdateindustrystatus(industryid ,updatedValue )
  };
  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);
  
    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handleupdateindustrystatus(userId,updatedStatus )
    }
  };
const navigate = useNavigate();
const handleDownloadEXcel = () => {
  // Custom headers
  const headers = [
   "Industry Application Name" ,
  "Industry Application ID"
  ];

  // Map data to a new array with custom headers
  const dataWithCustomHeaders = [
    headers,
    ...listdata.map((item) => [
      item?.industry,
     item?._id,
    ]),
  ];
  // Generate Excel file
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  // Save file
  const fileData = new Blob([excelBuffer], {
    type: "application/octet-stream",
  });
  saveAs(fileData, "ChildIndustries.xlsx");
};


const [isHovered , setIsHovered] = useState(false)

const handleItemsPerPageChange = (value) => {
  setRecordPerPage(value);
  setPage(1); // Reset to page 1 whenever items per page changes
};

const calculateSerialNumber = (index) => {
  return (page - 1) * recordperpage + index + 1;
};
const startSerialNumber = calculateSerialNumber(0);
const endSerialNumber = calculateSerialNumber(listdata.length - 1);

  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading "> Child Part Industries Applications</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
            <div className="input-group">
                <input type="text" className="form-control bg-white border-0 small page_search" placeholder="Search" aria-label="Search" aria-describedby="basic-addon2"  onChange={(e) =>setSearch(e.target.value)  } />
                <div className="input-group-append">
                    <button className="btn bg-white" type="button">
                        <img src="/assets/images/search-icon.svg" alt="" className="img-fluid" />
                    </button>
                </div>
            </div>
        </form>
       
        <div className="d-flex gap-2 flex-wrap ms-1">
          
          <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <button className={`button ${isHovered ? 'hovered' : ''}`}
                onClick={() => handleDownloadEXcel()}
            >
              <span>Excel Export</span>
              <div className='pageheader_icons_container' >
              <MdOutlineCloudDownload className='pageheader_react_icons' />

              </div>
            </button>
          </div>
       
      {
                  usertype != "CP" && 
          <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <button className={`button ${isHovered ? 'hovered' : ''}`}
               onClick={() => {
                handleOpen();
            
      
              }} 
            >
              <span>Add Industry </span>
              <div className='pageheader_icons_container' >
              <IoMdAddCircleOutline className='pageheader_react_icons' />
              </div>
            </button>
          </div>
      
            }
          
               
              </div> 
      </div>
      

      <div className="page_header_slider_container justify-content-end ">
      

      <div className="">
    
      
    
        <button className='inactive_page_btn' 
        onClick={() => navigate("/childpartmaster/inactiveindustries")} > <FaRegRectangleList className='list_icons' /> <span> Inactive Industry List </span> </button>
       
      </div>
    </div>

      <div className="table-container">
        <table className="table ">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">Industries Applications Name</td>
              <td scope="col">Action </td>
              {
                  usertype != "CP" &&
              <td scope="col">Status </td>
}

          
            </tr> 
            <br />
          </thead>
          <tbody>
            {listdata.map((value, i) => (
              <>
              <tr key={i}>
              <td>{calculateSerialNumber(i)}</td>

               
                <td>{value?.industry}</td>
              
                <td className="d-flex justify-content-center gap-1 action_td" >
                {
                  usertype != "CP" &&
                 
                       <button  className='table_edit_btn'
                  
                       onClick={ () => { handleUpdateBoxOpen()  ; handleviewindustry(value?._id); }}
                       
                       >
                              <TbPencil />
                              </button>
}
                   
                  
                     <button  className='table_edit_btn'
                  
                  onClick={ () => {handleViewBoxOpen() ; handleviewindustry(value?._id); }} 
                  
                  >
                         <AiOutlineEye />
                         </button>
                </td>
                {
                  usertype != "CP" &&
                <td>   <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={value?.active}
                      onChange={() => toggleStatus(value?._id)}
                    />
                 
                  </div></td>
}
          
              </tr>
              <br />
              </>
            ))}
          </tbody>
        </table>
      </div>


<div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} >   Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => handleItemsPerPageChange(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>





      {/* ADD CATEGORY BOX START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box" >
           
              <div className="model_header">
             
                <div className='d-flex align-items-center gap-3' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Add Child Part Industry </p>
            </div>
                <div className="d-flex align-items-center gap-3 ">
                
                  <button className="closebtn_cont" onClick={handleClose} >
                 
                    <RxCross2 className="closebox_logo" />{" "}
                  </button>
                </div>
              </div>
            
            <div className="inputbox_cont">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="" className="mb-1">Industry Name</label>
                  <input type="text" name="" id="" placeholder="Industry Name" className="add_inut_field" value={industryname} onChange={(e)=> setIndustryName(e.target.value)} />
                </div>
                </div>
              <div className="addbox_btn_cont" >
                <button onClick={() => handleaddindustry()} >Save</button>
               
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* ADD CATEGORY BOX EBD  */}

      {/* EDIT CATEGORY BOX START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={updateboxopen}
        onClose={handleUpdateBoxClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={updateboxopen}>
          <Box sx={styleForMediaQuery} className="model_box" >
           
              <div className="model_header">
              <div className="d-flex align-items-center gap-3">
              <button  className='table_edit_btn' >
                         <TbPencil />
                         </button>
                <p className="m-0" >Edit Child Part Industry</p>
                </div>
               
                <div className="d-flex align-items-center gap-3 ">
                {
                  usertype != "CP" &&
                <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={industryStatus == true}
                      onChange={handleSwitchChange}
                      
                    />
                  </div>  
}
                  <button className="closebtn_cont" onClick={handleUpdateBoxClose} >
                 
                    <RxCross2 className="closebox_logo" />{" "}
                  </button>
                </div>
              </div>
            
            <div className="inputbox_cont">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="" className="mb-1">Industry Name</label>
                  <input type="text" name="" id="" placeholder="Industry Name" className="add_inut_field" value={newindustryname} onChange={(e)=> setNewIndustryName(e.target.value)} />
                </div>
                </div>
              <div className="addbox_btn_cont" >
                <button onClick={() => handleupdateindustry(industryid)} >Save</button>
                <button onClick={handleUpdateBoxClose} >Back</button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* EDIT CATEGORY BOX END  */}
      
       {/* VIEW CATEGORY BOX START  */}
       <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewboxopen}
        onClose={handleUpdateBoxClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={viewboxopen}>
          <Box sx={styleForMediaQuery} className="model_box" >
           
              <div className="model_header">
                
                <div className="d-flex align-items-center gap-3">
              <button  className='table_edit_btn' >
                         <AiOutlineEye />
                         </button>
                <p className="m-0" >View Child Part Industry</p>
                </div>
                <div className="d-flex align-items-center gap-3 ">
                
                  <button className="closebtn_cont" onClick={handleViewBoxClose} >
                 
                    <RxCross2 className="closebox_logo" />{" "}
                  </button>
                </div>
              </div>
            
            <div className="inputbox_cont">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="" className="mb-1">Industry Name</label>
                  <input type="text" name="" id="" placeholder="Industry Name" className="add_inut_field" value={newindustryname} disabled />
                </div>
                </div>
              <div className="addbox_btn_cont" >
                <button onClick={handleViewBoxClose} >Back</button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* EDIT CATEGOTY BOX START  */}
    
    </div>
  );
}

export default IndustryTable;
