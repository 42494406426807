import React  , {useState , useEffect} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LuFilter } from "react-icons/lu";
import Select from "react-select";
import store from "../../../Redux/MyRedux/store";
import { OrderRecommandationMTOlistDropdown, OrderRecommandationlistDropdown } from "../../../Redux/MyRedux/action/userActions";
import "./filter.css";
import { AiOutlineClose } from "react-icons/ai";
import * as moment from 'moment'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
 import DateRangePicker from '@wojtekmaj/react-daterange-picker';


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "#F4F6FA",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
  pb: 3,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
};

export default function FilterModal({stateSetters , propsState , channelPartnerId}) {
  const [orderDropdown, setOrderDropdown] = useState([]);



  const [usertype, setUserType] = useState("");

  useEffect(()=>{
    setUserType(localStorage.getItem("usertype"));

  },[])

 

  const getOrderListDropDown = () => {
    const data = {
      channelPartnerId: channelPartnerId,
    };

    try {
      store.dispatch(OrderRecommandationMTOlistDropdown(data)).then((res) => {
        setOrderDropdown(res?.data);
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

 

 
  const uniqueorderIdLabels = [...new Set(orderDropdown.map((obj) => obj.uniqueNumber))];
  const uniqueOrderidLabelObjects = uniqueorderIdLabels.map((label) => ({
    value:  `PMS ${label}`,
    label:  `PMS ${label}`, 
  }));
  

  const uniquecpnameLabels = [...new Set(orderDropdown.map((obj) => obj.companyName))];
  const uniquecpnameLabelObjects = uniquecpnameLabels.map((label) => ({
    value: label,
    label: label 
  }));
  

 
  const uniqueExpectedDateLabels = [...new Set(orderDropdown.map((obj) => obj.expectedDeliveryDate))];
  const uniqueExpectewdDateLabelObjects = uniqueExpectedDateLabels.map((label) => ({
    value: moment(label).format("DD-MM-YYYY"),
    label: moment(label).format("DD-MM-YYYY"),
  }));



  const uniqueexpecteddaysLabels = [...new Set(orderDropdown.map((obj) => obj.expectedDeliveryDays))];
  const uniqueExpectedDaysLabelObjects = uniqueexpecteddaysLabels.map((label) => ({
    value: label,
    label: label 
  }));
  

  const uniqueOrderPlacementLabels = [...new Set(orderDropdown.map((obj) => obj.orderPlacementDate))];
  const uniqueOrderPlacementLabelObjects = uniqueOrderPlacementLabels.map((label) => ({
    value: moment(label).format("DD-MM-YYYY"),
    label: moment(label).format("DD-MM-YYYY"),
  }));

  const uniquestattusLabels = [...new Set(orderDropdown.map((obj) => obj.status))];
  const uniquestatusObjects = uniquestattusLabels.map((label) => ({
    value: label,
    label: label 
  }));
 
  const uniqueItemCodeLabels = [...new Set(orderDropdown.map((obj) => obj.itemCode))];
  const uniqueItemCodeObjects = uniqueItemCodeLabels.map((label) => ({
    value: label,
    label: label 
  }));



  const uniqueItemDescriptionLabels = [...new Set(orderDropdown.map((obj) => obj.itemDescription))];
    const uniqueItemDescriptionObjects = uniqueItemDescriptionLabels.map((label) => ({
      value: label,
      label: label 
    }));


 
  
  const uniqueProdcutNameLabels = [...new Set(orderDropdown.map((obj) => obj.productName))];
  const uniqueProductNameObjects = uniqueProdcutNameLabels.map((label) => ({
    value: label,
    label: label 
  }));











  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


    const clearFilter = () => {
      stateSetters.setFilterOrderId([]);
      stateSetters.setFilterCPName([]);
      stateSetters.setFilterExpectedDate([]);
      stateSetters.setFilterItemCode([]);
      stateSetters.setFilterItemDescription([]);
      stateSetters.setFilterProductName([]);
      stateSetters.setFilterExpectedDay([]);
      stateSetters.setFilterOrderPlacementDate([]);
      stateSetters.setFilterStatus([]);
      stateSetters.setUpdateAPI("253")
  
  };
  const [filterCpDateStartEnd, setFilterCpDateStartEnd] = useState(["", ""]);
  const handleSelectDateRange  = (e) => {
    console.log(e)
    setFilterCpDateStartEnd(e)
    stateSetters.setFilterCpDateStartEnd([{
      from:moment(e[0]).format("YYYY-MM-DD"),
      to:moment(e[1]).format("YYYY-MM-DD")
    }])
    
  }
   console.log(filterCpDateStartEnd , 'filterCpDateStartEnd filterCpDateStartEnd filterCpDateStartEndf')
  
  return (
    <div>
      <button
        className="filter_btn"
        type="button"
        onClick={() => {
          getOrderListDropDown();
          handleOpen();
        }}
      >
        <LuFilter />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <div className="filter_modal_header">
            <div className="d-flex justify-content-between ">
              <div className="modal_heading d-flex align-items-center gap-2">
                <button
                  className="filter_btn"
                  type="button"
                
                 
                >
                  <LuFilter />
                </button>{" "}
                <span> Filter </span>
              </div>
              <div className="close_modal_btn"   onClick={() => handleClose()}>
                <AiOutlineClose />
              </div>
            </div>
          </div>
          <div className="filter_select_container gap-2">
            
            
        <div className="row">
          <div className="form-group col-md-4">
              <Select
                options={uniqueOrderidLabelObjects}
                placeholder="Order Id"
                value={propsState.filterOrderId}
                onChange={(e) => stateSetters.setFilterOrderId(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>

            {usertype != "CP" && (
              <>
                <div className="form-group col-md-4">
                  <Select
                    options={uniquecpnameLabelObjects}
                    placeholder="CP Name"
                    value={propsState.filterCpName}
                    onChange={(e) => stateSetters.setFilterCPName(e)}
                    isSearchable={true}
                    className="react_inbuild_select"
                    isMulti
                  />{" "}
                </div>
              </>
            )}
            <div className="form-group col-md-4">
              <Select
                options={uniqueItemCodeObjects}
                placeholder="ItemCode"
                value={propsState.filterItemCode}
                onChange={(e) => stateSetters.setFilterItemCode(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>

            </div>
        <div className="row">

            <div className="form-group col-md-4">
              <Select
                options={uniqueItemDescriptionObjects}
                placeholder="Item Description"
                value={propsState.filterItemDescription}
                onChange={(e) => stateSetters.setFilterItemDescription(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-4">
              <Select
                options={uniqueProductNameObjects}
                placeholder="Product Name"
                value={propsState.filterProductName}
                onChange={(e) => stateSetters.setFilterProductName(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>

            <div className="form-group col-md-4">
              <Select
                options={uniqueExpectewdDateLabelObjects}
                placeholder="Expected Delivery Date"
                value={propsState.filterexpercteddate}
                onChange={(e) => stateSetters.setFilterExpectedDate(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            </div>
        <div className="row">

            <div className="form-group col-md-4">
              <Select
                options={uniqueExpectedDaysLabelObjects}
                placeholder="Expected Delivery days"
                value={propsState.filterExpecteDay}
                onChange={(e) => stateSetters.setFilterExpectedDay(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-4">
              <Select
                options={uniqueOrderPlacementLabelObjects}
                placeholder="Order placement Date"
                value={propsState.filterOrderPlacementDate}
                onChange={(e) => stateSetters.setFilterOrderPlacementDate(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>

            <div className="form-group col-md-4">
              <Select
                options={uniquestatusObjects}
                placeholder="Status"
                value={propsState.filterstatus}
                onChange={(e) => stateSetters.setFilterStatus(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
          
      </div>
     
              <div className="drawer-foot-btns ">
                <button className="btn clear_btn" onClick={() => clearFilter()}>
                  Clear
                </button>
                <div className="">
                  <button
                    className="btn blue-btn p-2 px-3"
                   onClick={() => {handleClose() ; stateSetters.setUpdateAPI('update')}}
                  >
                    Apply
                  </button>
                </div>
              </div>
            
          </div>
        </Box>
      </Modal>
    </div>
  );
}
