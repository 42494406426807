import React, { useEffect, useState } from "react";
import "../Userlist/table.css";
import "./table.css";
import "../Dashboard/MainTable/MainTable.css";
import Pagination from "react-mui-pagination";
import store from "../../Redux/MyRedux/store";
import {
  AddSubCategory,
  Categorylistdropdowm,
  Subcategorylist,
  UpdateSubCategory,
  UpdateSubCategoryStatus,
  Userlist,
  ViewSubCategory,
  updateSUbCategoryExcelSheet,
} from "../../Redux/MyRedux/action/userActions";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineEye } from "react-icons/ai";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegRectangleList } from "react-icons/fa6";
import { TbPencil } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";

function SubcategoryTable() {
  const [newcategoryname, setNewCategoryName] = useState("");
  const [newsubcategoryname, setNewSubCategoryName] = useState("");

  const initalvalueforadd = {
    subcategory: "",
  };

  const initalvalueforupdate = {
    subcategory: "",
  };
  const [newupdatesubcategoryname, setNewUpdateSubCategoryName] =
    useState(initalvalueforupdate);

  const validationSchema = Yup.object().shape({
    subcategory: Yup.string().required("Sub-Category Name is required"),
  });

  const onaddsubcategory = (values, { resetForm }) => {
    // Handle form submission here, e.g., send data to an API
    const data = {
      subcategoryName: values?.subcategory,
      categoryid: category?.value,
    };
    try {
      store.dispatch(AddSubCategory(data)).then((res) => {
        if (res?.isError === false) {
          handleClose();
          toast.success(res?.message);
          getdata();
          setCategory("");
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
    resetForm();
  };
  const onupdatesubcategory = (values, { resetForm }) => {
    // Handle form submission here, e.g., send data to an API
    const data = {
      subcategoryName: values?.subcategory,
      categoryid: newcategoryname?.value,
    };
    try {
      store.dispatch(UpdateSubCategory(data, subcategory_id)).then((res) => {
        if (res?.isError == false) {
          handleUpdateBoxClose();
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
    resetForm();
  };

  const [subcategory_id, setSubCategoryId] = useState("");

  const [category, setCategory] = useState("");
  const [categorydropdown, setCategorydropdown] = useState([]);
  const [Search, setSearch] = useState("");
  const [usertype, setUserType] = useState("");
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [listdata, setListdata] = useState([]);
  const [page, setPage] = React.useState(1);
  const [total_count, setTotalCount] = React.useState(0);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 480,
    bgcolor: "#F4F6FA",
    boxShadow: 24,
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };
  const handleChange = (event, value) => {
    setPage(value);
  };

  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      active: true,
    };

    try {
      store.dispatch(Subcategorylist(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data[0]?.docs.length > 0) {
            setListdata(res?.data[0]?.docs);
            setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          } else {
            setListdata([]);
            setTotalCount(0);
          }
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    setUserType(localStorage.getItem("usertype"));
    getdata();
  }, [recordperpage, page, Search]);

  const getcategorydropdownlist = () => {
    try {
      store.dispatch(Categorylistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setCategorydropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
  useEffect(() => {
    getcategorydropdownlist();
  }, []);

  const handleviewsubcategory = (id) => {
    const data = {
      id: id,
    };
    try {
      store.dispatch(ViewSubCategory(data)).then((res) => {
        if (res?.isError === false) {
          //  setNewCategoryName(res?.data?.categoryid)
          setNewSubCategoryName(res?.data?.subcategoryName);
          setNewCategoryName({
            value: res?.data?.categoryid,
            label: res?.data?.categoryName,
          });
          setNewUpdateSubCategoryName((prevState) => ({
            ...prevState,
            subcategory: res?.data?.subcategoryName,
          }));
        } else {
          setNewCategoryName("");
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [updateboxopen, setUpdateBoxOpen] = React.useState(false);
  const handleUpdateBoxOpen = () => setUpdateBoxOpen(true);
  const handleUpdateBoxClose = () => setUpdateBoxOpen(false);

  const [viewboxopen, setViewBoxOpen] = React.useState(false);
  const handleViewBoxOpen = () => setViewBoxOpen(true);
  const handleViewBoxClose = () => setViewBoxOpen(false);

  const optionlistcategory = categorydropdown?.map((res, i) => ({
    value: res?.value,
    label: res?.label,
  }));
  const handleSubCategoryStatusUpdate = (id, value) => {
    const data = {
      active: value,
      subcategoryid: id,
    };
    try {
      store.dispatch(UpdateSubCategoryStatus(data, id)).then((res) => {
        if (res?.isError == false) {
          //  handleUpdateBoxClose()
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handleSubCategoryStatusUpdate(userId, updatedStatus);
    }
  };

  const navigate = useNavigate();

  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = ["Sub-category Name", "Sub Category ID"];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [item?.subcategoryName, item?._id]),
    ];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "data.xlsx");
  };
  const handleDownloadEXcelTemplate = () => {
    // Custom headers
    const headers = ["Category Name", "Sub-category Name"];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [headers, []];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "Sub-category Template.xlsx");
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        // Parse the Excel file to JSON
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Define a mapping between old header names and new parameter names
        const headerMapping = {
          "Category Name": "categoryName",
          "Sub-category Name": "subcategoryName",
        };

        // Map the old header names to the new parameter names in the JSON data
        const mappedJsonData = jsonData.map((item) => {
          const mappedItem = {};
          for (const oldHeader in item) {
            if (oldHeader in headerMapping) {
              mappedItem[headerMapping[oldHeader]] = item[oldHeader];
            }
          }
          return mappedItem;
        });

        // setExcelJson(mappedJsonData);

        updateNewExcel(mappedJsonData);
      };
      reader.readAsBinaryString(file);
    }
  };
  const updateNewExcel = async (json) => {
    // Mark the function as async
    try {
      const res = await store.dispatch(updateSUbCategoryExcelSheet(json));

      if (res?.isError === false) {
        toast.success(res?.message);

        if (res?.data?.errorArrray.length > 0) {
          toast.error(
            ` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`
          );
          const headers = ["Category Name", "Sub-category Name", "Error"];

          // Map data to a new array with custom headers
          const dataWithCustomHeaders = [
            headers,
            ...res?.data?.errorArrray.map((item) => [
              item.categoryName,
              item.subcategoryName,
              item.error,
            ]),
          ];
          // Generate Excel file
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          // Save file
          const fileData = new Blob([excelBuffer], {
            type: "application/octet-stream",
          });
          saveAs(fileData, "Errordata.xlsx");
        } else {
          // Display an alert when errorArray is empty
          toast.success("No error data found. All data upload Successfully");
        }
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };
  const [isHovered, setIsHovered] = useState(false);
  const handleItemsPerPageChange = (value) => {
    setRecordPerPage(value);
    setPage(1); // Reset to page 1 whenever items per page changes
  };
  
  const calculateSerialNumber = (index) => {
    return (page - 1) * recordperpage + index + 1;
  };
  const startSerialNumber = calculateSerialNumber(0);
  const endSerialNumber = calculateSerialNumber(listdata.length - 1);
  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading "> Sub-Category</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="text"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <img
                  src="/assets/images/search-icon.svg"
                  alt=""
                  className="img-fluid"
                />
              </button>
            </div>
          </div>
        </form>
      
        <div className="d-flex gap-2 flex-wrap ms-1">
          {usertype != "CP" && (
            <div className="button-container">
              <button
                className={`button ${
                  isHovered ? "hovered" : ""
                } import_export_btns`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <span>Excel Import</span>
                <div className="pageheader_icons_container">
                  <RiAttachment2 className="import_attach_icon" />
                </div>
                <input
                  type="file"
                  accept=".xlsx"
                  onChange={handleFileUpload}
                  className="import_excel_input"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                />
              </button>
            </div>
          )}
          <div
            className="button-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <button
              className={`button ${isHovered ? "hovered" : ""}`}
              onClick={() => handleDownloadEXcel()}
            >
              <span>Excel Export</span>
              <div className="pageheader_icons_container">
                <MdOutlineCloudDownload className="pageheader_react_icons" />
              </div>
            </button>
          </div>
          {usertype != "CP" && (
            <div
              className="button-container"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <button
                className={`button ${isHovered ? "hovered" : ""}`}
                onClick={() => handleDownloadEXcelTemplate()}
              >
                <span>Import Template</span>
                <div className="pageheader_icons_container">
                  <TbFileDownload className="pageheader_react_icons" />
                </div>
              </button>
            </div>
          )}
          {usertype != "CP" && (
            <div
              className="button-container"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <button
                className={`button ${isHovered ? "hovered" : ""}`}
                onClick={() => {
                  handleOpen();
                }}
              >
                <span>Add Sub-Category </span>
                <div className="pageheader_icons_container">
                  <IoMdAddCircleOutline className="pageheader_react_icons" />
                </div>
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="page_header_slider_container">
        <div class="active-filters"></div>
        <div class="">
          <button
            className="inactive_page_btn"
            onClick={() => navigate("/inactivesubcategorylist")}
          >
            {" "}
            <FaRegRectangleList className="list_icons" />{" "}
            <span> Inactive Sub-Category List </span>{" "}
          </button>
        </div>
      </div>

      <div className="table-container">
        <table className="table">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">Sub-Category Name</td>
              <td scope="col">Action </td>
              {usertype != "CP" && <td scope="col">Status </td>}
            </tr>
            <br />
          </thead>
          <tbody>
            {listdata.map((value, i) => (
              <>
                <tr key={i}>
                <td>{calculateSerialNumber(i)}</td>


                  <td>{value?.subcategoryName}</td>

                  <td className="d-flex justify-content-center gap-1 action_td">
                    {usertype != "CP" && (
                      <button
                        className="table_edit_btn"
                        onClick={() => {
                          handleUpdateBoxOpen();
                          setSubCategoryId(value?._id);
                          handleviewsubcategory(value?._id);
                        }}
                      >
                        <TbPencil />
                      </button>
                    )}

                    <button
                      className="table_edit_btn"
                      onClick={() => {
                        handleViewBoxOpen();
                        handleviewsubcategory(value?._id);
                      }}
                    >
                      <AiOutlineEye />
                    </button>
                  </td>
                  {usertype != "CP" && (
                    <td>
                      {" "}
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input addbox_radio"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          checked={value?.active}
                          onChange={() => toggleStatus(value?._id)}
                        />
                      </div>
                    </td>
                  )}
                </tr>
                <br />
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
        <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results
          </p>
        </div>

        <div className="d-flex   justify-content-center align-items-center">
          <Pagination
            page={page}
            setPage={handleChange}
            total={total_count}
            className="pagination "
            perPage={recordperpage}
          />
        </div>
        <div className="d-flex align-items-center">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            Total Count
          </p>
          <select
            className="w-auto form-select form-select-sm p3 shadow-none mx-3 "
            style={{ fontFamily: "Poppins" }}
            value={recordperpage}
            onChange={(e) => handleItemsPerPageChange(e.target.value)}
          >
            <option value="5">5</option>

            {total_count > 5 && <option value="10">10</option>}
            {total_count > 10 && <option value="25">25</option>}
            {total_count > 25 && <option value="50">50</option>}
            {total_count > 50 && <option value="100">100</option>}
            {total_count > 100 && <option value="500">500</option>}
            {total_count > 500 && <option value="1000">1000</option>}
          </select>
        </div>
      </div>

      {/* ADD SUB-CATEGORY BOX OPEN START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex align-items-center gap-3">
                <button className="btn  blue-btn  px-1 py-0 fs-6">
                  <AiOutlinePlus className="mb-1" />
                </button>
                <p className="m-0">Add Sub-Category </p>
              </div>
              <div className="d-flex align-items-center gap-3 ">
                <button className="closebtn_cont" onClick={handleClose}>
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <Formik
                initialValues={initalvalueforadd}
                validationSchema={validationSchema}
                onSubmit={onaddsubcategory}
              >
                {(formik) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="" className="mb-1">
                          Category Name
                        </label>
                        <Select
                          options={optionlistcategory}
                          placeholder="Select"
                          value={category}
                          onChange={setCategory}
                          isSearchable={true}
                          className="react_inbuild_select"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-md-12">
                        <label htmlFor="subcategory" className="mb-1">
                          {" "}
                          Sub-Category Name*
                        </label>
                        <Field
                          type="text"
                          name="subcategory"
                          id="subcategory"
                          placeholder="Enter Sub-Category Name"
                          className="add_inut_field "
                        />
                        <ErrorMessage
                          name="subcategory"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="addbox_btn_cont mt-3">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* ADD SUB-CATEGORY BOX OPEN  END */}
      {/* UPDATE SUB-CATEGORY BOX OPEN START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={updateboxopen}
        onClose={handleUpdateBoxClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={updateboxopen}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex align-items-center gap-3">
                <button className="table_edit_btn">
                  <TbPencil />
                </button>
                <p className="m-0">Edit Sub-Category </p>
              </div>
              <div className="d-flex align-items-center gap-3 ">
                <button
                  className="closebtn_cont"
                  onClick={handleUpdateBoxClose}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>
            <div className="inputbox_cont">
              <Formik
                initialValues={newupdatesubcategoryname}
                validationSchema={validationSchema}
                onSubmit={onupdatesubcategory}
              >
                {(formik) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <label htmlFor="" className="mb-1">
                          Category Name
                        </label>
                        <Select
                          options={optionlistcategory}
                          placeholder="Select"
                          value={newcategoryname}
                          onChange={setNewCategoryName}
                          isSearchable={true}
                          className="react_inbuild_select"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div className="col-md-12">
                        <label htmlFor="subcategory" className="mb-1">
                          {" "}
                          Sub-Category Name*
                        </label>
                        <Field
                          type="text"
                          name="subcategory"
                          id="subcategory"
                          placeholder="Enter Sub-Category Name"
                          className="add_inut_field "
                        />
                        <ErrorMessage
                          name="subcategory"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="addbox_btn_cont mt-3">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* UPDATE SUB-CATEGORY BOX OPEN  END */}

      {/* View SUB-CATEGORY BOX OPEN START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={viewboxopen}
        onClose={handleViewBoxClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={viewboxopen}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex align-items-center gap-3 ">
                <button className="table_edit_btn">
                  <AiOutlineEye />
                </button>
                <p className="m-0"> View Sub-Category </p>
              </div>
              <div className="d-flex align-items-center gap-3 ">
                <button className="closebtn_cont" onClick={handleViewBoxClose}>
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="" className="mb-1">
                    Category Name
                  </label>

                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Sub-Category Name"
                    className="add_inut_field"
                    value={newcategoryname?.label}
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="" className="mb-1">
                    Sub-Category Name
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Sub-Category Name"
                    className="add_inut_field"
                    value={newsubcategoryname}
                    disabled
                  />
                </div>
              </div>

              <div className="addbox_btn_cont">
                {/* <button onClick={() => handladdnewsubcategory()} >Save</button> */}
                <button onClick={handleViewBoxClose}>Back</button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* VIEW SUB-CATEGORY BOX   END */}
    </div>
  );
}

export default SubcategoryTable;
