import { API_URL } from "./userActions"

    // API FOR LOGIN USERS
    export const loginuser  = (data ,id) => dispatch  => {
        return  fetch(`${API_URL}/api/user/auth/login
        `, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${token}`,
            },
            body:JSON.stringify(data)
        }).then(res => {
            
            return res.json()
        })
        }