import React, { useState } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import MainTable from './MainTable/MainTable'
import Header from '../../Header/Header'
function RotexInventory() {
  const [sidebaropen , setSidebarOpen] = useState(false)
  return (
 <div className='d-flex'>
            <Sidebar setSidebarOpen={setSidebarOpen} />
            <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
            <Header  />
            <MainTable />
            </div> 
        </div>
  )
}

export default RotexInventory;
