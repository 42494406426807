import React, { useState } from "react";
import Header from "../../Header/Header";
import Sidebar from "../../Sidebar/Sidebar";
import InactiveIndustryListTable from "./inactivetable";

const InactiveIndustryList = () =>{
  const [sidebaropen , setSidebarOpen] = useState(false)
    
    return (
        <>
        {/* <section>
        <Header />
        <div id='wrapper'>
            <Sidebar />
            <div id="content-wrapper" className="d-flex flex-column">
                <InactiveIndustryListTable />
            </div>
        </div>
    </section> */}
    <div className='d-flex'>
     <Sidebar setSidebarOpen={setSidebarOpen} />
     <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
     <Header  />
     <InactiveIndustryListTable />
     </div>
     </div>
        </>
    )
}
export default InactiveIndustryList ;