import React , { useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import store from "../../../../Redux/MyRedux/store";
import { AiOutlinePlus } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {  addSpMaster } from "../../../../Redux/MyRedux/action/phase3";
import * as Yup from "yup";
import { toast } from "react-toastify";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#F4F6FA",
    boxShadow: 24,
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

export default function AddModal({setUpdateAPI }) {
  


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [isHovered , setIsHovered] = useState(false)
 
 





  const initialvalueforadd = {
    cpname: "",
    contactperson: "",
    city: "",
    state: "",
    address: "",
    phone: "",
    email: "",
    // leadtime: "",
  };

  const validationSchema = Yup.object().shape({
    cpname: Yup.string().required("Supplier is required"),
    contactperson: Yup.string().required("Contact Person is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    address: Yup.string().required("Address is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone must be exactly 10 digits")
      .required("Phone is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
    // leadtime: Yup.number()
    //   .typeError("Lead Time must be a number")
    //   .required("Lead Time is required")
    //   .min(0, "Lead Time must be at least 0")
    //   .integer("Lead Time must be an integer"),
  });
  const onSubmit = (values, { resetForm }) => {
    // Handle form submission here, e.g., send data to an API
    const data = {
        supplierName: values.cpname,
        contactPersonName: values.contactperson,
        phone: values.phone,
        email: values.email,
        address: values.address,
        city: values.city,
        state: values.state,
    //   leadTime: values.leadtime,
    };
    try {
      store.dispatch(addSpMaster(data)).then((res) => {
        if (res?.isError == false) {
          handleClose();
          toast.success(res?.message);
          resetForm();
          setUpdateAPI('add')

        } else {
          toast.error(res?.error);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
    // Reset the form after submission
  };
  return (
    <div>
       <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
           onClick={() => {
            handleOpen();
          
          }} 
      >
        <span>Add Supplier</span>
        <div className='pageheader_icons_container' >
        <IoMdAddCircleOutline className='pageheader_react_icons' />
        </div>
      </button>
    </div>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex align-items-center gap-3">
                <button className="btn  blue-btn  px-1 py-0 fs-6">
                  <AiOutlinePlus className="mb-1" />
                </button>
                <p className="m-0"> Add Supplier </p>
              </div>
              <div className="d-flex align-items-center gap-3 ">
                <button className="closebtn_cont" onClick={handleClose}>
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <Formik
                initialValues={initialvalueforadd}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="cpname" className="mb-1">
                         Supplier Name*
                        </label>
                        <Field
                          type="text"
                          name="cpname"
                          id="cpname"
                          placeholder="Enter Supplier Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="cpname"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="contactperson" className="mb-1">
                          Contact Person*
                        </label>
                        <Field
                          type="text"
                          name="contactperson"
                          id="contactperson"
                          placeholder="Enter Contact Person Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="contactperson"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="city" className="mb-1">
                          City*
                        </label>
                        <Field
                          type="text"
                          name="city"
                          id="city"
                          placeholder="Enter City"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="city"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="state" className="mb-1">
                          State*
                        </label>
                        <Field
                          type="text"
                          name="state"
                          id="state"
                          placeholder="Enter State"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="state"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="address" className="mb-1">
                          Address*
                        </label>
                        <Field
                          type="text"
                          name="address"
                          id="address"
                          placeholder="Enter Address"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="address"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone" className="mb-1">
                          Phone*
                        </label>
                        <Field
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Enter Phone"
                          className="add_inut_field lead_time_field"
                        />
                        <ErrorMessage
                          name="phone"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="email" className="mb-1">
                          Email*
                        </label>
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Enter Email"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="email"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="leadtime" className="mb-1">
                          Lead Time (In Weeks)*
                        </label>
                        <Field
                          type="number"
                          name="leadtime"
                          id="leadtime"
                          placeholder="Enter Lead Time"
                          className="add_inut_field lead_time_field"
                        />
                        <ErrorMessage
                          name="leadtime"
                          component="p"
                          className=" error-message"
                        />
                      </div> */}
                    </div>
                    <div className="addbox_btn_cont mt-2 ">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
