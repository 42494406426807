import './App.css';
import Dashboard from './components/Dashboard/Dashboard';
import { BrowserRouter , Routes , Route, useNavigate } from "react-router-dom";
import CustomMaster from './components/Custome Master/list';
import UserList from './components/Userlist/list';
import SubCategoryList from './components/SubCategory/list';
import CategoryList from './components/Category/list';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import ProductMaster from './components/Product Master/list';
import ConsumptionMaster from './components/Consumption  Master/list';
import UnitList from './components/Units/list';
import IndustryList from './components/Industry/list';
import LoginPage from './components/Login /login';
import { useEffect , useHistory, useState } from 'react';
import OrderList from './components/Order Recommandation/list';
import InactiveConsumptionMaster from './components/Consumption  Master/Inactivedata/inactivelist';
import InactiveDashboard from './components/Dashboard/Inactivelist /list';
import CPInactiveList from './components/Custome Master/InactiveList/list';
import InactiveCustomtable from './components/Custome Master/InactiveList/inactibetable';
import UserInactiveList from './components/Userlist/InactiveList/list';
import InactiveUnitList from './components/Units/InactiveUnit/list';
import InactiveIndustryList from './components/Industry/InactiveUnit/list';
import InactiveSubCategoryList from './components/SubCategory/Inactivelist/list';
import InactiveCategoryList from './components/Category/InactiveList/list';
import InactiveProductMaster from './components/Product Master/InactiveList/list';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { createBrowserHistory } from 'history';
import OrderListMTO from './components/MTO/list';
import CPInventoryList from './components/Custome Master/Inventory/Active Inventory/list';
import CPInactiveInventoryList from './components/Custome Master/Inventory/Inactive Inventory/list';
import ViewCPLeadTime from './components/MTO/ViewLeadTime/list';
import SupplierMaster from './components/SupplierMaster/SupplierMaster/list';
import SPInactiveList from './components/SupplierMaster/SupplierMaster/InactiveList/list';
import ChildPartMaster from './components/SupplierMaster/ChilPartMasters/chilspartmaster/list';
import ChildPartCategoryList from './components/SupplierMaster/ChilPartMasters/childpartcategory/list';
import InactiveChildCategoryList from './components/SupplierMaster/ChilPartMasters/childpartcategory/InactiveList/list';
import ChildSubCategoryList from './components/SupplierMaster/ChilPartMasters/childsubcategory/list';
import InactiveChildSubCategoryList from './components/SupplierMaster/ChilPartMasters/childsubcategory/Inactivelist/list';
import ChildUnitList from './components/SupplierMaster/ChilPartMasters/childunit/list';
import InactiveChildUnitList from './components/SupplierMaster/ChilPartMasters/childunit/InactiveUnit/list';
import InactiveChildIndustryList from './components/SupplierMaster/ChilPartMasters/childindustry/InactiveUnit/list';
import ChildIndustryList from './components/SupplierMaster/ChilPartMasters/childindustry/list';
import InactiveChildMaster from './components/SupplierMaster/ChilPartMasters/chilspartmaster/InactiveList/list';
import RotexInventory from './components/phase3/Rotexinventory/Dashboard';
import InactiveRotexInventory from './components/phase3/Rotexinventory/Inactivelist /list';
import RotexOrderList from './components/phase3/Rotexorder/list';
import SpFgInventory from './components/SupplierMaster/SupplierMaster/FGInventory/Active Inventory/list';
import SpInactiveInventory from './components/SupplierMaster/SupplierMaster/FGInventory/Inactive Inventory/list';
import SpOrderList from './components/SupplierMaster/SupplierMaster/ProductionRecommendation/list';
import RotexConsumptionMaster from './components/phase3/RotexConsumption/list';
import InactiveRotexConsumptionMaster from './components/phase3/RotexConsumption/Inactivedata/inactivelist';
import SPConsumptionMaster from './components/SupplierMaster/SpConsumptionList/list';
import InactiveSPConsumptionMaster from './components/SupplierMaster/SpConsumptionList/Inactivedata/inactivelist';
const customHistory = createBrowserHistory();

function App() {
  const navigate = useNavigate();



  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
     }
  }, [navigate]);
  

  return (
    <div className="App">
      <Routes>
      <Route    exact path='/' element={  <LoginPage />}/>
        <Route exact  path='/dashboard' element={  <Dashboard />}/>
        <Route exact  path='/inactiveinventory' element={  <InactiveDashboard />}/>
        <Route exact  path='/custommaster' element={  <CustomMaster />}/>
        <Route exact  path='/inactivecustommaster' element={  < CPInactiveList />}/>
        <Route exact  path='/userlist' element={  <UserList />}/>
        <Route exact  path='/subcategorylist' element={  <SubCategoryList />}/>
        <Route exact  path='/categorylist' element={  <CategoryList />}/>
        <Route exact  path='/productmasterlist' element={  <ProductMaster />}/>
        <Route exact  path='/consumptionlist' element={  <ConsumptionMaster />}/>
        <Route exact  path='/inactiveconsumptionlist' element={  <InactiveConsumptionMaster />}/>
        <Route exact  path='/unitlist' element={  <UnitList />}/>
        <Route exact  path='/inactiveuserlist' element={  <UserInactiveList />}/>
        <Route exact  path='/industrylist' element={  <IndustryList />}/>
        <Route exact  path='/inventorylist/:cpid' element={  <CPInventoryList />}/>
        <Route exact  path='/Inactiveinventorylist/:cpid' element={  <CPInactiveInventoryList />}/>

        <Route exact  path='/orderrecommandation' element={  <OrderList />}/>
        <Route exact  path='/inactiveunitlist' element={  <InactiveUnitList />}/>
        <Route exact  path='/inactiveindustrylist' element={  <InactiveIndustryList />}/>
        <Route exact  path='/inactivesubcategorylist' element={  <InactiveSubCategoryList />}/>
        <Route exact  path='/inactivecategorylist' element={  <InactiveCategoryList />}/>
        <Route exact  path='/inactiveproductmaster' element={  <InactiveProductMaster />}/>
        <Route exact  path='/orderlistmto' element={  <OrderListMTO />}/>

        <Route exact  path='/suppliermaster' element={  <SupplierMaster />}/>
        <Route exact  path='/suppliermaster/inactive' element={  <SPInactiveList />}/>
        <Route exact  path='/suppliermaster/fginventory/:id' element={  <SpFgInventory />}/>
        <Route exact  path='/suppliermaster/fginventory/inactive/:id' element={  <SpInactiveInventory />}/>

        <Route exact  path='/suppliermaster/productionrecommendation/:id' element={  <SpOrderList />}/>




        <Route exact  path='/childpartmaster' element={  <ChildPartMaster />}/>
        <Route exact  path='/inactivechildpartmaster' element={  <InactiveChildMaster />}/>


        <Route exact  path='/childpartmaster/category' element={  <ChildPartCategoryList />}/>
        <Route exact  path='/childpartmaster/inactivecategory' element={  <InactiveChildCategoryList />}/>

        <Route exact  path='/childpartmaster/subcategory' element={  <ChildSubCategoryList />}/>
        <Route exact  path='/childpartmaster/inactivesubcategory' element={  <InactiveChildSubCategoryList />}/>


        <Route exact  path='/childpartmaster/units' element={  <ChildUnitList />}/>
        <Route exact  path='/childpartmaster/inactiveunits' element={  <InactiveChildUnitList />}/>

        <Route exact  path='/childpartmaster/industries' element={  <ChildIndustryList />}/>
        <Route exact  path='/childpartmaster/inactiveindustries' element={  <InactiveChildIndustryList />}/>

        <Route exact  path='/rotex/inventory' element={  <RotexInventory />}/>
        <Route exact  path='/rotex/inventory/inactive' element={  <InactiveRotexInventory />}/>

        <Route exact  path='/rotex/order' element={  <RotexOrderList />}/>


        <Route exact  path='/rotex/consumption' element={  <RotexConsumptionMaster />}/>
        <Route exact  path='/rotex/consumption/inactive' element={  <InactiveRotexConsumptionMaster />}/>




        <Route exact  path='/supplier/consumption' element={  <SPConsumptionMaster />}/>
        <Route exact  path='/supplier/consumption/inactive' element={  <InactiveSPConsumptionMaster />}/>





        <Route exact  path='/CP/ViewLeadtime' element={  <ViewCPLeadTime />}/>


</Routes>
      <ToastContainer />   
    </div>
  );
}

export default App;
