import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LuFilter } from "react-icons/lu";
import Select from "react-select";
import store from "../../../Redux/MyRedux/store";
import { productmasterlistdropdowm } from "../../../Redux/MyRedux/action/userActions";
import { AiOutlineClose } from "react-icons/ai";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "#F4F6FA",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",

  pb: 3,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
};

export default function FilterModal({stateSetters ,propsState}) {
    const [productlistdropdown, setProductListDropdown] = React.useState([]);


  const getproductdropdownlist = () => {
    try {
      store.dispatch(productmasterlistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setProductListDropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

 

  

  const uniqueitemcodeLabels = [...new Set(productlistdropdown.map(obj => obj.itemCode))];
  const uniqueitemcodeLabelObjects = uniqueitemcodeLabels.map(label => ({
         value: label,
         label: label, // Convert to string if necessary
       }));


  const uniqueproductnameLabels = [...new Set(productlistdropdown.map(obj => obj.productName))];
  const uniqueproductnameLabelObjects = uniqueproductnameLabels.map(label =>({
    value: label,
    label: label, // Convert to string if necessary
  }));


  const uniquecategoryLabels = [...new Set(productlistdropdown.map(obj => obj.categoryName))];
  const uniquecategoryLabelObjects = uniquecategoryLabels.map(label => ({
    value: label,
    label: label, // Convert to string if necessary
  }));



  const uniquesubcategoryLabels = [...new Set(productlistdropdown.map(obj => obj.subcategoryName))];
  const uniquesubcategoryLabelObjects = uniquesubcategoryLabels.map(label => ({
    value: label,
    label: label, // Convert to string if necessary
  }));


  const uniquemoqLabels = [...new Set(productlistdropdown.map(obj => obj.moq))];
  const uniquemoqLabelObjects = uniquemoqLabels.map(label => ({
    value: label,
    label: label, // Convert to string if necessary
  }));

  const uniqueunitLabels = [...new Set(productlistdropdown.map(obj => obj.unit))];
  const uniqueunitLabelObjects = uniqueunitLabels.map(label => ({
    value: label,
    label: label, // Convert to string if necessary
  }));

  const uniqueListindustryLabels = [...new Set(productlistdropdown.map(obj => obj.industry))];
  const uniqueListindustryLabelObjects = uniqueListindustryLabels.map(label => ({
    value: label,
    label: label, // Convert to string if necessary
  }));



  const uniqueFOSLabels = [...new Set(productlistdropdown.map(obj => obj.factorOfSafety))];
  const uniqueFOSLabelObjects = uniqueFOSLabels.map(label => ({
    value: label,
    label: label, // Convert to string if necessary
  }));



  const uniqueitemDescLabels = [...new Set(productlistdropdown.map(obj => obj.itemDescription))];
  const uniqueitemDescLabelObjects = uniqueitemDescLabels.map(label =>  ({
    value: label,
    label: label, // Convert to string if necessary
  }));

 

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const clearFilter = () => {
    stateSetters.setFilterItemCode([]);
    stateSetters.setFilterItemDesc([]);
    stateSetters.setFilterProductName([]);
    stateSetters.setFilterCategory([]);
    stateSetters.setFilterSubCategory([]);
    stateSetters.setFilterMOQ([]);
    stateSetters.setFilterFactorySafety([]);
    stateSetters.setFilterUnit([]);
    stateSetters.setFilterIndustry([]);
    stateSetters.setUpdateAPI('clear');

 
  };

  

  return (
    <div>
      <button
        className="filter_btn"
        type="button"
        onClick={() => {
            getproductdropdownlist();
          handleOpen();
        }}
      >
        <LuFilter />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <div className="filter_modal_header">
            <div className="d-flex justify-content-between ">
              <div className="modal_heading d-flex align-items-center gap-2">
                <button className="filter_btn" type="button">
                  <LuFilter />
                </button>{" "}
                <span> Filter </span>
              </div>
              <div className="close_modal_btn" onClick={() => handleClose()}>
                <AiOutlineClose />
              </div>
            </div>
          </div>
          <div className="filter_select_container">
            <div className="">
              <div className="filter_select_container ">
                <div className="row">

            <div className="form-group col-md-4">
         
              <Select
                options={uniqueitemcodeLabelObjects}
                placeholder="Item Code"
                value={propsState.filterItemCode}
                onChange={(e) => stateSetters.setFilterItemCode(e) }
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>
       
            <div className="form-group col-md-4">
              <Select
                options={uniqueproductnameLabelObjects}
                placeholder="Product Name"
                value={propsState.filterProductName}
                onChange={(e) => stateSetters.setFilterProductName(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
          
            <div className="form-group col-md-4">
              <Select
                options={uniquecategoryLabelObjects}
                placeholder="Category"
                value={propsState.filterCategory}
                onChange={(e) => stateSetters.setFilterCategory(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>
            </div>
            <div className="row">

            <div className="form-group col-md-4">
              <Select
                options={uniquesubcategoryLabelObjects}
                placeholder="Sub-Category "
                value={propsState.filterSubCategory}
                onChange={(e) => stateSetters.setFilterSubCategory(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-4">
              <Select
                options={uniquemoqLabelObjects}
                placeholder="MOQ"
                value={propsState.filterMoq}
                onChange={(e) => stateSetters.setFilterMOQ(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />
            </div>

            <div className="form-group col-md-4">
              <Select
                options={uniqueFOSLabelObjects}
                placeholder="Factor of Safety "
                value={propsState.filterfacorysafety}
                onChange={(e) => stateSetters.setFilterFactorySafety(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            </div>
            <div className="row">
            <div className="form-group col-md-4">
              <Select
                options={uniqueunitLabelObjects}
                placeholder="Unit"
                value={propsState.filterUnit}
                onChange={(e) => stateSetters.setFilterUnit(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-4">
              <Select
                options={uniqueListindustryLabelObjects}
                placeholder="Industries Application"
                value={propsState.filterIndustry}
                onChange={(e) => stateSetters.setFilterIndustry(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            <div className="form-group col-md-4">
              <Select
                options={uniqueitemDescLabelObjects}
                placeholder="Item Description"
                value={propsState.filterItemdesc}
                onChange={(e) => stateSetters.setFilterItemDesc(e)}
                isSearchable={true}
                className="react_inbuild_select"
                isMulti
              />{" "}
            </div>
            </div>
      
                
              </div>
              <div className="drawer-foot-btns mt-5">
                <button className="btn clear_btn" onClick={() => clearFilter()}>
                  Clear
                </button>
                <div className="">
                  <button
                    className="btn blue-btn p-2 px-3"
                    onClick={() => {
                      handleClose();
                      stateSetters.setUpdateAPI('update')
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
