import React, { useState } from 'react'
import ViewCPLeadTimeTable from './table';
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';
function ViewCPLeadTime() {
  const [sidebaropen , setSidebarOpen] = useState(false)

  return (
   
    <div className='d-flex'>
    <Sidebar setSidebarOpen={setSidebarOpen} />
    <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
    <Header  />
    <ViewCPLeadTimeTable />
    </div> 
    </div>
  )
}

export default ViewCPLeadTime;
