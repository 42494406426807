import React, { useState } from 'react'
import InactiveConsumptionMasterTable from './Inactivelisttable';
import Header from '../../../Header/Header';
import Sidebar from '../../../Sidebar/Sidebar';

function InactiveSPConsumptionMaster() {
  const [sidebaropen , setSidebarOpen] = useState(false)

  return (
   
     <div className='d-flex'>
     <Sidebar setSidebarOpen={setSidebarOpen} />
     <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
     <Header  />
     <InactiveConsumptionMasterTable />
     </div>
     </div>
  )
}

export default InactiveSPConsumptionMaster;
