import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LuFilter } from "react-icons/lu";
import Select from "react-select";
import store from "../../../Redux/MyRedux/store";
import { ConsumptionlistDropdown } from "../../../Redux/MyRedux/action/userActions";
// import "./filter.css";
import { AiOutlineClose } from "react-icons/ai";
import * as moment from "moment";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "#F4F6FA",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",

  pb: 3,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
};

export default function FilterModal({statesetter  , propsState}) {
  const [consumptiondeopdown, setConsumptionDropDown] = React.useState([]);

  const getConsumptionListDropDown = () => {
    try {
      store.dispatch(ConsumptionlistDropdown()).then((res) => {
        if (res?.isError === false) {
          setConsumptionDropDown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

 

  const uniquecpnameLabels = [
    ...new Set(consumptiondeopdown.map((obj) => obj.cpName)),
  ];
  const uniquecpnameLabelObjects = uniquecpnameLabels.map((label) => ({
    value: label,
    label: label,
  }));



  const uniqueitemcodeLabels = [
    ...new Set(consumptiondeopdown.map((obj) => obj.itemCode)),
  ];
  const uniqueitemcodeLabelObjects = uniqueitemcodeLabels.map((label) => ({
    value: label,
    label: label,
  }));



  const uniqueProductNameLabels = [
    ...new Set(consumptiondeopdown.map((obj) => obj.productName)),
  ];
  const uniqueProductNameLabelObjects = uniqueProductNameLabels.map((label) => ({
    value: label,
    label: label,
  }));





  const uniqueCategoryLabels = [
    ...new Set(consumptiondeopdown.map((obj) => obj.categoryName)),
  ];
  const uniqueCategoryLabelObjects = uniqueCategoryLabels.map((label) => ({
    value: label,
    label: label,
  }));



  const uniqueSubCatrgoryLabels = [
    ...new Set(consumptiondeopdown.map((obj) => obj.subCategoryName)),
  ];
  const uniqueSubCatrgoryLabelObjects = uniqueSubCatrgoryLabels.map((label) => ({
    value: label,
    label: label,
  }));

  const uniqueQTYLabels = [...new Set(consumptiondeopdown.map((obj) => obj.qty))];
  const uniqueQTYLabelObjects = uniqueQTYLabels.map((label) =>  ({
    value: label,
    label: label,
  }));




  const uniquedateLabels = [...new Set(consumptiondeopdown.map((obj) => obj.date))];
  const uniquedateLabelObjects = uniquedateLabels.map((label) => ({
    value:  moment(label).format("DD-MM-YYYY"),
    label:  moment(label).format("DD-MM-YYYY"),
  }));

  const uniquedescriptionLabels = [
    ...new Set(consumptiondeopdown.map((obj) => obj.itemDescription)),
  ];
  const uniquedescriptionLabelObjects = uniquedescriptionLabels.map((label) =>  ({
    value: label,
    label: label,
  }));

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


 

  const clearfilter = () => {
    statesetter.setFilterCpName([]);
    statesetter.setFilteItemCode([]);
    statesetter.setFilterProsuctName([]);
    statesetter.setFilterCategory([]);
    statesetter.setFilterSubCategory([]);
    statesetter.setFilterQTY([]);
    statesetter.setFilterDate([]);
    statesetter.setFilterDescription([]);
    statesetter.setUpdateAPI('0101')
  };

  return (
    <div>
      <button
        className="filter_btn"
        type="button"
        onClick={() => {
          getConsumptionListDropDown();
          handleOpen();
        }}
      >
        <LuFilter />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <div className="filter_modal_header">
            <div className="d-flex justify-content-between ">
              <div className="modal_heading d-flex align-items-center gap-2">
                <button className="filter_btn" type="button">
                  <LuFilter />
                </button>{" "}
                <span> Filter </span>
              </div>
              <div className="close_modal_btn" onClick={() => handleClose()}>
                <AiOutlineClose />
              </div>
            </div>
          </div>
          <div className="filter_select_container">
            <div className="">
              <div className="filter_select_container ">
               
                 

                    
                        <div className="row">
                      <div className="form-group col-md-4">
                        <Select
                          options={uniquecpnameLabelObjects}
                          placeholder="CP Name"
                          value={propsState.filtercpname}
                          onChange={(e) => statesetter.setFilterCpName(e)}
                          isSearchable={true}
                          className="react_inbuild_select"
                          isMulti
                        />{" "}
                      </div>
                      <div className="form-group col-md-4">
                        <Select
                          options={uniqueitemcodeLabelObjects}
                          placeholder="Item Code"
                          value={propsState.ilterItemCode}
                          onChange={(e) => statesetter.setFilteItemCode(e)}
                          isSearchable={true}
                          className="react_inbuild_select"
                          isMulti
                        />{" "}
                      </div>
                      <div className="form-group col-md-4">
                      
                        <Select
                          options={uniqueProductNameLabelObjects}
                          placeholder="Product Name"
                          value={propsState.filterProductName}
                          onChange={(e) => statesetter.setFilterProsuctName(e)}
                          isSearchable={true}
                          className="react_inbuild_select"
                          isMulti
                        />{" "}
                      </div>
                      </div>
                      <div className="row">
                      <div className="form-group col-md-4">
                      
                        <Select
                          options={uniquedescriptionLabelObjects}
                          placeholder="Item Description"
                          value={propsState.filterdescription}
                          onChange={(e) => statesetter.setFilterDescription(e)}
                          isSearchable={true}
                          className="react_inbuild_select"
                          isMulti
                        />{" "}
                      </div>
                      
                      <div className="form-group col-md-4">
                     
                        <Select
                          options={uniqueCategoryLabelObjects}
                          placeholder="Category"
                          value={propsState.filterCategory}
                          onChange={(e) =>statesetter.setFilterCategory(e)}
                          isSearchable={true}
                          className="react_inbuild_select"
                          isMulti
                        />{" "}
                      </div>
                      <div className="form-group col-md-4">
                       
                        <Select
                          options={uniqueSubCatrgoryLabelObjects}
                          placeholder="Sub-Category"
                          value={propsState.filterSubCategory}
                          onChange={(e) =>  statesetter.setFilterSubCategory(e) }
                          isSearchable={true}
                          className="react_inbuild_select"
                          isMulti
                        />{" "}
                      </div>
                      </div>
                      <div className="row">
                      <div className="form-group col-md-4">
                      
                        <Select
                          options={uniqueQTYLabelObjects}
                          placeholder="QTY"
                          value={propsState.filterqty}
                          onChange={(e) => statesetter.setFilterQTY(e)}
                          isSearchable={true}
                          className="react_inbuild_select"
                          isMulti
                        />{" "}
                      </div>
                      <div className="form-group col-md-4">
                      
                        <Select
                          options={uniquedateLabelObjects}
                          placeholder="Date"
                          value={propsState.filterdate}
                          onChange={(e) => statesetter.setFilterDate(e)}
                          isSearchable={true}
                          className="react_inbuild_select"
                          isMulti
                        />{" "}
                      </div>
                      </div>
                   
                
                
              </div>
              <div className="drawer-foot-btns ">
                <button className="btn clear_btn" onClick={() => clearfilter()}>
                  Clear
                </button>
                <div className="">
                  <button
                    className="btn blue-btn p-2 px-3"
    
    onClick={() => {handleClose() ;
      statesetter.setUpdateAPI('4556')
    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
