import React, { useState } from 'react'
import CustomTable from './table'
import Header from '../../Header/Header';
import Sidebar from '../../Sidebar/Sidebar';

function SupplierMaster() {
  const [sidebaropen , setSidebarOpen] = useState(false)
  return (
    
    <div className='d-flex'>  
    <Sidebar setSidebarOpen={setSidebarOpen} />
    <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
    <Header  />
    <CustomTable />
    </div> 
</div>
  ) 
}

export default SupplierMaster;
