import React, { useState, useEffect } from "react";
import "../Dashboard/MainTable/MainTable.css";
import store from "../../Redux/MyRedux/store";
import {
  Addproductmaster,
  Categorylistdropdowm,
  Industrylistdropdowm,
  Productmasterlist,
  Subcategorylistdropdowm,
  Unitlistdropdowm,
  UpdateProductMasterStatus,
  Updateproductmaster,
  Viewproductmaster,
  productmasterlistdropdowm,
  updateProductExcelSheet,
} from "../../Redux/MyRedux/action/userActions";
import Pagination from "react-mui-pagination";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { IoMdAdd } from "react-icons/io";
import { toast, useToast } from "react-toastify";
import { LuSearch } from "react-icons/lu";
import { FaFilter } from "react-icons/fa";
import "./table.css";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Select from "react-select";
import { FaSort } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {FiDownload} from "react-icons/fi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Slider from "react-slick";
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegRectangleList } from "react-icons/fa6";
import { TbPencil } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";
import sort_logo_img from '../../Assets/table/shortarrow.png'
import FilterModal from "./filterpopup/filter";


function ProductMasterTable() {
  const [page, setPage] = React.useState(1);
  const [product_id, setProductId] = React.useState(1);
  const [categorydropdown, setCategorydropdown] = useState([]);
  const [subcategorydropdown, setSubCategorydropdown] = useState([]);
  const [unitdropdown, setUnitdropdown] = useState([]);
  const [indutrydropdown, setIndustrydropdown] = useState([]);
  const [userType, setUserType] = useState("");
  const [updatedAPI, setUpdateAPI] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [updateboxopen, setUpdateBoxOpen] = React.useState(false);
  const handleUpdateBoxOpen = () => setUpdateBoxOpen(true);
  const handleUpdateBoxClose = () => setUpdateBoxOpen(false);
  const [filterItemCode, setFilterItemCode] = useState([]);
  const [filterItemdesc, setFilterItemDesc] = useState([]);
  const [filterProductName, setFilterProductName] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterSubCategory, setFilterSubCategory] = useState([]);
  const [filterMoq, setFilterMOQ] = useState([]);
  const [filterfacorysafety, setFilterFactorySafety] = useState([]);
  const [filterUnit, setFilterUnit] = useState([]);
  const [filterIndustry, setFilterIndustry] = useState([]);


  const stateSetters = {
    setFilterItemCode,
    setFilterItemDesc,
    setFilterProductName,
    setFilterCategory,
    setFilterSubCategory,
    setFilterMOQ,
    setFilterFactorySafety,
    setFilterUnit,
    setFilterIndustry,
    setUpdateAPI
  }
  const propsState = {
    filterItemCode,
    filterItemdesc,
    filterProductName,
    filterCategory,
    filterSubCategory,
    filterMoq,
    filterfacorysafety,
    filterUnit,
    filterIndustry,
  }








  const [total_count, setTotalCount] = React.useState(0);
  
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [Search, setSearch] = useState("");
  const [listdata, setListdata] = useState([]);
  const [category, setCategory] = useState({});
  const [subcategory, setSubCategory] = useState({});
  const [unit, setUnit] = useState({});
  const [industry, setIndustry] = useState({});
  const [newcategory, setNewCategory] = useState({});
  const [newsubcategory, setNewSubCategory] = useState({});
  const [newunit, setNewUnit] = useState({});
  const [newindustry, setNewIndustry] = useState({});
  const cleataddtimedata = () => {
    setCategory({});
    setSubCategory({});
    setUnit({});
    setIndustry({});
  };

  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      itemCode: filterItemCode.map((item) => item.label),
      itemDescription: filterItemdesc.map((item) => item.label),
      productName: filterProductName.map((item) => item.label),
      subcategoryName: filterSubCategory.map((item) => item.label),
      categoryName: filterCategory.map((item) => item.label),
      active: true,
      unit: filterUnit.map((item) => item.label),
      industry: filterIndustry.map((item) => item.label),
      moq: filterMoq.map((item) => item.label),
      factorOfSafety:filterfacorysafety.map((item) => item.label),
    };

    try {
      store.dispatch(Productmasterlist(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data?.docs.length > 0) {
            setListdata(res?.data?.docs);
            setTotalCount(res?.data?.paginate[0]?.totalDocs);
          }
          else{
            setListdata([]);
            setTotalCount(0);
          }
        }
        setUpdateAPI('')

      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    setUserType(localStorage.getItem("usertype"));
    getdata();
  }, [recordperpage, page, Search , updatedAPI]);

  const initialvalueforadd = {
    itemcode: "",
    itemdesc: "",
    Productname: "",
    moq: "",
    factorysafety: "",
  };
  const [addfromdata, setAddFormData] = useState(initialvalueforadd);

  const addhandlechange = (e) => {
    const { name, value } = e.target;
    setAddFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const initialvalueforupdate = {
    itemcode: "",
    itemdesc: "",
    Productname: "",
    subcategory_name: "",
    subcategory_id: "",
    category: "",
    category_id: "",
    moq: "",
    factorysafety: "",
    unit: "",
    industry: "",
    manufacturingleadtime :"",
  };
  const [updatefromdata, setUpdateFormData] = useState(initialvalueforupdate);


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius: "20px",
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

 

  const handleviewuser = (id) => {
    setProductId(id);
    const data = {
      id: id,
    };
    try {
      store.dispatch(Viewproductmaster(data)).then((res) => {
        if (res?.isError === false) {
          setUpdateFormData((prevState) => ({
            ...prevState,
            itemcode: res?.data?.itemCode,
            itemdesc: res?.data?.itemDescription,
            Productname: res?.data?.productName,
            subcategory_name: res?.data?.subcategoryid,
            subcategory_id: res?.data?.subcategoryid,
            category: res?.data?.categoryid,
            category_id: res?.data?.categoryid,
            moq: res?.data?.moq,
            factorysafety: res?.data?.factorOfSafety,
            unit: res?.data?.unitid,
            industry: res?.data?.industryid,
            manufacturingleadtime:res?.data?.manufactureleadTime
          }));
          setNewUnit({
            value: res?.data?.unitid,
            label: res?.data?.unit,
          });
          setNewIndustry({
            value: res?.data?.industryid,
            label: res?.data?.industry,
          });
          setNewCategory({
            value: res?.data?.categoryid,
            label: res?.data?.categoryName,
          });
          setNewSubCategory({
            value: res?.data?.subcategoryid,
            label: res?.data?.subcategoryName,
          });
          setCPStatus(res?.data?.active);
          handleUpdateBoxOpen();
        } else {
          setUpdateFormData(initialvalueforupdate);
          handleUpdateBoxOpen();
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

 

  const getcategorydropdownlist = () => {
    try {
      store.dispatch(Categorylistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setCategorydropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

  const getunitdropdownlist = () => {
    try {
      store.dispatch(Unitlistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setUnitdropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
  const getindustrydropdownlist = () => {
    try {
      store.dispatch(Industrylistdropdowm()).then((res) => {
        if (res?.isError === false) {
          setIndustrydropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

  const getsubcategorydropdownlist = () => {
    try {
      store
        .dispatch(
          Subcategorylistdropdowm(category?.value || newcategory?.value)
        )
        .then((res) => {
          if (res?.isError === false) {
            setSubCategorydropdown(res?.data);
          }
        });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };
  useEffect(() => {
    if (category?.value || newcategory?.value) {
      getsubcategorydropdownlist();
    }
  }, [category?.value || newcategory?.value]);

  const optionlistcategory = categorydropdown?.map((res, i) => ({
    value: res?.value,
    label: res?.label,
  }));

  const optionlistsubcategory = subcategorydropdown?.map((res, i) => ({
    value: res?._id,
    label: res?.subcategoryName,
  }));
  const optionlistunit = unitdropdown?.map((res, i) => ({
    value: res?._id,
    label: res?.unit,
  }));
  const optionlistindustry = indutrydropdown?.map((res, i) => ({
    value: res?._id,
    label: res?.industry,
  }));

 


  const handleProductStatusUpdate = (id, value) => {
    const data = {
      active: value,
      productid: id,
    };
    try {
      store.dispatch(UpdateProductMasterStatus(data, id)).then((res) => {
        if (res?.isError == false) {
          //  handleUpdateBoxClose()
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const navigate = useNavigate();

  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.active;
      handleProductStatusUpdate(userId, updatedStatus);
    }
  };
  const [Cpstatus, setCPStatus] = useState(false);
  const handleStatusUpdate = () => {
    const Updatecpstatus = !Cpstatus;
    setCPStatus(Updatecpstatus);
    handleProductStatusUpdate(product_id, Updatecpstatus);
  };





const handleDownloadEXcel = () => {
  // Custom headers
  const headers = [
    "Item code",
    "Item Description ",
    "Product Name",
    "Sub-Category",
    "Sub-catrgory Id ",
    "Category Id",
    "Category",
    "MOQ",
    "Factor of Safety",
    "Unit",
    "Industry",
  ];

  // Map data to a new array with custom headers
  const dataWithCustomHeaders = [
    headers,
    ...listdata.map((item) => [
      item.itemCode,
      item.itemDescription,
      item.productName,
      item.subcategoryName,
      item.subcategoryid,
      item.categoryid,
      item.categoryName,
      item.moq,
      item.factorOfSafety,
      item.unit,
      item.industry,
    ]),
  ];
  // Generate Excel file
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  // Save file
  const fileData = new Blob([excelBuffer], {
    type: "application/octet-stream",
  });
  saveAs(fileData, "data.xlsx");
};
const handleTemplateDownload = () => {
  // Custom headers
  const headers = [
    "Item code",
    "Item Description ",
    "Product Name",
    "Sub-Category",
    "Category",
    "MOQ",
    "Factor of Safety",
    "Unit",
    "Industry",
  ];

  // Map data to a new array with custom headers
  const dataWithCustomHeaders = [
    headers,[]
    
  ];
  // Generate Excel file
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  // Save file
  const fileData = new Blob([excelBuffer], {
    type: "application/octet-stream",
  });
  saveAs(fileData, "product template.xlsx");
};

const handleFileUpload = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = event.target.result;
      // Parse the Excel file to JSON
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
      const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

      // Define a mapping between old header names and new parameter names
      const headerMapping = {
"Item code":"itemCode",
"Category" : "categoryName",
"Sub-Category" : "subcategoryName",
"Item Description " : "itemDescription",
"Product Name" :"productName",
"MOQ":"moq",
"Factor of Safety":"factorOfSafety",
"Industry":"industry",
"Unit":"unit",
      };

      // Map the old header names to the new parameter names in the JSON data
      const mappedJsonData = jsonData.map((item) => {
        const mappedItem = {};
        for (const oldHeader in item) {
          if (oldHeader in headerMapping) {
            mappedItem[headerMapping[oldHeader]] = item[oldHeader];
          }
        }
        return mappedItem;
      });

      // setExcelJson(mappedJsonData);

      updateNewExcel(mappedJsonData)
    };
    reader.readAsBinaryString(file);
  }
};

const updateNewExcel = async (json) => { // Mark the function as async
  try {
    const res = await store.dispatch(updateProductExcelSheet(json));

    if (res?.isError === false) {
      toast.success(res?.message);

      if (res?.data?.errorArrray.length > 0) {
        toast.error(` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`);
        const headers = [
          "Item code",
          "Item Description ",
          "Sub-Category",
          "Category",
          "MOQ",
          "Factor of Safety",
          "Unit",
          "Industry",
          "Product Name",
          "Error"
        ];
      
        // Map data to a new array with custom headers
        const dataWithCustomHeaders = [
          headers,
          ...res?.data?.errorArrray.map((item) => [
            item.itemCode,
            item.itemDescription,
            item.subcategoryName,
            item.categoryName,
            item.moq,
            item.factorOfSafety,
            item.productName,
            item.unit,
            item.industry,
            item.error
          ]),
        ];
        // Generate Excel file
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        // Save file
        const fileData = new Blob([excelBuffer], {
          type: "application/octet-stream",
        });
        saveAs(fileData,"Errordata.xlsx");

        
      } else {
        // Display an alert when errorArray is empty
        toast.success("No error data found. All data upload Successfully");
      }
    } else {
      toast.error(res?.message);
    }
  } catch (error) {
    console.error("An error occurred:", error);
    // Handle the error as needed
  }
}

const clearFilter = (filterName) => {
  switch (filterName) {
    case 'ItemCode':
      setFilterItemCode([]);
      setUpdateAPI("1")
      break;
    case 'ProductName':
      setFilterProductName([]);
      setUpdateAPI("2")
      break;
    case 'Category':
      setFilterCategory([]);
      setUpdateAPI("3")
      break;
    case 'SubCategory':
      setUpdateAPI("4")
      setFilterSubCategory([]);
      break;
    case 'MOQ':
      setFilterMOQ([]);
      setUpdateAPI("5")
      break;
    case 'FactorySafety':
      setFilterFactorySafety([]);
      setUpdateAPI("6")
      break;
    case 'Unit':
      setFilterUnit([]);
      setUpdateAPI("7")
      break;
      case 'IndustryApplication':
        setFilterIndustry([]);
        setUpdateAPI("7")
        break;
        case 'itemDesc':
          setFilterItemDesc([]);
          setUpdateAPI("itemDesc")
          break;
        
    default:
      break;
  }


};



  const validationSchema = Yup.object().shape({
    itemcode: Yup.string()
    .matches(/^\d+$/, "Item Code must contain only digits")
    .required("Item Code is required"),
    itemdesc: Yup.string().required("Item Description is required"),
    Productname: Yup.string().required("Product Name is required"),
    moq: Yup.string()
      .matches(/^\d+$/, "MOQ must be a valid number")
      .min(0, "MOQ must be greater than or equal to 0")
      .required("MOQ is required"),
    factorysafety: Yup.string()
      .matches(/^\d+$/, "Factor of Safety must be a valid number")
      .min(0, "Factor of Safety must be greater than or equal to 0")
      .required("Factor of Safety is required"),
      manufacturingleadtime : Yup.string()
      .matches(/^\d+$/, "MOQ must be a valid number")
      .min(0, "MOQ must be greater than or equal to 0")
      .required("Manufacturing lead time is required"),
  });

const [showadderror , setShowAdderror]= useState(false)
const onSubmit = (values, { resetForm }) => {
  // Handle form submission here, e.g., send data to an API
 if(
  
  (category?.value == undefined || category?.value == null || category?.value == "" )||
  (subcategory?.value == undefined || subcategory?.value == null || subcategory?.value == "" )||
  (unit?.value == undefined || unit?.value == null || unit?.value == "" )||
  (industry?.value == undefined || industry?.value == null || industry?.value == "" )
){
  setShowAdderror(true)
 }
 else{

 
 const data = {
  itemCode: values.itemcode,
  itemDescription: values.itemdesc,
  productName: values.Productname,
  categoryid: category?.value,
  subcategoryid: subcategory?.value,
  moq: values.moq,
  factorOfSafety: values.factorysafety,
  unitid: unit?.value,
  Industryid: industry?.value,
  manufactureleadTime :values.manufacturingleadtime
};
try {
  store.dispatch(Addproductmaster(data)).then((res) => {
    if (res?.isError == false) {
      handleClose();
      toast.success(res?.message);
      getdata();
      setAddFormData(initialvalueforadd);
      cleataddtimedata();
    } else {
      toast.error(res?.message);
    }
  });
} catch (error) {
  console.error("Error occurred:", error);
}
  resetForm();
}
};

const [showupdateerror , setShowUpdateError]= useState(false)

const onupdate = (values, { resetForm }) => {
  // Handle form submission here, e.g., send data to an API
 if(
  
  (newcategory?.value == undefined || newcategory?.value == null || newcategory?.value == "" )||
  (newsubcategory?.value == undefined || newsubcategory?.value == null || newsubcategory?.value == "" )||
  (newunit?.value == undefined || newunit?.value == null || newunit?.value == "" )||
  (newindustry?.value == undefined || newindustry?.value == null || newindustry?.value == "" )
){
  setShowUpdateError(true)
 }
 else{
  const data = {
    itemCode: values.itemcode,
    itemDescription: values.itemdesc,
    productName: values.Productname,
    categoryid: newcategory?.value,
    subcategoryid: newsubcategory?.value,
    moq: values.moq,
    factorOfSafety: values.factorysafety,
    unitid: newunit?.value,
    Industryid: newindustry.value,
    manufactureleadTime:values.manufacturingleadtime
  };
  try {
    store.dispatch(Updateproductmaster(data, product_id)).then((res) => {
      if (res?.isError == false) {
        handleUpdateBoxClose();
        toast.success(res?.message);
        getdata();
      } else {
        toast.error(res?.message.split(":")[1]);
      }
    });
  } catch (error) {
    console.error("Error occurred:", error);
    return error;
  }
  resetForm();
}
};

const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
};


const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
const sortBy = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  const sortedData = [...listdata].sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  setListdata(sortedData);
  setSortConfig({ key, direction });
};
const [isHovered , setIsHovered] = useState(false)

const handleItemsPerPageChange = (value) => {
  setRecordPerPage(value);
  setPage(1); // Reset to page 1 whenever items per page changes
};

const calculateSerialNumber = (index) => {
  return (page - 1) * recordperpage + index + 1;
};
const startSerialNumber = calculateSerialNumber(0);
const endSerialNumber = calculateSerialNumber(listdata.length - 1);

  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading">Product Master</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              value={Search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>

       

<div className="d-flex gap-2 flex-wrap ms-1">
{
            userType != "CP" &&
       <div className="button-container">
      <button
        className={`button ${isHovered ? 'hovered' : ''} import_export_btns`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>Excel Import</span>
        <div className='pageheader_icons_container'>
          <RiAttachment2 className='import_attach_icon' />
        </div>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          className="import_excel_input"
          style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
        />
      </button>
    </div>
}
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
          onClick={() => handleDownloadEXcel()}
      >
        <span>Excel Export</span>
        <div className='pageheader_icons_container' >
        <MdOutlineCloudDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
    {
            userType != "CP" &&
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`} 
       onClick={() => handleTemplateDownload()} 
      >
        <span>Import Template</span>
        <div className='pageheader_icons_container' >
        <TbFileDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
}

    {userType != "CP" && 
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
           onClick={() => {
            handleOpen();
            getcategorydropdownlist();
            getunitdropdownlist();
            getindustrydropdownlist();
          }} 
      >
        <span>Add Product</span>
        <div className='pageheader_icons_container' >
        <IoMdAddCircleOutline className='pageheader_react_icons' />
        </div>
      </button>
    </div>
}
    
         
        </div>
      </div>



      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center gap-3" style={{ width:"100%" }}  >
        <div className="page_header_slider_container">
         
          <FilterModal stateSetters={stateSetters} propsState={propsState} />
        </div>
        <div className="slider_container ms-5">
          {" "}
          <Slider {...settings}>
            {filterItemCode.length > 0 && (
              <div class="active-filters">
               <button>
               <span className='filter_text_cont' >  Item Code is  {filterItemCode.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterItemCode.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter('ItemCode')} />{" "}
              </button>
              </div>
            )}
            {filterProductName.length > 0 && (
              <div class="active-filters">
                   <button>
                   <span className='filter_text_cont' >     Product Name is  {filterProductName.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterProductName.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter('ProductName')} />{" "}
              </button>
              </div>
            )}
            {filterCategory.length > 0 && (
              <div class="active-filters">
                    <button>
                    <span className='filter_text_cont' >    Category is {filterCategory.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterCategory.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("Category")} />{" "}
              </button>
              </div>
            )}
            {filterSubCategory.length > 0 && (
              <div class="active-filters">
                 <button>
                 <span className='filter_text_cont' >   Sub-Category is {filterSubCategory.map((item, index) => ( <span  className="ellipsis-button_stock" key={index}>
      {item.label}
      {index < filterSubCategory.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("SubCategory")}/>{" "}
              </button>
              </div>
            )}
            {filterMoq.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' >     MOQ is {filterMoq.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterMoq.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilter("MOQ")} />{" "}
              </button>
              </div>
            )}
            {filterfacorysafety.length > 0 && (
              <div class="active-filters">
                 <button>
                 <span className='filter_text_cont' >     Factor of Safety is {filterfacorysafety.map((item, index) => ( <span  className="ellipsis-button_QD" key={index}>
      {item.label}
      {index < filterfacorysafety.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("FactorySafety")} />{" "}
              </button>
              </div>
            )}
            {filterUnit.length > 0 && (
              <div class="active-filters">
                 <button>
                 <span className='filter_text_cont' >     Unit is {filterUnit.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterUnit.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("Unit")} />{" "}
              </button>
              </div>
            )}
             {filterIndustry.length > 0 && (
              <div class="active-filters">
                   <button>
                   <span className='filter_text_cont' >      Industry Applica.. {filterIndustry.map((item, index) => ( <span  className="ellipsis-button_industry" key={index}>
      {item.label}
      {index < filterIndustry.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilter("IndustryApplication")} />{" "}
              </button>
              </div>
            )}
             {filterItemdesc.length > 0 && (
              <div class="active-filters">
                   <button>
                   <span className='filter_text_cont' >     Item Description is {filterItemdesc.map((item, index) => ( <span  className="ellipsis-button_industry" key={index}>
      {item.label}
      {index < filterItemdesc.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("itemDesc")} />{" "}
              </button>
              </div>
            )}
          </Slider>
        </div>
        </div>
        <div className="d-flex">
        

          <button className='inactive_page_btn' 
        onClick={() => navigate("/inactiveproductmaster")} > <FaRegRectangleList className='list_icons' /> <span>  Inactive Product Master List </span> </button>

       
        </div>
      </div>

      <div className="table-container">
        <table className="table ">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              {userType != "CP" && <td scope="col">Action</td>}
              <td scope="col">
                <div className="th_common">
                  <span>Item Code</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("itemCode")}
                  /> */}
                  <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("itemCode")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Manufacturing lead time</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("manufactureleadTime")}
                  /> */}
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("manufactureleadTime")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Item Description</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("itemDescription")}
                  /> */}
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("itemDescription")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Product Name</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("productName")}
                  /> */}
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("productName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Category</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("categoryName")}
                  /> */}
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("categoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Sub-Category</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("subcategoryName")}
                  /> */}
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("subcategoryName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>MOQ</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("moq")}
                  />   */}
                  <img src={sort_logo_img} alt="sort" className="img-fluid"
                  style={{ cursor: "pointer" }}
                  onClick={() => sortBy("moq")}
                 />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Factor of Safety</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("factorOfSafety")}
                  /> */}
                   <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("factorOfSafety")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Unit</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("unit")}
                  /> */}
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("unit")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Industries Applications</span>
                  {/* <FaSort
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("industry")}
                  /> */}
                    <img src={sort_logo_img} alt="sort" className="img-fluid"
                   style={{ cursor: "pointer" }}
                   onClick={() => sortBy("industry")}
                  />
                </div>
              </td>
              {
                  userType != "CP" &&
              <td scope="col">
                <div className="th_common">
                  <span>Status</span>
                
                </div>
              </td>
}
            </tr>
            <br />
          </thead>
          <tbody>
            {listdata?.map((value, i) => (
              <>
              <tr key={i}>
              <td>{calculateSerialNumber(i)}</td>

                {userType != "CP" && (
                  <td>
                   
                    <button  className='table_edit_btn'
                  
                  onClick={() => {
                    handleviewuser(value?._id);
                    getcategorydropdownlist();
                    getunitdropdownlist();
                 
                    getindustrydropdownlist();
                  }}
                  
                  >
                         <TbPencil />
                         </button>
                  </td>
                )}
                <td>{value?.itemCode}</td>
                <td>{value?.manufactureleadTime}</td>

                <td>{value?.itemDescription}</td>
                <td>{value?.productName}</td>
                <td>{value?.categoryName}</td>
                <td>{value?.subcategoryName}</td>
                <td>{value?.moq}</td>
                <td>{value?.factorOfSafety}</td>
                <td>{value?.unit}</td>
                <td>{value?.industry}</td>
                {
                  userType != "CP" &&
                <td>
                  {" "}
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={value?.active}
                      onChange={() => toggleStatus(value?._id)}
                    />
                  </div>
                </td>
}
              </tr>
              <br />
              </>
            ))}
          </tbody>
        </table>
      </div>

    
        <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} >   Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => handleItemsPerPageChange(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>
      {/* // ADD PRODUCT MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
            <div className='d-flex align-items-center gap-3' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Add Product </p>
            </div>
              <div className="d-flex align-items-center gap-3 ">
                <button className="closebtn_cont" onClick={handleClose}>
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

 
            <Formik
        initialValues={initialvalueforupdate}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => (
          <Form>
              <div className="inputbox_cont">
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="itemcode" className="mb-1">itemcode*</label>
                <Field
                  type="text"
                  name="itemcode"
                  id="itemcode"
                  placeholder="Enter itemcode Name"
                  className="add_inut_field "
                />
                <ErrorMessage name="itemcode" component="p" className="error-message"  />
              </div>
              <div className="col-md-4">
                <label htmlFor="itemdesc" className="mb-1">Item Description*</label>
                <Field
                  type="text"
                  name="itemdesc"
                  id="itemdesc"
                  placeholder="Enter Item Description"
                  className="add_inut_field"
                />
                <ErrorMessage name="itemdesc" component="p" className="error-message"  />
              </div>
              <div className="col-md-4">
                <label htmlFor="Productname" className="mb-1">Product Name*</label>
                <Field
                  type="text"
                  name="Productname"
                  id="Productname"
                  placeholder="Enter Product Name"
                  className="add_inut_field"
                />
                <ErrorMessage name="Productname" component="p" className="error-message"  />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="city" className="mb-1">Category*</label>
                <Select
                    options={optionlistcategory}
                    placeholder="Select Category"
                    value={category}
                    onChange={setCategory}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
                {
                  showadderror && (category.value == null || category.value == undefined || category.value == "") && 
                  <p className=" error-message" >Category is required</p>  
                }
              </div>
              <div className="col-md-4">
                <label htmlFor="state" className="mb-1">Sub-Category*</label>
                <Select
                    options={optionlistsubcategory}
                    placeholder="Select Sub-Category"
                    value={subcategory}
                    onChange={setSubCategory}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
                  {
                  showadderror && (subcategory.value == null || subcategory.value == undefined || subcategory.value == "") && 
                  <p className=" error-message" >Sub-Category is required</p>  
                }
              </div>
              <div className="col-md-4">
              <label htmlFor="address" className="mb-1">MOQ*</label>
                <Field
                  type="text"
                  name="moq"
                  id="moq"
                  placeholder="Enter MOQ"
                  className="add_inut_field "
                />
                <ErrorMessage name="moq" component="p" className=" error-message"  />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="factorysafety" className="mb-1">Factor of Safety*</label>
                <Field
                  type="text"
                  name="factorysafety"
                  id="factorysafety"
                  placeholder="Enter Factor of Safety"
                  className="add_inut_field "
                />
                <ErrorMessage name="factorysafety" component="p" className=" error-message"  />
              </div>
              <div className="col-md-4">
                <label htmlFor="phone" className="mb-1">Unit*</label>
                <Select
                    options={optionlistunit}
                    placeholder="Select Unit"
                    value={unit}
                    onChange={setUnit}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
               {
                  showadderror && (unit.value == null || unit.value == undefined || unit.value == "") && 
                  <p className=" error-message" >Unit is required</p>  
                }
              </div>
              <div className="col-md-4">
                <label htmlFor="email" className="mb-1">Industry Application*</label>
                <Select
                    options={optionlistindustry}
                    placeholder="Select Industry Application"
                    value={industry}
                    onChange={setIndustry}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
              {
                  showadderror && (industry.value == null || industry.value == undefined || industry.value == "") && 
                  <p className=" error-message" >Industry Application is required</p>  
                }
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="itemcode" className="mb-1">Manufacturing lead time *</label>
                <Field
                  type="number"
                  name="manufacturingleadtime"
                  id="manufacturingleadtime"
                  placeholder="Enter Manufacturing lead time "
                  className="add_inut_field "
                />
                <ErrorMessage name="manufacturingleadtime" component="p" className="error-message"  />
              </div>
              </div>
          
            <div className="addbox_btn_cont mt-3 ">
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
</div>

          </Form>
        )}
      </Formik>


          </Box>
        </Fade>
      </Modal>
      {/* // ADD PRODUCT MODEL END  */}

      {/* // EDIT PRODUCT MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={updateboxopen}
        onClose={handleUpdateBoxClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={updateboxopen}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex align-items-center gap-3 ">
            <button  className='table_edit_btn' >
                         <TbPencil />
                         </button>
              <p className="m-0">Edit Product</p>
              </div>
              <div className="d-flex align-items-center gap-3 ">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input addbox_radio"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={Cpstatus == true}
                    onChange={handleStatusUpdate}
                  />
                </div>
                <button
                  className="closebtn_cont"
                  onClick={handleUpdateBoxClose}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            
            <Formik
        initialValues={updatefromdata}
        validationSchema={validationSchema}
        onSubmit={onupdate}
      >
        {(formik) => (
          <Form>
            <div className="inputbox_cont">
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="itemcode" className="mb-1">itemcode*</label>
                <Field
                  type="text"
                  name="itemcode"
                  id="itemcode"
                  placeholder="Enter itemcode Name"
                  className="add_inut_field "
                />
                <ErrorMessage name="itemcode" component="p" className="error-message"  />
              </div>
              <div className="col-md-4">
                <label htmlFor="itemdesc" className="mb-1">Item Description*</label>
                <Field
                  type="text"
                  name="itemdesc"
                  id="itemdesc"
                  placeholder="Enter Item Description"
                  className="add_inut_field"
                />
                <ErrorMessage name="itemdesc" component="p" className="error-message"  />
              </div>
              <div className="col-md-4">
                <label htmlFor="Productname" className="mb-1">Product Name*</label>
                <Field
                  type="text"
                  name="Productname"
                  id="Productname"
                  placeholder="Enter Product Name"
                  className="add_inut_field"
                />
                <ErrorMessage name="Productname" component="p" className="error-message"  />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="city" className="mb-1">Category*</label>
                <Select
                    options={optionlistcategory}
                    placeholder="Select Category"
                    value={newcategory}
                    onChange={setNewCategory}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
                {
                  showupdateerror && (newcategory.value == null || newcategory.value == undefined || newcategory.value == "") && 
                  <p className=" error-message" >Category is required</p>  
                }
              </div>
              <div className="col-md-4">
                <label htmlFor="state" className="mb-1">Sub-Category*</label>
                <Select
                    options={optionlistsubcategory}
                    placeholder="Select Sub-Category"
                    value={newsubcategory}
                    onChange={setNewSubCategory}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
                  {
                  showupdateerror && (newsubcategory.value == null || newsubcategory.value == undefined || newsubcategory.value == "") && 
                  <p className=" error-message" >Sub-Category is required</p>  
                }
              </div>
              <div className="col-md-4">
              <label htmlFor="address" className="mb-1">MOQ*</label>
                <Field
                  type="text"
                  name="moq"
                  id="moq"
                  placeholder="Enter MOQ"
                  className="add_inut_field "
                />
                <ErrorMessage name="moq" component="p" className=" error-message"  />
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="factorysafety" className="mb-1">Factor of Safety*</label>
                <Field
                  type="text"
                  name="factorysafety"
                  id="factorysafety"
                  placeholder="Enter Factor of Safety"
                  className="add_inut_field "
                />
                <ErrorMessage name="factorysafety" component="p" className=" error-message"  />
              </div>
              <div className="col-md-4">
                <label htmlFor="phone" className="mb-1">Unit*</label>
                <Select
                    options={optionlistunit}
                    placeholder="Select Unit"
                    value={newunit}
                    onChange={setNewUnit}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
               {
                  showupdateerror && (newunit.value == null || newunit.value == undefined || newunit.value == "") && 
                  <p className=" error-message" >Unit is required</p>  
                }
              </div>
              <div className="col-md-4">
                <label htmlFor="email" className="mb-1">Industry Application*</label>
                <Select
                    options={optionlistindustry}
                    placeholder="Select Industry Application"
                    value={newindustry}
                    onChange={setNewIndustry}
                    isSearchable={true}
                    className="react_inbuild_select"
                  />
              {
                  showupdateerror && (newindustry.value == null || newindustry.value == undefined || newindustry.value == "") && 
                  <p className=" error-message" >Industry Application is required</p>  
                }
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label htmlFor="itemcode" className="mb-1">Manufacturing lead time *</label>
                <Field
                  type="number"
                  name="manufacturingleadtime"
                  id="manufacturingleadtime"
                  placeholder="Enter Manufacturing lead time "
                  className="add_inut_field "
                />
                <ErrorMessage name="manufacturingleadtime" component="p" className="error-message"  />
              </div>
              </div>
            <div className="addbox_btn_cont mt-3 ">
              <button type="submit" className="btn btn-primary">Save</button>
            </div>
            </div>
          </Form>
        )}
      </Formik>


          </Box>
        </Fade>
      </Modal>
      {/* // EDIT PRODUCT MODEL END  */}

    
    </div>
  );
}

export default ProductMasterTable;
