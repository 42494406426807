import React, { useState, useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Pagination from "react-mui-pagination";
import { toast } from "react-toastify";
import "./table.css";
import {
  API_URL,
  UserProfileInfo,
} from "../../../../Redux/MyRedux/action/userActions";
import store from "../../../../Redux/MyRedux/store";
import { AiOutlineEye } from "react-icons/ai";
import Moment from "react-moment";
import { LuSearch } from "react-icons/lu";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Slider from "react-slick";
import * as moment from "moment";
import sort_logo_img from "../../../../Assets/table/shortarrow.png";
import { MdOutlineCloudDownload } from "react-icons/md";
import calender_img from "../../../../Assets/orderView/calendar-lines (1) 1.png";
import unit_img from "../../../../Assets/orderView/Vector.png";
import FilterModal from "./filterpopup/filter";
import {
  spOrderEdit,
  spOrderFlag,
  spOrderView,
  spProductRecommendation,
} from "../../../../Redux/MyRedux/action/phase3";

function OrderTable() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius: "20px",
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

  const styleforflow = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "#F4F6FA",
    boxShadow: 24,
    borderRadius: "20px",
    overflowY: "auto",
    height: "91%",
  };

  const styleForMediaQueryflow = {
    ...styleforflow,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

  const [recordperpage, setRecordperPage] = useState(1000);
  const [total_count, setTotalCount] = useState(10);
  const [page, setPage] = useState(1);
  const [cpOrderPermission, setCpOrderPermission] = useState("PENDING");

  const [orderPageData, setOrdePageData] = useState({
    tog: "",
    netflow: "",
    qualifiedDemand: "",
    moq: "",
    leadTime: "",
    openOrder: "",
    itemcode: "",
    item_description: "",
    unique_number: "",
    onHandStock: "",
  });

  const [fileOrderId, setFileOrderId] = useState("");
  const [fileStatus, setFileStatus] = useState("");
  const [downloadFile, setDownloadFile] = useState("");

  const [filterqty, setFilterQTY] = useState([]);
  const [filterOrderId, setFilterOrderId] = useState([]);
  const [filterSapSo, setFilterSapSo] = useState([]);
  const [filtertog, setFilterTOG] = useState([]);
  const [filterinHandStock, setFilterInHandStock] = useState([]);
  const [filterNetflow, setFilterNetFlow] = useState([]);
  const [filterCpName, setFilterCPName] = useState([]);
  const [filterContactPerson, setFilterContactPerson] = useState([]);
  const [filterCity, setFilterCiy] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [filterItemCode, setFilterItemCode] = useState([]);
  const [filterItemDesc, setFilterItemDesc] = useState([]);
  const [filterstatus, setFilterStatus] = useState([]);
  const [filterProductName, setFilterProductName] = useState([]);
  const [filterDate, setFilterDate] = useState([]);
  const [filterunit, setFilterUnit] = useState([]);
  const [filterQD, setFilterQD] = useState([]);
  const [filterOpenOrder, setFilterOpenOrder] = useState([]);
  const [updatedAPI, setUpdateAPI] = useState("");

  const propsState = {
    filterqty,
    filterOrderId,
    filterSapSo,
    filtertog,
    filterinHandStock,
    filterNetflow,
    filterCpName,
    filterContactPerson,
    filterCity,
    filterState,
    filterItemCode,
    filterItemDesc,
    filterstatus,
    filterProductName,
    filterDate,
    filterunit,
    filterOpenOrder,
    filterQD,
  };

  const StateSetters = {
    setFilterQTY,
    setFilterOrderId,
    setFilterSapSo,
    setFilterTOG,
    setFilterInHandStock,
    setFilterNetFlow,
    setFilterCPName,
    setFilterContactPerson,
    setFilterCiy,
    setFilterState,
    setFilterItemCode,
    setFilterItemDesc,
    setFilterStatus,
    setFilterProductName,
    setFilterDate,
    setFilterUnit,
    setFilterQD,
    setFilterOpenOrder,
    setUpdateAPI,
  };

  const [sapNumber, setSapNumber] = useState();
  const [showSONOinput, setShowSONOInput] = useState(false);
  const [sapSoInput, setSapSoInput] = useState(null);
  const [editSapSo, setEditSapSo] = useState();
  const [isEditingSap, setIsEditingSap] = useState(false);
  const [addremarkbyCP, setAddRemarkByCP] = useState(false);
  const [addremarkbyRotex, setAddRemarkByRotex] = useState(false);
  const [confirmdispatch, setConfirmDispatch] = useState(false);
 

  const [userTypeForRemark, setUserTypeForRemark] = useState("");

  const [remarkbyCP, setRemarkByCP] = useState("");
  const [qtyForAll, setQTYForAll] = useState("");

  const [remarkbyrotex, setRemarkByROTEX] = useState("");

  const [disabledunitbutton, Setdisableunitbtn] = useState(false);

  const handleCPOptionChange = (e) => {
    setConfirmDispatch(false);
    setCpOrderPermission(e.target.value);
    if (e.target.value == "ACCEPT") {
      setShowConfirmPopup(true);
    } else if (e.target.value == "REJECT") {
      EditOrderRecoommand("REJECT" ,false);
    }
  };
  const [usertype, setUserType] = useState("");
  const [isAcceptanceAtRotex, setIsAcceptanceAtRotex] = useState("");
  const [isAcceptanceAtWIP, setIsAcceptanceAtWIP] = useState("");

  const [rotexOrderPermission, setRotexOrderPermission] = useState("PENDING");
  const handlerotexOptionChange = (e) => {
    // if (addSapNumber || sapSoInput) {
    setConfirmDispatch(false);

    setRotexOrderPermission(e.target.value);
    setAcceptType("ROTEX");
    if (e.target.value == "ACCEPT") {
      setShowConfirmPopup(true);
    } else if (e.target.value == "REJECT") {
      EditOrderRecoommand("REJECT" , false);
    }
    // } else {
    //   toast.error("Please Add SO NO");
    // }
  };
  const [wiptoggleactive, setWIPtoggleAcive] = useState(false);

  const handleWIPOptionChange = (e) => {
    setConfirmDispatch(true);
    setWIPtoggleAcive(true);
    if (e.target.value == "ACCEPT") {
      setShowConfirmPopup(true);
    }
  };
  const [intrasittoggleactive, setIntrasittoggleactive] = useState(false);
  const handleTransitOptionChange = (e) => {
    setConfirmDispatch(false);

    setIntrasittoggleactive(true);
    if (e.target.value == "ACCEPT") {
      setShowConfirmPopup(true);
    }
  };
  const [viewdata, setViewData] = useState([]);
  const [orderID, setOrderId] = useState("");
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [acceptType, setAcceptType] = useState("");

  const handleclodeqtyconfirmation = () => {
    setShowConfirmPopup(false);
    setWIPtoggleAcive(false);
    setIntrasittoggleactive(false);
  };

  const [
    showConfirmPopupforpartially,
    setShowConfirmPopupForPartiallyDispatch,
  ] = useState(false);
  const handlecloseconfirmationforPartially = () => {
    setShowConfirmPopupForPartiallyDispatch(false);
    setWIPtoggleAcive(false);
    setIntrasittoggleactive(false);
  };

  const [showChangeqtyPopup, setShowChangeqtyPopup] = useState(false);
  const [showpartiallyqtyPopup, setShowPartiallyqtyPopup] = useState(false);
  const [showAddRemark, setShowAddRemark] = useState(false);

  const [partiallydispatch, setPartiallyDispatch] = useState(false);

  const [spId, setSpId] = useState("");
  const [orderFlag, setOrderFlag] = useState([]);
  const [flagName, setFlagName] = useState("ORDER_RECOMMENDATION");
  const [quantityValue, setQuantityValue] = useState("");
  const [partiallyquantityValue, setPartiallyQuantityValue] = useState("");


  const [productId, setProductId] = React.useState("");

  const [Search, setSearch] = React.useState("");
  const [addSapNumber, setAddSapNumber] = useState(null);

  const handleChange = (event, value) => {
    setPage(value);
  };
  const [listData, setListData] = useState([]);

  const getdata = () => {
    const data = {
      flag: flagName,
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      active: true,
      orderBy: [],
      // companyName: filterCpName.map((item) => item.label),
      contactPersonName: filterContactPerson.map((item) => item.label),
      itemDescription: filterItemDesc.map((item) => item.label),
      itemCode: filterItemCode.map((item) => item.label),
      productName: filterProductName.map((item) => item.label),
      subcategoryName: [],
      categoryName: [],
      onHandStock: filterinHandStock.map((item) => item.label),
      industry: [],
      qualifiedDemand: filterQD.map((item) => item.label),
      tog: filtertog.map((item) => item.label),
      netFlow: filterNetflow.map((item) => item.label),
      qty: filterqty.map((item) => item.label),
      sapNumber: filterSapSo.map((item) => item.label),
      status: filterstatus.map((item) => item.label),
      uniqueNumber: filterOrderId.map((item) => item.value),
      city: filterCity.map((item) => item.label),
      state: filterState.map((item) => item.label),
      createdAt: filterDate.map((item) => item.value),
      unit: filterunit.map((item) => item.label),
      openOrder: filterOpenOrder.map((item) => item.label),
      supplierPartnerId: spId,
    };
    try {
      store.dispatch(spProductRecommendation(data)).then((res) => {
        if (res?.isError == false) {

          if (res?.data[0]?.docs.length > 0) {
            setListData(res?.data[0]?.docs);
            setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          } else {
            setListData([]);
            setTotalCount(0);
          }
        }
        setUpdateAPI('')

      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const handleFlagCount = () => {
    const data = {
      supplierPartnerId: spId,
      active: true,
    };
    try {
      store.dispatch(spOrderFlag(data)).then((res) => {
        if (res?.isError == false) {
          setOrderFlag(res?.data);
        } 
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  useEffect(() => {
    setUserType(localStorage.getItem("usertype"));
    setSpId(
      localStorage.getItem("channelPartnerId") == null ||
        localStorage.getItem("channelPartnerId") == undefined ||
        localStorage.getItem("channelPartnerId") == ""
        ? window.location.href.split("/")[5]
        : localStorage.getItem("channelPartnerId")
    );

    if (spId) {
      getdata();
      handleFlagCount();
    }
  }, [flagName, updatedAPI, spId, page, recordperpage, Search]);

  const viewOrderRecoommand = (id) => {
    setOrderId(id);
    try {
      store.dispatch(spOrderView(id)).then((res) => {
        if (res?.isError == false) {
          setProductId(res?.data[0]?.productId);

          setSapNumber(res?.data[0]?.sapNumber);
          if (res?.data[0]?.sapNumber == "") {
            setEditSapSo(false);
            setShowSONOInput(false);
            setSapSoInput("");
          } else {
            setSapSoInput(res?.data[0]?.sapNumber);
            setShowSONOInput(true);
            setEditSapSo(true);
          }

          setRemarkByCP(res?.data[0]?.cpRemark);
          setRemarkByROTEX(res?.data[0]?.rotexRemark);
          setAddRemarkByRotex(res?.data[0]?.rotexRemark == "" ? false : true);
          setQTYForAll(res?.data[0]?.qty);
          setViewData(res?.data);
          setQuantityValue(res?.data[0]?.qty);
          setCpOrderPermission(res?.data?.sp?.stage);
          setRotexOrderPermission(res?.data?.cp?.stage);
          setDownloadFile(res?.data[0]?.attachment);

          setOrdePageData((prevState) => {
            return {
              ...prevState,
              tog: res?.data[0]?.tog,
              netflow: res?.data[0]?.netFlow,
              qualifiedDemand: res?.data[0]?.qualifiedDemand,
              moq: res?.data[0]?.moq,
              leadTime: res?.data[0]?.leadTime,
              openOrder: res?.data[0]?.openOrder,
              itemcode: res?.data[0]?.itemCode,
              item_description: res?.data[0]?.itemDescription,
              unique_number: res?.data[0]?.uniqueNumber,
              onHandStock: res?.data[0]?.onHandStock,
            };
          });
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  let userid = localStorage.getItem("user_id");
  const getProfiledata = () => {
    try {
      store.dispatch(UserProfileInfo(userid)).then((res) => {
        if (res?.isError == false) {
          setIsAcceptanceAtRotex(res?.data?.isAcceptanceAtRotex);
          setIsAcceptanceAtWIP(res?.data?.isAcceptanceAtWIP);
        }
      });
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getProfiledata();
  }, []);

  
 
  const EditOrderRecoommand = (value,isChangeQTY) => {
    Setdisableunitbtn(true);
     
    const data = {
      stage: value,
      qty: quantityValue,
      partcialDispatch: partiallydispatch,
      changeQty: partiallyquantityValue,
      isChangeQty: isChangeQTY
    };
    console.log(data , 'data')

    try {
      store.dispatch(spOrderEdit(data, orderID)).then((res) => {
        if (res?.isError == false) {
          toast.success(res?.message);
          setShowChangeqtyPopup(false);
          setShowConfirmPopup(false);
          viewOrderRecoommand(orderID);
          Setdisableunitbtn(false);
          handlecloseconfirmationforPartially();
          setShowPartiallyqtyPopup(false);
          getdata();
          handleFlagCount();
          setPartiallyDispatch(false);
          setPartiallyQuantityValue("");

        } else {
          toast.error(res?.message);
          Setdisableunitbtn(false);


        }
        if (acceptType == "ROTEX") {
          sapSoNumberUpdate();
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  const sapSoNumberUpdate = () => {
    setAcceptType("");
    Setdisableunitbtn(true);

    const data = {
      sapNumber: addSapNumber ? addSapNumber : sapSoInput,
      orderId: fileOrderId,
    };

    // try {
    //   store.dispatch(addRotexSAPNo(data)).then((res) => {
    //     if (res?.isError == false) {
    //       toast.success(res?.message);
    //       setShowChangeqtyPopup(false);
    //       setShowConfirmPopup(false);
    //       Setdisableunitbtn(false);
    //       setEditSapSo(true);
    //       setIsEditingSap(false);

    //       setAddSapNumber(null);

    //       viewOrderRecoommand(orderID);
    //       //API RUN FOR UPDATE LIST AND SAHBOARD BTN COUNTS
    //       getdata();
    //       handleFlagCount();
    //     } else {
    //       toast.success(res?.message);
    //       Setdisableunitbtn(false);
    //     }
    //   });
    // } catch (error) {
    //   console.error("Error occurred:", error);
    // }
  };

  const AddremarkCP = () => {
    const data = {
      spRemark: remarkbyCP,
      rotexRemark: remarkbyrotex,
    };
    // try {
    //   store.dispatch(addRemarkByRotex(data, orderID)).then((res) => {
    //     if (res?.isError == false) {
    //       toast.success(res?.message);
    //       getdata();
    //       setShowAddRemark(false);
    //       handleFlagCount();
    //       setRemarkByCP("");
    //       viewOrderRecoommand(orderID);
    //       setAddRemarkByCP(false);
    //       setAddRemarkByRotex(false);
    //     } else {
    //       toast.success(res?.message);
    //     }
    //   });
    // } catch (error) {
    //   console.error("Error occurred:", error);
    // }
  };

  const [open, setOpen] = useState(false);
  const handleeditwmodelopen = () => setOpen(true);
  const handleeditmodelclose = () => setOpen(false);

  const clearFilter = (filterName) => {
    switch (filterName) {
      case "QTY":
        setFilterQTY([]);
        setUpdateAPI("1");
        break;
      case "TOG":
        setFilterTOG([]);
        setUpdateAPI("2");

        break;
      case "OnHandStock":
        setFilterInHandStock([]);
        setUpdateAPI("3");

        break;
      case "NetFlow":
        setUpdateAPI("4");
        setFilterNetFlow([]);
        break;
      case "CPName":
        setFilterCPName([]);
        setUpdateAPI("5");

        break;
      case "ContactPerson":
        setFilterContactPerson([]);
        setUpdateAPI("6");

        break;
      case "City":
        setFilterCiy([]);
        setUpdateAPI("city");

        break;
      case "State":
        setFilterState([]);
        setUpdateAPI("states");

        break;
      case "ItemCode":
        setFilterItemCode([]);
        setUpdateAPI("9");
        break;
      case "ProductName":
        setFilterProductName([]);
        setUpdateAPI("10");
        break;

      case "OrderId":
        setFilterOrderId([]);
        setUpdateAPI("12");
        break;
      case "sapNumber":
        setFilterSapSo([]);
        setUpdateAPI("13");
        break;

      case "Date":
        setFilterDate([]);
        setUpdateAPI("date");
        break;

      case "unit":
        setFilterUnit([]);
        setUpdateAPI("ynit");
        break;

      case "QD":
        setFilterQD([]);
        setUpdateAPI("QD");
        break;

      case "openOrder":
        setFilterOpenOrder([]);
        setUpdateAPI("OpenOrder");
        break;
      case "status":
        setFilterStatus([]);
        setUpdateAPI("status");
        break;
      case "itemDesc":
        setFilterItemDesc([]);
        setUpdateAPI("itemDesc");
        break;
      case "itemCode":
        setFilterItemCode([]);
        setUpdateAPI("itemCode");
        break;

        break;
      default:
        break;
    }
  };
  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      "Order ID",
      "Stage",
      "Status",
      "Accepted QTY BY SP ",
      "Sap Number",
      "Supplier Name",
      "Item Code",
      "Item Description",
      "Product Name",
      "Unit",
      "Recommended QTY",
      "Accepted QTY BY SP ",
      "Recommended QTY - Accepted CP QTY",
      // "Accepted QTY BY Rotex",
      // "Accepted CP QTY - Acepted Rotex QTY",
      "WIP QTY",
      "Dispatch",
      "Dispatch QTY",
      // "GRN QTY",
      "Recommendation Date",
      "Accepted SP Date",
      // "Accepted Rotex Date ",
      "WIP Date ",
      "Dispatch Date",
      // "GRN Date",
      // "CP ACCEPTANCE DELAY DAYS",
      // "ROTEX ACCEPTANCE DELAY DAYS",
      "ACTUAL LEAD TIME",
      // "CP GRN Lead time",
      "Order Net Flow",
      "Order TOG",
      "Order On Hand Stock",
      "Order Open Production Order",
      "Order Qualified Demand",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listData.map((item) => [
        `PROD ${item?.uniqueNumber}`,
        item?.grn?.stage == "ACCEPT"
          ? " GRN"
          : item?.in_trasit?.stage == "ACCEPT"
          ? "GRN"
          : item?.wip?.stage == "ACCEPT"
          ? "In Transit"
          : item?.rotex?.stage == "ACCEPT"
          ? "WIP"
          : item?.cp?.stage == "ACCEPT"
          ? " Accepted By CP"
          : item?.recommendation?.stage == "RECOMMENDATION"
          ? "Order recommendation"
          : "",
        item?.status == "YELLOW"
          ? "Yellow"
          : item?.status == "RED"
          ? "Red"
          : item?.status == "BLACK"
          ? "Black"
          : item?.status == "GREEN"
          ? "Green"
          : "White",
        item?.acceptedByCpQty,
        item.sapNumber,
        item.supplierName,
        item.itemCode,
        item.itemDescription,
        item.productName,
        item.unit,
        item.recommendedQty,
        item?.acceptedByCpQty,
        item?.recQtyAndCpQtyDiff,
        item?.acceptedByRotexQty,
        item?.cpQtyAndRotexQtyDiff,
        item?.wipQty,
        item?.isPartialDispatch === false
          ? "Full"
          : item?.isPartialDispatch === true
          ? "Partial"
          : "",
        item?.in_trasitQty,
        item.grnQty,
        moment(item?.recommendedDate).format("DD-MM-YYYY"),
        item?.cpDate == "" ? "" : moment(item?.cpDate).format("DD-MM-YYYY"),
        item?.rotexDate == ""
          ? ""
          : moment(item?.rotexDate).format("DD-MM-YYYY"),
        item?.wipDate == "" ? "" : moment(item?.wipDate).format("DD-MM-YYYY"),
        item?.dispatchDate == ""
          ? ""
          : moment(item?.dispatchDate).format("DD-MM-YYYY"),
        item?.grnDate == "" ? "" : moment(item?.grnDate).format("DD-MM-YYYY"),
        Math.round(item?.cpAcceptanceDelayDays),
        Math.round(item?.rotexAcceptanceDelayDays),
        Math.round(item?.actualLeadTime),
        item?.grnLeadTime < 0 ? 0 : Math.round(item?.grnLeadTime),
        item.OldNetFlow,
        Math.round(item?.oldTOg),
        item.oloOnHandStock,
        item.oldOpenOrder,
        item.oldQualifiedDemand,
      ]),
    ];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "Supplier Order Report.xlsx");
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  // Add file
  const handleFileInputChange = (event, id) => {
    const dataFile = event.target.files[0];
    if (dataFile) {
      handlePostFile(dataFile, id);
    }
  };
  const handlePostFile = (dataFile, id) => {
    const formData = new FormData();
    formData.append("attachment", dataFile);
    formData.append("orderId", fileOrderId);

    // try {
    //   store.dispatch(addAttchmentByRotexOrder(formData)).then((res) => {
    //     if (res?.isError === false) {
    //       toast.success(res?.message);
    //       setFileStatus(res?.isError);
    //       viewOrderRecoommand(id);
    //     } else {
    //       toast.error(res?.message);
    //     }
    //   });
    // } catch (error) {
    //   console.error("Error occurred:", error);
    // }
  };

  const handleDownload = async () => {
    const cleanedFilePath = downloadFile.replace("uploaded/", "");
    if (downloadFile) {
      const completeUrl = API_URL + "/" + cleanedFilePath;
      const fileExtension = cleanedFilePath.split(".").pop().toLowerCase();
      const headers = new Headers();
      headers.append(
        "Content-Disposition",
        `attachment; filename=downloadedFile.${fileExtension}`
      );
      const response = await fetch(completeUrl, { headers });
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `downloadedFile.${fileExtension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const sortBy = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    const sortedData = [...listData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });
    setListData(sortedData);
    setSortConfig({ key, direction });
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleItemsPerPageChange = (value) => {
    setRecordperPage(value);
    setPage(1); // Reset to page 1 whenever items per page changes
  };
  
  const calculateSerialNumber = (index) => {
    return (page - 1) * recordperpage + index + 1;
  };
  const startSerialNumber = calculateSerialNumber(0);
  const endSerialNumber = calculateSerialNumber(listData.length - 1);
  
  

  return (
    <div className="page">
      <div className="filter_list d-flex justify-content-between align-items-center   mb-1">
        <ul className="list-inline mb-0 d-flex flex-wrap gap-1">
          {orderFlag.map((item, index) => (
            <>
              <li className="list-inline-item" key={index}>
                <button
                  className=" order_btn"
                  onClick={() => {
                    setFlagName("ORDER_RECOMMENDATION");
                    setPage("1");
                  }}
                >
                  {" "}
                  <span> Production Recommendation </span>{" "}
                  <span className="intry-count">
                    {item?.order_recommentdation}
                  </span>
                </button>
              </li>

              <li className="list-inline-item">
                <button
                  className="  cp_accept_btn "
                  onClick={() => {
                    setFlagName("SP_ACCEPT");
                    setPage("1");
                  }}
                >
                  {" "}
                  <span>WIP</span>{" "}
                  <span className="intry-count">{item?.SP_ACCEPT}</span>
                </button>
              </li>

              <li className="list-inline-item">
                <button
                  className="wip_accept_btn  "
                  onClick={() => {
                    setFlagName("WIP");
                    setPage("1");
                  }}
                >
                  Added to Stock{" "}
                  <span className="intry-count">{item?.wip}</span>
                </button>
              </li>
              {/* <li className="list-inline-item">
                <button
                  className=" in_transit_accept_btn "
                  onClick={() => {
                    setFlagName("IN_TRANSIT");
                    setPage("1");
                  }}
                >
                  In-Transit{" "}
                  <span className="intry-count">{item?.in_trasit}</span>
                </button>
              </li> */}
              {/* <li className="list-inline-item">
                <button
                  className=" grn_accept_btn "
                  onClick={() => {
                    setFlagName("GRN");
                    setPage("1");
                  }}
                >
                  GRN <span className="intry-count">{item?.grn}</span>
                </button>
              </li> */}
              <li className="list-inline-item">
                <button
                  className=" all_accept_btn "
                  onClick={() => {
                    setFlagName(" ");
                    setPage("1");
                  }}
                >
                  ALL <span className="intry-count">{item?.all}</span>
                </button>
              </li>
            </>
          ))}
        </ul>
        <div className="d-flex align-items-center gap-2">
          <div
            className="button-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <button
              className={`button ${isHovered ? "hovered" : ""}`}
              onClick={() => handleDownloadEXcel()}
            >
              <span>SP Order Report</span>
              <div className="pageheader_icons_container">
                <MdOutlineCloudDownload className="pageheader_react_icons" />
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="page_header_slider_container">
        <div className="d-flex align-items-center gap-1">
          <FilterModal propsState={propsState} stateSetters={StateSetters} />
        </div>
        <div className="slider_container">
          <Slider {...settings}>
            {filterOrderId.length > 0 && (
              <div class="active-filters">
                {" "}
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    Order Id is{" "}
                    {filterOrderId.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterOrderId.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("OrderId")} />
                </button>
              </div>
            )}
            {filterSapSo.length > 0 && (
              <div class="active-filters">
                {" "}
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    SO No is{" "}
                    {filterSapSo.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterSapSo.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("sapNumber")} />
                </button>
              </div>
            )}

            {filterqty.length > 0 && (
              <div class="active-filters">
                {" "}
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    QTY is{" "}
                    {filterqty.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterqty.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("QTY")} />
                </button>
              </div>
            )}

            {filtertog.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    TOG is{" "}
                    {filtertog.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filtertog.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("TOG")} />
                </button>
              </div>
            )}
            {filterinHandStock.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    On Hand Stock is{" "}
                    {filterinHandStock.map((item, index) => (
                      <span
                        className="ellipsis-button_contact_person"
                        key={index}
                      >
                        {item.label}
                        {index < filterinHandStock.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("OnHandStock")} />
                </button>
              </div>
            )}
            {filterCpName.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    Rotex is{" "}
                    {filterCpName.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterCpName.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("CPName")} />
                </button>
              </div>
            )}
            {filterContactPerson.length > 0 && (
              <div class="active-filters">
                {" "}
                <button>
                  <span className="filter_text_cont">
                    {" "}
                    Contact Person is{" "}
                    {filterContactPerson.map((item, index) => (
                      <span
                        className="ellipsis-button_contact_person"
                        key={index}
                      >
                        {item.label}
                        {index < filterContactPerson.length - 1
                          ? ", "
                          : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("ContactPerson")} />
                </button>
              </div>
            )}
            {filterProductName.length > 0 && (
              <div class="active-filters">
                {" "}
                <button>
                  <span className="filter_text_cont">
                    {" "}
                    Product Name is{" "}
                    {filterProductName.map((item, index) => (
                      <span
                        className="ellipsis-button_contact_person"
                        key={index}
                      >
                        {item.label}
                        {index < filterProductName.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("ProductName")} />
                </button>
              </div>
            )}
            {filterCity.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    City is{" "}
                    {filterCity.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterCity.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("City")} />
                </button>
              </div>
            )}
            {filterState.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    State is{" "}
                    {filterState.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterState.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("State")} />
                </button>
              </div>
            )}
            {filterItemCode.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    Item Code is{" "}
                    {filterItemCode.map((item, index) => (
                      <span className="ellipsis-button_stock" key={index}>
                        {item.label}
                        {index < filterItemCode.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("itemCode")} />
                </button>
              </div>
            )}

            {filterNetflow.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    NetFlow is{" "}
                    {filterNetflow.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterNetflow.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("NetFlow")} />
                </button>
              </div>
            )}

            {filterDate.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    Date is{" "}
                    {filterDate.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterDate.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("Date")} />
                </button>
              </div>
            )}
            {filterunit.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    Unit is{" "}
                    {filterunit.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterunit.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("unit")} />
                </button>
              </div>
            )}

            {filterQD.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    Qualified Demand is{" "}
                    {filterQD.map((item, index) => (
                      <span className="ellipsis-button_QD" key={index}>
                        {item.label}
                        {index < filterQD.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("QD")} />
                </button>
              </div>
            )}

            {filterOpenOrder.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    Open Order is{" "}
                    {filterOpenOrder.map((item, index) => (
                      <span className="ellipsis-button_stock" key={index}>
                        {item.label}
                        {index < filterOpenOrder.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("openOrder")} />
                </button>
              </div>
            )}
            {filterstatus.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    status is{" "}
                    {filterstatus.map((item, index) => (
                      <span className="ellipsis-button" key={index}>
                        {item.label}
                        {index < filterstatus.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("status")} />
                </button>
              </div>
            )}
            {filterItemDesc.length > 0 && (
              <div class="active-filters">
                <button>
                  {" "}
                  <span className="filter_text_cont">
                    {" "}
                    Item Description is{" "}
                    {filterItemDesc.map((item, index) => (
                      <span
                        className="ellipsis-button_contact_person"
                        key={index}
                      >
                        {item.label}
                        {index < filterItemDesc.length - 1 ? ", " : ""}{" "}
                        {/* Add a comma if not the last item */}
                      </span>
                    ))}{" "}
                  </span>{" "}
                  <RxCross2 onClick={() => clearFilter("itemDesc")} />
                </button>
              </div>
            )}
          </Slider>
        </div>

        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group my-1 ">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              value={Search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="table-container">
        <table className=" table  ">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">Action</td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span> Stage </span>
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>
                    Supplier <br /> Inventory Status{" "}
                  </span>
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Production Id</span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("uniqueNumber")}
                  />
                </div>{" "}
              </td>
              {/* <td scope="col">
                <div className="th_common">
                  <span>SO No</span>
              
                   <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("sapNumber")}
                  />
                </div>{" "}
              </td> */}
              <td scope="col">
                <div className="th_common">
                  <span>Recommendation Date</span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("recommendedDate")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Supplier Approval Date</span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("cpDate")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>Recommended QTY</span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("recommendedQty")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Accepted QTY By Supplier </span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("acceptedByCpQty")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Process QTY </span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("qty")}
                  />
                </div>{" "}
              </td>

              <>
                {/* <td scope="col">
                    {" "}
                    <div className="th_common">
                      <span>SP </span>
                      
                       <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("companyName")}
                  />
                    </div>
                  </td> */}
              </>

              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Item Code </span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("itemCode")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Item Description</span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("itemDescription")}
                  />
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Product Name </span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("productName")}
                  />
                </div>
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>Unit </span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("unit")}
                  />
                </div>{" "}
              </td>
              {usertype != "CP" && (
                <>
                  {/* <td scope="col">
                    {" "}
                    <div className="th_common">
                      <span>
                        Contact Person{" "}
                      </span>
                      
                       <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("contactPersonName")}
                  />
                    </div>{" "}
                  </td> */}
                  {/* <td scope="col">
                    {" "}
                    <div className="th_common">
                      <span>City </span>
                    
                       <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("city")}
                  />
                    </div>
                  </td> */}
                  {/* <td scope="col">
                    {" "}
                    <div className="th_common">
                      <span>State </span>
                    
                       <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("state")}
                  />
                    </div>
                  </td> */}
                </>
              )}

              {/* <td scope="col">
                {" "}
                <div className="th_common">
                  <span>
                    {" "}
                    Order NetFlow{" "}
                  </span>
                 
                    <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("OldNetFlow")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                {" "}
                <div className="th_common">
                  <span>
                    {" "}
                    Order TOG{" "}
                  </span>
                  
                    <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("oldTOg")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>
                    {" "}
                    Order <br /> On Hand 
                    Stock{" "}
                  </span>
               
                    <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("oloOnHandStock")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>
                  Order Open <br />Prodcution Order
                  </span>
                
                  <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("oldOpenOrder")}
                  />
                </div>{" "}
              </td>{" "}
              <td scope="col">
                <div className="th_common">
                  <span>
                    {" "}
                    Order <br /> Qualified  Demand{" "}
                  </span>
                  
                    <img src={sort_logo_img} alt="sort logo"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortBy("oldQualifiedDemand")}
                  />
                </div>{" "}
              </td> */}
              {/* <td scope="col">
                <div className="th_common">
                  <span>
                    {" "}
                    Supplier <br /> On Hand Stock
                  </span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("oldQualifiedDemand")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>
                    {" "}
                    Supplier <br /> Qualified Demand{" "}
                  </span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("oldQualifiedDemand")}
                  />
                </div>{" "}
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>
                    {" "}
                    Supplier Open <br /> Production Order
                  </span>

                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortBy("oldQualifiedDemand")}
                  />
                </div>{" "}
              </td> */}
            </tr>
            <br />
          </thead>
          <tbody>
            {listData?.map((val, i) => (
              <>
                <tr key={i}>
                             <td>{calculateSerialNumber(i)}</td>

                  <td className="">
                    <button
                      className="table_edit_btn"
                      onClick={() => {
                        handleeditwmodelopen();
                        viewOrderRecoommand(val?._id);
                        setFileOrderId(val?._id);
                        setShowConfirmPopup(false);
                        setIntrasittoggleactive(false);
                        setWIPtoggleAcive(false);
                      }}
                    >
                      <AiOutlineEye />
                    </button>
                  </td>
                  {
                    // val?.grn?.stage == "ACCEPT" ?
                    // (
                    //   <td>
                    //     <button className=" order_recommend_btn recoomand">
                    //       {" "}
                    //       <b> GRN </b>
                    //     </button>
                    //   </td>
                    // ) : val?.in_trasit?.stage == "ACCEPT" ? (
                    //   <td>
                    //     <button className=" order_recommend_btn recoomand ">
                    //       GRN
                    //     </button>
                    //   </td>
                    // ) : val?.wip?.stage == "ACCEPT" ? (
                    //   <td>
                    //     <button className=" order_recommend_btn rejected ">
                    //       In Transit
                    //     </button>
                    //   </td>
                    // )
                    // :
                    val?.wip?.stage == "ACCEPT" ? (
                      <td>
                        <button className=" order_recommend_btn wip_btn">
                          Added to Stock
                        </button>
                      </td>
                    ) : val?.sp?.stage == "ACCEPT" ? (
                      <td>
                        <button className=" order_recommend_btn pending_order_btn">
                          WIP
                        </button>
                      </td>
                    ) : val?.recommendation?.stage == "RECOMMENDATION" ? (
                      <td>
                        <button className=" order_recommend_btn recommand_btn">
                          Production Recommendation
                        </button>
                      </td>
                    ) : (
                      <td></td>
                    )
                  }

                  {val?.status == "YELLOW" ? (
                    <td>
                      <button className="btn btn-warning status_btn_order">
                        Yellow
                      </button>
                    </td>
                  ) : val?.status == "RED" ? (
                    <td>
                      <button className="btn btn-danger status_btn_order">
                        Red
                      </button>
                    </td>
                  ) : val?.status == "BLACK" ? (
                    <td>
                      <button className="btn btn-dark status_btn_order">
                        Black
                      </button>
                    </td>
                  ) : val?.status == "GREEN" ? (
                    <td>
                      <button className="btn btn-success status_btn_order">
                        Green
                      </button>
                    </td>
                  ) : (
                    <td>
                      <button className="btn border status_btn_order">
                        White
                      </button>
                    </td>
                  )}
                  <td> PROD {val?.uniqueNumber} </td>
                  {/* <td> {val?.sapNumber} </td> */}

                  <td>
                    {" "}
                    <Moment format="DD-MM-YYYY">
                      {val.recommendedDate}
                    </Moment>{" "}
                  </td>
                  <td>
                    {val.spDate ? (
                      <Moment format="DD-MM-YYYY">{val.spDate}</Moment>
                    ) : (
                      "NA"
                    )}
                  </td>
                  <td>{val?.recommendedQty}</td>
                  <td>{val?.acceptedBySpQty}</td>
                  <td>{val?.qty}</td>

                  {/* {usertype != "CP" && <td>{val?.companyName}</td>} */}
                  <td>{val?.itemCode}</td>
                  <td>{val?.itemDescription}</td>
                  <td>{val?.productName}</td>
                  <td>{val?.unit}</td>

                  {usertype != "CP" && (
                    <>
                      {/* <td>{val?.contactPersonName}</td>
                    <td>{val?.city}</td>
                    <td>{val?.state}</td> */}
                    </>
                  )}

                  {/* 
                <td>{val?.OldNetFlow}</td>
                <td>{Math.round(val?.oldTOg)}</td> */}
                  {/* <td>{val?.oloOnHandStock}</td>
                  <td>{val?.oldQualifiedDemand}</td>
                  <td>{val?.oldOpenOrder}</td> */}
                </tr>
                <br />
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
        <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            {" "}
            Showing 01 -{" "}
            Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results


          </p>
        </div>

        <div className="d-flex   justify-content-center align-items-center">
          <Pagination
            page={page}
            setPage={handleChange}
            total={total_count}
            className="pagination "
            perPage={recordperpage}
          />
        </div>
        <div className="d-flex align-items-center">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            Total Count
          </p>
          <select
            className="w-auto form-select form-select-sm p3 shadow-none mx-3 "
            style={{ fontFamily: "Poppins" }}
            value={recordperpage}
            onChange={(e) => handleItemsPerPageChange(e.target.value)}
          >
            <option value="5">5</option>

            {total_count > 5 && <option value="10">10</option>}
            {total_count > 10 && <option value="25">25</option>}
            {total_count > 25 && <option value="50">50</option>}
            {total_count > 50 && <option value="100">100</option>}
            {total_count > 100 && <option value="500">500</option>}
            {total_count > 500 && <option value="1000">1000</option>}
          </select>
        </div>
      </div>

      {/* //  VIEW ORDERFLOW Master MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleeditmodelclose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQueryflow} className="model_box">
            <div className="model_header">
              <p className="m-0"> Order flow history </p>
              <div className="d-flex align-items-center gap-3 ">
                <button
                  className="mtoclosebtn_cont"
                  onClick={handleeditmodelclose}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="cp_list_cont_model">
              <div className="cp_name  px-4 py-0 ">
                <div>
                  <p className="m-0 fw-bold text-black mt-2 mb-1">
                    Supplier Inventory Details
                  </p>

                  <div
                    className="d-flex gap-2 p-2 flex-wrap"
                    style={{ whiteSpace: "pre" }}
                  >
                    <p className="about_order_val ">
                      {" "}
                      TOG :- {Math.round(orderPageData.tog)}{" "}
                    </p>{" "}
                    <p className="m-0 about_order_val">
                      OnHandStock :- {orderPageData.onHandStock}
                    </p>
                    <p className="m-0 about_order_val">
                      Qualified Demand :- {orderPageData.qualifiedDemand}
                    </p>{" "}
                    <p className="m-0 about_order_val">
                      NetFlow :- {orderPageData.netflow}
                    </p>{" "}
                    <p className="m-0 about_order_val">
                      Open Production Order :- {orderPageData.openOrder}
                    </p>{" "}
                    <p className="m-0 about_order_val">
                      MOQ :- {!orderPageData.moq ? 0 : orderPageData.moq}
                    </p>{" "}
                    <p className="m-0 about_order_val">
                      Lead Time :-{" "}
                      {!orderPageData.leadTime ? 0 : orderPageData.leadTime}
                    </p>{" "}
                 
                    <p className=" about_order_val ">
                      {" "}
                      Item Code :- {orderPageData.itemcode}{" "}
                    </p>{" "}
                    <p className="m-0 about_order_val">
                      Item Description :- {orderPageData.item_description}
                    </p>
                    <p className="m-0 about_order_val">
                      Production Id :- PROD {orderPageData.unique_number}
                    </p>
                    {/* <p className="m-0 about_order_val">SO No :- {sapNumber}</p> */}
                  </div>
                </div>
              </div>

              <div className="complted_stage_cont">
                {viewdata?.map((res) => (
                  <>
                    {res?.recommendation?.status == true && (
                      <div className="cp_name  px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">Production Recommendation</p>
                            <div className="d-flex gap-4">
                              <div className="m-0 d-flex align-items-center gap-2">
                                <img src={calender_img} alt="img-fluid" />
                                <Moment format="MMM,DD HH:mm">
                                  {res?.recommendation?.createdAt}
                                </Moment>
                              </div>
                              <div className="m-0 d-flex align-items-center gap-2">
                                <img src={unit_img} alt="img-fluid" />

                                <span> Qty : {res?.recommendation?.qty} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* <div className="cp_name  px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <SupplierList id={productId} qty={qtyForAll} orderId={orderID} />
                           
                          </div>
                        </div>
                      </div> */}

                    {res?.rotex?.status == true && (
                      <div className="cp_name d-flex justify-content-between align-items-center px-4  ">
                        {/* <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">Acceptance at Rotex </p>

                            {res?.rotex?.stage != "PENDING" && (
                              <>
                              <div className="d-flex gap-4">
                            <div className="m-0 d-flex align-items-center gap-2">
                              <img src={calender_img} alt="img-fluid"  />
                              <Moment format="MMM,DD HH:mm">
                              {res?.rotex?.createdAt}
                              </Moment>
                            </div>
                            <div className="m-0 d-flex align-items-center gap-2">
                            <img src={unit_img} alt="img-fluid"  />

                            <span>  Qty : {res?.rotex?.qty} </span>
                            </div>
                            </div>
                                
                              </>
                            )}
                          </div>
                        </div> */}

                        <div className="d-flex align-items-center gap-2">
                          <div className="d-flex flex-column gap-1">
                            <div className="sap-btn-container w-100">
                              <div className="col-4 w-100">
                                {/* {editSapSo == true && (
                                  <div className="sap-btn-container w-100">
                                    <div className="d-flex align-items-end flex-column mb-4">
                                      <span>
                                        <h6>SO No</h6>{" "}
                                      </span>
                                      <input
                                        type="text"
                                        class="form-control w-25"
                                        id="inlineFormInput"
                                        placeholder="SO No"
                                        onChange={(e) =>
                                          setSapSoInput(e.target.value)
                                        }
                                        value={sapSoInput}
                                        disabled={!isEditingSap}
                                      />
                                    </div>
                                    {isEditingSap ? (
                                      <>
                                        <div className="tick-cross-btn-container ">
                                          <div>
                                            <button
                                              className="sap-so-btn"
                                              disabled={disabledunitbutton}
                                              onClick={() =>
                                                sapSoNumberUpdate()
                                              }
                                            >
                                              <svg
                                                width="24px"
                                                height="24px"
                                                viewBox="0 -0.5 25 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="#00ff11"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <path
                                                    d="M5.5 12.5L10.167 17L19.5 8"
                                                    stroke="#00ff11"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></path>{" "}
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                          <div>
                                            <button
                                              className="sap-so-btn"
                                              onClick={() => {
                                                setIsEditingSap(false);
                                                setSapSoInput(null);
                                              }}
                                            >
                                              <svg
                                                width="20px"
                                                height="20px"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="#ff0000"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <path
                                                    d="M19 5L5 19M5.00001 5L19 19"
                                                    stroke="#ff0000"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></path>{" "}
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      
                                        usertype != "CP" && 
                                      
                                      <button
                                        onClick={() => setIsEditingSap(true)}
                                        className="sap-so-btn"
                                      >
                                        <svg
                                          width="20px"
                                          height="20px"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g
                                            id="SVGRepo_bgCarrier"
                                            stroke-width="0"
                                          ></g>
                                          <g
                                            id="SVGRepo_tracerCarrier"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          ></g>
                                          <g id="SVGRepo_iconCarrier">
                                            {" "}
                                            <path
                                              d="M11 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40974 4.40973 4.7157 4.21799 5.09202C4 5.51985 4 6.0799 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.0799 20 7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V12.5M15.5 5.5L18.3284 8.32843M10.7627 10.2373L17.411 3.58902C18.192 2.80797 19.4584 2.80797 20.2394 3.58902C21.0205 4.37007 21.0205 5.6364 20.2394 6.41745L13.3774 13.2794C12.6158 14.0411 12.235 14.4219 11.8012 14.7247C11.4162 14.9936 11.0009 15.2162 10.564 15.3882C10.0717 15.582 9.54378 15.6885 8.48793 15.9016L8 16L8.04745 15.6678C8.21536 14.4925 8.29932 13.9048 8.49029 13.3561C8.65975 12.8692 8.89125 12.4063 9.17906 11.9786C9.50341 11.4966 9.92319 11.0768 10.7627 10.2373Z"
                                              stroke="#000000"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                            ></path>{" "}
                                          </g>
                                        </svg>
                                      </button>
                                      
                                      
                                    )}
                                  </div>
                                )} */}

                                {/* {editSapSo === false &&
                                  (showSONOinput || sapNumber != "" ? (
                                    <div className="w-50 sap-btn-container btn-add ">
                                      <div className="w-50 btn-input-sap  ">
                                        <h6 className="text-center">SO No</h6>

                                        <input
                                          type="text"
                                          class="form-control"
                                          id="inlineFormInput"
                                          placeholder="SO No"
                                          onChange={(e) =>
                                            setAddSapNumber(e.target.value)
                                          }
                                          value={addSapNumber}
                                        />
                                        <div className="d-flex gap-2 align-items-center justify-content-end mt-1 ">
                                          <div>
                                            <button
                                              className="sap-so-btn"
                                              disabled={disabledunitbutton}
                                              onClick={() =>
                                                sapSoNumberUpdate()
                                              }
                                            >
                                              <svg
                                                width="24px"
                                                height="24px"
                                                viewBox="0 -0.5 25 25"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="#00ff11"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <path
                                                    d="M5.5 12.5L10.167 17L19.5 8"
                                                    stroke="#00ff11"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></path>{" "}
                                                </g>
                                              </svg>
                                            </button>
                                          </div>
                                
                                          
                                          <div>
                                            <button
                                              className="sap-so-btn"
                                              onClick={() => {
                                                setShowSONOInput(false);
                                                setAddSapNumber("");
                                              }}
                                            >
                                              <svg
                                                width="20px"
                                                height="20px"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                stroke="#ff0000"
                                              >
                                                <g
                                                  id="SVGRepo_bgCarrier"
                                                  stroke-width="0"
                                                ></g>
                                                <g
                                                  id="SVGRepo_tracerCarrier"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                ></g>
                                                <g id="SVGRepo_iconCarrier">
                                                  {" "}
                                                  <path
                                                    d="M19 5L5 19M5.00001 5L19 19"
                                                    stroke="#ff0000"
                                                    stroke-width="1.5"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                  ></path>{" "}
                                                </g>
                                              </svg>
                                            </button>
                                          </div>

                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  ))} */}
                              </div>
                            </div>
                            {/* {(usertype !== "CP" && !showSONOinput) ? 
                                <div className="btn-add">
                                  
                                  <button
                                    onClick={() => setShowSONOInput(true)}
                                    className="btn add-file-btn text-black px-4 "
                                  >
                                    Add SO No
                                  </button>
                                </div>
                               : ''
                             } */}

                            {/* Add Remark Functionality by Roitex Side */}
                            {/* <div className="d-flex flex-column gap-2">
                              {!addremarkbyRotex && usertype != "CP" && (
                                <div className="btn-add text-end">
                                  <button
                                    onClick={() => {
                                      setAddRemarkByRotex(true);
                                      setShowAddRemark(true);
                                      setUserTypeForRemark("ROTEX");
                                    }}
                                    className="btn add-file-btn  text-black px-3"
                                  >
                                    Add Remark
                                  </button>
                                </div>
                              )}

                              {(addremarkbyRotex || remarkbyrotex != "") && (
                                <button
                                  onClick={() => {
                                    setAddRemarkByRotex(true);
                                    setShowAddRemark(true);
                                    setUserTypeForRemark("ROTEX");
                                  }}
                                  className="btn add-file-btn  text-black px-3"
                                >
                                  View Remark
                                </button>
                              )}
                            </div> */}
                          </div>
                          {/* 
                          <div>
                            <select
                              name=""
                              id=""
                              className={`${
                                rotexOrderPermission == "ACCEPT" ||
                                res?.rotex?.stage == "ACCEPT"
                                  ? "accept"
                                  : "bg-light"
                              } order_flow_select `}
                              value={res?.rotex?.stage}
                              onChange={handlerotexOptionChange}
                              disabled={
                                usertype === "CP" ||
                                rotexOrderPermission === "ACCEPT" ||
                                res?.rotex?.stage === "ACCEPT" ||
                                (usertype === "ROTEX" &&
                                  isAcceptanceAtRotex == "false")
                              }
                            >
                              <option value="PENDING">Pending</option>
                              <option value="ACCEPT">Accept</option>
                            </select>
                          </div> */}
                        </div>
                      </div>
                    )}

                    {res?.sp?.status == true && (
                      <div className="cp_name d-flex justify-content-between align-items-center px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">Approval</p>
                            <div className="d-flex gap-4">
                              <div className="m-0 d-flex align-items-center gap-2">
                                <img src={calender_img} alt="img-fluid" />
                                <Moment format="MMM,DD HH:mm">
                                  {res?.sp?.createdAt}
                                </Moment>
                              </div>
                              <div className="m-0 d-flex align-items-center gap-2">
                                <img src={unit_img} alt="img-fluid" />

                                <span> Qty : {res?.sp?.qty} </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex flex-column-reverse gap-1 justify-content-end align-items-end   ">
                          {/* // Add Remark Functionality */}
                          {/* <div className="d-flex flex-column gap-2">
                            {!(addremarkbyCP || remarkbyCP) &&
                              usertype == "SP" && (
                                <div className="btn-add">
                                  <button
                                    onClick={() => {
                                      setAddRemarkByCP(true);
                                      setShowAddRemark(true);
                                      setUserTypeForRemark("CP");
                                    }}
                                    className="btn add-file-btn text-black"
                                  >
                                    Add Remark
                                  </button>
                                </div>
                              )}
                            {remarkbyCP && (
                              <button
                                onClick={() => {
                                  setAddRemarkByCP(true);
                                  setShowAddRemark(true);
                                  setUserTypeForRemark("CP");
                                }}
                                className="btn add-file-btn text-black"
                              >
                                View Remark
                              </button>
                            )}
                          </div> */}

                          {/* Add file functionality */}
                          <div className="btn-container">
                            {/* {res?.cp?.stage == "ACCEPT" &&
                              res?.rotex?.stage != "ACCEPT" &&
                              usertype == "SP" &&
                              downloadFile != "" && (
                                <div>
                                  <label className="btn add-file-btn">
                                    <svg
                                      width="20px"
                                      height="20px"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <path
                                          d="M11 4H7.2C6.0799 4 5.51984 4 5.09202 4.21799C4.71569 4.40974 4.40973 4.7157 4.21799 5.09202C4 5.51985 4 6.0799 4 7.2V16.8C4 17.9201 4 18.4802 4.21799 18.908C4.40973 19.2843 4.71569 19.5903 5.09202 19.782C5.51984 20 6.0799 20 7.2 20H16.8C17.9201 20 18.4802 20 18.908 19.782C19.2843 19.5903 19.5903 19.2843 19.782 18.908C20 18.4802 20 17.9201 20 16.8V12.5M15.5 5.5L18.3284 8.32843M10.7627 10.2373L17.411 3.58902C18.192 2.80797 19.4584 2.80797 20.2394 3.58902C21.0205 4.37007 21.0205 5.6364 20.2394 6.41745L13.3774 13.2794C12.6158 14.0411 12.235 14.4219 11.8012 14.7247C11.4162 14.9936 11.0009 15.2162 10.564 15.3882C10.0717 15.582 9.54378 15.6885 8.48793 15.9016L8 16L8.04745 15.6678C8.21536 14.4925 8.29932 13.9048 8.49029 13.3561C8.65975 12.8692 8.89125 12.4063 9.17906 11.9786C9.50341 11.4966 9.92319 11.0768 10.7627 10.2373Z"
                                          stroke="#000000"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        ></path>{" "}
                                      </g>
                                    </svg>
                                    <input
                                      type="file"
                                      onChange={(event) =>
                                        handleFileInputChange(event, res?._id)
                                      }
                                      className="browse-file"
                                    />
                                  </label>
                                </div>
                              )} */}
                            {/* // Download Image Functionality */}
                            {/* {res?.Sp?.stage == "ACCEPT" && (
                              <button
                                className="btn bg-white"
                                type="button"
                                onClick={() => handleDownload()}
                                disabled={
                                  downloadFile === "" || fileStatus == true
                                }
                              >
                                <img
                                  src="/assets/images/download-icon.svg"
                                  alt=""
                                  className="img-fluid"
                                />
                              </button>
                            )} */}
                            <div>
                              {/* {res?.cp?.stage == "ACCEPT" &&
                                res?.rotex?.stage != "ACCEPT" &&
                                usertype == "SP" &&
                                downloadFile == "" && (
                                  <div>
                                    <label className="btn add-file-btn">
                                      Browse Files
                                      <input
                                        type="file"
                                        onChange={(event) =>
                                          handleFileInputChange(event, res?._id)
                                        }
                                        className="browse-file"
                                      />
                                    </label>
                                  </div>
                                )} */}
                            </div>
                            <select
                              name=""
                              id=""
                              className={`${
                                // cpOrderPermission == "ACCEPT" ||
                                res?.sp?.stage == "ACCEPT"
                                  ? "accept"
                                  : "bg-light"
                              } order_flow_select`}
                              value={res?.sp?.stage}
                              onChange={handleCPOptionChange}
                              disabled={
                                usertype != "SP" || res?.sp?.stage == "ACCEPT"
                                // || cpOrderPermission == "ACCEPT"
                              }
                            >
                              <option value="PENDING">Pending</option>
                              <option value="ACCEPT">Accept</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

                    {res?.wip?.status == true && (
                      <div className="cp_name  d-flex justify-content-between px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">Work In Progress</p>
                            <div className="d-flex gap-4">
                              <div className="m-0 d-flex align-items-center gap-2">
                                <img src={calender_img} alt="img-fluid" />
                                <Moment format="MMM,DD HH:mm">
                                  {res?.rotex?.createdAt}
                                </Moment>
                              </div>
                              <div className="m-0 d-flex align-items-center gap-2">
                                <img src={unit_img} alt="img-fluid" />

                                <span> Qty : { !res?.in_trasit?.status ?    res?.wip?.qty :  res?.wip?.partialDispatchQty ?     res?.wip?.qty +  res?.wip?.partialDispatchQty  : res?.wip?.qty   }
                                   {/* { res?.wip?.isChangeQty ?  res?.wip?.qty  :  res?.sp?.qty } */}
                                    </span>
                              </div>
                            </div>
{/* 
                            {(res?.wip?.isPartialDispatch ||
                              res?.partialOrder?.length > 1) &&
                              res?.partialOrder.map((date, i) => (
                                <>
                                  {i + 1} )
                                  <div className="m-0 d-flex align-items-center gap-2">
                                    <img src={calender_img} alt="img-fluid" />
                                    <span>
                                      Partial Add to Stock Date:-
                                      <Moment format="MMM,DD HH:mm">
                                        {date?.wip?.createdAt}
                                      </Moment>
                                    </span>
                                  </div>
                                  <div className="m-0 d-flex align-items-center gap-2">
                                    <img src={unit_img} alt="img-fluid" />
                                    <span>
                                      Partial Add to Stock Qty :{" "}
                                      {date?.wip?.partialDispatchQty}{" "}
                                    </span>
                                  </div>
                                  <div className="m-0 d-flex align-items-center gap-2">
                                    <img src={unit_img} alt="img-fluid" />
                                    <span>
                                      Remaining Qty : {date?.remainingQty}{" "}
                                    </span>
                                  </div>
                                </>
                              ))} */}

                            {res?.wip?.stage != "PENDING" && (
                              <>
                                {res?.wip?.stage == "ACCEPT" && (
                                  <div className="m-0 d-flex align-items-center gap-2">
                                    <img src={calender_img} alt="img-fluid" />
                                    <span>
                                      End Date :-
                                      <Moment format="MMM,DD HH:mm">
                                        {res?.wip?.createdAt}
                                      </Moment>
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>

                        <div className="d-flex gap-2 align-items-center">
                          <span>Add to Stock</span>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input order_toggle"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              checked={
                                wiptoggleactive == true ||
                                res?.wip?.stage == "ACCEPT"
                              }
                              value="ACCEPT"
                              disabled={
                                res?.wip?.stage == "ACCEPT" ||
                                usertype == "CP" ||
                                (usertype === "ROTEX" &&
                                  isAcceptanceAtWIP == "false")
                              }
                              onChange={handleWIPOptionChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                     {res?.in_trasit?.status == true && (
                      <div className="cp_name  d-flex justify-content-between px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">Added to Stock</p>
                            {/* <div className="m-0 d-flex align-items-center gap-2">
                              <img src={calender_img} alt="img-fluid" />
                              <span>
                                {" "}
                                Start Date :-
                                <Moment format="MMM,DD HH:mm">
                                  {res?.wip?.createdAt}
                                </Moment>
                              </span>
                            </div> */}
                              <>
                              
                               
 <div className="m-0 d-flex align-items-center gap-2">
                              <img src={unit_img} alt="img-fluid" />

                              <span>Qty : {res?.qty}
                                 {/* {(res?.wip?.isPartialDispatch ||
                              res?.partialOrder?.length > 1)  ?  res?.wip?.partialDispatchQty :res?.wip?.qty} */}
                              
                              </span>
                            </div>
                              </>
                            
                          </div>
                        </div>
                        {/* <div className="d-flex gap-2 align-items-center">
                          <span>GRN</span>
                          <div className="form-check form-switch">
                            <input
                              className="form-check-input order_toggle"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              checked={
                                intrasittoggleactive == true ||
                                res?.in_trasit?.stage == "ACCEPT"
                              }
                              value="ACCEPT"
                              disabled={
                                res?.in_trasit?.stage == "ACCEPT" ||
                                usertype == "SP"
                              }
                              onChange={handleTransitOptionChange}
                            />
                          </div>
                        </div> */}
                      </div>
                    )} 
                    {/* {res?.grn?.status == true && (
                      <div className="cp_name  px-4 ">
                        <div className="d-flex align-items-center gap-4 ">
                          <AiOutlineEye className="view_icons" />
                          <div>
                            <p className="m-0">GRN</p>
                            <p className="m-0">
                              <Moment format="MMM,DD HH:mm">
                                {res?.grn?.createdAt}
                              </Moment>
                            </p>
                            <p className="m-0">Qty : {res?.grn?.qty}</p>
                          </div>
                        </div>
                      </div>
                    )} */}
                  </>
                ))}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* MODAL BOX FOR ADD REMARK 
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showAddRemark}
        onClose={() => setShowAddRemark(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showAddRemark}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex align-items-center justify-content-between w-100 ">
                <p className="m-0"> Add Remark</p>
                <button
                  className="closebtn_cont"
                  onClick={() => setShowAddRemark(false)}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                <div className="col-md-12 d-flex flex-column gap-1 ">
                  <label htmlFor="" className="mb-1">
                    Enter Remark
                  </label>
                  {userTypeForRemark == "CP" ? (
                    <textarea
                      name=""
                      id=""
                      cols="60"
                      rows="5"
                      placeholder="Enter Remark"
                      className="Remark_text_box"
                      value={remarkbyCP}
                      onChange={(e) => setRemarkByCP(e.target.value)}
                      disabled={usertype != "CP"}
                    ></textarea>
                  ) : (
                    <textarea
                      name=""
                      id=""
                      cols="60"
                      rows="5"
                      placeholder="Enter Remark"
                      className="Remark_text_box"
                      value={remarkbyrotex}
                      onChange={(e) => setRemarkByROTEX(e.target.value)}
                      disabled={usertype == "CP"}
                    ></textarea>
                  )}
                </div>
              </div>
              <div className="addbox_btn_cont">
                <button
                  onClick={() => {
                    AddremarkCP();
                  }}
                  disabled={
                    (userTypeForRemark == "CP" &&
                      (usertype == "ROTEX" || usertype == "ADMIN")) ||
                    (userTypeForRemark != "CP" && usertype == "CP")
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal> */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showConfirmPopup}
        onClose={() => handleclodeqtyconfirmation()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showConfirmPopup}>
          <Box
            sx={styleForMediaQuery}
            className="model_box order_confirmation_box"
          >
            <div>
              <h4 className="mb-4"> Confirmation</h4>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
              <p className="m-0">
                Are you sure you want to add {quantityValue} Qtys?
              </p>
            </div>
            <div className="d-flex gap-2 align-items-center justify-content-end ">
              {confirmdispatch ? (
                <>
                 <button
                 className="btn btn-primary "
                 onClick={() => {
                   setShowChangeqtyPopup(true);
                 
                 }}
               >
                 Change Qty
               </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setShowConfirmPopup(false);

                      EditOrderRecoommand("ACCEPT" , false);
                    }}
                    disabled={disabledunitbutton }
                  >
                    Add Fully
                  </button>
                  <button
                    className="btn btn-outline-primary"
                    onClick={() => {
                      setShowPartiallyqtyPopup(true);
                      setPartiallyDispatch(true);
                    }}
                    disabled={quantityValue == '0'}
                  >
                    Add Partially
                  </button>
                 
                </>
              ) : (
                <>
                <button
                  className="btn btn-outline-primary"
                  onClick={() => {
                    setShowConfirmPopup(false);

                    EditOrderRecoommand("ACCEPT" , false);
                  }}
                  disabled={disabledunitbutton}
                >
                  Yes
                </button>
                 <button
                 className="btn btn-primary "
                 onClick={() => {
                   setShowChangeqtyPopup(true);
                 }}
               >
                 Change Qty
               </button>
               </>
              )}
   
             
            </div>
            {/* { confirmdispatch &&
      quantityValue == '0' && 
      <p className="text-end mt-2 mb-0 text-danger fw-bold " > Please change your QTY first and than add  </p> 
    } */}
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showConfirmPopupforpartially}
        onClose={() => handlecloseconfirmationforPartially()}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showConfirmPopupforpartially}>
          <Box
            sx={styleForMediaQuery}
            className="model_box order_confirmation_box"
          >
            <div>
              <h4 className="mb-4"> Confirmation</h4>
            </div>
            <div className="d-flex flex-column gap-1 mb-3">
              <p className="m-0">Do you want to add partially ?</p>
            </div>
            <div className="d-flex gap-2 align-items-center justify-content-end ">
              <button
                className="btn btn-outline-primary"
                onClick={() => {
                  setShowConfirmPopup(false);
                  setShowPartiallyqtyPopup(true);
                  setPartiallyDispatch(true);
                }}
              >
                Yes
              </button>
              <button
                className="btn btn-primary "
                onClick={() => {
                  setShowConfirmPopup(false);
                  Setdisableunitbtn(true);

                  setShowPartiallyqtyPopup(false);
                  EditOrderRecoommand("ACCEPT" , false);
                }}
                disabled={disabledunitbutton}
              >
                No
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showpartiallyqtyPopup}
        onClose={() => setShowPartiallyqtyPopup(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showpartiallyqtyPopup}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex align-items-center gap-3 ">
                <button
                  className="closebtn_cont"
                  onClick={() => {
                    setShowPartiallyqtyPopup(false);
                    setPartiallyDispatch(false);
                  }}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                <div className="col- md-12">
                  <label htmlFor="" className="mb-1">
                    Enter partial Add to Stock qty
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter QTY "
                    className="add_inut_field"
                    value={partiallyquantityValue}
                    onChange={(e) =>
                      setPartiallyQuantityValue(
                        e.target.value === "" ? "" : parseInt(e.target.value)
                      )
                    }
                  />
                </div>
              </div>
              <div className="addbox_btn_cont">
                <button
                  onClick={() => {
                    Setdisableunitbtn(true);
                    EditOrderRecoommand("ACCEPT",false);
                  }}
                  disabled={disabledunitbutton}
                >
                  Save
                </button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={showChangeqtyPopup}
        onClose={() => setShowChangeqtyPopup(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={showChangeqtyPopup}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <p className="m-0">Add QTY </p>
              <div className="d-flex align-items-center gap-3 ">
                <button
                  className="closebtn_cont"
                  onClick={() => setShowChangeqtyPopup(false)}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <div className="row">
                <div className="col- md-12">
                  <label htmlFor="" className="mb-1">
                    QTY{" "}
                  </label>
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Enter QTY "
                    className="add_inut_field"
                    value={quantityValue}
                    onChange={(e) =>
                      setQuantityValue(
                        e.target.value === "" ? "" : parseInt(e.target.value)
                      )
                    }
                  />
                </div>
              </div>
              <div className="addbox_btn_cont">
                {confirmdispatch ? (
                  <button
                    onClick={() => {
                          EditOrderRecoommand( "ACCEPT",true);
                       
               
                       
                    }}
                  >
                     Save
                  </button>
                ) : (
                  
                  <button
                    onClick={() => {
                 
                        EditOrderRecoommand("ACCEPT",false);
                        
                     
                      
                    }}
                    disabled={disabledunitbutton}
                  >
                    Save
                  </button>
               )} 
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default OrderTable;
