import React , {useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import store from "../../../../Redux/MyRedux/store";
import { RxCross2 } from "react-icons/rx";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {  updateSpMaster, viewSpMaster, spMasterChangeStatus } from "../../../../Redux/MyRedux/action/phase3";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { TbPencil } from "react-icons/tb";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#F4F6FA",
    boxShadow: 24,
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };

export default function EditModal({id  , setUpdateAPI}) {

  


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

 
  const [spstatus, setSPStatus] = useState(false);



 

 

  

  const [initialvalueforupdate, setInitialValueForUpdate] = useState({
    updatecpname: "",
    updatecontactperson: "",
    updatecity: "",
    updatestate: "",
    updateaddress: "",
    updatephone: "",
    updateemail: "",
  
  });

  const validationSchemaforupdate = Yup.object().shape({
    updatecpname: Yup.string().required("Supplier Name is required"),
    updatecontactperson: Yup.string().required("Contact Person is required"),
    updatecity: Yup.string().required("City is required"),
    updatestate: Yup.string().required("State is required"),
    updateaddress: Yup.string().required("Address is required"),
    updatephone: Yup.string()
      .matches(/^\d{10}$/, "Phone must be exactly 10 digits")
      .required("Phone is required"),
    updateemail: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
    // updateleadtime: Yup.number()
    //   .typeError("Lead Time must be a number")
    //   .required("Lead Time is required")
    //   .min(0, "Lead Time must be at least 0")
    //   .integer("Lead Time must be an integer"),
  });

  const onUpdate = (values, { resetForm }) => {
    const data = {
        supplierName: values.updatecpname,
      contactPersonName: values.updatecontactperson,
      phone: values.updatephone,
      email: values.updateemail,
      address: values.updateaddress,
      city: values.updatecity,
      state: values.updatestate,
    //   leadTime: values.updateleadtime,
    };
    try {
      store.dispatch(updateSpMaster(data, id)).then((res) => {
        if (res?.isError == false) {
          handleClose();
          toast.success(res?.message);
          setUpdateAPI('update')
         
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
      return error;
    }
    resetForm();
  };


  const handleViewSP = () => {
    try {
      store.dispatch(viewSpMaster(id)).then((res) => {
        if (res?.isError == false) {
          setInitialValueForUpdate({
            updatecpname: res?.data?.supplierName || "",
            updatecontactperson: res?.data?.contactPersonName || "",
            updatecity: res?.data?.city || "",
            updatestate: res?.data?.state || "",
            updateaddress: res?.data?.address || "",
            updatephone: res?.data?.phone || "",
            updateemail: res?.data?.email || "",
            // updateleadtime: res?.data?.leadTime || 0,
          });
          setSPStatus(res?.data?.isActive);
            handleOpen();
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };



 

  const handleupdateSPstatus = (id, value) => {
    const data = {
      active: value,
    };
    try {
      store.dispatch(spMasterChangeStatus(id , data)).then((res) => {
        if (res?.isError == false) {
         
          toast.success(res?.message);
         setUpdateAPI('status')         
          
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
      return error;
    }
  };


  const handleStatusUpdate = () => {
    const Updatecpstatus = !spstatus;
    setSPStatus(Updatecpstatus);
    handleupdateSPstatus(id, Updatecpstatus);
  };



  return (
    <div>
        <button  className='table_edit_btn'
                  
                  onClick={() => {
                   
                    handleViewSP();

                  }}
                  
                  >
                         <TbPencil />
                         </button>
    
                         <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex align-items-center gap-3">
                <button className="table_edit_btn">
                  <TbPencil />
                </button>
                <p className="m-0">Edit Supplier</p>
              </div>
              <div className="d-flex align-items-center gap-5 ">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input addbox_radio"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={spstatus == true}
                    onChange={handleStatusUpdate}
                  />
                </div>
                <button
                  className="closebtn_cont"
                  onClick={() => handleClose()}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <Formik
                initialValues={initialvalueforupdate}
                validationSchema={validationSchemaforupdate}
                onSubmit={onUpdate}
              >
                {(formik) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="cpname" className="mb-1">
                        Supplier Name*
                        </label>
                        <Field
                          type="text"
                          name="updatecpname"
                          id="updatecpname"
                          placeholder="Enter Supplier Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatecpname"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="contactperson" className="mb-1">
                          Contact Person*
                        </label>
                        <Field
                          type="text"
                          name="updatecontactperson"
                          id="updatecontactperson"
                          placeholder="Enter Contact Person Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatecontactperson"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="updatecity" className="mb-1">
                          City*
                        </label>
                        <Field
                          type="text"
                          name="updatecity"
                          id="updatecity"
                          placeholder="Enter City"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatecity"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="updatestate" className="mb-1">
                          State*
                        </label>
                        <Field
                          type="text"
                          name="updatestate"
                          id="updatestate"
                          placeholder="Enter State"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatestate"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="updateaddress" className="mb-1">
                          Address*
                        </label>
                        <Field
                          type="text"
                          name="updateaddress"
                          id="updateaddress"
                          placeholder="Enter Address"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updateaddress"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="updatephone" className="mb-1">
                          Phone*
                        </label>
                        <Field
                          type="text"
                          name="updatephone"
                          id="updatephone"
                          placeholder="Enter Phone"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatephone"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="updateemail" className="mb-1">
                          Email*
                        </label>
                        <Field
                          type="text"
                          name="updateemail"
                          id="updateemail"
                          placeholder="Enter Email"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updateemail"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      {/* <div className="col-md-6">
                        <label htmlFor="updateleadtime" className="mb-1">
                          Lead Time* (In Weeks)
                        </label>
                        <Field
                          type="number"
                          name="updateleadtime"
                          id="updateleadtime"
                          placeholder="Enter Lead Time"
                          className="add_inut_field lead_time_field"
                        />
                        <ErrorMessage
                          name="updateleadtime"
                          component="p"
                          className="error-message"
                        />
                      </div> */}
                    </div>
                    <div className="addbox_btn_cont mt-2">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
