import React, { useState } from 'react'
import Sidebar from '../../../Sidebar/Sidebar'
import OrderTable from './table';
import Header from '../../../Header/Header';
function SpOrderList() {
  const [sidebaropen , setSidebarOpen] = useState(false)

  return (
    
     <div className='d-flex'>
     <Sidebar setSidebarOpen={setSidebarOpen} />
     <div className={`${sidebaropen ? 'wrapper_full' : 'wrapper_half'}`}>
     <Header  />
     <OrderTable />
     </div> 
 </div>
  )
}

export default SpOrderList;
