import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { LuFilter } from "react-icons/lu";
import Select from "react-select";
import store from "../../../../Redux/MyRedux/store";
import { AiOutlineClose } from "react-icons/ai";
import { rotexInventoryDropdown } from "../../../../Redux/MyRedux/action/phase3";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  bgcolor: "#F4F6FA",
  border: "none",
  boxShadow: 24,
  borderRadius: "10px",
 

  pb: 3,
  fontFamily: "Poppins",
  fontSize: "14px",
  fontWeight: 500,
};

export default function FilterModal({stateSetters , propsState}) {
  const [inventoryDropDown, setInventoryDropdown] = React.useState([]);

  const getInventoryListDropDown = () => {
    try {
      store.dispatch(rotexInventoryDropdown()).then((res) => {
        if (res?.isError === false) {
          setInventoryDropdown(res?.data);
        }
      });
    } catch (error) {
      console.log("Error Fetching :", error);
    }
  };

  const uniqueTogValues = [
    ...new Set(inventoryDropDown.map((val) => val?.tog)),
  ];
  const uniqueOptionsTOG = uniqueTogValues.map((roundedTogValue) => ({
    value: roundedTogValue,
    label: Math.round(roundedTogValue)
  }));

  const uniqueOnHandStockValues = [
    ...new Set(inventoryDropDown.map((val) => val?.onHandStock)),
  ];
  const uniquehandstockLabelObjects = uniqueOnHandStockValues.map(
    (roundedOnhandStockValue) => ({
      value: roundedOnhandStockValue,
      label: Math.round(roundedOnhandStockValue)
    })
  );

  const uniqueCpnameValues = [
    ...new Set(inventoryDropDown.map((val) => val?.companyName)),
  ];
  const uniquecpnameLabelObjects = uniqueCpnameValues.map((roundedcpValue) => ({
    value: roundedcpValue,
    label: roundedcpValue, // Convert to string if necessary
  }));





  const optionlistItemCode = [
    ...new Set(inventoryDropDown.map((val) => val?.itemCode)),
  ];
  const uniqueitemcodeLabelObjects = optionlistItemCode.map(
    (roundeditemCodeValue) => ({
      value: roundeditemCodeValue,
      label: roundeditemCodeValue, // Convert to string if necessary
    })
  );

  const optionlistconsumption = [
    ...new Set(inventoryDropDown.map((val) => val?.avgWeeklyConsumption)),
  ];
  const uniqueconsumptionLabelObjects = optionlistconsumption.map(
    (roundedavgValue) => ({
      value: roundedavgValue,
      label: roundedavgValue, // Convert to string if necessary
    })
  );

  const optionlistcleadTime = [
    ...new Set(inventoryDropDown.map((val) => val?.leadTime)),
  ];
  const uniqueleadtimeLabelObjects = optionlistcleadTime.map(
    (roundedleadTimeValue) => ({
      value: roundedleadTimeValue,
      label: roundedleadTimeValue, // Convert to string if necessary
    })
  );

  const optionlistFactorySafety = [
    ...new Set(inventoryDropDown.map((val) => val?.factorOfSafety)),
  ];
  const uniquefactorysafetyLabelObjects = optionlistFactorySafety.map(
    (roundedfactorOfSafetyValue) => ({
      value: roundedfactorOfSafetyValue,
      label: roundedfactorOfSafetyValue, // Convert to string if necessary
    })
  );

  const optionlistqulifiedDemand = [
    ...new Set(inventoryDropDown.map((val) => val?.qualifiedDemand)),
  ];
  const uniqueQDLabelObjects = optionlistqulifiedDemand.map(
    (roundedqualifiedDemandValue) => ({
      value: roundedqualifiedDemandValue,
      label: roundedqualifiedDemandValue, // Convert to string if necessary
    })
  );

  const optionlistNetFlow = [
    ...new Set(inventoryDropDown.map((val) => val?.netFlow)),
  ];
  const uniqueNetFlowLabelObjects = optionlistNetFlow.map(
    (roundednetFlowValue) => ({
      value: roundednetFlowValue,
      label: roundednetFlowValue, // Convert to string if necessary
    })
  );

  const optionlistMoq = [...new Set(inventoryDropDown.map((val) => val?.moq))];
  const uniqueMoqLabelObjects = optionlistMoq.map((roundedmoqValue) => ({
    value: roundedmoqValue,
    label: roundedmoqValue, // Convert to string if necessary
  }));

  const uniqueorderRecoomandLabels = [
    ...new Set(inventoryDropDown.map((obj) => obj.orderRecommendation)),
  ];
  const uniqueorderRecoomandLabelObjects = uniqueorderRecoomandLabels.map(
    (label) => ({
      value: label,
      label: label, // Convert to string if necessary
    })
  );

  const uniqueunitLabels = [
    ...new Set(inventoryDropDown.map((obj) => obj.unit)),
  ];
  const uniqueunitLabelObjects = uniqueunitLabels.map((label) => ({
    value: label,
    label: label, // Convert to string if necessary
  }));

  const uniqueOpenOrderLabels = [
    ...new Set(inventoryDropDown.map((obj) => obj.openOrder)),
  ];
  const uniqueOpenOrderLabelObjects = uniqueOpenOrderLabels.map((label) => ({
    value: label,
    label: label, // Convert to string if necessary
  }));

  const uniqueRealAWCLabels = [
    ...new Set(inventoryDropDown.map((obj) => Math.round(obj?.avg))),
  ];
  const uniqueRealAWCLabelObjects = uniqueRealAWCLabels.map((label) => ({
    value: label,
    label: label, // Convert to string if necessary
  }));

  const uniqueItemDescLabels = [
    ...new Set(inventoryDropDown.map((obj) => obj.itemDescription)),
  ];
  const uniqueItemDescLabelObjects = uniqueItemDescLabels.map((label) => ({
    value: label,
    label: label, // Convert to string if necessary
  }));

  const uniqueorderRecoomandstatusLabels = [
    ...new Set(inventoryDropDown.map((obj) => obj?.orderRecommendationStatus)),
  ];
  const uniqueorderRecoomandstatusLabelObjects =
    uniqueorderRecoomandstatusLabels.map((label) => ({
      value: label,
      label: Math.round(label)
    }));

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


    const clearFilter = () => {
      stateSetters.setFilterTOG([]);
      stateSetters.setFilterInHandStock([]);
      stateSetters.setFilterNetFlow([]);
      stateSetters.setFilterCPName([]);

      stateSetters.setFilterItemCode([]);
      stateSetters.setFilterAvgConsumption([]);
      stateSetters.setFilterQualifiedDemand([]);
      stateSetters.setFilterLeadTime([]);
      stateSetters.setFilterFactorySafety([]);
      stateSetters.setFilterMOQ([]);
      stateSetters.setFilterOrderRecommendation([]);
      stateSetters.setFilterUnit([]);
      stateSetters.setFilterRealAWC([]);
      stateSetters.setFilterOpenOrder([]);
      stateSetters.setFilterOrderRecommendationStatus([]);
      stateSetters.setFilterItemDesc([]);
  
      stateSetters.setUpdateAPI("253")
  
  };
  return (
    <div>
      <button
        className="filter_btn"
        type="button"
        onClick={() => {
          getInventoryListDropDown();
          handleOpen();
        }}
      >
        <LuFilter />
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={style}>
          <div className="filter_modal_header">
            <div className="d-flex justify-content-between ">
              <div className="modal_heading d-flex align-items-center gap-2">
                <button
                  className="filter_btn"
                  type="button"
                
                 
                >
                  <LuFilter />
                </button>{" "}
                <span> Filter </span>
              </div>
              <div className="close_modal_btn"   onClick={() => handleClose()}>
                <AiOutlineClose />
              </div>
            </div>
          </div>
          <div className="filter_select_container">
            <div className="">
              <div className="filter_select_container ">
                <div className="row">
                  <div className="form-group col-md-3  ">
                 
                    <Select
                      options={uniqueOptionsTOG}
                      placeholder="TOG"
                      value={propsState.filtertog}
                      onChange={(e) => stateSetters.setFilterTOG(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                  <div className="form-group col-md-3">
                  
                    <Select
                      options={uniquehandstockLabelObjects}
                      placeholder="On HandStock"
                      value={propsState.filterinHandStock}
                      onChange={(e) => stateSetters.setFilterInHandStock(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>

                  <div className="form-group col-md-3">
               
                    <Select
                      options={uniquecpnameLabelObjects}
                      placeholder="Rotex Name"
                      value={propsState.filterCpName}
                      onChange={(e) => stateSetters.setFilterCPName(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                  <div className="form-group col-md-3">
                 
                 <Select
                   options={uniqueitemcodeLabelObjects}
                   placeholder="Item Code"
                   value={propsState.filterItemCode}
                   onChange={(e) => stateSetters.setFilterItemCode(e)}
                   isSearchable={true}
                   className="react_inbuild_select"
                   isMulti
                 />{" "}
               </div>
                </div>
                <div className="row">
                  
               
                  <div className="form-group col-md-3">
                  
                    <Select
                      options={uniqueconsumptionLabelObjects}
                      placeholder="Avg. Weekly consumption"
                      value={propsState.filterAvgConsumption}
                      onChange={(e) => stateSetters.setFilterAvgConsumption(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                  <div className="form-group col-md-3">
                 
                 <Select
                   options={uniqueleadtimeLabelObjects}
                   placeholder="Lead Time"
                   value={propsState.filterLeadTime}
                   onChange={(e) => stateSetters.setFilterLeadTime(e)}
                   isSearchable={true}
                   className="react_inbuild_select"
                   isMulti
                 />{" "}
               </div>
               <div className="form-group col-md-3">
                
                 <Select
                   options={uniquefactorysafetyLabelObjects}
                   placeholder="Factor of Safety"
                   value={propsState.filterFactorySafety}
                   onChange={(e) => stateSetters.setFilterFactorySafety(e)}
                   isSearchable={true}
                   className="react_inbuild_select"
                   isMulti
                 />{" "}
               </div>
               
               <div className="form-group col-md-3">
                  
                  <Select
                    options={uniqueQDLabelObjects}
                    placeholder="Qualified Demand"
                    value={propsState.filterQualifiedDemand}
                    onChange={(e) => stateSetters.setFilterQualifiedDemand(e)}
                    isSearchable={true}
                    className="react_inbuild_select"
                    isMulti
                  />{" "}
                </div>
                </div>
                <div className="row">
                 
                
                  <div className="form-group col-md-3">
                   
                    <Select
                      options={uniqueNetFlowLabelObjects}
                      placeholder="NetFlow"
                      value={propsState.filterNetflow}
                      onChange={(e) => stateSetters.setFilterNetFlow(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                  <div className="form-group col-md-3">
                  
                    <Select
                      options={uniqueMoqLabelObjects}
                      placeholder="MOQ"
                      value={propsState.filterMoq}
                      onChange={(e) => stateSetters.setFilterMOQ(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                  <div className="form-group col-md-3">
                   
                    <Select
                      options={uniqueorderRecoomandLabelObjects}
                      placeholder="Order Recommendation"
                      value={propsState.filterOrderRecommendation}
                      onChange={(e) => stateSetters.setFilterOrderRecommendation(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                  <div className="form-group col-md-3">
                
                    <Select
                      options={uniqueunitLabelObjects}
                      placeholder="Unit"
                      value={propsState.filterUnit}
                      onChange={(e) => stateSetters.setFilterUnit(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                </div>
                <div className="row">
                  
                  <div className="form-group col-md-3">
                 
                    <Select
                      options={uniqueRealAWCLabelObjects}
                      placeholder="Real Time AWC"
                      value={propsState.filterRealAWC}
                      onChange={(e) => stateSetters.setFilterRealAWC(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                  <div className="form-group col-md-3">
                    
                    <Select
                      options={uniqueOpenOrderLabelObjects}
                      placeholder="Open Order"
                      value={propsState.filterOpenOrder}
                      onChange={(e) => stateSetters.setFilterOpenOrder(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                  <div className="form-group col-md-3">
                 
                    <Select
                      options={uniqueorderRecoomandstatusLabelObjects}
                      placeholder="Order Recommendation Status"
                      value={propsState.filterOrderRecommendationStatus}
                      onChange={(e) => stateSetters.setFilterOrderRecommendationStatus(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                  <div className="form-group col-md-3">
                  
                    <Select
                      options={uniqueItemDescLabelObjects}
                      placeholder="Item Description"
                      value={propsState.filterItemDesc}
                      onChange={(e) => stateSetters.setFilterItemDesc(e)}
                      isSearchable={true}
                      className="react_inbuild_select"
                      isMulti
                    />{" "}
                  </div>
                </div>
               
              </div>
              <div className="drawer-foot-btns ">
                <button className="btn clear_btn" onClick={() => clearFilter()}>
                  Clear
                </button>
                <div className="">
                  <button
                    className="btn blue-btn p-2 px-3"
                   onClick={() => {handleClose() ; stateSetters.setUpdateAPI('update')}}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
