import React, { useState, useEffect } from "react";

import store from "../../../Redux/MyRedux/store";
import Pagination from "react-mui-pagination";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { LuSearch } from "react-icons/lu";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import { FaRegRectangleList } from "react-icons/fa6";
import sort_logo_img from "../../../Assets/table/shortarrow.png";
import FilterModal from "./filterpopup/filter.jsx";
import { spMasterChangeStatus, spMaterList, updateSpExcelSheet } from "../../../Redux/MyRedux/action/phase3.js";
import { Tooltip as ReactTooltip } from 'react-tooltip'
import AddModal from "./AddSP/add.jsx";
import EditModal from "./EditSP/edit.jsx";
function CustomTable() {
  const [filterCpName, setFilterCpName] = useState([]);
  const [filterContactPerson, setFilterContactPerson] = useState([]);
  const [filterCity, setFilterCity] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [filterLeadTime, setFilterLeadTime] = useState([]);
  const [filterEmail, setFilterEmail] = useState([]);
  const [filterPhone, setFilterPhone] = useState([]);
  const [filteraddress, setFilterAddress] = useState([]);
  const [updatedAPI, setUpdateAPI] = useState("");

  const stateSetters = {
    setFilterCpName,
    setFilterContactPerson,
    setFilterCity,
    setFilterState,
    setFilterLeadTime,
    setFilterEmail,
    setFilterPhone,
    setFilterAddress,
    setUpdateAPI,
  };

  const propsState = {
    filterCpName,
    filterContactPerson,
    filterCity,
    filterState,
    filterLeadTime,
    filterEmail,
    filterPhone,
    filteraddress,
  };

  const [page, setPage] = React.useState(1);
  const [sortOrder, setSortOrder] = useState("asc"); // Default to ascending order

  const [total_count, setTotalCount] = React.useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [Search, setSearch] = useState("");

  const [listdata, setListdata] = useState([]);
  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      supplierName: filterCpName.map((item) => item.label),
      contactPersonName: filterContactPerson.map((item) => item.label),
      phone: filterPhone.map((item) => item.label),
      email: filterEmail.map((item) => item.label),
      city: filterCity.map((item) => item.label),
      state: filterState.map((item) => item.label),
      address: filteraddress.map((item) => item.label),
      isActive: true,
    };

    try {
      store.dispatch(spMaterList(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data[0]?.docs.length > 0) {
            setListdata(res?.data[0]?.docs);
            setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
            setUpdateAPI('')
          } else {
            setListdata([]);
            setTotalCount(0);
          }
        }
        setUpdateAPI('')

      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getdata();
  }, [recordperpage, page, Search, updatedAPI]);


  const handleupdateSPstatus = (id, value) => {
    const data = {
      active: value,
    };
    try {
      store.dispatch(spMasterChangeStatus(id , data)).then((res) => {
        if (res?.isError == false) {
         
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
      return error;
    }
  };

 
  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      "Supplier",
      "Contact Person",
      "City",
      "State",
      "Address",
      "Phone",
      "Email",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [
        item.supplierName,
        item.contactPersonName,
        item.city,
        item.state,
        item.address,
        item.phone,
        item.email,
      ]),
    ];

    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "SupplierMaster.xlsx");
  };

  const navigate = useNavigate();

  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.isActive;
      handleupdateSPstatus(userId, updatedStatus);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        // Parse the Excel file to JSON
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Define a mapping between old header names and new parameter names
        const headerMapping = {
          "Supplier": "supplierName",
          "Contact Person": "contactPersonName",
          "City": "city",
          "State": "state",
          "Address": "address",
          "Phone": "phone",
          "Email": "email",
        
        };

        // Map the old header names to the new parameter names in the JSON data
        const mappedJsonData = jsonData.map((item) => {
          const mappedItem = {};
          for (const oldHeader in item) {
            if (oldHeader in headerMapping) {
              mappedItem[headerMapping[oldHeader]] = item[oldHeader];
            }
          }
          return mappedItem;
        });

        // setExcelJson(mappedJsonData);

        updateNewExcel(mappedJsonData);
      };
      reader.readAsBinaryString(file);
    }
  };

  const updateNewExcel = async (json) => {
    // Mark the function as async
    try {
      const res = await store.dispatch(updateSpExcelSheet(json));
      getdata();
      if (res?.isError === false) {
        toast.success(res?.message);
        if (res?.data?.errorArrray.length > 0) {
          toast.error(
            ` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`
          );
          const headers = [
            "Supplier",
            "Contact Person ",
            "City ",
            "State",
            "Address",
            "Phone",
            "Email",
            "Error",
          ];
          // Map data to a new array with custom headers
          const dataWithCustomHeaders = [
            headers,
            ...res?.data?.errorArrray.map((item) => [
              item.supplierName,
              item.contactPersonName,
              item.city,
              item.state,
              item.address,
              item.phone,
              item.email,
              item.error,
            ]),
          ];
          // Generate Excel file
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          // Save file
          const fileData = new Blob([excelBuffer], {
            type: "application/octet-stream",
          });
          saveAs(fileData, "SPErrordata.xlsx");
        } else {
          // Display an alert when errorArray is empty
          toast.success("No error data found. All data upload Successfully");
        }
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };

  const handleTemplateDownload = () => {
    // Custom headers
    const headers = [
      "Supplier",
      "Contact Person",
      "City",
      "State",
      "Address",
      "Phone",
      "Email",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [headers, []];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "Supplier Master template.xlsx");
  };

  const clearFilter = (filterName) => {
    switch (filterName) {
      case "CPName":
        setFilterCpName([]);
        setUpdateAPI("1");
        break;
      case "ContactPerson":
        setFilterContactPerson([]);
        setUpdateAPI("2");
        break;
      case "Email":
        setFilterEmail([]);
        setUpdateAPI("3");
        break;
      case "Phone":
        setUpdateAPI("4");
        setFilterPhone([]);
        break;
      case "City":
        setFilterCity([]);
        setUpdateAPI("5");
        break;
      case "State":
        setFilterState([]);
        setUpdateAPI("66");
        break;
      case "LeadTime":
        setFilterLeadTime([]);
        setUpdateAPI("7");
        break;
      case "address":
        setFilterAddress([]);
        setUpdateAPI("add");
        break;
      default:
        break;
    }
  };



  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  const sortDataByName = (sortByField) => {
    const sortedData = [...listdata].sort((a, b) => {
      const nameA = a[sortByField].toLowerCase();
      const nameB = b[sortByField].toLowerCase();

      if (sortOrder === "asc") {
        return nameA < nameB ? -1 : 1;
      } else {
        return nameA > nameB ? -1 : 1;
      }
    });
    setListdata(sortedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleItemsPerPageChange = (value) => {
    setRecordPerPage(value);
    setPage(1); // Reset to page 1 whenever items per page changes
  };
  
  const calculateSerialNumber = (index) => {
    return (page - 1) * recordperpage + index + 1;
  };
  const startSerialNumber = calculateSerialNumber(0);
  const endSerialNumber = calculateSerialNumber(listdata.length - 1);
  
  
  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading">Supplier Master</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>

        <div className="d-flex gap-2 flex-wrap ms-1">
          <div className="button-container">
      <button
        className={`button ${isHovered ? 'hovered' : ''} import_export_btns`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>Excel Import</span>
        <div className='pageheader_icons_container'>
          <RiAttachment2 className='import_attach_icon' />
        </div>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          className="import_excel_input"
          style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
        />
      </button>
    </div>

          <div
            className="button-container"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <button
              className={`button ${isHovered ? "hovered" : ""}`}
              onClick={() => handleDownloadEXcel()}
            >
              <span>Excel Export</span>
              <div className="pageheader_icons_container">
                <MdOutlineCloudDownload className="pageheader_react_icons" />
              </div>
            </button>
          </div>

          <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`} 
       onClick={() => handleTemplateDownload()} 
      >
        <span>Import Template</span>
        <div className='pageheader_icons_container' >
        <TbFileDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>

    
    <AddModal setUpdateAPI={setUpdateAPI} />
        </div>
      </div>
      <div className="page_header_slider_container">
        <div className="d-flex align-items-center gap-1 w-100">
          <div className="d-flex gap-5 align-items-center ">
            <FilterModal propsState={propsState} stateSetters={stateSetters} />
          </div>
          <div className="slider_container ms-5">
            {" "}
            <Slider {...settings}>
              {filterCpName.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      Supplier is{" "}
                      {filterCpName.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterCpName.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearFilter("CPName")} />{" "}
                  </button>
                </div>
              )}
              {filterContactPerson.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      Contact Person is{" "}
                      {filterContactPerson.map((item, index) => (
                        <span
                          className="ellipsis-button_contact_person"
                          key={index}
                        >
                          {item.label}
                          {index < filterContactPerson.length - 1
                            ? ", "
                            : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>
                    <RxCross2 onClick={() => clearFilter("ContactPerson")} />{" "}
                  </button>
                </div>
              )}
              {filterEmail.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      Email is{" "}
                      {filterEmail.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterEmail.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearFilter("Email")} />{" "}
                  </button>
                </div>
              )}
              {filterPhone.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      Phone is{" "}
                      {filterPhone.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterPhone.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearFilter("Phone")} />{" "}
                  </button>
                </div>
              )}
              {filterCity.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      City is{" "}
                      {filterCity.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterCity.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearFilter("City")} />
                  </button>
                </div>
              )}
              {filterState.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      State is{" "}
                      {filterState.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterState.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearFilter("State")} />
                  </button>
                </div>
              )}
              {filterLeadTime.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      Lead Time is{" "}
                      {filterLeadTime.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filterLeadTime.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearFilter("LeadTime")} />{" "}
                  </button>
                </div>
              )}
              {filteraddress.length > 0 && (
                <div class="active-filters">
                  <button>
                    <span className="filter_text_cont">
                      {" "}
                      Address is{" "}
                      {filteraddress.map((item, index) => (
                        <span className="ellipsis-button" key={index}>
                          {item.label}
                          {index < filteraddress.length - 1 ? ", " : ""}{" "}
                          {/* Add a comma if not the last item */}
                        </span>
                      ))}{" "}
                    </span>{" "}
                    <RxCross2 onClick={() => clearFilter("address")} />{" "}
                  </button>
                </div>
              )}
            </Slider>
          </div>
        </div>

        <div className="d-flex">
          <button
            className="inactive_page_btn"
            onClick={() => navigate("/suppliermaster/inactive")}
          >
            {" "}
            <FaRegRectangleList className="list_icons" />{" "}
            <span> Inactive Suppliers </span>{" "}
          </button>
        </div>
      </div>

      <div className="table-container mt-1">
        <table className="table t">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">Action</td>
              <td scope="col" className="">
                <div className="th_common">
                  <span> Supplier Name</span>
                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortDataByName("supplierName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Contact Person</span>
                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortDataByName("contactPersonName")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> City</span>
                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortDataByName("city")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> State</span>
                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortDataByName("state")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Address</span>
                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortDataByName("address")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Phone</span>
                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortDataByName("phone")}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Email</span>
                  <img
                    src={sort_logo_img}
                    alt="sort logo"
                    style={{ cursor: "pointer" }}
                    onClick={() => sortDataByName("email")}
                  />
                </div>
              </td>

              <td scope="col">
                <div className="th_common">
                  <span> Status</span>
                </div>
              </td>
            </tr>
            <br />
          </thead>
          <tbody>
            {listdata?.map((value, i) => (
              <>
                <tr key={i}>
                <td>{calculateSerialNumber(i)}</td>

                  <td className="d-flex align-items-center b-0 justify-content-center gap-1" style={{border:'none'}}>
                   
                    <EditModal id={value?._id} setUpdateAPI={setUpdateAPI} />
           

                    <button className="table_edit_btn   "
                    onClick={() => navigate(`/suppliermaster/fginventory/${value?._id}`)}
                     id={`fginventory_tool${i}`}
                     data-tooltip-content="FG Inventory Buffer"
                    >
                      <AiOutlineEye />
                    </button>
                    <ReactTooltip anchorId={`fginventory_tool${i}`} place='bottom' />

                    <button className="table_edit_btn "
                    onClick={() => navigate(`/suppliermaster/productionrecommendation/${value?._id}`)}

                    
                    id={`production_tool${i}`}
                    data-tooltip-content="Production Recommendation"
                    >
                      <AiOutlineEye />
                    </button>
                    <ReactTooltip anchorId={`production_tool${i}`} place='bottom' />

                  </td>
                  <td>{value?.supplierName}</td>
                  <td>{value?.contactPersonName}</td>
                  <td>{value?.city}</td>
                  <td>{value?.state}</td>
                  <td className="remark_td">{value?.address}</td>
                  <td>{value?.phone}</td>
                  <td>{value?.email}</td>

                  <td>
                    {" "}
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input addbox_radio"
                        type="checkbox"
                        id="flexSwitchCheckChecked"
                        checked={value?.isActive}
                        onChange={() => toggleStatus(value?._id)}
                      />
                    </div>
                  </td>
                </tr>
                <br />
              </>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
        <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
        <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} > Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results</p>
        </div>

        <div className="d-flex   justify-content-center align-items-center">
          <Pagination
            page={page}
            setPage={handleChange}
            total={total_count}
            className="pagination "
            perPage={recordperpage}
          />
        </div>
        <div className="d-flex align-items-center">
          <p
            className="p3 m-0"
            style={{ fontFamily: "Poppins", fontSize: "14px" }}
          >
            Total Count
          </p>
          <select
            className="w-auto form-select form-select-sm p3 shadow-none mx-3 "
            style={{ fontFamily: "Poppins" }}
            value={recordperpage}
            // onChange={(e) => setRecordPerPage(e.target.value)}
            onChange={(e) => handleItemsPerPageChange(e.target.value)}

          >
            <option value="5">5</option>

            {total_count > 5 && <option value="10">10</option>}
            {total_count > 10 && <option value="25">25</option>}
            {total_count > 25 && <option value="50">50</option>}
            {total_count > 50 && <option value="100">100</option>}
            {total_count > 100 && <option value="500">500</option>}
            {total_count > 500 && <option value="1000">1000</option>}
          </select>
        </div>
      </div>

  
    </div>
  );
}

export default CustomTable;
