import React, { useState, useEffect } from "react";
import "./table.css";
import "../Dashboard/MainTable/MainTable.css";
import store from "../../Redux/MyRedux/store";
import {
  Addcpmaster,
  CPMaterList,
  UpdateCpmasterStatus,
  Updatecpmaster,
  Viewcpmaster,
  updateCpExcelSheet,
} from "../../Redux/MyRedux/action/userActions";
import Pagination from "react-mui-pagination";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { LuSearch } from "react-icons/lu";
import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Slider from "react-slick";
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineCloudDownload } from "react-icons/md";
import { TbFileDownload } from "react-icons/tb";
import { IoMdAddCircleOutline } from "react-icons/io";
import { FaRegRectangleList } from "react-icons/fa6";
import { TbPencil } from "react-icons/tb";
import { AiOutlinePlus } from "react-icons/ai";
import sort_logo_img from '../../Assets/table/shortarrow.png'
import FilterModal from './filterpopup/filter.jsx'

function CustomTable() {
  const [Cpstatus, setCPStatus] = useState(false);
  const [filterCpName, setFilterCpName] = useState([]);
  const [filterContactPerson, setFilterContactPerson] = useState([]);
  const [filterCity, setFilterCity] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [filterLeadTime, setFilterLeadTime] = useState([]);
  const [filterEmail, setFilterEmail] = useState([]);
  const [filterPhone, setFilterPhone] = useState([]);
  const [filteraddress, setFilterAddress] = useState([]);
  const [updatedAPI, setUpdateAPI] = useState("");

const stateSetters = {
  setFilterCpName,
  setFilterContactPerson,
  setFilterCity,
  setFilterState,
  setFilterLeadTime,
  setFilterEmail,
  setFilterPhone,
  setFilterAddress,
  setUpdateAPI,
}

const propsState = {
  filterCpName,
  filterContactPerson,
  filterCity,
  filterState,
  filterLeadTime,
  filterEmail,
  filterPhone,
  filteraddress,
  
}


  const handleStatusUpdate = () => {
    const Updatecpstatus = !Cpstatus;
    setCPStatus(Updatecpstatus);
    handleupdateCPstatus(cpmaster_id, Updatecpstatus);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "#F4F6FA",
    boxShadow: 24,
  };
  const mediaQuery = `@media (max-width: 768px)`;
  const styleForMediaQuery = {
    ...style,
    [mediaQuery]: {
      width: "80%", // Example: Make the width 100% for screens matching the media query
    },
  };
  const initialvalueforadd = {
    cpname: "",
    contactperson: "",
    city: "",
    state: "",
    address: "",
    phone: "",
    email: "",
    leadtime: "",
  };

  const [initialvalueforupdate, setInitialValueForUpdate] = useState({
    updatecpname: "",
    updatecontactperson: "",
    updatecity: "",
    updatestate: "",
    updateaddress: "",
    updatephone: "",
    updateemail: "",
    updateleadtime: 0,
  });


  const [page, setPage] = React.useState(1);
  const [cpmaster_id, setCPmasterId] = React.useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // Default to ascending order

  const [total_count, setTotalCount] = React.useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [Search, setSearch] = useState("");

  const [listdata, setListdata] = useState([]);
  const getdata = () => {
    const data = {
      search: Search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      companyName: filterCpName.map((item) => item.label),
      contactPersonName: filterContactPerson.map((item) => item.label),
      phone: filterPhone.map((item) => item.label),
      email: filterEmail.map((item) => item.label),
      city: filterCity.map((item) => item.label),
      state: filterState.map((item) => item.label),
      address: filteraddress.map((item) => item.label),
      leadTime: filterLeadTime.map((item) => item.label),
      isActive: true,
    };

    try {
      store.dispatch(CPMaterList(data)).then((res) => {
        if (res?.isError == false) {
          if (res?.data[0]?.docs.length > 0) {
            setListdata(res?.data[0]?.docs);
            setTotalCount(res?.data[0]?.paginate[0]?.totalDocs);
          } else {
            setListdata([]);
            setTotalCount(0);
          }
        }
        setUpdateAPI('')

      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getdata();
  }, [recordperpage, page, Search, updatedAPI]);


  const handleviewproduct = (id, val) => {
    setCPmasterId(id);
    const data = {
      id: id,
    };
    try {
      store.dispatch(Viewcpmaster(data)).then((res) => {
        if (res?.isError == false) {
          setInitialValueForUpdate({
            updatecpname: res?.data?.companyName || "",
            updatecontactperson: res?.data?.contactPersonName || "",
            updatecity: res?.data?.city || "",
            updatestate: res?.data?.state || "",
            updateaddress: res?.data?.address || "",
            updatephone: res?.data?.phone || "",
            updateemail: res?.data?.email || "",
            updateleadtime: res?.data?.leadTime || 0,
          });
          setCPStatus(res?.data?.isActive);

          if (val == "1") {
            handleUpdateBoxOpen();
          }
        } else {
          // setUpdateFormData(initialvalueforupdate);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  

  const handleupdateCPstatus = (id, value) => {
    const data = {
      active: value,
    };
    try {
      store.dispatch(UpdateCpmasterStatus(data, id)).then((res) => {
        if (res?.isError == false) {
          // handleUpdateBoxClose();
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message)
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
      return error;
    }
  };


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [updateboxopen, setUpdateBoxOpen] = React.useState(false);
  const handleUpdateBoxOpen = () => setUpdateBoxOpen(true);
  const handleUpdateBoxClose = () => setUpdateBoxOpen(false);

  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      "Cp name",
      "Contact Person ",
      "City ",
      "State",
      "Address",
      "Phone",
      "Email",
      "Lead Time",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [
        item.companyName,
        item.contactPersonName,
        item.city,
        item.state,
        item.address,
        item.phone,
        item.email,
        item.leadTime,
      ]),
    ];

    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "data.xlsx");
  };

  const navigate = useNavigate();

 

  const toggleStatus = (userId) => {
    // Find the user by userId in the 'data' state
    const userToUpdate = listdata.find((user) => user._id === userId);

    if (userToUpdate) {
      const updatedStatus = !userToUpdate.isActive;
      handleupdateCPstatus(userId, updatedStatus);
    }
  };



  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        // Parse the Excel file to JSON
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0]; // Assuming you want the first sheet
        const jsonData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

        // Define a mapping between old header names and new parameter names
        const headerMapping = {
          "Cp name": "companyName",
          "Contact Person ": "contactPersonName",
          "City ": "city",
          State: "state",
          Address: "address",
          Phone: "phone",
          Email: "email",
          "Lead Time": "leadTime",
        };

        // Map the old header names to the new parameter names in the JSON data
        const mappedJsonData = jsonData.map((item) => {
          const mappedItem = {};
          for (const oldHeader in item) {
            if (oldHeader in headerMapping) {
              mappedItem[headerMapping[oldHeader]] = item[oldHeader];
            }
          }
          return mappedItem;
        });

        // setExcelJson(mappedJsonData);

        updateNewExcel(mappedJsonData);
      };
      reader.readAsBinaryString(file);
    }
  };

  const updateNewExcel = async (json) => {
    // Mark the function as async
    try {
      const res = await store.dispatch(updateCpExcelSheet(json));

      if (res?.isError === false) {
        toast.success(res?.message);

        if (res?.data?.errorArrray.length > 0) {
          toast.error(
            ` ${res?.data?.errorArrray.length} Wrong data  download in new Excel.`
          );
          const headers = [
            "Cp name",
            "Contact Person ",
            "City ",
            "State",
            "Address",
            "Phone",
            "Email",
            "Lead Time",
            "Error",
          ];

          // Map data to a new array with custom headers
          const dataWithCustomHeaders = [
            headers,
            ...res?.data?.errorArrray.map((item) => [
              item.companyName,
              item.contactPersonName,
              item.city,
              item.state,
              item.address,
              item.phone,
              item.email,
              item.leadTime,
              item.error,
            ]),
          ];
          // Generate Excel file
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
          XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
          const excelBuffer = XLSX.write(workbook, {
            bookType: "xlsx",
            type: "array",
          });
          // Save file
          const fileData = new Blob([excelBuffer], {
            type: "application/octet-stream",
          });
          saveAs(fileData, "Errordata.xlsx");
        } else {
          // Display an alert when errorArray is empty
          toast.success("No error data found. All data upload Successfully");
        }
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      // Handle the error as needed
    }
  };

  const handleTemplateDownload = () => {
    // Custom headers
    const headers = [
      "Cp name",
      "Contact Person ",
      "City ",
      "State",
      "Address",
      "Phone",
      "Email",
      "Lead Time",
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [headers, []];
    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "CPMaster template.xlsx");
  };

  const clearFilter = (filterName) => {
    switch (filterName) {
      case "CPName":
        setFilterCpName([]);
        setUpdateAPI("1");
        break;
      case "ContactPerson":
        setFilterContactPerson([]);
        setUpdateAPI("2");
        break;
      case "Email":
        setFilterEmail([]);
        setUpdateAPI("3");
        break;
      case "Phone":
        setUpdateAPI("4");
        setFilterPhone([]);
        break;
      case "City":
        setFilterCity([]);
        setUpdateAPI("5");
        break;
      case "State":
        setFilterState([]);
        setUpdateAPI("66");
        break;
      case "LeadTime":
        setFilterLeadTime([]);
        setUpdateAPI("7");
        break;
        case "address":
        setFilterAddress([]);
        setUpdateAPI("add");
        break;
      default:
        break;
    }
  };

  const validationSchema = Yup.object().shape({
    cpname: Yup.string().required("CP Name is required"),
    contactperson: Yup.string().required("Contact Person is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    address: Yup.string().required("Address is required"),
    phone: Yup.string()
      .matches(/^\d{10}$/, "Phone must be exactly 10 digits")
      .required("Phone is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
    leadtime: Yup.number()
      .typeError("Lead Time must be a number")
      .required("Lead Time is required")
      .min(0, "Lead Time must be at least 0")
      .integer("Lead Time must be an integer"),
  });
  const validationSchemaforupdate = Yup.object().shape({
    updatecpname: Yup.string().required("CP Name is required"),
    updatecontactperson: Yup.string().required("Contact Person is required"),
    updatecity: Yup.string().required("City is required"),
    updatestate: Yup.string().required("State is required"),
    updateaddress: Yup.string().required("Address is required"),
    updatephone: Yup.string()
      .matches(/^\d{10}$/, "Phone must be exactly 10 digits")
      .required("Phone is required"),
    updateemail: Yup.string()
      .email("Invalid email address")
      .required("Email is required")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        "Invalid email address"
      ),
    updateleadtime: Yup.number()
      .typeError("Lead Time must be a number")
      .required("Lead Time is required")
      .min(0, "Lead Time must be at least 0")
      .integer("Lead Time must be an integer"),
  });

  const onSubmit = (values, { resetForm }) => {
    // Handle form submission here, e.g., send data to an API
    const data = {
      companyName: values.cpname,
      contactPersonName: values.contactperson,
      phone: values.phone,
      email: values.email,
      address: values.address,
      city: values.city,
      state: values.state,
      leadTime: values.leadtime,
    };
    try {
      store.dispatch(Addcpmaster(data)).then((res) => {
        if (res?.isError == false) {
          handleClose();
          toast.success(res?.message);
          getdata();
          // setAddFormData(initialvalueforadd);
        } else {
          toast.error(res?.error);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
    // Reset the form after submission
    resetForm();
  };
  const onUpdate = (values, { resetForm }) => {
    const data = {
      companyName: values.updatecpname,
      contactPersonName: values.updatecontactperson,
      phone: values.updatephone,
      email: values.updateemail,
      address: values.updateaddress,
      city: values.updatecity,
      state: values.updatestate,
      leadTime: values.updateleadtime,
    };
    try {
      store.dispatch(Updatecpmaster(data, cpmaster_id)).then((res) => {
        if (res?.isError == false) {
          handleUpdateBoxClose();
          toast.success(res?.message);
          getdata();
        } else {
          toast.error(res?.message);

        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
      return error;
    }
    resetForm();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

 
  
  const sortDataByName = (sortByField) => {
    const sortedData = [...listdata].sort((a, b) => {
      const nameA = a[sortByField].toLowerCase();
      const nameB = b[sortByField].toLowerCase();

      if (sortOrder === 'asc') {
        return nameA < nameB ? -1 : 1;
      } else {
        return nameA > nameB ? -1 : 1;
      }
    });
    setListdata(sortedData);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };
const [isHovered , setIsHovered] = useState(false)

const handleItemsPerPageChange = (value) => {
  setRecordPerPage(value);
  setPage(1); // Reset to page 1 whenever items per page changes
};

const calculateSerialNumber = (index) => {
  return (page - 1) * recordperpage + index + 1;
};
const startSerialNumber = calculateSerialNumber(0);
const endSerialNumber = calculateSerialNumber(listdata.length - 1);


  return (
    <div className="page">
      <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
        <ul className="list-inline mb-0">
          <li className=" page_heading">CP Master</li>
        </ul>
        <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
          <div className="input-group">
            <input
              type="search"
              className="form-control bg-white border-0 small page_search"
              placeholder="Search here"
              aria-label="Search"
              aria-describedby="basic-addon2"
              onChange={(e) => setSearch(e.target.value)}
            />
            <div className="input-group-append">
              <button className="btn bg-white" type="button">
                <LuSearch className="search_logo" />
              </button>
            </div>
          </div>
        </form>


<div className="d-flex gap-2 flex-wrap ms-1">

       <div className="button-container">
      <button
        className={`button ${isHovered ? 'hovered' : ''} import_export_btns`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span>Excel Import</span>
        <div className='pageheader_icons_container'>
          <RiAttachment2 className='import_attach_icon' />
        </div>
        <input
          type="file"
          accept=".xlsx"
          onChange={handleFileUpload}
          className="import_excel_input"
          style={{ opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', cursor: 'pointer' }}
        />
      </button>
    </div>

    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
          onClick={() => handleDownloadEXcel()}
      >
        <span>Excel Export</span>
        <div className='pageheader_icons_container' >
        <MdOutlineCloudDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>
  
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`} 
       onClick={() => handleTemplateDownload()} 
      >
        <span>Import Template</span>
        <div className='pageheader_icons_container' >
        <TbFileDownload className='pageheader_react_icons' />
        </div>
      </button>
    </div>


  
    <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
         onClick={handleOpen}
      >
        <span>Add CP</span>
        <div className='pageheader_icons_container' >
        <IoMdAddCircleOutline className='pageheader_react_icons' />
        </div>
      </button>
    </div>

    
         
        </div>

      </div>
      <div className="page_header_slider_container">
      <div className="d-flex align-items-center gap-1 w-100">

        <div className="d-flex gap-5 align-items-center ">
          
          <FilterModal propsState={propsState} stateSetters={stateSetters} />
        </div>
        <div className="slider_container ms-5">
          {" "}
          <Slider {...settings}>
            {filterCpName.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' >  CP Name is {filterCpName.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterCpName.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilter("CPName")} />{" "}
                </button>
              </div>
            )}
            {filterContactPerson.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' > Contact Person  is {filterContactPerson.map((item, index) => ( <span  className="ellipsis-button_contact_person" key={index}>
      {item.label}
      {index < filterContactPerson.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>
                  <RxCross2 onClick={() => clearFilter("ContactPerson")} />{" "}
                </button>
              </div>
            )}
            {filterEmail.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' > Email is {filterEmail.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterEmail.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("Email")} />{" "}
                </button>
              </div>
            )}
            {filterPhone.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' >  Phone is {filterPhone.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterPhone.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("Phone")} />{" "}
                </button>
              </div>
            )}
            {filterCity.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' >   City is {filterCity.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterCity.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("City")} />
                </button>
              </div>
            )}
            {filterState.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' >  State is {filterState.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterState.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span> <RxCross2 onClick={() => clearFilter("State")} />
                </button>
              </div>
            )}
            {filterLeadTime.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' > Lead Time is {filterLeadTime.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filterLeadTime.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilter("LeadTime")} />{" "}
                </button>
              </div>
            )}
              {filteraddress.length > 0 && (
              <div class="active-filters">
                <button>
                <span className='filter_text_cont' >  Address  is {filteraddress.map((item, index) => ( <span  className="ellipsis-button" key={index}>
      {item.label}
      {index < filteraddress.length - 1 ? ", " : ""} {/* Add a comma if not the last item */}
    </span>
  ))} </span>  <RxCross2 onClick={() => clearFilter("address")} />{" "}
                </button>
              </div>
            )}
          </Slider>
        </div>
        </div>

        <div className="d-flex">
          
          <button className='inactive_page_btn' 
        onClick={() => navigate("/inactivecustommaster")} > <FaRegRectangleList className='list_icons' /> <span> Inactive CP List </span> </button>

       
          
        </div>
      </div>

      <div className="table-container mt-1">
        <table className="table t">
          <thead>
            <tr>
              <td scope="col">Sr. No</td>
              <td scope="col">Action</td>
              <td scope="col" className="">
                <div className="th_common">
                  <span> CP Name</span>
                  <img src={sort_logo_img} alt="sort logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortDataByName('companyName')}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span>
                    {" "}
                    Contact Person
                  </span>
                   <img src={sort_logo_img} alt="sort logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortDataByName('contactPersonName')}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> City</span>
                   <img src={sort_logo_img} alt="sort logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortDataByName('city')}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> State</span>
                   <img src={sort_logo_img} alt="sort logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortDataByName('state')}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Address</span>
                   <img src={sort_logo_img} alt="sort logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortDataByName('address')}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Phone</span>
                   <img src={sort_logo_img} alt="sort logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortDataByName('phone')}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Email</span>
                   <img src={sort_logo_img} alt="sort logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortDataByName('email')}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Lead Time </span>
                   <img src={sort_logo_img} alt="sort logo" 
                   style={{ cursor: "pointer" }}
                   onClick={() => sortDataByName('leadTime')}
                  />
                </div>
              </td>
              <td scope="col">
                <div className="th_common">
                  <span> Status</span>
                
                </div>
              </td>
            </tr>
            <br />
          </thead>
          <tbody>
            {listdata?.map((value, i) => (
              <>
              <tr key={i}>
              <td>{calculateSerialNumber(i)}</td>

                <td >


                
 <button  className='table_edit_btn' onClick={() => {
                    handleviewproduct(value?._id, 1);
                  }} >
                         <TbPencil />
                         </button>

                 
                
              <button  className='table_edit_btn ms-2'
               onClick={() => navigate(`/inventorylist/${value?._id}`)}
               >
                         <AiOutlineEye /> 
                         </button>
                
                </td>
                <td>{value?.companyName}</td>
                <td>{value?.contactPersonName}</td>
                <td>{value?.city}</td>
                <td>{value?.state}</td>
                <td className="remark_td">{value?.address}</td>
                <td>{value?.phone}</td>
                <td>{value?.email}</td>
                <td>{value?.leadTime}</td>
                <td>
                  {" "}
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input addbox_radio"
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                      checked={value?.isActive}
                      onChange={() => toggleStatus(value?._id)}
                    />
                  </div>
                </td>
              </tr>
              <br />
              </>
            ))}
          </tbody>
        </table>
      </div>


<div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} > Showing {startSerialNumber} - {endSerialNumber} of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => handleItemsPerPageChange(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>



      {/* // ADD USER MODEL START  */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className='d-flex align-items-center gap-3' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Add CP </p>
            </div>
              <div className="d-flex align-items-center gap-3 ">
                <button className="closebtn_cont" onClick={handleClose}>
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

       
            <div className="inputbox_cont">
              <Formik
                initialValues={initialvalueforadd}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {(formik) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="cpname" className="mb-1">
                          CP Name*
                        </label>
                        <Field
                          type="text"
                          name="cpname"
                          id="cpname"
                          placeholder="Enter CP Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="cpname"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="contactperson" className="mb-1">
                          Contact Person*
                        </label>
                        <Field
                          type="text"
                          name="contactperson"
                          id="contactperson"
                          placeholder="Enter Contact Person Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="contactperson"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="city" className="mb-1">
                          City*
                        </label>
                        <Field
                          type="text"
                          name="city"
                          id="city"
                          placeholder="Enter City"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="city"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="state" className="mb-1">
                          State*
                        </label>
                        <Field
                          type="text"
                          name="state"
                          id="state"
                          placeholder="Enter State"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="state"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="address" className="mb-1">
                          Address*
                        </label>
                        <Field
                          type="text"
                          name="address"
                          id="address"
                          placeholder="Enter Address"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="address"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="phone" className="mb-1">
                          Phone*
                        </label>
                        <Field
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Enter Phone"
                          className="add_inut_field lead_time_field"
                        />
                        <ErrorMessage
                          name="phone"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="email" className="mb-1">
                          Email*
                        </label>
                        <Field
                          type="text"
                          name="email"
                          id="email"
                          placeholder="Enter Email"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="email"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="leadtime" className="mb-1">
                          Lead Time (In Weeks)*
                        </label>
                        <Field
                          type="number"
                          name="leadtime"
                          id="leadtime"
                          placeholder="Enter Lead Time"
                          className="add_inut_field lead_time_field"
                        />
                        <ErrorMessage
                          name="leadtime"
                          component="p"
                          className=" error-message"
                        />
                      </div>
                    </div>
                    <div className="addbox_btn_cont mt-2 ">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* // ADD USER MODEL END  */}
      {/* // EDIT USER MODEL END  */}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={updateboxopen}
        onClose={handleUpdateBoxClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={updateboxopen}>
          <Box sx={styleForMediaQuery} className="model_box">
            <div className="model_header">
              <div className="d-flex align-items-center gap-3">
            <button  className='table_edit_btn' >
                         <TbPencil />
                         </button>
              <p className="m-0">Edit CP</p>
              </div>
              <div className="d-flex align-items-center gap-5 ">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input addbox_radio"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={Cpstatus == true}
                    onChange={handleStatusUpdate}
                  />
                </div>
                <button
                  className="closebtn_cont"
                  onClick={handleUpdateBoxClose}
                >
                  <RxCross2 className="closebox_logo" />{" "}
                </button>
              </div>
            </div>

            <div className="inputbox_cont">
              <Formik
                initialValues={initialvalueforupdate}
                validationSchema={validationSchemaforupdate}
                onSubmit={onUpdate}
              >
                {(formik) => (
                  <Form>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="cpname" className="mb-1">
                          CP Name*
                        </label>
                        <Field
                          type="text"
                          name="updatecpname"
                          id="updatecpname"
                          placeholder="Enter CP Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatecpname"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="contactperson" className="mb-1">
                          Contact Person*
                        </label>
                        <Field
                          type="text"
                          name="updatecontactperson"
                          id="updatecontactperson"
                          placeholder="Enter Contact Person Name"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatecontactperson"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="updatecity" className="mb-1">
                          City*
                        </label>
                        <Field
                          type="text"
                          name="updatecity"
                          id="updatecity"
                          placeholder="Enter City"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatecity"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="updatestate" className="mb-1">
                          State*
                        </label>
                        <Field
                          type="text"
                          name="updatestate"
                          id="updatestate"
                          placeholder="Enter State"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatestate"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="updateaddress" className="mb-1">
                          Address*
                        </label>
                        <Field
                          type="text"
                          name="updateaddress"
                          id="updateaddress"
                          placeholder="Enter Address"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updateaddress"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="updatephone" className="mb-1">
                          Phone*
                        </label>
                        <Field
                          type="text"
                          name="updatephone"
                          id="updatephone"
                          placeholder="Enter Phone"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updatephone"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <label htmlFor="updateemail" className="mb-1">
                          Email*
                        </label>
                        <Field
                          type="text"
                          name="updateemail"
                          id="updateemail"
                          placeholder="Enter Email"
                          className="add_inut_field"
                        />
                        <ErrorMessage
                          name="updateemail"
                          component="p"
                          className="error-message"
                        />
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="updateleadtime" className="mb-1">
                          Lead Time* (In Weeks)
                        </label>
                        <Field
                          type="number"
                          name="updateleadtime"
                          id="updateleadtime"
                          placeholder="Enter Lead Time"
                          className="add_inut_field lead_time_field"
                        />
                        <ErrorMessage
                          name="updateleadtime"
                          component="p"
                          className="error-message"
                        />
                      </div>
                    </div>
                    <div className="addbox_btn_cont mt-2">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Box>
        </Fade>
      </Modal>

      {/* // EDIT USER MODEL END  */}

   
    </div>
  );
}

export default CustomTable;
