import React , { useState} from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Select from "react-select";
import store from "../../../../../Redux/MyRedux/store";
import { AiOutlinePlus } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { Formik, Form, Field } from "formik";
import {  editSpInventory,rotexProductDropdownList, updateRotexInvetoryStatus, viewSpInventory } from "../../../../../Redux/MyRedux/action/phase3";
import { toast } from "react-toastify";
import { TbPencil } from "react-icons/tb";


 const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "#F4F6FA",
  boxShadow: 24,
  borderRadius:"20px",

};
const mediaQuery = `@media (max-width: 768px)`; 
const styleForMediaQuery = {
  ...style, 
  [mediaQuery]: {
    width: "80%", // Example: Make the width 100% for screens matching the media query
  },
};

export default function EditModal({spId , id ,setUpdateAPI }) {
  
  const [productlistdropdown , setProductListDropdown] = useState([])
  const [disableAddButton , setDisableAddButton] = useState([])
  const [inventoryStatus , setInventoryStatus] = useState(false)

  




  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

 
 

  const getChildMasterproduct = () =>{
    
    try{
      store.dispatch(rotexProductDropdownList()).then((res)=>{
        if(res?.isError === false){
               setProductListDropdown(res?.data)
        }
      })
    }catch (error){
      console.log("Error Fetching :", error)
    }
  }



  
  const optionListproduct = productlistdropdown?.map((val, i) => ({
    value: val?._id,
    label: val?.itemCode,
  }));
  const [initialValue , setInitialValue ] = useState({
   product_Id :'',
   itemDescription:'',
   leadTime:'',
   factoryOfSafety:'',
   tog:'',
   onHandStock:'',
   qualifiedDemand:'',
   moq:'',
   spName:'',
   contactPerson:'',
   city:'',
   state:''
  })

 


    const hanlechangeitemcode = (e)=>{
 
      const selectedProduct = productlistdropdown.find(product => product._id == e.value); // Use the updated value
  
        if (selectedProduct) {
          setInitialValue(prevState => ({
            ...prevState,
            itemDescription: selectedProduct.itemDescription,
            factoryOfSafety:selectedProduct?.factorOfSafety,
          
          }));
        }
      }

  const handleSubmit = (values , {resetForm})=>{
    setDisableAddButton(true)


    const data = {

      productId:values?.product_Id?.value,
      onHandStock:values.onHandStock,
      qualifiedDemand:0,
      tog:values.tog,
      supplierPartnerId:spId,
      leadTime:parseInt(values.leadTime),
      moq:parseInt(values.moq)


    }
    try{
      store.dispatch(editSpInventory(data ,id)).then((res)=>{
        setDisableAddButton(false)

        if(res?.isError == false){
          toast.success(res?.message)
          handleClose();
          resetForm();
          setUpdateAPI('update')
        }
        else{
          const errorMessage = res.message.includes(":") ? res.message.split(":")[1] : res.message;
          toast.error(errorMessage);
       
        }
      })
    }catch (error){
      return error
    }
  }

  const handlUpdateStatus  =( id ,value) =>{
    const data = {
      active :value
    }
    store.dispatch(updateRotexInvetoryStatus(data , id)).then((res)=>{
      if(res?.isError == false){
        toast.success(res?.message)
        setUpdateAPI('update-status')

      }
      else {
        toast.error(res?.messsage)
      }

    })
   }

  const handleStatusUpdate = () =>{
    const updatestatus = !inventoryStatus ;
    setInventoryStatus(updatestatus)
    handlUpdateStatus( id, updatestatus)
   }


   const handleviewInventory =  () => {
  
    try {
      store.dispatch(viewSpInventory(id)).then((res) => {
        if (res?.isError == false) {
          setInitialValue(prevState => ({
            ...prevState,
           product_Id : { value:res?.data?.productId || '' , label: res?.data?.itemCode || ''},
   itemDescription:res?.data?.itemDescription,
   leadTime:res?.data?.leadTime,
   factoryOfSafety:res?.data?.factorOfSafety,
   tog:res?.data?.tog,
   onHandStock:res?.data?.onHandStock,
   qualifiedDemand:res?.data?.qualifiedDemand,
   moq:res?.data?.moq,
   spName:res?.data?.supplierName,
   contactPerson:res?.data?.contactPersonName,
   city:res?.data?.city,
   state:res?.data?.state
      
        
          }));
          setInventoryStatus(res?.data?.active)

         handleOpen();
        }
        else {
         
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  return (
    <div>
      <button  className='table_edit_btn'
                  
                  onClick={() => {
               
                    handleviewInventory(id);
                    getChildMasterproduct();

                  }}
                  
                  >
                         <TbPencil />
                         </button>
       <Modal
     aria-labelledby="transition-modal-title"
     aria-describedby="transition-modal-description"
     open={open}
     onClose={handleClose}
     closeAfterTransition
     slots={{ backdrop: Backdrop }}
     slotProps={{
       backdrop: {
         timeout: 500,
       },
     }}
   >
     <Fade in={open}>
       <Box sx={styleForMediaQuery} className="model_box">
         <div className="model_header">
         <div className='d-flex align-items-center gap-1' >
            <button  className='btn  blue-btn  px-1 py-0 fs-6'>< AiOutlinePlus className='mb-1' /></button>
              <p className="m-0"> Edit Supplier Inventory </p>
            </div>
           <div className="d-flex align-items-center gap-3 ">
             <button className="closebtn_cont" onClick={handleClose}>
               <RxCross2 className="closebox_logo" />{" "}
             </button>
           </div>
         </div>

     <Formik 
     initialValues={initialValue}
     onSubmit={handleSubmit}
     >
      {(formik ,values)=>(
      <Form>
        
         <div className="inputbox_cont">
        
         <div className="row">
           <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               SP Name
               </label>
              

               <input
                 type="text"
                 name="spName"
                 id=""
                 placeholder="Enter SP Person "
                 className="add_inut_field"
                 value={initialValue.spName}
      disabled
               />
               
       
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Contact Person
               </label>
               <input
                 type="text"
                 name="contactperson"
                 id=""
                 placeholder="Enter Contact Person "
                 className="add_inut_field"
                 value={initialValue.contactPerson}
      disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
            City
               </label>
               <input
                 type="text"
                 name="city"
                 id=""
                 placeholder="Enter City"
                 className="add_inut_field"
                 value={initialValue.city}
                 disabled
               />
             </div>
           </div>

           <div className='row' >
           <div className="col-md-4">
               <label htmlFor="" className="mb-1">
            State
               </label>
               <input
                 type="text"
                 name="state"
                 id=""
                 placeholder="Enter State"
                 className="add_inut_field"
                 value={initialValue?.state}
                 disabled

               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
                 {" "}
                 Item Code
               </label>
             
               <Select

options={optionListproduct}
placeholder="Select"
onChange={(e) => { formik.setFieldValue('product_Id' , e) ; hanlechangeitemcode(e) }}
value={formik.values.product_Id}
name='product_Id'
isSearchable={true}
className='react_inbuild_select'
/>
                 
               
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Item Description
               </label>
               <Field
                 type="text"
                 name="itemDescription"
                 value={initialValue.itemDescription}
                 placeholder="Enter Item description"
                 className="add_inut_field"
                 disabled
               />
             </div>
          
             </div>
           <div className="row">
           <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Lead Time 
               </label>
               <Field
                 type="number"
                 name="leadTime"
                 placeholder="Enter Lead Time"
                 className="add_inut_field"
              

                 value={formik.values.leadTime}
                 onChange={(e) => formik.setFieldValue('leadTime' , e.target.value)}
               />
                  
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               Factor of Safety
               </label>
               <Field
                 type="text"
                 name="factoryOfSafety"
                 placeholder="Enter Factor of Safety"
                 className="add_inut_field"
                 value={initialValue.factoryOfSafety}

                 disabled
               />
             </div>
             <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               TOG
               </label>
               <Field
                 type="number"
                 name="tog"
                 placeholder="Enter TOG (Optional) "
                 className="add_inut_field"
                
               />
             </div>
          
             </div>
           <div className="row">
           <div className="col-md-4">
               <label htmlFor="" className="mb-1">
               On Hand Stock
               </label>
               <Field
                 type="number"
                 name="onHandStock"
                 placeholder="Enter On hand Stock"
                 className="add_inut_field"
               />
             </div>
                
          {/* <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            Qualified Demand
            </label>
            <Field
              type="number"
              name="qualifiedDemand"
              placeholder="Enter Qualified Demand"
              className="add_inut_field"
             
            />
          </div> */}
          <div className="col-md-4">
            <label htmlFor="" className="mb-1">
            MOQ
            </label>
            <Field
              type="number" 
              name="moq"
              placeholder="Enter MOQ"
              className="add_inut_field"  
              value={formik.values.moq}
              onChange={(e) => formik.setFieldValue('moq' , e.target.value)}

            />
          </div>
           </div>
       
      
      
        
           <div className="addbox_btn_cont my-4 mt-3">
             <button  
               disabled={disableAddButton == true} type='submit'
              >Save</button>
           
           </div>
         </div>
        
         </Form>
          )}
         </Formik>
       </Box>
     </Fade>
   </Modal>
    </div>
  );
}
