import React , {useState , useEffect} from 'react'
import {LuSearch} from "react-icons/lu"
import Pagination from "react-mui-pagination";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { FaSort } from "react-icons/fa";

import store from '../../../../Redux/MyRedux/store';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import sorting_logo_img from '../../../../Assets/table/shortarrow.png'
import {FaRegRectangleList} from 'react-icons/fa6'
import {BiCloudUpload} from 'react-icons/bi'
import { rotexConsumptionList, rotexConsumptionStatusUpdate } from '../../../../Redux/MyRedux/action/phase3';



function InactiveConsumptionMasterTable() {



  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState("");
  const [total_count, setTotalCount] = useState(0);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const [recordperpage, setRecordPerPage] = React.useState(1000);
  const [listdata, setListdata] = useState([]);
  const channelPartnerId = localStorage.getItem("channelPartnerId")

  const getdata = () => {
    const data = {
      search: search,
      recordPerPage: recordperpage,
      currentPage: page,
      orderBy: ["createdAt"],
      itemCode: [],
    itemDescription: [],
    productName: [],
    subCategoryName: [],
    categoryName: [],
    qty : [],
    // channelPartnerId:channelPartnerId,
    active:false
    };


    try {
      store.dispatch(rotexConsumptionList(data)).then((res) => {
        if (res?.isError == false) {
          if(res?.data[0]?.docs.length >0) {
          setListdata(res?.data[0]?.docs);
          setTotalCount(res?.data[0]?.paginate[0]?.totalDocs || 0);
          }
          else {
            setListdata([]);
            setTotalCount(0);
          }
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  useEffect(() => {
    getdata();
  }, [recordperpage ,page ,search]);


  const handleDownloadEXcel = () => {
    // Custom headers
    const headers = [
      "Customer Id",
      "Cp name",
      " Item Code",
      " Item Description",
      "Product Name ",
      " Sub-category ",
      " Sub-category Id",
      "Category",
      "Category Id",
      "Qty",
     
    ];

    // Map data to a new array with custom headers
    const dataWithCustomHeaders = [
      headers,
      ...listdata.map((item) => [
        item.channelPartnerId,
        item.cpName,
        item.itemCode,
        item.itemDescription,
        item.productName,
        item.subCategoryName,
        item.subcategoryid,
        item.categoryName,
        item.categoryid,
        item.qty,
      ]),
    ];

    // Generate Excel file
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet(dataWithCustomHeaders);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Save file
    const fileData = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(fileData, "data.xlsx");
  };


const navigate = useNavigate();

const handleUpdateConsumptionStatus = (id ,value) =>{
  const data  = {
    active :value
  }
  store.dispatch(rotexConsumptionStatusUpdate(data ,id)).then((res)=>{
    if (res?.isError == false) {
      toast.success(res?.message)
      getdata()
    }
    else {
      toast.error(res?.message)

    }
    
  })
}

const toggleStatus = (userId) => {
  // Find the user by userId in the 'data' state
  const userToUpdate = listdata.find((user) => user._id === userId);

  if (userToUpdate) {
    const updatedStatus = !userToUpdate.active;
    handleUpdateConsumptionStatus(userId,updatedStatus )
  }
};

const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
const sortBy = (key) => {
  let direction = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    direction = 'descending';
  }
  const sortedData = [...listdata].sort((a, b) => {
    if (a[key] < b[key]) {
      return direction === 'ascending' ? -1 : 1;
    }
    if (a[key] > b[key]) {
      return direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  setListdata(sortedData);
  setSortConfig({ key, direction });
};
const [isHovered , setIsHovered] = useState(false)


  return (
    <div className="page">
        <div className="filter_list d-flex flex-row align-items-center justify-content-between mb-1">
            <ul className="list-inline mb-0">
            
                <li className=' page_heading' > Inactive Consumption Master</li>
             </ul>
             <form className="d-none d-lg-inline-block form-inline navbar-search ms-auto">
            <div className="input-group">
                <input type="search" className="form-control bg-white border-0 small page_search" placeholder="Search here" aria-label="Search" aria-describedby="basic-addon2"  onChange={(e) =>setSearch(e.target.value)  } />
                <div className="input-group-append">
                    <button className="btn bg-white" type="button">
                      
                        < LuSearch className='search_logo' />
                    </button>
                </div>
            </div>
        </form>
        <div className="d-flex gap-2 flex-wrap ms-1">
    {/* <div className="button-container" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <button className={`button ${isHovered ? 'hovered' : ''}`}
          onClick={() => handleDownloadEXcel()}
      >
        <span>Excel Export</span>
        <div className='pageheader_icons_container' >
        <BiCloudUpload className='pageheader_react_icons' />
        </div>
      </button>
    </div> */}
  
         
        </div>
        </div>
      <div className='page_header_slider_container justify-content-end' >
 
  
       
       <div class="">
        
            <button className='inactive_page_btn' 
       onClick={() => navigate(-1)}> <FaRegRectangleList className='list_icons' /> <span> Active Consumptions List </span> </button>

        </div> 

        </div> 
        
        <div className='table-container mt-1'>
            <table className="table ">
                <thead>
                    <tr>
                    
                        <td scope="col">Sr. No</td>
                      
                        {/* <td scope='col' > <div className="th_common">
                  <span> CP Name </span>
                  <img src={sorting_logo_img} alt="" 
                  style={{ cursor :"pointer" }}  onClick={() => sortBy('cpName')} 
                  />
                </div> </td> */}
               
                        <td scope="col"> <div className="th_common">
                  <span> Item Code</span>
                  <img src={sorting_logo_img} alt="" 
                  style={{ cursor :"pointer" }}  onClick={() => sortBy('itemCode')} 
                  />
                </div></td>
                        <td scope="col"> <div className="th_common">
                  <span> Item Description</span>
                  <img src={sorting_logo_img} alt="" 
                  style={{ cursor :"pointer" }}  onClick={() => sortBy('itemDescription')} 
                  />
                </div> </td>
                        <td scope="col"> 
                        <div className="th_common">
                  <span> Product Name</span>
                  <img src={sorting_logo_img} alt="" 
                  style={{ cursor :"pointer" }}  onClick={() => sortBy('productName')} 
                  />
                </div> 
                         </td>
                        <td scope="col">
                        <div className="th_common">
                  <span> Category</span>
                  <img src={sorting_logo_img} alt="" 
                  style={{ cursor :"pointer" }}  onClick={() => sortBy('categoryName')} 
                  />
                </div> 
                           </td>
                        <td scope="col"> 
                        <div className="th_common">
                  <span> Sub-Category</span>
                  <img src={sorting_logo_img} alt="" 
                  style={{ cursor :"pointer" }}  onClick={() => sortBy('subCategoryName')} 
                  />
                </div> 
                         </td>
                        <td scope="col"> 
                        <div className="th_common">
                  <span> QTY</span>
                  <img src={sorting_logo_img} alt="" 
                  style={{ cursor :"pointer" }}  onClick={() => sortBy('qty')} 
                  />
                </div> 
                         </td>
                         <td scope='col' > <div className="th_common">
                  <span> Status </span>
                  
                </div> </td>
                      
                    </tr>
                    <br />
                </thead>
                <tbody>
                  
                {listdata?.map((value , i)=>(
                  <>
                      <tr key={i}>
                        <td> { i+1  } </td>
                        {/* <td>{value?.cpName}</td> */}
                        <td>{value?.itemCode}</td>
                        <td>{value?.itemDescription}</td>
                        <td>{value?.productName}</td>
                        <td>{value?.categoryName}</td>
                        <td>{value?.subCategoryName}</td>
                        <td>{value?.qty}</td>

                     
                        <td>   
                   
                   <div className="form-check form-switch">
                  <input
                    className="form-check-input addbox_radio"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={value?.active}
                    onChange={() => toggleStatus(value?._id)}
                  />
               
                
                </div></td>

                      </tr>
                    <br />
                    </>
                    ))
                  }
                  

                    
                      
                </tbody>
            </table>
        </div>

        <div className="d-md-flex align-items-center justify-content-between mt-3 px-3">
          <div className="d-flex align-items-center justify-content-center my-4 my-md-0">
           
            <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px'}} > Showing 01 - {recordperpage > total_count ? total_count : recordperpage } 
            
              &nbsp; of {total_count} Results</p>
          </div>

          <div className="d-flex   justify-content-center align-items-center">
          <Pagination  page={page} setPage={handleChange} total={total_count} className='pagination ' 
            perPage={recordperpage} 
          
          />

          </div>
          <div className='d-flex align-items-center' >
           <p className="p3 m-0" style={{fontFamily :"Poppins" , fontSize:'14px' }}  >Total Count</p>
            <select className="w-auto form-select form-select-sm p3 shadow-none mx-3 " style={{fontFamily :"Poppins"}}  value={recordperpage} onChange={(e) => setRecordPerPage(e.target.value)} >
            <option value="5">5</option>
            
            {
            total_count > 5 && 
         
           <option value="10">10</option>
           }
            {
            total_count > 10 && 
         
           <option value="25">25</option>
           }
            {
            total_count > 25 && 
         
           <option value="50">50</option>
           }
           {
            total_count > 50 && 
         
           <option value="100">100</option>
           }
           {
            total_count > 100 && 
         
           <option value="500">500</option>
           }
           {
            total_count > 500 && 
         
           <option value="1000">1000</option>
           }
            </select>
            </div>
        </div>
        
       
    </div>
  )
}

export default InactiveConsumptionMasterTable;
